"use client";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { AccountTypeData } from "../../model";
import { useContext, useEffect } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  AccordionComponent,
  AddNewButton,
  CardContainer,
} from "@/components/common";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  ACCT_TYPE_REL_SUMMARY_LIST,
  IC_ENTITY,
  SIDEBAR_STATUS,
  deepCopy,
  shouldUpdateWorkflowStatus,
} from "@/utils";
import { IconEdit } from "public/assets";
import { Box, Flex, Text, useToast } from "@/components/ChakraUiManager";
import Link from "next/link";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import "@/components/transaction-code/entry-setup/entry-summary/TcEntrySummary.scss";
import { updateAccountTypeWithPut } from "../../ic-config-api-service";

type AccountTypeSummaryProp = {
  data: { formData?: AccountTypeData | null };
};
const initialFormValues: any = {
  rel: [],
};
export default function AccountTypeSummary(props: AccountTypeSummaryProp) {
  const {
    data: { formData },
  } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    navigateTo,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    setSideBarCurrentIndex,
    getCurrentStageStatusName,
    sideBarMainMenuIndex,
    entityWorkflowData,
    configPageTitle,
    selectedBtnType,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const params = useSearchParams();
  const entityType = params?.get("entityType");
  const toast = useToast();
  const toastId = "account-type-summary";

  const onSubmit = async (values: any, actions: any) => {
    const existingFormData = deepCopy(formData);
    const currentStage = getCurrentStageStatusName();
    // Update workflow if required
    if (
      !dirty &&
      shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
    ) {
      await updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: existingFormData?.acctType!,
        stage: IC_ENTITY.account_type,
        status: currentStage,
      });
    }
    if (dirty && existingFormData?.rels?.length) {
      const commonToastOptions = {
        toast,
        toastId,
      };
      existingFormData.rels = values.rels;
      const response = await updateAccountTypeWithPut({
        formData: {
          ...existingFormData,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },

        stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
          ? currentStage
          : "",
      });
    }
    updateFormStatus?.(SIDEBAR_STATUS.completed);
    updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
    // User navigates to respective page on sidebar click
    if (selectedBtnType === "sidebar") {
      navigateToNextPage?.("sidebar");
      return;
    }
    if (params?.get("initialFlow")) {
      navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
    } else if (params?.get("entityType")) {
      navigateTo(
        `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${existingFormData?.acctType}&entityType=${IC_ENTITY.account_type}`
      );
    } else {
      navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
    }
  };

  const formInitialVal = formData?.rels?.length
    ? deepCopy(formData)
    : initialFormValues;

  const formikConfigs = useFormik({
    onSubmit,
    initialValues: formInitialVal,
  });
  // using useFormik hook from Formik Library
  const { values, handleSubmit, setFieldValue, setValues, dirty } =
    formikConfigs;

  const getUrlQueryParam = (index: number) => {
    const defaultUrl = entityType
      ? `${QUERY_PARAM_KEY.ENTITY_ID}=${entityId}&entityType=${IC_ENTITY.account_type}`
      : `${QUERY_PARAM_KEY.ENTITY_ID}=${entityId}`;
    return `?${defaultUrl}&${QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX}=${index}`;
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <FormikProvider value={formikConfigs}>
      <form onSubmit={handleSubmit} noValidate id="finxact-form">
        <FieldArray
          name="rels"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              {values?.rels?.map((rel: any, index: number) => (
                <CardContainer key={index}>
                  <AccordionComponent
                    key={`accContainer${index}`}
                    accordionTitle={`Account type relationship ${index + 1}`}
                    limitName={rel.entryName}
                    deleteIcon
                    onDeleteHandler={() => {
                      remove(index);
                    }}
                    isExpand={index === 0 ? 0 : 1}
                  >
                    <Flex
                      className="app-form-field-container"
                      direction={"column"}
                      gap={13}
                    >
                      {ACCT_TYPE_REL_SUMMARY_LIST.map(
                        (item, stepIndex: number) => (
                          <Flex
                            key={item.name}
                            className="tc-entry-summary-item"
                            justifyContent={"space-between"}
                            gap={13}
                            p={9}
                          >
                            <Text>{item.name}</Text>
                            <Link
                              href={`${item.href}${getUrlQueryParam(index)}`}
                              onClick={() => {
                                setSideBarCurrentIndex?.(
                                  sideBarMainMenuIndex,
                                  stepIndex
                                );
                              }}
                            >
                              <Image src={IconEdit} alt="edit icon" />
                            </Link>
                          </Flex>
                        )
                      )}
                    </Flex>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Link
                href={`${ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS}${getUrlQueryParam(values?.rels?.length)}`}
              >
                <AddNewButton
                  label={
                    values?.rels?.length
                      ? "Add another relationship"
                      : "Add a relationship"
                  }
                  onClick={() => {}}
                />
              </Link>
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
}
