"use client";
import React, { useContext, useEffect, useState } from "react";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  RadioButtonGroup,
  TimeField,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  InterestAccrualDetailsProps,
  interestAccrual,
  getValidationSchema,
} from "./InterestAccrualValidation";
import { useFormik } from "formik";
import { Option } from "@/utils/types";
import { createInterestComponent } from "../../product-config-client-service";
import { InterestComponentData, PostSchema } from "../../../model/types";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type Props = {
  data: {
    negativeAccruedOptions: Option<number>[];
    accrualCarryoverOptions: Option<number>[];
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};
const InterestAccrual = (props: Props) => {
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  // Form Submit Handler
  const onSubmit = async (
    values: InterestAccrualDetailsProps,
    actions: any
  ) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            negAccrOpt: values.negAccrOpt,
            accrCalcTm: values.accrCalcTm,
            accrCarryOverOpt: values.accrCarryOverOpt,
          },
          toastOptions: {
            toast,
            toastId: "interest-accrual",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      accrCarryOverOpt:
        formData?.accrCarryOverOpt ?? interestAccrual.accrCarryOverOpt,
      accrCalcTm: formData?.accrCalcTm ?? interestAccrual.accrCalcTm,
      negAccrOpt: formData?.negAccrOpt ?? interestAccrual.negAccrOpt,
    },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const interestAccrualHandler = (key: string) => (value: string | number) => {
    setFieldValue(key, value);
  };
  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is there a negative accrued option?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("negAccrOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.negAccrOpt}
            onChange={interestAccrualHandler("negAccrOpt")}
            radioPropList={props.data.negativeAccruedOptions}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.negAccrOpt && touched.negAccrOpt && (
            <ErrorAlert>
              <span>{errors.negAccrOpt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the accrual calculation time?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accrCalcTm")
                ?.tooltip_text_main
            }
          />
          <TimeField
            ariaLabel="accural calculation time"
            value={values.accrCalcTm}
            onBlur={handleBlur}
            onChange={interestAccrualHandler("accrCalcTm")}
          />
          {errors.accrCalcTm && touched.accrCalcTm && (
            <ErrorAlert>
              <span>{errors.accrCalcTm}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Select a interest accrual carryover option."
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accrCarryOverOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.accrCarryOverOpt}
            onChange={interestAccrualHandler("accrCarryOverOpt")}
            radioPropList={props.data.accrualCarryoverOptions}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.accrCarryOverOpt && touched.accrCarryOverOpt && (
            <ErrorAlert>
              <span>{errors.accrCarryOverOpt}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default InterestAccrual;
