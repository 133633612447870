"use client";

import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "@/components/common";
import { CourseProfileInfoCard } from "./course-profile-box/course-info-card/CourseProfileInfoCard";
import RecommendedCourseBox from "./course-profile-box/CourseProfileBox";
import { CourseDetail } from "@/utils";
import {
  getCourseDetails,
  addBookmark,
  deleteBookmark,
  getEnrollmentById,
} from "@/client-api-manager/alm-api";
import { Heading, VStack, Image } from "@chakra-ui/react";
import { noCatalogFound } from "public/assets";

interface CourseProfileProps {
  fetchData: () => Promise<CourseDetail[]>;
  title: string;
  subtitle: string;
  viewAll?: boolean;
  noResultsFoundText?: string;
  reload?: boolean;
  setReload?: (value: boolean) => void;
}

const CourseProfile: React.FC<CourseProfileProps> = ({
  fetchData,
  title,
  subtitle,
  viewAll = false,
  noResultsFoundText,
  reload,
  setReload,
}) => {
  const [courseDetails, setCourseDetails] = useState<CourseDetail[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const loadData = useCallback(async () => {
    setError(null);
    try {
      const coursesData = await fetchData();
      await processEnrollments(coursesData);
    } catch (error) {
      setError(
        "We're working on fetching the course details. Thank you for your patience."
      );
    } finally {
      setIsLoading(false);
    }
  }, [fetchData]);

  useEffect(() => {
    loadData();
  }, [lastUpdated, loadData]);

  useEffect(() => {
    if (reload) {
      loadData();
      setReload?.(false);
    }
  }, [reload, setReload, loadData]);

  const processEnrollments = async (courses: CourseDetail[]) => {
    const enrollmentDetailsPromises = courses
      .filter((course) => course.isEnrolled)
      .map((course) => getEnrollmentById(course.enrollmentId));
    const enrollmentDetails = await Promise.all(enrollmentDetailsPromises);

    const updatedCourseDetails = courses.map((course) => {
      const enrollmentDetail = enrollmentDetails.find(
        (detail) => detail.data.id === course.enrollmentId
      );
      return {
        ...course,
        enrollmentInfo: enrollmentDetail
          ? {
              ...enrollmentDetail.data.attributes,
              id: enrollmentDetail.data.id,
            }
          : undefined,
      };
    });

    setCourseDetails(updatedCourseDetails);
  };

  const toggleBookmark = async (courseId: string) => {
    try {
      const courseIndex = courseDetails.findIndex(
        (course) => course.id === courseId
      );
      if (courseIndex > -1) {
        const course = courseDetails[courseIndex];
        course.isBookmarked
          ? await deleteBookmark(courseId)
          : await addBookmark(courseId);
        const updatedCourse = await getCourseDetails(courseId);
        setCourseDetails((prev) =>
          prev.map((c, index) =>
            index === courseIndex
              ? {
                  ...c,
                  isBookmarked: updatedCourse.data.attributes.isBookmarked,
                }
              : c
          )
        );
        setReload?.(true);
      }
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  if (error) return <div>{error}</div>;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <CardContainer>
      <RecommendedCourseBox
        title={title}
        subtitle={subtitle}
        viewAll={viewAll && courseDetails.length > 5}
      >
        {courseDetails.length ? (
          courseDetails.map((course) => (
            <CourseProfileInfoCard
              key={course.id}
              courseId={course.id}
              ratingValue={course.rating?.averageRating}
              title={course.name}
              description={course.description}
              authorNames={
                Array.isArray(course.authorNames)
                  ? course.authorNames.join(", ")
                  : ""
              }
              imageUrl={course.imageUrl}
              timeline={course.timeline}
              date={course.date}
              isBookmarked={course.isBookmarked}
              progressPercent={course.enrollmentInfo?.progressPercent ?? 0}
              toggleBookmark={() => toggleBookmark(course.id)}
              label={course.loType}
            />
          ))
        ) : (
          <VStack w={"100%"} justifyContent={"center"} mt={20} gap={6}>
            <Image src={noCatalogFound.src} alt={"No courses found"} mb={4} />
            <Heading as="h3" fontSize={24}>
              {noResultsFoundText}
            </Heading>
          </VStack>
        )}
      </RecommendedCourseBox>
    </CardContainer>
  );
};

export default CourseProfile;
