import { ProductType } from "../model/product-models";

interface FeatureLibraryObject {
  featureId: number | string;
  featureName: string;
  featureType: string;
  featureDesc: string;
}
interface AdditionalTemplateFeatureObject {
  featureId?: number | string;
  componentName: string;
  featureDesc: string;
  componentClass: string;
  version?: number;
  featureType?: string;
  isTemplated?: boolean;
}
interface AdditionalFeaturesState {
  templatedFeatures: AdditionalTemplateFeatureObject[];
  featuresLibrary: FeatureLibraryObject[];
  addedFeatures: AdditionalTemplateFeatureObject[];
  addedLibrary: FeatureLibraryObject[];
}
const initialState: AdditionalFeaturesState = {
  templatedFeatures: [],
  featuresLibrary: [],
  addedFeatures: [],
  addedLibrary: [],
};

const additionalFeatureReducer = (
  state: AdditionalFeaturesState,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case "ADDED_TO_FEATURES":
      if (payload.key === "Added_Features") {
        return {
          ...state,
          addedFeatures: [...state.addedFeatures, { ...payload.addedFeatures }],
        };
      } else {
        return {
          ...state,
          addedLibrary: [...state.addedLibrary, { ...payload }],
        };
      }

    case "REMOVE_FROM_FEATURES":
      if (payload.key === "Added_Features") {
        return {
          ...state,
          addedFeatures: state.addedFeatures?.filter(
            (addedFeature) =>
              addedFeature.componentClass !==
                payload.addedFeatures.componentClass &&
              addedFeature.componentName !== payload.addedFeatures.componentName
          ),
        };
      } else {
        return {
          ...state,
          addedLibrary: state.addedLibrary?.filter(
            (addedLibrary) => addedLibrary.featureId !== payload.featureId
          ),
        };
      }
    case "UPDATE_EXISTING_FEATURES":
      return {
        ...state,
        addedFeatures: payload.data,
      };
    case "UPDATE_FEATURE_LIBRARY":
      return {
        ...state,
        featuresLibrary: payload.data,
      };
    case "UPDATE_TEMPLATED_FEATURES":
      return {
        ...state,
        templatedFeatures: payload.data,
      };
    default:
      return state;
  }
};
export { additionalFeatureReducer as reducer, initialState };
export type {
  AdditionalFeaturesState,
  AdditionalTemplateFeatureObject,
  FeatureLibraryObject,
};
