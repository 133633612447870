"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CoupleRadioWithDropdown,
  ErrorAlert,
  StaticText,
  DurationInput,
  SelectDropdown,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  AdditionalSpecificationsDetails,
  AdditionalSpecificationsProps,
  getValidationSchema,
} from "./AdditionalSpecificationsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { FinancialInstitutionData } from "../../model";
import {
  IC_ENTITY,
  MAIN_FORM_ID,
  Option,
  shouldUpdateWorkflowStatus,
  RjsfData,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils";
import { updateBankParameters } from "../../ic-config-api-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import { roleUiSchema } from "@finxact/finxact-shared-ui";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    depositChargeOptions: Option<string>[];
    roles: Option<string>[];
    formData: FinancialInstitutionData | null;
    addNewRoleSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    bankParamOptionsData: PostSchema;
  };
};

const AdditionalSpecifications = (props: Props) => {
  const {
    depositChargeOptions,
    roles,
    formData,
    addNewRoleSchema,
    addNewOptionEndpoint,
    bankParamOptionsData,
  } = props.data;
  const toast = useToast();

  const onSubmit = async (values: AdditionalSpecificationsProps) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    if (formData) {
      const res = await updateBankParameters({
        formData: {
          _vn: formData._vn,
          _Id: formData._Id,
          // PATCH Feilds
          tenureDur: values.tenureDur,
          garnishOffsetDur: values.garnishOffsetDur,
          depChrgOffCloseReason:
            values.depChrgOffCloseReason ??
            AdditionalSpecificationsDetails.depChrgOffCloseReason,
          userRole: values.userRole,
        },
        toastOptions: {
          toast: toast,
          toastId: "additional-spec",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
          ? currentStage
          : "",
      });
      if (res) {
        navigateToNextPage?.();
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      tenureDur: formData?.tenureDur ?? "",
      garnishOffsetDur: formData?.garnishOffsetDur ?? "",
      userRole: formData?.userRole ?? "",
      depChrgOffCloseReason: formData?.depChrgOffCloseReason as number,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  // component handler
  const tenureDurationInputHandler = (value: string | number) => {
    // Below setFieldValue is coming from Formik
    setFieldValue("tenureDur", value);
  };

  const garnishmentOffsetDurationInputHandler = (value: string | number) => {
    // Below setFieldValue is coming from Formik
    setFieldValue("garnishOffsetDur", value);
  };

  const authorizationRoleHandler = (value: any) => {
    // Below setFieldValue is coming from Formik
    setFieldValue("userRole", value);
  };

  const existingDepositChargeOffSelectHandler = (
    value: string | string[] | number
  ) => {
    setFieldValue("depChrgOffCloseReason", value);
  };

  const schema = useMemo(() => {
    const { actions, name } = addNewRoleSchema;
    return { name, ...actions.POST };
  }, [addNewRoleSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("userRole", response.userRole);
  };

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <CardContainer>
        <Box>
          <DurationInput
            labelName="What is the tenure duration?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tenureDur")
                ?.tooltip_text_main
            }
            value={values.tenureDur}
            onChange={tenureDurationInputHandler}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter tenure duration"}
            modalLabelName="What is the tenure duration?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tenureDur")
                ?.tooltip_text_main
            }
          />
          {errors.tenureDur && touched.tenureDur && (
            <ErrorAlert>
              <span>{errors.tenureDur}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box>
          <DurationInput
            labelName="What is the garnishment offset duration?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("garnishOffsetDur")
                ?.tooltip_text_main
            }
            value={values.garnishOffsetDur}
            onChange={garnishmentOffsetDurationInputHandler}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter garnishment offset duration"}
            modalLabelName="What is the garnishment offset duration?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("garnishOffsetDur")
                ?.tooltip_text_main
            }
          />
          {errors.garnishOffsetDur && touched.garnishOffsetDur && (
            <ErrorAlert>
              <span>{errors.garnishOffsetDur}</span>
            </ErrorAlert>
          )}
        </Box>

        <div className="app-form-field-container">
          <AppFormLabel
            labelName="What is the system GL journal date?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("glJourDt")
                ?.tooltip_text_main
            }
          />
          <StaticText textValue="N/A" />
        </div>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the authorization role?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("userRole")
                ?.tooltip_text_main
            }
            labelFor="authorization_role"
          />
          <CoupleRadioWithDropdown
            primaryOptionValue={values.userRole}
            primaryRadioName={"Use existing authorization role"}
            secondaryRadioName={"Add new authorization role"}
            handlePrimaryOptionSelect={authorizationRoleHandler}
            placeHolderName="Select existing Role"
            dropdownList={roles?.map((d: { label: string }) => d.label)}
            onBlur={handleBlur}
            id="authorization_role"
            schema={schema}
            uiSchema={roleUiSchema}
            onAddNewSubmit={addNewOption}
          />
          {errors.userRole && touched.userRole && (
            <ErrorAlert>
              <span>{errors.userRole}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the deposit charge-off close reason code?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("depChrgOffCloseReason")
                ?.tooltip_text_main
            }
            labelFor="reason_code-input"
          />
          <SelectDropdown
            value={values.depChrgOffCloseReason}
            onChange={existingDepositChargeOffSelectHandler}
            dropdownList={depositChargeOptions}
            id="reason_code"
          />
          {errors.depChrgOffCloseReason && touched.depChrgOffCloseReason && (
            <ErrorAlert>
              <span>{errors.depChrgOffCloseReason}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AdditionalSpecifications;
