import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Rollover-Product-Interest
interface RolloverProductInterestRatesProps {
  rollProd: string;
}
// Defining Intitial Value For Rollover-Product-Interest
const rolloverProductInterestDetails: RolloverProductInterestRatesProps = {
  rollProd: "",
};

const rolloverProductInterestDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rollProd"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RolloverProductInterestRatesProps };
// Exporting Validation Schema and Intial value
export {
  rolloverProductInterestDetails,
  rolloverProductInterestDetailsSchema as getValidationSchema,
};
