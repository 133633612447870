import * as Yup from "yup";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { GENERIC_ERROR } from "@/components/data/error-data";
//Defining Interface For corresponding-network-identification
interface GeneralLimitComponentDetailsProps {
  componentName: string;
  acctMinimumBalance: SplitBtnInputValType;
  minimumRequiredBalance: string;
  minimumAmountToOpen: string;
  maximumBalance: string;
  deminimisAmount: string;
  restrictCredits: SplitBtnInputValType;
  restrictCreditsAfterFundingExpiration: SplitBtnInputValType;
  restrictDebits: SplitBtnInputValType;
  minimumBalanceFee: string;
  minimumBalanceOption: number;
}
// Defining Intitial Value For corresponding-network-identification
const generalLimitComponentDetails: GeneralLimitComponentDetailsProps = {
  componentName: "",
  acctMinimumBalance: undefined,
  minimumRequiredBalance: "",
  minimumAmountToOpen: "",
  maximumBalance: "",
  deminimisAmount: "",
  restrictCredits: undefined,
  restrictCreditsAfterFundingExpiration: undefined,
  restrictDebits: undefined,
  minimumBalanceFee: "",
  minimumBalanceOption: -1,
};
// YUP LIBRARY :Defining Schema For validation of corresponding-network-identification
const generalLimitComponentDetailsSchema = Yup.object().shape({
  componentName: Yup.string().required(GENERIC_ERROR.required),
  acctMinimumBalance: Yup.boolean(),
  minimumRequiredBalance: Yup.string().when("acctMinimumBalance",{
    is: 'selectLeft',
    then: (schema) => 
    schema.required(GENERIC_ERROR.required)
  }),
  minimumAmountToOpen: Yup.number().required(GENERIC_ERROR.required),
  maximumBalance: Yup.number().required(GENERIC_ERROR.required),
  deminimisAmount: Yup.number().required(GENERIC_ERROR.required),
  restrictCredits: Yup.boolean(),
  restrictCreditsAfterFundingExpiration: Yup.boolean(),
  restrictDebits: Yup.boolean(),
  minimumBalanceFee: Yup.string().required(GENERIC_ERROR.required),
  minimumBalanceOption: Yup.number()
    .min(0, GENERIC_ERROR.required)
    .required(GENERIC_ERROR.required),
});
// Exporting Interface
export type { GeneralLimitComponentDetailsProps };
// Exporting Validation Schema and Intial value
export {
  generalLimitComponentDetails,
  generalLimitComponentDetailsSchema as validationSchema,
};
