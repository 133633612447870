"use client";
import { Box, Button, Divider, Link, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import { CardContainer } from "@/components/common";
import Image from "next/image";
import { warningIcon } from "public/assets";
import { CRT_PROGRESS_STATUS_JSON } from "@/components/data/form-data";
import {
  ENTITLEMENT_STATUS,
  SUMMARY_LANDING_PAGE_TYPES,
  SUMMARY_LANDING_SCREEN_STATUS,
} from "@/utils";
import InProgress from "@/components/institutional-configuration/ic-summary-landing-page/in-progress-page/InProgress";
import ReadyToStart from "@/components/institutional-configuration/ic-summary-landing-page/ready-to-start-page/ReadyToStart";
import UnmetPreRequisites from "@/components/institutional-configuration/ic-summary-landing-page/unmet-pre-requisites-page/UnmetPreRequisites";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import "../../institutional-configuration/ic-summary-landing-page/IcSummaryLandingPage.scss";
import CompletedPage from "./completed-page/CompletedPage";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { ResourceSectionProps } from "@/models/global-models";
import { HELPFUL_RESOURCES_SLUG } from "@/utils/constants";
import { getHelpfulResourcesConfig } from "@/prismic-api/prismic-api-service";
import { buildURL } from "@/prismic-api/common-service";
import { ENTITLEMENT_MESSAGE } from "@/components/data/error-data";
import { useSelector } from "react-redux";
import { getAppPrismicClient, getEntitlement } from "@/store";

interface CrtSummaryLandingPageProps {
  pageName: SUMMARY_LANDING_SCREEN_STATUS;
  nameOfStep?: string; //In CRT Summary Landing unmet page name of the step is required in toast
  componentInProgress?: number; //In CRT Summary Landing inprogress page we need to provide which step in progress Numbers are [1-6].
  resumeLink?: string; //In CRT Summary Landing inprogress page there is a resume button which requires a link of page
}

const CrtSummaryLandingPage = (props: CrtSummaryLandingPageProps) => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [closeWarning, setCloseWarning] = useState<boolean>(false);
  const LEFT_BADGE = "Networks";
  const RIGHT_BADGE = "Product Configuration";
  const TITLE = SUMMARY_LANDING_PAGE_TYPES.crt;
  const [resources, setResources] = useState<ResourceSectionProps>({});
  const toast = useToast();
  const entitlement = useSelector(getEntitlement);
  let content;
  switch (props.pageName) {
    case SUMMARY_LANDING_SCREEN_STATUS.unmet:
      content = (
        <UnmetPreRequisites
          configurationProps={{
            title: TITLE,
            leftBadgeLabel: LEFT_BADGE,
            rightBadgeLabel: RIGHT_BADGE,
            headingText:
              "Come back after finishing Networks setup to proceed with customer relationship types",
            paragraphText:
              "Customer relationship configuration enables you to define and manage the types of relationships customers can have within the system, ensuring tailored interactions and personalized services",
          }}
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.in_progress:
      content = (
        <InProgress
          data={CRT_PROGRESS_STATUS_JSON}
          progressIndex={props.componentInProgress}
          resumeLink={props.resumeLink}
          title={TITLE}
          leftBadgeLabel={LEFT_BADGE}
          rightBadgeLabel={RIGHT_BADGE}
          headingText={"Your customer relationship type is in-progress"}
          paragraphText={
            "Complete the following to define a customer relationship type and shift forward to product configuration."
          }
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.not_started:
      content = (
        <ReadyToStart
          configurationProps={{
            title: TITLE,
            leftBadgeLabel: LEFT_BADGE,
            rightBadgeLabel: RIGHT_BADGE,
            headingText: "It’s time to set up your customer relationship types",
            paragraphText:
              "Customer relationship configuration enables you to define and manage the types of relationships customers can have within the system, ensuring tailored interactions and personalized services",
            link: `${ROUTE_PATH.CRT_WALKTHROUGH}`,
          }}
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.completed:
      content = <CompletedPage />;
      break;
    default:
      throw Error("Unknown selection: " + props.pageName);
  }
  async function getCrtSummaryResources() {
    const resourceData = await getHelpfulResourcesConfig(
      prismicClient,
      HELPFUL_RESOURCES_SLUG.crt,
    );
    await Promise.all([resourceData]).then((values) => {
      setResources(values[0] as ResourceSectionProps);
    });
  }

  useEffect(() => {
    if (!resources?.resourcesLinks?.length) {
      getCrtSummaryResources();
    }
  }, []);

  //Check for entitlement
  useEffect(() => {
    const userEntitlement = entitlement;
    if (userEntitlement?.crt === ENTITLEMENT_STATUS.noAccess) {
      if (!toast.isActive("entitlement"))
        toast({
          id: "entitlement",
          description: ENTITLEMENT_MESSAGE.noAccess,
          status: "error",
          duration: null,
        });
    } else {
      if (toast.isActive("entitlement")) toast.close("entitlement");
    }
  }, [entitlement]);

  return (
    <Box className="trans-code-landing-page">
      <>
        {!closeWarning &&
          props.pageName === SUMMARY_LANDING_SCREEN_STATUS.unmet && (
            <CardContainer customClass="toast-conatiner">
              <Box className="toast-icon">
                <Image src={warningIcon} alt="warning" />
              </Box>
              <Box className="toast-text">
                <Text as="h5">Outstanding pre-requisites</Text>
                <Text>Complete your {props.nameOfStep} to proceed below.</Text>
              </Box>
              <Box className="toast-link">
                <Link href={ROUTE_BASE_URL["DASHBOARD_PATH"]}>
                  <Button className="app-btn-link">Update</Button>
                </Link>
              </Box>
              <Box className="toast-close">
                <CloseIcon
                  onClick={() => setCloseWarning(true)}
                  className="close-icon"
                />
              </Box>
            </CardContainer>
          )}
      </>
      {content}
      <Box
        className={`${props.pageName === SUMMARY_LANDING_SCREEN_STATUS.in_progress ? "" : "product-image"}`}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <Divider className="bottom-divider" />
          <Text as="h4" className="resource-heading">
            {resources?.sectionTitle ||
              "Helpful customer relationship type resources"}
          </Text>
          {resources?.resourcesLinks?.length &&
            resources?.resourcesLinks.map((res: any, index: number) => (
              <CardContainer
                key={`resourceContainer${index}`}
                customClass="resource-container"
              >
                <div>
                  <Text className="resource-title">{res.link_title}</Text>
                  <Text className="resource-caption">{res.link_caption}</Text>
                </div>
                <Box className="resource-img">
                  {res.link_type === "prismic link" ? (
                    <Link
                      href={buildURL(
                        res?.prismic_link?.type,
                        res?.prismic_link?.uid,
                      )}
                    >
                      <ArrowForwardIcon className="resource-img-icon" />
                    </Link>
                  ) : res.link_type === "academy" ? (
                    <Link
                      href={
                        `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                        `${res?.academy_course_link}`
                      }
                    >
                      <ArrowForwardIcon className="resource-img-icon" />
                    </Link>
                  ) : (
                    <a
                      href={
                        res?.external_link?.link_type === "Document"
                          ? buildURL(
                              res?.external_link?.type,
                              res?.external_link?.uid,
                            )
                          : res?.external_link?.url
                      }
                    >
                      <ArrowForwardIcon className="resource-img-icon" />
                    </a>
                  )}
                </Box>
              </CardContainer>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CrtSummaryLandingPage;
