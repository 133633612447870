import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import {
  REGEX_PATTERN,
  generateRegexToRestrictNoOfDigits,
  generateYupSchema,
} from "@/utils";
import * as Yup from "yup";

//Defining Interface For Charge_offTiming_thresholds-setup
interface ChargeOffTimingThresholdsProps {
  chrgOffDays: string;
  chrgOffMin: string;
  chrgOffOpt: string;
}
// Defining Intitial Value For Charge_offTiming_thresholds-setup
const chargeOffTimingThresholdsDetails: ChargeOffTimingThresholdsProps = {
  chrgOffDays: "",
  chrgOffMin: "",
  chrgOffOpt: "",
};

const chargeOffTimingThresholdsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["chrgOffDays", "chrgOffMin", "chrgOffOpt"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { ChargeOffTimingThresholdsProps };
// Exporting Validation Schema and Intial value
export {
  chargeOffTimingThresholdsDetails,
  chargeOffTimingThresholdsSchema as getValidationSchema,
};
