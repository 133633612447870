"use client";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  SplitButton,
} from "@/components/common";
import { Box, Heading, useToast } from "@chakra-ui/react";
import {
  ProcessFlagsValueProps,
  getValidationSchema,
} from "./ProcessFlagsValidation";

import { useFormik } from "formik";

import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type ProcessFlagProps = {
  data: {
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
};
const ProcessFlags = (props: ProcessFlagProps) => {
  const toast = useToast();
  const toastId = "gpp-process-flag";
  const updateQueryParams = useQueryParams();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);
  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (values: ProcessFlagsValueProps, actions: any) => {
    const currentStage = "";
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        processFlags: {
          isRestrict: values.isRestrict,
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  const handleOnChange = (key: string) => (value: SplitBtnInputValType) => {
    setFieldValue(key, value);
  };
  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, setFieldValue, dirty } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      isRestrict: props.data.formData?.processFlags?.isRestrict ?? undefined,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION"]
        }?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);
  const formTitle = (
    <CommonTitle
      depositName="Financial Organization"
      featureName=""
      version=""
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
        "General Party Parameters - 7 of 9"
      }
      subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || ""}
    />
  );
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      {formTitle}
      <CardContainer>
        <Box className="app-form-field-container">
          <Heading as="h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || "Process Flags"}
          </Heading>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Restrictions?"
            labelFor="restriction"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("restriction")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isRestrict}
            onSelect={handleOnChange("isRestrict")}
          />
          {errors.isRestrict && (
            <ErrorAlert>
              <span>{errors.isRestrict}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default ProcessFlags;
