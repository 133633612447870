"use client";

import { TransCodeResponse } from "../../models";
import { Box, Flex, Heading, useToast } from "@/components/ChakraUiManager";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  CardContainer,
  CoupledFieldWithExpr,
  InputText,
  SplitBtnInputValType,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  DASHBOARD_ACCORDION_STATUS,
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_ORDER,
  TC_STAGE_STATUS,
  deepCopy,
  generateYupSchema,
  useQueryParams,
} from "@/utils";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import {
  constructTcEntryPayload,
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import { useSearchParams } from "next/navigation";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

interface TcEntryFoundationalDetailsProp {
  data: {
    formData: TransCodeResponse;
    accGroupOptions: FastDefaultDropdownProps[];
    tranCodeOptionsSchema: PostSchema;
  };
}

interface TcEntryFoundationalFormProp {
  comment: string;
  commentExpr: string;
  entryName: string;
  acctGroup: number | null;
  acctGroupExpr: string;
  acctNbr: string;
  acctNbrExpr: string;
  isDr: SplitBtnInputValType;
  isGlDtl: SplitBtnInputValType;
  showAdv?: SplitBtnInputValType;
  trnCode?: string;
}

const initialFormValues: TcEntryFoundationalFormProp = {
  comment: "",
  commentExpr: "",
  entryName: "",
  acctGroup: null,
  acctGroupExpr: "",
  acctNbr: "",
  acctNbrExpr: "",
  isDr: undefined,
  isGlDtl: false,
  showAdv: undefined,
};

export default function TcEntryFoundationalDetails(
  props: TcEntryFoundationalDetailsProp
) {
  const {
    data: { formData, accGroupOptions, tranCodeOptionsSchema },
  } = props;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    navigateTo,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    entityWorkflowData,
    setSideBarCurrentIndex,
    sideBarMainMenuIndex,
    tooltipFlyoutDetails,
    sideBarMenuList,
    updateSidebarMenu,
    sideBarSubMenuIndex,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "tc-entry-foundational-details";
  const updateQueryParams = useQueryParams();
  const queryParams = useSearchParams();
  const currEntriesIndex =
    queryParams?.get(QUERY_PARAM_KEY.TC_ENTRY_INDEX) || 0;
  const [showAddNewBtn, setAddNewBtn] = useState<boolean>(
    !formData?.entries?.length
  );

  function constructEntryFoundationFormVal(
    existingFormData: TransCodeResponse
  ) {
    const currValues = deepCopy(values);
    let constructedVal: any = {
      ...currValues,
      trnCode: existingFormData?.trnCode,
    };

    if (currValues.isDr !== null) {
      constructedVal.isDr = currValues.isDr ? true : false;
    } else {
      delete constructedVal.isDr;
    }
    if (currValues.isGlDtl !== null) {
      constructedVal.isGlDtl = currValues.isGlDtl ? true : false;
    } else {
      delete constructedVal.isGlDtl;
    }
    delete currValues.showAdv;
    return constructedVal;
  }

  const onSubmit = async (
    values: TcEntryFoundationalFormProp,
    actions: any
  ) => {
    if (!dirty) {
      if (!canCheckFormStatus) {
        moveToNextStep();
      }
      return;
    }
    const existingFormData = deepCopy(formData);
    const commonToastOptions = {
      toast,
      toastId,
    };
    let constructedVal: any = constructEntryFoundationFormVal(existingFormData);

    let payload = constructTcEntryPayload(
      existingFormData,
      constructedVal,
      Number(currEntriesIndex)
    );
    const response = await updateTransCode({
      formData: {
        ...payload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : getEntryStageStatus(
              TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_foundational,
              entityWorkflowData
            ),
    });

    if (response && !canCheckFormStatus) {
      moveToNextStep();
    }
  };

  function moveToNextStep() {
    const needAdvanceSection = values?.showAdv;
    const currentEntryStage = entityWorkflowData?.filter(
      (wfd) => wfd.stage === TC_STAGE_STATUS.entry_setup
    )?.[0]?.status;
    if (!needAdvanceSection) {
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      updateTheStatusOfSkippedForm();
      checkFormStatus?.(false);
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : currentEntryStage === DASHBOARD_ACCORDION_STATUS.completed
              ? DASHBOARD_ACCORDION_STATUS.completed
              : TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification,
      });
      navigateTo?.(
        `${ROUTE_PATH.TC_TRANSACTION_ENTRY_SUMMARY}?${updateQueryParams()}`
      );
    } else {
      if (
        !currentEntryStage ||
        currentEntryStage === DASHBOARD_ACCORDION_STATUS.notStarted
      ) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.tcConfig,
          key: formData?.trnCode!,
          stage: TC_STAGE_STATUS.entry_setup,
          status:
            entityId && !entityWorkflowData?.length
              ? ""
              : getEntryStageStatus(
                  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_foundational,
                  entityWorkflowData
                ),
        });
      }
      navigateToNextPage?.();
    }
  }

  const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
  let constructedFormValues: TcEntryFoundationalFormProp = {
    ...initialFormValues,
    trnCode: formData.trnCode,
  };
  if (savedCurrEntries?.trnCode) {
    constructedFormValues = {
      ...(savedCurrEntries as TcEntryFoundationalFormProp),
      isDr: Boolean(savedCurrEntries.isDr),
      isGlDtl: Boolean(savedCurrEntries.isGlDtl),
      showAdv: false,
    };
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(fields, tranCodeOptionsSchema);
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [tranCodeOptionsSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructedFormValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const { values, handleSubmit, handleBlur, setFieldValue, dirty } =
    formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    setSideBarCurrentIndex?.(
      TC_STAGE_ORDER.entry_setup,
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_foundational
    );
  }, []);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_foundational
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  function updateTheStatusOfSkippedForm() {
    if (
      sideBarMainMenuIndex >= 0 &&
      !values?.showAdv &&
      sideBarMenuList?.length &&
      sideBarMenuList[sideBarMainMenuIndex]?.subMenuList?.length
    ) {
      const index =
        sideBarMenuList[sideBarMainMenuIndex].subMenuList?.findIndex((item) => {
          return item.href === ROUTE_PATH.TC_TRANSACTION_ENTRY_SUMMARY;
        }) || -1;
      if (index >= 0) {
        let updatedMainMenuList = sideBarMenuList.map((mainMenu, idx) => {
          if (idx === sideBarMainMenuIndex) {
            let updatedSubmenu = mainMenu?.subMenuList?.map(
              (subMenu, subIdx) => {
                if (
                  sideBarSubMenuIndex === 0 &&
                  subIdx < index &&
                  subIdx > sideBarSubMenuIndex
                ) {
                  return {
                    ...subMenu,
                    status: SIDEBAR_STATUS.completed,
                  };
                }
                return subMenu;
              }
            );
            return {
              ...mainMenu,
              subMenuList: updatedSubmenu,
            };
          }
          return mainMenu;
        });
        updateSidebarMenu?.(updatedMainMenuList);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate id="finxact-form">
      {!showAddNewBtn || formData?.entries?.length ? (
        <CardContainer>
          <AccordionComponent
            accordionTitle={`Transaction entry ${Number(currEntriesIndex) + 1}`}
            limitName={values.entryName}
            isExpand={0}
          >
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the transaction entry name?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("entryName")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.entryName}
                onChange={handleOnChange("entryName")}
                onBlur={handleBlur}
                label="What is the transaction entry name?"
                name="entryName"
              />
            </Box>
            <Heading as="h6" className="app-heading-box">
              Enable any of the following
            </Heading>
            <CoupledFieldWithExpr
              customClass="app-heading-box-contents"
              expOnValue={values.commentExpr}
              expOnFormKey={"commentExpr"}
              expOffValue={values.comment}
              expOffFormKey={"comment"}
              labelName={"Add a transaction comment"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("comment")
                  ?.tooltip_text_main
              }
              onExpOnChange={handleOnChange("commentExpr")}
              onExpOffChange={handleOnChange("comment")}
              onExpOffBlur={handleBlur}
            />
            <CoupledFieldWithExpr
              customClass="app-heading-box-contents"
              expOnValue={values.acctGroupExpr}
              expOnFormKey={"acctGroupExpr"}
              expOffValue={values.acctGroup}
              expOffFormKey={"acctGroup"}
              labelName={"Would you like to define the account group?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")
                  ?.tooltip_text_main
              }
              onExpOnChange={handleOnChange("acctGroupExpr")}
              onExpOffChange={handleOnChange("acctGroup")}
              onExpOffBlur={handleBlur}
              dropdownList={accGroupOptions}
              expOffFormType={"singleSelect"}
            />
            <CoupledFieldWithExpr
              customClass="app-heading-box-contents"
              expOnValue={values.acctNbrExpr}
              expOnFormKey={"acctNbrExpr"}
              expOffValue={values.acctNbr}
              expOffFormKey={"acctNbr"}
              labelName={"Would you like to define the account number?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("acctNbr")
                  ?.tooltip_text_main
              }
              onExpOnChange={handleOnChange("acctNbrExpr")}
              onExpOffChange={handleOnChange("acctNbr")}
              onExpOffBlur={handleBlur}
            />
            <Heading as="h6" className="app-heading-box">
              Enable any of the following
            </Heading>
            <Flex
              justify={"space-between"}
              className="app-form-field-container app-heading-box-contents"
            >
              <AppFormLabel
                labelName="Is this a debit entry?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isDr")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                name="isDr"
                onSelect={handleOnChange("isDr")}
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.isDr}
              />
            </Flex>
            <Flex
              justify={"space-between"}
              className="app-form-field-container app-heading-box-contents"
            >
              <AppFormLabel
                labelName="Do you want to accumulate statistics for all transactions at a detailed level?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isGlDtl")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                name="isGlDtl"
                onSelect={handleOnChange("isGlDtl")}
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.isGlDtl}
              />
            </Flex>
            <Flex
              justify={"space-between"}
              className="app-form-field-container app-heading-box-contents"
            >
              <AppFormLabel
                labelName="Show advanced Transaction Entry options?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("showAdv")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                name="showAdv"
                onSelect={handleOnChange("showAdv")}
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.showAdv!}
              />
            </Flex>
          </AccordionComponent>
        </CardContainer>
      ) : (
        ""
      )}
      {showAddNewBtn ? (
        <AddNewButton
          label={"Add transaction entry"}
          onClick={() => {
            setAddNewBtn(false);
          }}
        />
      ) : (
        ""
      )}
    </form>
  );
}
