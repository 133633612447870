import {
  APPLICATION_KEY,
  EMAIL_KEY,
  NAME_KEY,
  ORGANIZATION_KEY,
  PROFILE_TEMPLATE_KEY,
  ROLE_NAME_KEY,
  ROLE_TYPE_KEY,
  STATUS_KEY,
  TEAM_STATUS_KEY,
} from "@/utils";

export const PROFILE_TEMPLATE_TABLE_COLUMN_REF = [
  {
    header: "Profile template",
    accessorKey: NAME_KEY,
    enableSorting: true,
  },
  {
    header: "Roles type(s)",
    accessorKey: ROLE_TYPE_KEY,
    enableSorting: false,
  },
  {
    header: "",
    accessorKey: "action",
    enableSorting: false,
  },
];

export const TEAM_TABLE_COLUMN_REF = [
  {
    header: "Name",
    accessorKey: NAME_KEY,
    enableSorting: true,
  },
  {
    header: "Email",
    accessorKey: EMAIL_KEY,
    enableSorting: true,
  },
  {
    header: "Profile template",
    accessorKey: PROFILE_TEMPLATE_KEY,
    enableSorting: true,
  },
  {
    header: "Status",
    accessorKey: TEAM_STATUS_KEY,
    enableSorting: true,
  },
  {
    header: "",
    accessorKey: "action",
    enableSorting: false,
  },
];

export const CUSTOM_ROLES_TABLE_COLUMN_REF = [
  {
    header: "Role name",
    accessorKey: NAME_KEY,
    enableSorting: true,
  },
  {
    header: "Organization",
    accessorKey: ORGANIZATION_KEY,
    enableSorting: true,
  },
  {
    header: "Status",
    accessorKey: STATUS_KEY,
    enableSorting: true,
  },
  {
    header: "Application",
    accessorKey: APPLICATION_KEY,
    enableSorting: true,
  },

  {
    header: "",
    accessorKey: "action",
    enableSorting: false,
  },
];

export const CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF = [
  { header: "Role", accessorKey: "name", enableSorting: false },
  { header: "Description", accessorKey: "desc", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];

export const CORE_ROLES_ENV_TABLE_COLUMN_DEF = [
  { header: "Role", accessorKey: "userRole", enableSorting: false },
  { header: "Description", accessorKey: "desc", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];

export const CORE_ROLES_TABLE_COLUMN_DEF = [
  { header: "Role", accessorKey: "name", enableSorting: false },
  { header: "Description", accessorKey: "desc", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];

export const ORG_PROFILE_TEMPLATE_COLUMN_DEF = [
  { header: "", accessorKey: "editAction", enableSorting: false },
  { header: "Profile Template", accessorKey: "name", enableSorting: false },
  { header: "Description", accessorKey: "roles", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];

export const NEW_ORG_OVERIDE_MAPPING = {
  environments: [],
  name: "Override",
  orgId: "",
  roles: [],
  type: "user",
};
