"use client";
import {
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
} from "@/components/common";
import React, { useContext, useEffect, useState } from "react";

import "./RepaymentPrePaymentConfigurations.scss";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  getValidationSchema,
  repaymentPrepaymentConfigurationDetails,
  repaymentPrepaymentConfigurationProps,
} from "./RepaymentPrePaymentConfigurationsValidation";
import { useFormik } from "formik";
import { PostSchema, RepaymentComponentData } from "../../../model/types";
import { Box, useToast } from "@/components/ChakraUiManager";
import { createRepaymentComponent } from "../../product-config-client-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { getIfxProdType } from "@/components/product-management";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: RepaymentComponentData | null;
    repayPaymentOptionsData: PostSchema;
  };
};

const RepaymentPrePaymentConfigurations = (props: any) => {
  const { depositName, featureName, version, mainTitle, subText, data } = props;

  const { formData } = props.data;

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.repayPaymentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.repayPaymentOptionsData]);
  const toast = useToast();
  const onSubmit = async (
    values: repaymentPrepaymentConfigurationProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && productDetails) {
      const res = await createRepaymentComponent({
        productDetails,
        formData: {
          ...formData,
          // PATCH fields
          prePmtMinAmt: parseFloat(values.prePmtMinAmt),
          prePmtMinPct: parseFloat(values.prePmtMinPct),
        },
        toastOptions: {
          toast,
          toastId: "repayment-loan",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      prePmtMinAmt:
        formData?.prePmtMinAmt?.toString() ??
        repaymentPrepaymentConfigurationDetails.prePmtMinAmt,
      prePmtMinPct:
        formData?.prePmtMinPct?.toString() ??
        repaymentPrepaymentConfigurationDetails.prePmtMinPct,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const repaymentPrePaymentConfigurationFormHandler =
    (key: string) => (value: string | number) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <div className="app-form-field-container">
          <AppNumberInput
            labelName="What is the pre-payment minimum amount?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("prePmtMinAmt")
                ?.tooltip_text_main
            }
            value={values.prePmtMinAmt}
            onChange={repaymentPrePaymentConfigurationFormHandler(
              "prePmtMinAmt"
            )}
            valuePrefix="$"
          />
          {errors.prePmtMinAmt && touched.prePmtMinAmt && (
            <ErrorAlert>
              <span>{errors.prePmtMinAmt}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="app-form-field-container">
          <AppNumberInput
            labelName="What is the pre-payment minimum percentage?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("prePmtMinPct")
                ?.tooltip_text_main
            }
            value={values.prePmtMinPct}
            onChange={repaymentPrePaymentConfigurationFormHandler(
              "prePmtMinPct"
            )}
            valueSuffix="%"
          />
          {errors.prePmtMinPct && touched.prePmtMinPct && (
            <ErrorAlert>
              <span>{errors.prePmtMinPct}</span>
            </ErrorAlert>
          )}
        </div>
      </CardContainer>
    </form>
  );
};

export default RepaymentPrePaymentConfigurations;
