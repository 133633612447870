"use client";

import { useContext, useEffect } from "react";
import { useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  FeeComponentData,
  PostSchema,
} from "@/components/product-management/model/types";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS, Option } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { createFeeComponent } from "../../product-config-client-service";

type EarningAnalysisProps = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: FeeComponentData | null;
    earningsAnalysisOptions: Option<number>[];
    feeComponentOptionsData: PostSchema;
  };
};

export default function EarningAnalysis(props: EarningAnalysisProps) {
  const {
    data: { formData },
  } = props;
  const {
    productDetails,
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();

  const onSubmit = async (values: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["fee"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData) {
      if (productDetails) {
        const res = await createFeeComponent({
          productDetails,
          formData: {
            ...formData,
            earningsAnalysis: values.earningsAnalysis,
          },
          toastOptions: {
            toast,
            toastId: "earning-analysis",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
          getWorflowStatusToBeUpdated?.() &&
            updateWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.product,
              key: productDetails?.name ?? "",
              stage: COMPONENT_CLASS["fee"],
              status: getWorflowStatusToBeUpdated?.() ?? "",
            });
        }
      }
    }
  };

  // using useFormik hook from Formik Library
  const { values, handleSubmit, setFieldValue, dirty } = useFormik({
    initialValues: {
      earningsAnalysis: formData?.earningsAnalysis ?? undefined,
    },
    onSubmit,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <AppFormLabel
          labelName="How will excess earnings be handled?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("earningsAnalysis")
              ?.tooltip_text_main
          }
        />
        <RadioButtonGroup
          value={values.earningsAnalysis}
          onChange={(value) => setFieldValue("earningsAnalysis", value)}
          radioPropList={props.data.earningsAnalysisOptions}
          stackDirection={"row"}
          isBoxedRadio
        />
      </CardContainer>
    </form>
  );
}
