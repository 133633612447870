import { UserAttributesType } from "@/models/academy-models";
import { createContext } from "react";

type AcademyContextType = {
  userAttributes?: UserAttributesType | null;
  setUserAttributes?: (value: UserAttributesType) => void;
};

const defaultComponentConfigContextVal: AcademyContextType = {
  userAttributes: null,
};

type AcademyReducerTypes = "UserAttributes";

type AcademyContextAction = {
  type: AcademyReducerTypes;
  payload: any;
};

function academyReducer(
  state: AcademyContextType,
  action: AcademyContextAction
) {
  const { type, payload } = action;

  switch (type) {
    case "UserAttributes":
      return {
        ...state,
        userAttributes: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

const academyContext = createContext<AcademyContextType>(
  defaultComponentConfigContextVal
);

//type export
export type { AcademyContextType };

//conext exports
export { academyContext, defaultComponentConfigContextVal, academyReducer };
