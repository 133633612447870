import { Box, Image } from "@chakra-ui/react";

export default function Avatar({
  url,
  initials,
}: {
  url?: string;
  initials: string;
}) {
  return url ? (
    <Image src={url} alt="profile image of user" width={95} height={95} />
  ) : (
    <Box className="avatar-container">{initials}</Box>
  );
}
