"use client";
import { Box, Flex, Heading, Stepper, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MainContent from "../main-content/MainContent";

import "./JumpstartActivation.scss";
import {
  createWorkflowGeneric,
  updateWorkflowGeneric,
  getWorkflowGeneric,
  getAllWorkflowGeneric,
  DashboardWorkflowResponse,
} from "@/api-config/api-service";
import {
  DASHBOARD_MODEL_KEY,
  DASHBOARD_ACCORDION_STATUS,
  FOUNDATIONAL_SETUP_STEPS_NAME,
  JUMPSTART_ACTIVATION_STEPS_NAME,
  PLATFORM_SETUP_STATUS,
  numberOfAccordionStepsCompleted,
  showButtonOnAccordion,
  showStepsStatus,
  DASHBOARD_COMPLETED_ORG_PHASES,
} from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

import { useRouter } from "next/navigation";
import { JumpstartProps } from "../model";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { useSelector } from "react-redux";
import { getSelectedOrg } from "@/store";

interface JumpstartActivationProps {
  title: string;
  jumpstartDetails: JumpstartProps;
  disabledState: boolean;
  activeStep: (goToNextStep: boolean) => void;
  access: {
    accessManagement: boolean;
  };
}

const JumpstartActivation = (props: JumpstartActivationProps) => {
  const { title, jumpstartDetails, disabledState, activeStep, access } = props;
  const [jumpstartWokflowData, setData] = useState<any>([]);
  const router = useRouter();
  const [completed, setCompleted] = useState<number>(0);
  const currOrg = useSelector(getSelectedOrg);

  const platformSetupDetails = jumpstartDetails?.platform_setup;
  const accessManagementDetails = jumpstartDetails?.access_management;
  const visitAcademyDetails = jumpstartDetails?.visit_academy;
  const exploreDocumentationDetails = jumpstartDetails?.explore_documentation;
  const isCompleted = DASHBOARD_COMPLETED_ORG_PHASES.includes(currOrg?.phase);

  useEffect(() => {
    const getData = async () => {
      const [data, platformSetup] = await Promise.all([
        getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.jumpstartActivation,
        }),
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.platform),
      ]).then((data: any) => data);
      const isPlatformSetupComplete = platformSetup?.every(
        (ele: DashboardWorkflowResponse) =>
          ele.status == PLATFORM_SETUP_STATUS.completed
      );
      if (
        platformSetup?.length &&
        isPlatformSetupComplete &&
        showStepsStatus(data, JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup) ==
          DASHBOARD_ACCORDION_STATUS.inProgress
      ) {
        const [stepUpdateStatus, nextStepUpdateStatus, workflowData] =
          await Promise.all([
            updateWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.dashboard,
              key: DASHBOARD_MODEL_KEY.jumpstartActivation,
              stage: JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup,
              status: DASHBOARD_ACCORDION_STATUS.completed,
            }),
            createWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.dashboard,
              key: DASHBOARD_MODEL_KEY.jumpstartActivation,
              stage: JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement,
              status: DASHBOARD_ACCORDION_STATUS.inProgress,
            }),
            getWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.dashboard,
              key: DASHBOARD_MODEL_KEY.jumpstartActivation,
            }),
          ]).then((data) => data);
        setData(workflowData);
        setCompleted(numberOfAccordionStepsCompleted(workflowData));
      } else {
        setData(data);
        setCompleted(numberOfAccordionStepsCompleted(data));
      }
    };
    getData();
  }, []);

  const handleOnClick = async (step: string, e: any) => {
    e.stopPropagation();
    let nextStep = "";

    switch (step) {
      case JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup:
        router.push(ROUTE_BASE_URL.PLATFORM_SETUP);
        break;
      case JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement:
        router.push(ROUTE_BASE_URL.ACCESS_MANAGEMENT);
        nextStep = JUMPSTART_ACTIVATION_STEPS_NAME.visitAcademy;
        break;
      case JUMPSTART_ACTIVATION_STEPS_NAME.visitAcademy:
        router.push(ROUTE_BASE_URL.ACADEMY);
        nextStep = JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation;
        break;
      case JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation:
        router.push(ROUTE_BASE_URL.DOCUMENT_CENTER);
        nextStep = JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation;
        break;
      default:
        throw new Error("Step does not exists");
    }

    //If it is last step insert entry for foundational setup view.
    if (step === JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation) {
      const [stepUpdateStatus, next] = await Promise.all([
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.jumpstartActivation,
          stage: step,
          status: DASHBOARD_ACCORDION_STATUS.completed,
        }),
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
          stage: FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration,
          status: DASHBOARD_ACCORDION_STATUS.inProgress,
        }),
      ]).then((data) => data);
    } else if (nextStep == "") {
      return;
    } else {
      const [stepUpdateStatus, nextStepUpdateStatus] = await Promise.all([
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.jumpstartActivation,
          stage: step,
          status: DASHBOARD_ACCORDION_STATUS.completed,
        }),
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.jumpstartActivation,
          stage: nextStep,
          status: DASHBOARD_ACCORDION_STATUS.inProgress,
        }),
      ]).then((data) => data);
    }

    //Get the workflow data to set the status in stepper and mark the step as completed.
    const getData = async () => {
      const data = await getWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.jumpstartActivation,
      });
      setData(data);
      setCompleted(numberOfAccordionStepsCompleted(data));
      const findNotCompleted = data.find(
        (d: any) =>
          d.modelKey === DASHBOARD_MODEL_KEY.jumpstartActivation &&
          d.status === DASHBOARD_ACCORDION_STATUS.inProgress
      );
      if (!findNotCompleted) {
        activeStep(true);
      }
    };

    getData();
  };

  return (
    <>
      <Box>
        <Flex className="jumpstart-activation-content">
          <Heading as={"h4"}>{title}</Heading>
          <Text className="jumpstart-activation-status">{`${
            isCompleted ? 4 : completed
          } of 4 complete`}</Text>
        </Flex>
        <Stepper
          size="lg"
          index={isCompleted ? 4 : completed}
          orientation="vertical"
        >
          <MainContent
            btnLink
            btnName="Get started"
            showBtn={showButtonOnAccordion(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup
            )}
            onClick={handleOnClick.bind(
              null,
              JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup
            )}
            btnDisabled={disabledState}
            title={platformSetupDetails?.sectionTitle}
            desc={platformSetupDetails?.sectionSubTitle}
            overviewHeader={platformSetupDetails?.header}
            overview={platformSetupDetails?.description}
            helpfullRes={platformSetupDetails?.resourcesLinks}
            status={showStepsStatus(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup
            )}
          />
          <MainContent
            btnLink
            btnName="Get started"
            showBtn={
              showButtonOnAccordion(
                jumpstartWokflowData,
                JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement
              ) && access.accessManagement
            }
            btnDisabled={disabledState && access.accessManagement}
            onClick={handleOnClick.bind(
              null,
              JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement
            )}
            title={accessManagementDetails?.sectionTitle}
            desc={accessManagementDetails?.sectionSubTitle}
            overviewHeader={accessManagementDetails?.header}
            overview={accessManagementDetails?.description}
            helpfullRes={accessManagementDetails?.resourcesLinks}
            status={showStepsStatus(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement,
              access.accessManagement
            )}
            hasAdminAccess={
              showButtonOnAccordion(
                jumpstartWokflowData,
                JUMPSTART_ACTIVATION_STEPS_NAME.accessManagement
              ) && !access.accessManagement
            }
          />
          <MainContent
            btnLink
            btnName="Get started"
            showBtn={showButtonOnAccordion(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.visitAcademy
            )}
            onClick={handleOnClick.bind(
              null,
              JUMPSTART_ACTIVATION_STEPS_NAME.visitAcademy
            )}
            btnDisabled={disabledState}
            title={visitAcademyDetails?.sectionTitle}
            desc={visitAcademyDetails?.sectionSubTitle}
            overviewHeader={visitAcademyDetails?.header}
            overview={visitAcademyDetails?.description}
            helpfullRes={visitAcademyDetails?.resourcesLinks}
            status={showStepsStatus(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.visitAcademy
            )}
          />
          <MainContent
            btnLink
            btnName="Get started"
            showBtn={showButtonOnAccordion(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation
            )}
            onClick={handleOnClick.bind(
              null,
              JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation
            )}
            btnDisabled={disabledState}
            title={exploreDocumentationDetails?.sectionTitle}
            desc={exploreDocumentationDetails?.sectionSubTitle}
            overviewHeader={exploreDocumentationDetails?.header}
            overview={exploreDocumentationDetails?.description}
            helpfullRes={exploreDocumentationDetails?.resourcesLinks}
            status={showStepsStatus(
              jumpstartWokflowData,
              JUMPSTART_ACTIVATION_STEPS_NAME.exploreDocumentation
            )}
            isLastItem
          />
        </Stepper>
      </Box>
    </>
  );
};

export default JumpstartActivation;
