"use client";

import {
  Button,
  ChevronRightIcon,
  useToast,
} from "@/components/ChakraUiManager";
import "./CloverCheckoutButton.scss";
import { Ref, useEffect, useRef, useState } from "react";
import AppModal from "../app-modal/AppModal";
import CloverCheckout from "@/components/academy/clover-checkout/CloverCheckout";
import { useFormik } from "formik";
import {
  fetchCloverChargeDetails,
  verifyPaymentStatus,
} from "@/client-api-manager/clover-api";
import { ROUTE_PATH } from "@/route-config/route-path";
import { getCloverPaymentConfig } from "@/prismic-api/prismic-api-service";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

type CheckoutButtonProps = {
  btnText?: string;
  isDisabled?: boolean;
  showRightArrowIcon?: boolean;
  imageUrl: string;
  courseName: string;
  priceDetails: PriceDetails;
  courseId: string;
  buttonRef?: Ref<any>;
  locked: boolean;
};
export type PriceDetails = {
  subTotal: string;
  tax: string;
  total: string;
};

const CloverCheckoutButton = ({
  btnText,
  isDisabled,
  showRightArrowIcon = true,
  imageUrl,
  priceDetails,
  courseName,
  courseId,
  buttonRef,
  locked,
}: CheckoutButtonProps) => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [pageData, setPageData] = useState<any>();
  useEffect(() => {
    if (!pageData)
      getCloverPaymentConfig(prismicClient).then((data) =>
        setPageData(data?.[0]?.data),
      );
  }, [pageData]);
  const modalRef = useRef<any>();
  function showModal() {
    if (modalRef?.current) {
      modalRef.current.openModal();
    }
  }

  const toast = useToast();

  const getUrlPath = () => {
    if (!courseId) return "";
    return `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${courseId}`;
  };

  const openHandler = () => {
    if (courseId) {
      fetchCloverChargeDetails(courseId)
        .then((response) => {
          if (verifyPaymentStatus(response.status, response.id)) {
            const coursePageUrl = getUrlPath();
            window.location.href = coursePageUrl;
          } else {
            showModal();
          }
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: "Failed to verify payment for this exam",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  let buttonText;
  if (locked) {
    buttonText = "Final Exam Coming Soon";
  } else {
    buttonText = btnText;
  }

  return (
    <>
      <Button
        className="app-btn-inverse-secondary"
        w="100%"
        rightIcon={showRightArrowIcon ? <ChevronRightIcon /> : undefined}
        isDisabled={isDisabled}
        onClick={openHandler}
        ref={buttonRef}
      >
        {buttonText || "Checkout"}
      </Button>
      <AppModal
        customClass="academy-payment-modal"
        ref={modalRef}
        modalTitle={""}
        isCentered
      >
        <CloverCheckout
          courseId={courseId}
          imageUrl={imageUrl}
          courseName={courseName}
          priceDetails={priceDetails}
          pageData={pageData}
        />
      </AppModal>
    </>
  );
};

export default CloverCheckoutButton;
