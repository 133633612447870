"use client";

import ModalSelectorV1 from "@/components/common/form-fields/modal-selector-v1/ModalSelectorV1";
import { Box } from "@/components/ChakraUiManager";
import { TrnCodeOption } from "../models";

type Props = {
  trnCodeOptions: TrnCodeOption[];
  savePreferences: (values: string[]) => void;
  selectedTrnCodes: string[];
};

const UpdatePreferences = ({
  trnCodeOptions,
  savePreferences,
  selectedTrnCodes,
}: Props) => {
  return (
    <Box className="update-preferences">
      {trnCodeOptions && (
        <ModalSelectorV1
          value={selectedTrnCodes}
          linkName={"Update preferences"}
          modalTitle={"Select your transaction code(s)"}
          optionList={trnCodeOptions}
          isSingleSelect={false}
          savePreferences={savePreferences}
        />
      )}
    </Box>
  );
};

export default UpdatePreferences;
