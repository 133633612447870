import { CoupleRadioOptionType } from "@/components/common/form-fields/couple-radio-with-dropdown/CoupleRadioWithDropdown";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Scra_Properties
interface ScraPropertiesProps {
  extraReliefDur: string;
  rateMatrix: string;
  scraRateMatrix?: CoupleRadioOptionType;
}
// Defining Intitial Value For Scra_Properties_InitialValues
const scraPropertiesInitial: ScraPropertiesProps = {
  extraReliefDur: "",
  rateMatrix: "",
  scraRateMatrix: "",
};

const scraPropertiesSchema = async (optionsSchema: PostSchema) => {
  const fields = ["bankparamUSBankInfo"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig) as any;
};

// Exporting Interface
export type { ScraPropertiesProps };
// Exporting Validation Schema and Intial value
export { scraPropertiesInitial, scraPropertiesSchema as getValidationSchema };
