import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface AdditionalProductFormProps {
  fundTerm: string;
  zeroBalCloseDays: string;
  zeroBalAlertDays: string;
  apy: string;
  svcs: {
    svcName?: string;
  }[];
}

const additionalProductsConfigurationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["fundTerm", "zeroBalAlertDays", "zeroBalCloseDays"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

export type { AdditionalProductFormProps };
export { additionalProductsConfigurationSchema as getValidationSchema };
