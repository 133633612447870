import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Service_Charge_Detail
interface ServiceChargeProps {
  addPeriodicServiceCharge: SplitBtnInputValType;
  svcChrgFreq: string;
  svcChrgFreqMatrix: string;
  feeMin: string;
  feeMax: string;
  feeMinTrnCode: string | null;
  feeMaxTrnCode: string | null;
}
// Defining Intitial Value For Service_Charge_Detail
const serviceChargeDetails: ServiceChargeProps = {
  addPeriodicServiceCharge: undefined,
  svcChrgFreq: "",
  svcChrgFreqMatrix: "",
  feeMin: "",
  feeMax: "",
  feeMinTrnCode: null,
  feeMaxTrnCode: null,
};

// YUP LIBRARY :Defining Schema For validation of Service_Charge_Detail
const serviceChargeDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["svcChrgFreq", "svcChrgFreqMatrix", "feeMax", "feeMin"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { ServiceChargeProps };
// Exporting Validation Schema and Intial value
export {
  serviceChargeDetails,
  serviceChargeDetailSchema as getValidationSchema,
};
