import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ApiClient } from "@/api-config/api-client";
import {
  getCoreModelEndpoint,
  createComponent,
  updateComponent,
  updateComponentWithPut,
  createWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import {
  AccoutingSegmentsCreatePayload,
  AccountingSegmentsUpdatePayload,
  AccountingSegmentsResponse,
  AccountsResponse,
  AccountsUpdatePayload,
  SystemAccountsResponse,
  SystemAccountsCreatePayload,
  AccountsCreatePayload,
  SystemAccountsUpdatePayload,
  SetsResponse,
  SetsCreatePayload,
  SetsUpdatePayload,
} from "./model";
import { GL_ENTITY } from "@/utils";

// Create, update, get for Accounting Segments
export const getAccountingSegments = async (
  accountingSegmentName: string
): Promise<AccountingSegmentsResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(
    `${API_ROUTE_CONFIGURATION.accountingSegments}/${accountingSegmentName}`
  );
};

export const createAccountingSegments = async (
  config: AccoutingSegmentsCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.accountingSegments}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
        key: res.acctgSeg,
        stage: GL_ENTITY.accounting_segments,
        status: stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateAccountingSegments = async (
  config: AccountingSegmentsUpdatePayload
) => {
  const {
    formData,
    stageName,
    updateWorkFlow,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.accountingSegments}/${formData.acctgSeg}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        if (updateWorkFlow) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.acctgSeg,
            stage: GL_ENTITY.accounting_segments,
            status: stageName,
          });
        } else {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.acctgSeg,
            stage: GL_ENTITY.accounting_segments,
            status: stageName,
          });
        }
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create, update, get for Accounts
export const getAccounts = async (
  accountGroup: string,
  accountNumber: string
): Promise<AccountsResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(
    `${API_ROUTE_CONFIGURATION.accounts}/${accountGroup}/${accountNumber}`
  );
};

export const createAccounts = async (config: AccountsCreatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.accounts}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
        key: `${res.acctGroup}~${res.acctNbr}`,
        stage: GL_ENTITY.accounts,
        status: stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateAccounts = async (config: AccountsUpdatePayload) => {
  const {
    formData,
    stageName,
    updateWorkFlow,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.accounts}/${formData.acctGroup}/${formData.acctNbr}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        if (updateWorkFlow) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: `${res.acctGroup}~${res.acctNbr}`,
            stage: GL_ENTITY.accounts,
            status: stageName,
          });
        } else {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: `${res.acctGroup}~${res.acctNbr}`,
            stage: GL_ENTITY.accounts,
            status: stageName,
          });
        }
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create, update and get for System Accounts
export const getSystemAccounts = async (
  accountName: string
): Promise<SystemAccountsResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.systemAccounts}/${accountName}`);
};

export const createSystemAccounts = async (
  config: SystemAccountsCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.systemAccounts}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
        key: res.acctName,
        stage: GL_ENTITY.system_accounts,
        status: stageName,
      });

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateSystemAccounts = async (
  config: SystemAccountsUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    updateWorkFlow = true,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.systemAccounts}/${formData.acctName}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        if (updateWorkFlow) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.acctName,
            stage: GL_ENTITY.system_accounts,
            status: stageName,
          });
        } else {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.acctName,
            stage: GL_ENTITY.system_accounts,
            status: stageName,
          });
        }
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create, update and get for Sets
export const getSets = async (glSetCode: string): Promise<SetsResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.sets}/${glSetCode}`);
};

export const createSets = async (config: SetsCreatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.sets}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
        key: res.glSetCode,
        stage: GL_ENTITY.sets,
        status: stageName,
      });

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateSets = async (config: SetsUpdatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    updateWorkFlow,
    isComplete = false,
  } = config;

  return updateComponentWithPut({
    url: `${API_ROUTE_CONFIGURATION.sets}/${formData.glSetCode}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        if (updateWorkFlow) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.glSetCode,
            stage: GL_ENTITY.sets,
            status: stageName,
          });
        } else {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
            key: res.glSetCode,
            stage: GL_ENTITY.sets,
            status: stageName,
          });
        }
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
