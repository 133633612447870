import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import { ErrorMessage } from "formik";
import * as Yup from "yup";

//Defining Interface 
interface AccountGroupFoundationDetailsProps {
  acctGroup: string;
  desc: string;
  acctClass: number | null;
}
// Defining Intitial Value 
const accountGroupFoundationDetails: AccountGroupFoundationDetailsProps = {
  acctGroup: "",
  desc: "",
  acctClass: null,
};
// YUP LIBRARY :Defining Schema 
const accountGroupFoundationDetailSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["acctGroup", "desc", "acctClass"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { AccountGroupFoundationDetailsProps };
// Exporting Validation Schema and Intial value
export {
  accountGroupFoundationDetails,
  accountGroupFoundationDetailSchema as getValidationSchema,
};
