type UnsafeUntypedObject = Record<string, any>;
import type { JSONSchema7 } from "json-schema";
import traverse from "json-schema-traverse";
import { get, set } from "lodash-es";
export async function getSchemaPointers(
  schema: UnsafeUntypedObject,
  currentNode: UnsafeUntypedObject,
  dereference: boolean,
  prefix: string,
  delimiter: string,
  leavesOnly: boolean
): Promise<string[]> {
  return new Promise<string[]>((resolve) => {
    const pointers: string[] = [];

    traverse(currentNode, {
      cb: {
        pre: async (
          subSchema: any,
          jsonPointer,
          rootSchema,
          parentPointer,
          parentKeyword,
          parentSchema,
          name
        ) => {
          // Ignore definitions
          if (jsonPointer?.startsWith("/definitions")) {
            return;
          }

          // Don't include /properties in pointer
          jsonPointer = jsonPointer.replace(/\/properties\//g, "/");

          // Follow $refs
          if (dereference && subSchema.$ref) {
            // subSchema.$ref is like #/definitions/Addr; convert to definitions.Addr
            const refSchemaPointer = subSchema.$ref
              .substring(2)
              .replace(/\/+/g, ".");
            const refSchema = get(schema, refSchemaPointer);
            const subSchemaPtrs = await getSchemaPointers(
              schema,
              refSchema,
              dereference,
              "",
              delimiter,
              leavesOnly
            );

            if (subSchemaPtrs) {
              pointers.push(
                ...subSchemaPtrs.map((ptr) =>
                  normalizeJSONPointer(
                    `${jsonPointer}/${ptr}`,
                    prefix,
                    delimiter
                  )
                )
              );
            }

            return;
          }

          // Ignore framework fields (start with _), and if leavesOnly is set, don't include objects or arrays, only the
          //   properties of those objects and arrays
          if (
            name &&
            typeof name === "string" &&
            !name.startsWith("_") &&
            (!leavesOnly ||
              (subSchema.type !== "object" && subSchema.type !== "array"))
          ) {
            // If leaves only and this is a pattern prop object, ignore
            if (leavesOnly && jsonPointer.endsWith("patternProperties..*")) {
              return;
            }

            pointers.push(normalizeJSONPointer(jsonPointer, prefix, delimiter));
          }
        },
        post: (subSchema: any) => {
          // We know we are done when we get back to the root; resolve
          if (subSchema === currentNode) {
            resolve(pointers);
          }
        },
      },
    });
  });
}
export function normalizeJSONPointer(
  pointer?: string,
  prefix?: string,
  delimiter?: string
): string {
  // Empty returns empty
  if (!pointer || !pointer.replace(/\/+/g, "")) {
    return "";
  }

  let newPointer: string | undefined = pointer;

  // If a prefix is set, add it to the beginning of the pointer
  if (prefix) {
    newPointer = `${prefix}/${newPointer}`;
  }

  // De-duplicate any slashes
  newPointer = newPointer.replace(/\/+/g, "/");

  // Strip leading and trailing slashes
  newPointer = removeSuffix(removePrefix(newPointer, "/"), "/");

  // If nothing is left, return empty string
  if (!newPointer) {
    return "";
  }

  // If a delimiter is set, update all slashes to delimiter
  if (delimiter) {
    newPointer = newPointer.replace(/\/+/g, delimiter);
  }

  return newPointer;
}

/**
 * Given a string "base" and a string "prefix", if base starts with prefix, that portion is removed.  The updated string
 * is returned.
 * @param base
 * @param prefix
 * @returns {string|*}
 */
export function removePrefix(base?: string, prefix?: string) {
  if (!base || !prefix) {
    return base;
  }

  if (base.startsWith(prefix)) {
    return base.substring(prefix.length);
  }

  return base;
}

/**
 * Given a string "base" and a string "suffix", if base ends with suffix, that portion is removed.  The updated string
 * is returned.
 * @param base
 * @param suffix
 * @returns {string|*}
 */
export function removeSuffix(base?: string, suffix?: string) {
  if (!base || !suffix) {
    return base;
  }

  if (base.endsWith(suffix)) {
    return base.substring(0, base.length - suffix.length);
  }

  return base;
}
/*
                 This listing is hard-coded because it is also hard-coded in core-server (per Steve) and there is no
                 mechanism which allows retrieving list of potential required fields in a dynamic way as of present date
                 (08/13/2019).  If core is ever changed to support full trnEntry schema, could do something like:

                  const paths = utils.getSchemaPaths(schema, definitions, '.', (path) => {
                  if (path.includes('_action')) {
                    return true;
                  } else if (path.endsWith('items')) {
                    return true;
                  } else {
                    return false;
                  }
                });
              */

export const IMMUTABLE_LIST_FIELDS = [
  "acctGroup",
  "acctNbr",
  "addHold",
  "assetClass",
  "assetId",
  "ccyCode",
  "comment",
  "company",
  "deptId",
  "glDist",
  "isDr",
  "posnId",
  "trnAmt",
  "vertical",
];

/*
This listing is hard-coded because it is also hard-coded in core-server and there is no mechanism
which allows retrieving list of potential required fields in a dynamic way as of present date
(08/13/2019).  See TranCd.go line #75 in core-server.
*/
export const REQD_BASE_LIST_FIELDS = [
  "acctGroup",
  "acctgSeg",
  "acctNbr",
  "addHold",
  "auth",
  "assetClass",
  "assetId",
  "ccyCode",
  "chkDtl",
  "comment",
  "entryName",
  "exch",
  "glSetCode",
  "isContact",
  "isDr",
  "passbook",
  "posnAcctNbr",
  "posnId",
  "removeHold",
  "seqNbr",
  "srcFunds",
  "subBalAmts",
  "trnAmt",
];
