"use client";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  FinancialOrganizationSpecificationsDetails,
  getValidationSchema,
} from "./FinancialOrganizationSpecificationsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type FinancialOrganizationSpecificationsDetailsProps = {
  data: any;
};
const FinancialOrganizationSpecifications = (
  props: FinancialOrganizationSpecificationsDetailsProps
) => {
  const toast = useToast();
  const toastId = "financial-organization";
  const {
    data: { formData },
  } = props;
  const updateQueryParams = useQueryParams();

  const { partyOrgFininstOptionsData } = props.data;

  let url: string;
  if (formData?.corrs?.length > 1) {
    //if corresponding network has multiple record navigating the user to summary page
    url = `${
      ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"]
    }?${updateQueryParams(QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"], "0")}`;
  } else {
    //if corresponding network has 1 record showing that record directly in destination network
    url = `${
      ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"]
    }?${updateQueryParams(QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"], "0")}`;
  }

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo?.(url);
    }
    return;
  };

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_spec;

    if (!dirty && url) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };

    const response = await updateFinOrg({
      formData: {
        _vn: formData?._vn,
        _Id: formData?._Id,
        rtCat: values.rtCat,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      rtCat:
        formData?.rtCat ?? FinancialOrganizationSpecificationsDetails.rtCat,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <Text className="content-box-title" as="h3">
            Financial organization specifications
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the routing category?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("rtCat")?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            value={values.rtCat}
            onChange={handleOnChange("rtCat")}
            radioPropList={props?.data?.routingCategory || []}
            stackDirection="column"
            spacing="1rem"
            onBlur={handleBlur}
            isBoxedRadio
          />
          {errors?.rtCat && touched?.rtCat && (
            <ErrorAlert>
              <span>{errors.rtCat as any}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default FinancialOrganizationSpecifications;
