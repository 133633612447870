"use client";

import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  SimpleGrid,
  Button,
  Tag,
  TagCloseButton,
  TagLabel,
  useBreakpointValue,
  Hide,
} from "@/components/ChakraUiManager";
import TutorialPreview from "./TutorialPreview";
import "./TutorialsLandingPage.scss";
import "../TutorialsFilter.scss";
import OverviewHeader from "../overview-header/OverviewHeader";
import TutorialsTagsFilter from "../TutorialsTagsFilter";

interface Tutorial {
  title: string;
  tags: string[];
  uid: string;
  previewText: Array<{
    type: string;
    text: string;
    spans: any[];
    direction: string;
  }>;
}

interface TutorialsLandingPageProps {
  tutorials: Tutorial[];
  loading: boolean;
  paramTags: any;
}

const TutorialsLandingPage: React.FC<TutorialsLandingPageProps> = (props) => {
  const { tutorials, loading, paramTags } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false); // State for showing/hiding the filter

  useEffect(() => {
    if (paramTags) {
      setSelectedTags(paramTags);
    }
  }, [paramTags]);

  const handleTagChange = (tag: string) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  const clearFilters = () => {
    setSelectedTags([]);
  };

  const removeTag = (tag: string) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.filter((t) => t !== tag)
    );
  };

  const filteredTutorials =
    selectedTags.length === 0
      ? tutorials
      : tutorials.filter((tutorial) =>
          tutorial.tags.some((tag) => selectedTags.includes(tag))
        );

  const StackComponent =
    useBreakpointValue({ base: VStack, md: HStack }) || HStack;

  return (
    <Box display="flex" flexDirection="column" height="100%" overflowY="auto">
      <OverviewHeader
        documentTitle="Tutorials"
        overview="Imagine, configure, and launch new financial product value propositions with Finxact Tutorials."
        breadcrumbs={["Docs"]}
      />
      <StackComponent
        gap={24}
        align="start"
        overflowY={{ base: "visible", md: "auto" }}
      >
        <div
          className={`filters-container ${showFilters ? "filters-visible" : ""}`}
        >
          <TutorialsTagsFilter
            selectedTags={selectedTags}
            handleTagChange={handleTagChange}
            clearFilters={clearFilters}
            setShowFilters={() => setShowFilters((prev) => !prev)} // Toggle visibility on small screens
            page="landing"
          />
        </div>

        {!loading && tutorials.length > 0 ? (
          <SimpleGrid
            gap={12.8}
            columns={{ base: 1, lg: 2 }}
            className="tutorial-grid"
          >
            {!showFilters && (
              <Hide above="md">
                <Box className="filters-button">
                  <Button
                    size="sm"
                    className="app-btn-reg-secondary-transparent"
                    onClick={() => setShowFilters(true)}
                    width="100%"
                    mx="auto"
                  >
                    Filters
                  </Button>
                </Box>
              </Hide>
            )}

            {!showFilters && selectedTags.length > 0 && (
              <Hide above="md">
                <Box className={`ais-CurrentRefinements-tutorials`}>
                  <HStack className="ais-CurrentRefinements-list" spacing={2}>
                    {selectedTags.map((tag, index) => (
                      <Tag
                        size="md"
                        key={index}
                        sx={{
                          backgroundColor: "transparent", // Replace with your color variable
                        }}
                        className="ais-CurrentRefinements-item"
                      >
                        <TagLabel className="ais-CurrentRefinements-category">
                          {tag}
                          <TagCloseButton
                            className="ais-CurrentRefinements-delete"
                            onClick={() => removeTag(tag)}
                          />
                        </TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                </Box>
              </Hide>
            )}

            {!showFilters &&
              filteredTutorials.map((tutorial: Tutorial, key: number) => (
                <Box key={key}>
                  <TutorialPreview data={tutorial} />
                </Box>
              ))}
          </SimpleGrid>
        ) : (
          <span className="no-tutorials">No Tutorials Found</span>
        )}
      </StackComponent>
    </Box>
  );
};

export default TutorialsLandingPage;
