import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Repayment-Prepayment-configuration
interface repaymentPrepaymentConfigurationProps {
  prePmtMinAmt: string;
  prePmtMinPct: string;
}

// Defining Intitial Value For  Repayment-Prepayment-configuration
const repaymentPrepaymentConfigurationDetails: repaymentPrepaymentConfigurationProps =
  {
    prePmtMinAmt: "",
    prePmtMinPct: "",
  };
// YUP LIBRARY :Defining Schema For validation of  Repayment-Prepayment-configuration
const repaymentPrepaymentConfigurationDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["prePmtMinAmt", "prePmtMinPct"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { repaymentPrepaymentConfigurationProps };
// Exporting Validation Schema and Intial value
export {
  repaymentPrepaymentConfigurationDetails,
  repaymentPrepaymentConfigurationDetailsSchema as getValidationSchema,
};
