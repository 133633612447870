import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For General Setup
interface GeneralSetupProps {
  custRelType: string;
  desc: string;
}

// Defining Intitial Value For General Setup
const generalSetupData: GeneralSetupProps = {
  custRelType: "",
  desc: "",
};

// YUP LIBRARY :Defining Schema For validation of General Setup
const generalSetupDataSchema = async (optionsSchema: PostSchema) => {
  const fields = ["custRelType", "desc"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { GeneralSetupProps };
// Exporting Validation Schema and Intial value
export { generalSetupData, generalSetupDataSchema as getValidationSchema };
