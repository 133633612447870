import { ApiClient, API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getConsoleAccountAdminEndpoint,
  getConsoleAccountAdminEnvEndpoint,
  createUserAccount,
  deleteCustomRole,
  deleteUserAccount,
  revokeUserAccount,
  updateUserAccount,
} from "@/api-config/api-service";
import { CONSOLE_ENDPOINTS } from "@/api-config/urls";

export const getCoreRoles = async (): Promise<any[]> => {
  const api = new ApiClient({
    baseUrl: getConsoleAccountAdminEnvEndpoint(),
  });
  return api.get(`${API_ROUTE_CONFIGURATION.coreRoles}`);
};
export const getEnvironments = async (): Promise<any[]> => {
  const api = new ApiClient({
    baseUrl: getConsoleAccountAdminEndpoint(),
  });
  return api.get(API_ROUTE_CONFIGURATION.amEnvironment);
};
export const updateProfileTemplate = async (formData: any): Promise<any[]> => {
  let body = JSON.stringify(formData);
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.post(`${API_ROUTE_CONFIGURATION.profileTemplate}`, { body });
};
export const updateConsoleRole = async (
  formData: any,
  { toast, toastId, msg }: any
): Promise<any[]> => {
  let body = JSON.stringify(formData);
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return await api
    .put(`${API_ROUTE_CONFIGURATION.consoleRole}`, { body })
    .then((data) => {
      toast({
        id: toastId,
        description: msg,
        status: "success",
      });
      return data;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const createConsoleRole = async (
  formData: any,
  { toast, toastId, msg }: any
): Promise<any[]> => {
  let body = JSON.stringify(formData);
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return await api
    .post(`${API_ROUTE_CONFIGURATION.consoleRole}`, { body })
    .then((data) => {
      toast({
        id: toastId,
        description: msg,
        status: "success",
      });
      return data;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const createNewUser = async (config: any) => {
  const {
    payload,
    profileTemplate,
    userData,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  const userAccountPayload = {
    ...userData,
    ...payload,
    profileTemplates: profileTemplate,
  };
  const response = await createUserAccount({ body: userAccountPayload })
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.error;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: "Unable to save user account",
          status: "error",
        });
      }
      return null;
    });
  return response;
};

export const editUser = async (config: any) => {
  const {
    orgId,
    payload,
    profileTemplate,
    userData,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  let userAccountPayload = {
    ...userData,
    ...payload,
    profileTemplates: profileTemplate,
  };
  const response = await updateUserAccount({
    body: userAccountPayload,
    id: userData?.id,
    orgId,
  })
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.error;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: "Unable to save user account",
          status: "error",
        });
      }
      return null;
    });
  return response;
};

export const deleteUser = async (config: any) => {
  const {
    id,
    forceDelete,
    toastOptions: { toast, toastId, successMessage },
  } = config;
  return await deleteUserAccount({ id: id, forceDelete: forceDelete })
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const deleteRole = async (config: any) => {
  const {
    id,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return await deleteCustomRole({ id: id })
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const revokeUser = async (config: any) => {
  const {
    id,
    toastOptions: { toast, toastId, successMessage },
  } = config;
  return await revokeUserAccount({ id: id })
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
