import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment";
import * as yup from "yup";

interface FinancialCalenderHolidayItem {
  title: string;
  date: Date | null;
  openTm?: string;
  closeTm?: string;
  isAllDay: boolean;
  onDelete?: () => void;
  onUpdate?: (updatedItem: FinancialCalenderHolidayItem) => void;
  errors?: FormikErrors<FinancialCalenderHolidayItem>[];
  touched?: FormikTouched<FinancialCalenderHolidayItem>[];
  index: number;
}

interface FinancialCalenderHolidaysConfigurationProps {
  days: FinancialCalenderHolidayItem[];
  calenderTimezone: string;
  refCalender: string;
}
const financialCalenderHolidayConfiguration: FinancialCalenderHolidaysConfigurationProps =
  {
    days: [],
    calenderTimezone: "UTC-05:00 Eastern Time (US & Canada)",
    refCalender: "Standard",
  };


const financialCalenderHolidayConfigurationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["holidays"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return yup.object().shape(shapeConfig);
};

export type {
  FinancialCalenderHolidaysConfigurationProps,
  FinancialCalenderHolidayItem,
};

export {
  financialCalenderHolidayConfiguration,
  financialCalenderHolidayConfigurationSchema as getValidationSchema,
};
