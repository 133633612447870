import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Accumulated_Transaction_Limit_Configuration
interface LimitConfigProps {
  idx: number | string;
  name: string;
  definedBy: string;
  statGroup: string;
  feeOption: string;
  date: string;
  time: string;
  crAmt: string;
  crCnt: string;
  drAmt: string;
  drCnt: string;
  totAmt: string;
  totCnt: string;
  violationAct: string;
  feeViolated: SplitBtnInputValType;
  violationFee: string;
  feeForViolationOption: string;
}
// Defining Intitial Value For Accumulated_Transaction_Limit_Configuration
const limitConfig: LimitConfigProps = {
  idx: "",
  name: "",
  definedBy: "",
  statGroup: "",
  feeOption: "",
  date: "",
  time: "",
  crAmt: "",
  crCnt: "",
  drAmt: "",
  drCnt: "",
  totAmt: "",
  totCnt: "",
  violationAct: "",
  feeViolated: undefined,
  violationFee: "",
  feeForViolationOption: "",
};
// YUP LIBRARY :Defining Schema For validation of Accumulated_Transaction_Limit_Configuration
const limitConfigSchema = async (optionsSchema: PostSchema) => {
  // declare top level fields specific to this form
  const fields = ["perTrnLimits"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { LimitConfigProps };
// Exporting Validation Schema and Intial value
export { limitConfig, limitConfigSchema as getValidationSchema };
