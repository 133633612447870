"use client";

import { FaFileArrowUp } from "react-icons/fa6";
import { FaRegFile } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import {
  VStack,
  Input,
  Icon,
  Heading,
  Button,
  Flex,
  CloseButton,
  Card,
  Box,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { deleteIconDefault } from "../../../../public/assets";
import { RiDeleteBin6Line } from "react-icons/ri";
import { checkReadOnlyEntitlement, getCurrentDateAndTime, isConsolePages, useUserEntitlement } from "@/utils";
import "./FileUploader.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
interface FileUploaderProps {
  file: File | null;
  setFile: (file: File | null) => void;
  id?: string;
}

const FileUploader = ({ file, setFile, id }: FileUploaderProps) => {
  const uploadRef = useRef<any>(null);
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const checkEntitlement = checkReadOnlyEntitlement(entitlement);
  const setFilesFromButton = () => {
    setFile(uploadRef.current?.files[0]);
  };
  const setFileFromDrop = (files?: any) => {
    setFile(files[0]);
  };
  return (
    <DragAndDrop uploadFile={setFileFromDrop}>
      <VStack spacing={7}>
        <Input
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          display="none"
          ref={uploadRef}
          onChange={setFilesFromButton}
          id={id}
        />
        <VStack spacing={2} className={file ? "disabled" : ""}>
          <Icon as={FaFileArrowUp} boxSize={"20px"} />
          <Heading as="h4" fontSize={"16px"}>
            FILE FORMAT(S)
          </Heading>
        </VStack>
        <Text fontSize={"16px"} className={file ? "disabled" : ""}>
          Drop one (1) file here to upload or
        </Text>
        <Button
          className="app-btn-link choose-file-text"
          isDisabled={(envIsDisabled && isConsolePage) || checkEntitlement || file ? true : false}
          onClick={() => uploadRef.current?.click()}
        >
          Choose file
        </Button>
        {file && (
          <Flex
            width={"100%"}
            p={6}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="file-status-bar"
          >
            <Box>
              <Icon as={FaRegFile} boxSize={6} /> {file.name}
            </Box>
            <HStack gap={1}>
              <Text>{getCurrentDateAndTime()}</Text>
              <Divider className="file-status-divider" orientation="vertical" />
              <Text>{(file.size / 1024).toFixed(2)}KB</Text>
              <Image
                src={deleteIconDefault}
                alt="delete"
                color="black"
                onClick={() => setFile(null)}
              />
            </HStack>
          </Flex>
        )}
      </VStack>
    </DragAndDrop>
  );
};

const DragAndDrop = ({ uploadFile, children }: any) => {
  const drop = useRef<any>(null);
  useEffect(() => {
    drop.current?.addEventListener("dragover", handleDragOver);
    drop.current?.addEventListener("drop", handleDrop);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
    };
  }, []);
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      uploadFile(files);
    }
  };
  return (
    <Card ref={drop} className="files-drag-drop-container">
      {children}
    </Card>
  );
};

export default FileUploader;
