"use client";
import * as prismic from "@prismicio/client";
import { AllDocumentTypes } from "../../prismicio-types";

const dashboardJourneySections = {
  explore_finxact: ["sandbox_overview", "sandbox_api", "enterprise"],
  foundational_setup: [
    "institutional_configuration",
    "general_ledger",
    "transaction_codes",
    "networks",
    "customer_relationship_types",
  ],
  jumpstart_activation: [
    "platform_setup",
    "access_management",
    "discover_features",
    "visit_academy",
    "explore_documentation",
  ],
  product_configuration: [
    "review_products",
    "review_components",
    "configure_products",
  ],
  test_and_deploy: ["testing_checklist", "diagnostics_dashboard"],
};

function getDashboardFormattedDetails(item: any, type: string) {
  const detail = {
    [type]: {
      sectionTitle: item.primary?.section_title,
      sectionSubTitle: item.primary?.section_subtitle,
      header: item.primary?.header,
      description: item.primary?.description,
      resourcesLinks: item.items,
    },
  };
  return detail;
}

export async function getDashboardConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
) {
  const dashboardData = await prismicClient.getByUID(
    "dashboard_config_content",
    "dashboard-config-content-item",
  );

  var exploreFinxactData = {};
  dashboardData.data.explore_finxact.map((item, index) => {
    const detail = getDashboardFormattedDetails(
      item,
      dashboardJourneySections.explore_finxact[index],
    );
    exploreFinxactData = { ...exploreFinxactData, ...detail };
  });

  var foundationalData = {};
  dashboardData.data.foundational_setup.map((item, index) => {
    const detail = getDashboardFormattedDetails(
      item,
      dashboardJourneySections.foundational_setup[index],
    );
    foundationalData = { ...foundationalData, ...detail };
  });

  var jumpstartData = {};
  dashboardData.data.jumpstart_activation.map((item, index) => {
    const detail = getDashboardFormattedDetails(
      item,
      dashboardJourneySections.jumpstart_activation[index],
    );
    jumpstartData = { ...jumpstartData, ...detail };
  });

  var productConfigData = {};
  dashboardData.data.product_configuration.map((item, index) => {
    const detail = getDashboardFormattedDetails(
      item,
      dashboardJourneySections.product_configuration[index],
    );
    productConfigData = { ...productConfigData, ...detail };
  });

  var testAndDeployData = {};
  dashboardData.data.test_and_deploy.map((item, index) => {
    const detail = getDashboardFormattedDetails(
      item,
      dashboardJourneySections.test_and_deploy[index],
    );
    testAndDeployData = {
      ...testAndDeployData,
      ...detail,
      view_link: dashboardData.data.testing_checklist,
    };
  });

  const recentSection = {
    recentTitle: dashboardData.data.recent_title,
    recentSubTitle: dashboardData.data.recent_subtitle,
  };
  const resources = dashboardData.data.resources_section.length
    ? dashboardData.data.resources_section[0]
    : null;
  const resourceSection = resources
    ? {
        sectionTitle: resources?.primary?.resources_title,
        sectionSubTitle: resources?.primary?.resources_subheader,
        resourcesLinks: resources?.items,
      }
    : {};
  const upgradeVersionLink = dashboardData.data.upgrade_version_link;
  const dashboardDetails = {
    explore_finxact: exploreFinxactData,
    jumpstart_activation: jumpstartData,
    foundational_setup: foundationalData,
    product_configuration: productConfigData,
    test_and_deploy: testAndDeployData,
    recent_section: recentSection,
    resources_section: resourceSection,
    upgradeVersionLink: upgradeVersionLink,
  };
  return dashboardDetails;
}
