"use client";

import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  SplitButton,
} from "@/components/common";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import {
  CompositeFieldConfigValue,
  CompositeDelimitedProps,
  CompositieFieldConfigProps,
  defaultCompositeDelimitedVal,
  defaultCompositieFieldConfig,
  getValidationSchema,
} from "./CompositeFieldConfigurationValidation";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { AccountGroupData } from "../../model";
import { updateAccountGroup } from "../../ic-config-api-service";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type CompositeFieldConfigurationProps = {
  data: {
    formData: AccountGroupData | null;
    accountGroupOptionsData: PostSchema;
  };
};

const CompositeFieldConfiguration = (
  props: CompositeFieldConfigurationProps
) => {
  const toast = useToast();
  const compositeFieldData = props.data?.formData ?? null;
  const activeAccordionRef = useRef<any>();
  const { accountGroupOptionsData } = props.data;

  const onSubmit = async (values: CompositeFieldConfigValue) => {
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (
        shouldUpdateWorkflowStatus(
          entityWorkflowData!,
          getCurrentStageStatusName()
        )
      ) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctGroup!,
          stage: IC_ENTITY.account_group,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      navigateToNextPage?.();
      return;
    }
    const delimValues = values.posnDelim
      .filter(Boolean)
      .map((item) => item.delimitedName);
    const payload: Partial<Pick<AccountGroupData, "posnFixed" | "posnDelim">> =
      {
        posnFixed: values.posnFixed.map((field) => ({
          field: field.field,
          isVarLength: field.isVarLength ?? null,
          prefix: parseFloat(field.prefix),
          size: parseFloat(field.size),
          offset: Number(field.offset),
        })),
      };
    if (delimValues?.length) {
      payload.posnDelim = delimValues;
    }
    const res = await updateAccountGroup({
      formData: {
        _vn: props.data.formData?._vn,
        acctGroup: props.data.formData?.acctGroup,
        // PATCH Feilds
        ...payload,
      },
      toastOptions: {
        toast: toast,
        toastId: "composite-field-configuration",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(
        entityWorkflowData!,
        getCurrentStageStatusName()
      )
        ? getCurrentStageStatusName()
        : "",
    });
    if (res) {
      navigateToNextPage?.();
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    if (key === "delimitedName") {
      setFieldValue(`posnDelim.${index}.${key}`, value);
      return;
    }
    setFieldValue(`posnFixed.${index}.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`posnFixed.${index}.${key}`, true);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(accountGroupOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [accountGroupOptionsData]);

  let initialCompositeFixedFields =
    compositeFieldData?.posnFixed?.map((field, idx) => ({
      idx: idx,
      field: field.field,
      isVarLength: field.isVarLength ?? undefined,
      prefix: field.prefix?.toString(),
      size: field.size?.toString(),
      offset: field.offset,
    })) ?? [];

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      posnFixed: initialCompositeFixedFields,
      posnDelim:
        compositeFieldData?.posnDelim?.map((item) => {
          return {
            delimitedName: item,
          };
        }) ?? [],
    },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form">
        <FieldArray
          name="posnFixed"
          render={({ remove, push }) => (
            <Box className="app-form-field-array-container">
              {values.posnFixed.map((field, index) => (
                <Box key={index} ref={activeAccordionRef}>
                  <CardContainer key={field.idx}>
                    <AccordionComponent
                      accordionTitle={`Composite Fixed ${index + 1}`}
                      description={field.field}
                      deleteIcon
                      onDeleteHandler={() => remove(index)}
                      isExpand={values.posnFixed.length !== index + 1 ? 1 : 0}
                    >
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the composite fixed field name?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("field")
                              ?.tooltip_text_main
                          }
                        />
                        <InputText
                          value={field.field}
                          onChange={handleOnChange("field", index)}
                          regex={REGEX_PATTERN.ALPHA_NUMERIC}
                          onBlur={handleOnBlur("field", index)}
                          label={`field${index}`}
                        />
                        {touched.posnFixed?.[index]?.field &&
                          (
                            errors.posnFixed?.[
                              index
                            ] as FormikErrors<CompositieFieldConfigProps>
                          )?.field && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnFixed?.[
                                      index
                                    ] as FormikErrors<CompositieFieldConfigProps>
                                  ).field
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="Is there a variable length field?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "isVarLength"
                            )?.tooltip_text_main
                          }
                        />
                        <SplitButton
                          value={field.isVarLength}
                          onSelect={handleOnChange("isVarLength", index)}
                          leftBtnName="Yes"
                          rightBtnName="No"
                          onBlur={handleOnBlur("isVarLength", index)}
                        />
                        {touched.posnFixed?.[index]?.isVarLength &&
                          (
                            errors.posnFixed?.[
                              index
                            ] as FormikErrors<CompositieFieldConfigProps>
                          )?.isVarLength && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnFixed?.[
                                      index
                                    ] as FormikErrors<CompositieFieldConfigProps>
                                  ).isVarLength
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel labelName="What is the offset?" />
                        <AppNumberInput
                          value={field.offset || ""}
                          onChange={handleOnChange("offset", index)}
                          onBlur={handleOnBlur("offset", index)}
                          label={`offset${index}`}
                        />
                        {touched.posnFixed?.[index]?.offset &&
                          (
                            errors.posnFixed?.[
                              index
                            ] as FormikErrors<CompositieFieldConfigProps>
                          )?.offset && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnFixed?.[
                                      index
                                    ] as FormikErrors<CompositieFieldConfigProps>
                                  ).offset
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the prefix?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("prefix")
                              ?.tooltip_text_main
                          }
                        />
                        <AppNumberInput
                          value={field.prefix}
                          onChange={handleOnChange("prefix", index)}
                          onBlur={handleOnBlur("prefix", index)}
                          label={`prefix${index}`}
                        />
                        {touched.posnFixed?.[index]?.prefix &&
                          (
                            errors.posnFixed?.[
                              index
                            ] as FormikErrors<CompositieFieldConfigProps>
                          )?.prefix && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnFixed?.[
                                      index
                                    ] as FormikErrors<CompositieFieldConfigProps>
                                  ).prefix
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the size of the field in number of digits?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("size")
                              ?.tooltip_text_main
                          }
                        />
                        <AppNumberInput
                          value={field.size}
                          onChange={handleOnChange("size", index)}
                          onBlur={handleOnBlur("size", index)}
                          label={`size${index}`}
                        />
                        {touched.posnFixed?.[index]?.size &&
                          (
                            errors.posnFixed?.[
                              index
                            ] as FormikErrors<CompositieFieldConfigProps>
                          )?.size && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnFixed?.[
                                      index
                                    ] as FormikErrors<CompositieFieldConfigProps>
                                  ).size
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                    </AccordionComponent>
                  </CardContainer>
                </Box>
              ))}
              <AddNewButton
                label={
                  !values.posnFixed.length
                    ? "Add a composite fixed"
                    : "Add another composite fixed"
                }
                onClick={() => {
                  push({
                    ...defaultCompositieFieldConfig,
                    idx: Math.random(),
                  });
                }}
              />
            </Box>
          )}
        ></FieldArray>
        <FieldArray
          name="posnDelim"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              {values.posnDelim.map((field, index) => (
                <Box
                  key={`compDelimitedContainer${index}`}
                  ref={activeAccordionRef}
                >
                  <CardContainer key={`compDelimitedSection${index}`}>
                    <AccordionComponent
                      key={`compDelimitedContainerAccr${index}`}
                      accordionTitle={`Composite delimited ${index + 1}`}
                      description={field.delimitedName}
                      deleteIcon
                      onDeleteHandler={() => remove(index)}
                      isExpand={values.posnDelim.length !== index + 1 ? 1 : 0}
                    >
                      <Box className="app-form-field-container">
                        <AppFormLabel labelName="What is the composite delimited field name?" />
                        <InputText
                          value={field.delimitedName}
                          onChange={handleOnChange("delimitedName", index)}
                          onBlur={handleOnBlur("delimitedName", index)}
                          label={`delimitedName${index}`}
                        />
                        {touched.posnDelim?.[index]?.delimitedName &&
                          (
                            errors.posnDelim?.[
                              index
                            ] as FormikErrors<CompositeDelimitedProps>
                          )?.delimitedName && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.posnDelim?.[
                                      index
                                    ] as FormikErrors<CompositeDelimitedProps>
                                  ).delimitedName
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                    </AccordionComponent>
                  </CardContainer>
                </Box>
              ))}
              <AddNewButton
                className="add-new-button-composite"
                label={
                  !values.posnDelim.length
                    ? "Add new composite delimited"
                    : "Add another composite delimited"
                }
                onClick={() => {
                  push({
                    ...defaultCompositeDelimitedVal,
                  });
                }}
              />
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
};

export default CompositeFieldConfiguration;
