// components/CertificationDescription.tsx
import React from "react";
import {
  Box,
  Divider,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import styles from "./CertificationDescription.module.scss";

interface CertificationDescriptionProps {
  completionImageSrc: string;
  title: string;
  descriptionText: string;
  overviewText: string;
  progressText: string;
}

const CertificationDescription = ({
  completionImageSrc,
  title,
  descriptionText,
  overviewText,
  progressText,
}: CertificationDescriptionProps) => {
  return (
    <Box className={styles.container}>
      <VStack className={styles.descriptionStack} align="stretch">
        <Box className={styles.card}>
          <HStack className={styles.cardStack} align="stretch">
            <Image src={completionImageSrc} className={styles.imageStyle} alt="badge image"/>
            <VStack className={styles.cardText} align="stretch">
              <Text className={styles.titleText}>{title}</Text>
              <Text className={styles.descriotionText}>{descriptionText}</Text>
            </VStack>
          </HStack>
        </Box>
        <Text
          className={styles.overviewText}
          dangerouslySetInnerHTML={{ __html: overviewText }}
        />
      </VStack>
    </Box>
  );
};

export default CertificationDescription;
