import * as Yup from "yup";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
//Defining Interface For Financial_Organization_Other_Identification
interface OtherIdentificationDetailsValueProps {
  addOtherIdentifications: SplitBtnInputValType;
  otherIds: { otherIdType: string; otherId: string }[];
}
// Defining Intitial Value For Financial_Organization_Other_Identification
const otherIdentificationDetails: OtherIdentificationDetailsValueProps = {
  addOtherIdentifications: undefined,
  otherIds: [{ otherIdType: "", otherId: "" }],
};
// YUP LIBRARY :Defining Schema For validation of Financial_Organization_Other_Identification

const otherIdentificationDetailsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["otherIds"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { OtherIdentificationDetailsValueProps };
// Exporting Validation Schema and Intial value
export {
  otherIdentificationDetails,
  otherIdentificationDetailsSchema as getValidationSchema,
};
