import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Team-Maturity-Configuration
interface MaturityConfigurationSetupProps {
  maturityOpt: number | undefined;
  notice: string;
}
// Defining Intitial Value For Team-Maturity-Configuration
const maturityConfigurationSetupDetails: MaturityConfigurationSetupProps = {
  maturityOpt: undefined,
  notice: "",
};
// YUP LIBRARY :Defining Schema For validation of Team-Maturity-Configuration
const maturityConfigurationSetupDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rollDrGrace", "rollCrGrace", "crTermExt", "rollDrGraceAdj"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { MaturityConfigurationSetupProps };
// Exporting Validation Schema and Intial value
export {
  maturityConfigurationSetupDetails,
  maturityConfigurationSetupDetailsSchema as getValidationSchema,
};
