"use client";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  SplitButton,
  SelectDropdown,
} from "@/components/common";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import {
  CorrespondingNetworkIdentificationValueProps,
  correspondingNetworkIdentificationDetails,
  getValidationSchema,
} from "./CorrespondingNetworkIdentificationValidation";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { CorrespondingNetworkDetailsProps } from "@/components/institutional-configuration/model";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  deepCopy,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { useSearchParams } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type OtherIdentificationDetailsProps = {
  data: any;
};
const CorrespondingNetworkIdentification = (
  props: OtherIdentificationDetailsProps
) => {
  const {
    data: { formData, defaultNetworkOptions },
  } = props;
  const toast = useToast();
  const queryParams = useSearchParams();
  const toastId = "financial-organization";
  const updateQueryParams = useQueryParams();

  const { partyOrgFininstOptionsData } = props.data;

  const onSubmit = async (
    values: CorrespondingNetworkIdentificationValueProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_corr_network_identification;
    let corrsPayload: CorrespondingNetworkDetailsProps[] = [];
    const corrsIndex = getCorrsIndexFromParam();
    let url = `${
      ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"]
    }?${updateQueryParams(QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"], corrsIndex)}`;
    if (!values.correspondentNetwork) {
      url = `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"]}?${updateQueryParams()}`;
    }
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      if (!canCheckFormStatus) {
        navigateTo?.(url);
      }
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    let corrsPaylod = {
      clearingGlPosnId: values.clearingGlPosnId || "",
      inProcessGlPosnId: values.inProcessGlPosnId || "",
      network: values.network || "",
    };
    if (corrsIndex && formData?.corrs?.length) {
      if (!formData.corrs[corrsIndex]) {
        formData.corrs[corrsIndex] = [];
      }
      formData.corrs[corrsIndex] = {
        ...formData.corrs[corrsIndex],
        ...corrsPaylod,
      };
      if (!values.correspondentNetwork) {
        // delete index in array
        formData.corrs.splice(corrsIndex, 1);
      }
      corrsPayload = [...formData.corrs];
    } else {
      corrsPayload = [];
      if (values.correspondentNetwork) {
        corrsPayload = [corrsPaylod];
        url = `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"]
        }?${updateQueryParams(
          QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"],
          corrsIndex || "0"
        )}`;
      }
    }

    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData._vn,
        _Id: props.data.formData._Id,
        corrs: corrsPayload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      if (!canCheckFormStatus) {
        navigateTo?.(url);
      }
    }
  };

  const handleOnChange = (key: string) => (value: any) => {
    let selectedObject;
    switch (key) {
      case "network":
        selectedObject =
          defaultNetworkOptions?.find(
            (obj: { label: string; value: number }) => obj.label === value
          ) || [];
        break;
    }
    setFieldValue(key, selectedObject?.value ? selectedObject?.value : value);
  };

  function constructFormInitialValues() {
    const corrsIndex = getCorrsIndexFromParam();
    if (corrsIndex && formData?.corrs?.length) {
      const currCorrsDetails = formData.corrs[corrsIndex]
        ? deepCopy(formData.corrs[corrsIndex])
        : "";
      if (!currCorrsDetails) {
        return deepCopy(correspondingNetworkIdentificationDetails);
      }
      return {
        correspondentNetwork:
          currCorrsDetails?.clearingGlPosnId ||
          currCorrsDetails?.inProcessGlPosnId ||
          currCorrsDetails?.network
            ? true
            : currCorrsDetails
              ? false
              : null,
        clearingGlPosnId: currCorrsDetails?.clearingGlPosnId || "",
        inProcessGlPosnId: currCorrsDetails?.inProcessGlPosnId || "",
        network: currCorrsDetails?.network || "",
      };
    }
    return deepCopy(correspondingNetworkIdentificationDetails);
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      const validation = yupSchema.fields.corrs;
      setValidationSchema(validation);
    })();
  }, [partyOrgFininstOptionsData]);

  let formInitialValues: any = constructFormInitialValues();

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: formInitialValues,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  function getCorrsIndexFromParam() {
    return (
      queryParams?.get(QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"])?.toString() || ""
    );
  }

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo?.(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS"]
        }?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-field-container">
        <Text className="content-box-title" as="h3">
          Corresponding network details - 1 of 3
        </Text>
      </Box>
      <CardContainer>
        <Box className="app-form-field-container">
          <Text className="content-box-title" as="h3">
            Corresponding network identification
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Add correspondent network?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("correspondentNetwork")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="correspondentNetwork"
            onSelect={handleOnChange("correspondentNetwork")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.correspondentNetwork}
          />
          {errors.correspondentNetwork && touched.correspondentNetwork && (
            <ErrorAlert>
              <span>{errors.correspondentNetwork}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.correspondentNetwork && (
          <>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the correspondent clearing GL account identifier?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("clearingGlPosnId")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.clearingGlPosnId}
                onChange={handleOnChange("clearingGlPosnId")}
                onBlur={handleBlur}
              />
              {errors.clearingGlPosnId && touched.clearingGlPosnId && (
                <ErrorAlert>
                  <span>{errors.clearingGlPosnId}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the correspondent InProcess GL account identifier?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("inProcessGlPosnId")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.inProcessGlPosnId}
                onChange={handleOnChange("inProcessGlPosnId")}
                onBlur={handleBlur}
              />
              {errors.inProcessGlPosnId && touched.inProcessGlPosnId && (
                <ErrorAlert>
                  <span>{errors.inProcessGlPosnId}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the network?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("network")
                    ?.tooltip_text_main
                }
                labelFor="corrsNetworkId"
              />
              <SelectDropdown
                id="corrsNetworkId"
                value={
                  defaultNetworkOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.network
                  )?.label || ""
                }
                onChange={handleOnChange("network")}
                onBlur={() => setFieldTouched("network")}
                placeholder="Select asset identifier"
                dropdownList={
                  defaultNetworkOptions?.map(
                    (d: { label: string }) => d.label
                  ) || []
                }
              />
              {errors.network && touched.network && (
                <ErrorAlert>
                  <span>{errors.network}</span>
                </ErrorAlert>
              )}
            </Box>
          </>
        )}
      </CardContainer>
    </form>
  );
};
export default CorrespondingNetworkIdentification;
