import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Limit_Restriction_Configuration
interface RestrictionConfigurationProps {
  restrictCrFundExp: SplitBtnInputValType;
  restrictCr: SplitBtnInputValType;
  restrictDr: SplitBtnInputValType;
}
// Defining Intitial Value For Limit_Restriction_Configuration
const restrictionConfiguration: RestrictionConfigurationProps = {
  restrictCrFundExp: undefined,
  restrictCr: undefined,
  restrictDr: undefined,
};

// YUP LIBRARY : Defining Schema For Validation Of Limit_Restriction_Configuration
const accountBalanceConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["restrictCrFundExp", "restrictCr", "restrictDr"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RestrictionConfigurationProps };
// Exporting Validation Schema and Intial value
export {
  restrictionConfiguration,
  accountBalanceConfigurationSchema as getValidationSchema,
};
