"use client";

import { CardContainer, CustomTable } from "@/components/common";
import {
  Box,
  Button,
  TableContainer,
  Text,
  ChevronRightIcon,
  VStack,
  Heading,
  Image,
} from "@/components/ChakraUiManager";
import { useEffect, useState } from "react";
import { getAllSessions } from "@/client-api-manager/alm-api";
import "./InstructorCourses.scss";
import Link from "next/link";
import { HeaderData, LearningObjectType } from "@/models/academy-models";
import { INSTRUCTOR_SESSION_CONFIG_TABLE_HEADER } from "@/components/data/form-data";
import { formatDate } from "../../learning-util-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import { noCatalogFound } from "public/assets";

type CourseType = {
  id: string;
  attributes: any;
};

const renderDataCell = (value: any, key: string) => {
  switch (key) {
    case "id": {
      return (
        <Box className="view-btn-container">
          <Link
            href={`${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${value}&from=my-learning`}
          >
            <Button
              className="app-btn-reg-secondary-transparent"
              rightIcon={<ChevronRightIcon boxSize={"1.8rem"} />}
            >
              View
            </Button>
          </Link>
        </Box>
      );
    }
    case "date": {
      return formatDate(value);
    }
    default: {
      return value;
    }
  }
};

const mapCourseData = (course: CourseType[]) => {
  return course.map((item: any) => ({
    id: item.id,
    name: item.attributes.localizedMetadata[0].name,
    description: item.attributes.localizedMetadata[0].description,
    date: item.attributes.dateCreated,
  }));
};

export default function InstructorCourse({
  headerData,
}: {
  headerData: HeaderData;
}) {
  const [instructorCourses, setInstructorCourses] = useState<
    LearningObjectType[]
  >([]);
  useEffect(() => {
    const getData = async () => {
      const data = await getAllSessions("Classroom,Virtual Classroom");
      if (data?.data?.data.length > 0) {
        const course = data.data.data.map((item: any) => {
          const deadLineDate = data.data.included.find(
            (i: any) => i.id.includes(item.id) && i.type === "resource"
          );
          return {
            id: item.id,
            name: item.attributes.localizedMetadata[0].name,
            description: item.attributes.localizedMetadata[0].description,
            date: deadLineDate?.attributes.completionDeadline,
          };
        });
        setInstructorCourses(course);
      }
    };
    getData();
  }, []);
  return (
    <CardContainer>
      <Box style={{ paddingLeft: "3rem", paddingTop: "3rem" }}>
        <Text as="h3" className="instructor-title">
          {headerData?.headline || "Upcoming instructor-led sessions"}
        </Text>
        <Text as="p" className="instructor-subtext">
          {headerData?.subhead || "Sign up for instructor-led sessions."}
        </Text>
      </Box>
      <TableContainer className="instructor-session" m="3rem" mt="1.5rem">
        {instructorCourses.length ? (
          <CustomTable
            data={instructorCourses}
            columns={INSTRUCTOR_SESSION_CONFIG_TABLE_HEADER}
            renderDataCell={renderDataCell}
            className="instructor-session"
            isPagination={false}
          />
        ) : (
          <VStack w={"100%"} justifyContent={"center"} mt={20} gap={6}>
            <Image src={noCatalogFound.src} alt={"No courses found"} mb={4} />
            <Heading as="h3" fontSize={24}>
              There are no upcoming instructor-led sessions
            </Heading>
          </VStack>
        )}
      </TableContainer>
    </CardContainer>
  );
}
