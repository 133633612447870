import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Foundational_Details
interface GeneralPartySpecificationsProps {
  isDataShare: SplitBtnInputValType;
}
// Defining Intitial Value For Promotional_Rate_Foundational_Details
const generalPartySpecificationsDetails: GeneralPartySpecificationsProps = {
  isDataShare: undefined,
};

const generalPartySpecificationsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["isDataShare"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
  
  
// Exporting Interface
export type { GeneralPartySpecificationsProps };
// Exporting Validation Schema and Intial value
export {
    generalPartySpecificationsDetails,
    generalPartySpecificationsSchema as getValidationSchema,
};
