import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import { SrvRecord } from "dns";
import * as Yup from "yup";

//Defining Interface For Term-Penalties-Adjustment
interface TermPenaltiesAndAdjustmentProps {
  earlyDrPen: number | undefined;
  penMatrix: string;
  penaltyMatrixSelector: string;
  penTrnCode: string;
  bumpLeadDays: string;
  skipIntPost: SplitBtnInputValType;
}

// Defining Intitial Value For Term-Penalties-Adjustment
const termPenaltiesAndAdjustmentDetails: TermPenaltiesAndAdjustmentProps = {
  earlyDrPen: undefined,
  penMatrix: "",
  penaltyMatrixSelector: "",
  penTrnCode: "",
  bumpLeadDays: "",
  skipIntPost: undefined,
};

const termPenaltiesAndAdjustmentDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "earlyDrPen",
    "penMatrix",
    "penaltyMatrixSelector",
    "penTrnCode",
    "bumpLeadDays",
    "skipIntPost",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { TermPenaltiesAndAdjustmentProps };
// Exporting Validation Schema and Intial value
export {
  termPenaltiesAndAdjustmentDetails,
  termPenaltiesAndAdjustmentDetailsSchema as getValidationSchema,
};
