import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { nominalError } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Foundational_Details
interface PromotionalRateFoundationProps {
  addPromotionalRateDetails: SplitBtnInputValType;
  promoDtl: {
    indexName: string;
    firstDur: string;
    nomRate: string;
    promoCode: string;
    promoTerm: string;
  };
}
// Defining Intitial Value For Promotional_Rate_Foundational_Details
const promotionalRateFoundationDetails: PromotionalRateFoundationProps = {
  addPromotionalRateDetails: undefined,
  promoDtl: {
    indexName: "",
    firstDur: "",
    nomRate: "",
    promoCode: "",
    promoTerm: "",
  },
};
// YUP LIBRARY :Defining Schema For validation of Promotional_Rate_Foundational_Details
const promotionalRateFoundationDetailSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["promoDtl"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PromotionalRateFoundationProps };
// Exporting Validation Schema and Intial value
export {
  promotionalRateFoundationDetails,
  promotionalRateFoundationDetailSchema as getValidationSchema,
};
