import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Review_Frequency_&_Rounding
interface PromotionalRateFrequencyProps {
  isPostNetInt: SplitBtnInputValType;
  postFreq: string;
  postFreqMatrix: string;
  postRoundOpt: number | null;
}
// Defining Intitial Value for Lone_Post_Frequency_&_Rounding
const promotionalRateFrequency: PromotionalRateFrequencyProps = {
  isPostNetInt: undefined,
  postFreq: "",
  postFreqMatrix: "",
  postRoundOpt: null,
};
// YUP LIBRARY :Defining Schema For validation of Lone_Post_Frequency_&_Rounding
const promotionalRateFrequencySchema = async (optionsSchema: PostSchema) => {
  const fields = ["isPostNetInt", "postRoundOpt", "postFreq", "postFreqMatrix"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PromotionalRateFrequencyProps };
// Exporting Validation Schema and Intial value
export {
  promotionalRateFrequency,
  promotionalRateFrequencySchema as getValidationSchema,
};
