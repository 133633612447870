import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";

//Defining Interface For Party_Phone_Details
interface GeneralLimitProps {
    addLimit: SplitBtnInputValType;
    partyIndentifier: string;
}
// Defining Intitial Value For Party_Phone_Details
const generalLimitInformation: GeneralLimitProps = {
    addLimit: undefined,
    partyIndentifier: "",
};
// YUP LIBRARY :Defining Schema For validation of Party_Phone_Details
const generalLimitInformationSchema = Yup.object({
   addLimit: Yup.boolean(),
});
// Exporting Interface
export type { GeneralLimitProps };
// Exporting Validation Schema and Intial value
export {
    generalLimitInformation,
  generalLimitInformationSchema as validationSchema,
};
