import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";

//Defining Interface For Us_Specification_Financial_Organization
interface USSpecificationFinancialOrganization {
  firstNoticeDate: Date | null;
  secondNoticeDate: Date | null;
  employeeStatus: string;
  naicsCode: string;
  isW8filled: SplitBtnInputValType;
  isW9filled: SplitBtnInputValType;
  w8receivedDate: Date | null;
  w9receivedDate: Date | null;
  federalWithHolding: SplitBtnInputValType;
  nraWithHolding: SplitBtnInputValType;
  stateWithHolding: SplitBtnInputValType;
  irsConsent: SplitBtnInputValType;
  electronicLoanBill: SplitBtnInputValType;
  electronicStatement: SplitBtnInputValType;
  paperLoanBill: SplitBtnInputValType;
  paperStatement: SplitBtnInputValType;
  irsConsentDate: any;
}
// Defining Intitial Value For General_Party_Parameter
const USSpecificationFinancialOrganizationParameter: USSpecificationFinancialOrganization =
  {
    firstNoticeDate: null,
    secondNoticeDate: null,
    employeeStatus: "",
    naicsCode: "",
    isW8filled: undefined,
    isW9filled: undefined,
    w8receivedDate: null,
    w9receivedDate: null,
    federalWithHolding: undefined,
    nraWithHolding: undefined,
    stateWithHolding: undefined,
    electronicLoanBill: undefined,
    electronicStatement: undefined,
    paperLoanBill: undefined,
    paperStatement: undefined,
    irsConsent: undefined,
    irsConsentDate: "",
  };
// YUP LIBRARY :Defining Schema For validation of General_Party_Parameter
const USSpecificationFinancialOrganizationParameterSchema = Yup.object().shape({
  firstNoticeDate: Yup.date().required(GENERIC_ERROR.required),
  secondNoticeDate: Yup.date().required(GENERIC_ERROR.required),
  employeeStatus: Yup.string().required(GENERIC_ERROR.required),
  naicsCode: Yup.string().required(GENERIC_ERROR.required),
  isW8filled: Yup.boolean(),
  isW9filled: Yup.boolean(),
  w8receivedDate: Yup.date().required(GENERIC_ERROR.required),
  w9receivedDate: Yup.date().required(GENERIC_ERROR.required),
  federalWithHolding: Yup.boolean(),
  nraWithHolding: Yup.boolean(),
  stateWithHolding: Yup.boolean(),
  electronicLoanBill: Yup.boolean(),
  electronicStatement: Yup.boolean(),
  paperLoanBill: Yup.boolean(),
  paperStatement: Yup.boolean(),
  irsConsent: Yup.boolean(),
  irsConsentDate: Yup.date(),
});
// Exporting Interface
export type { USSpecificationFinancialOrganization };
// Exporting Validation Schema and Intial value
export {
  USSpecificationFinancialOrganizationParameter,
  USSpecificationFinancialOrganizationParameterSchema as validationSchema,
};
