"use client";
import { useContext, useEffect, useState } from "react";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  RadioButtonGroup,
  SplitButton,
  TimeField,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  InterestAccrualDetailsProps,
  interestAccrual,
  getValidationSchema,
} from "./InterestAccrualValidation";
import { useFormik } from "formik";
import { Option } from "@/utils/types";
import { InterestComponentData, PostSchema } from "../../../model/types";
import { createInterestComponent } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    negativeAccruedOptions: Option<number>[];
    accrualCarryoverOptions: Option<number>[];
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

const InterestAccrual = (props: Props) => {
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: InterestAccrualDetailsProps) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            negAccrOpt: values.negAccrOpt,
            accrCalcTm: values.accrCalcTm,
            accrCarryOverOpt: values.accrCarryOverOpt,
            isCompoundDly: values.isCompoundDly,
          },
          toastOptions: {
            toast,
            toastId: "interest-accrual",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      accrCarryOverOpt:
        formData?.accrCarryOverOpt ?? interestAccrual.accrCarryOverOpt,
      isCompoundDly: formData?.isCompoundDly ?? undefined,
      accrCalcTm: formData?.accrCalcTm ?? interestAccrual.accrCalcTm,
      negAccrOpt: formData?.negAccrOpt ?? interestAccrual.negAccrOpt,
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMainMenuIndex,
    sideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const interestAccrualFormHandler =
    (key: string) => (value: string | SplitBtnInputValType | number) => {
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What processing method should the Core apply when a position has a net negative accrued interest balance?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("negAccrOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.negAccrOpt}
            onChange={interestAccrualFormHandler("negAccrOpt")}
            radioPropList={props.data.negativeAccruedOptions}
            stackDirection={"column"}
            spacing={"1rem"}
            onBlur={handleBlur}
          />
          {errors.negAccrOpt && touched.negAccrOpt && (
            <ErrorAlert>
              <span>{errors.negAccrOpt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the accrual calculation time?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accrCalcTm")
                ?.tooltip_text_main
            }
          />
          <TimeField
            ariaLabel="accural calculation time"
            value={values.accrCalcTm}
            onChange={interestAccrualFormHandler("accrCalcTm")}
            onBlur={handleBlur}
          />
          {errors.accrCalcTm && touched.accrCalcTm && (
            <ErrorAlert>
              <span>{errors.accrCalcTm}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is the interest compounded daily at the accrual cutoff time?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isCompoundDly")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={interestAccrualFormHandler("isCompoundDly")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isCompoundDly}
            onBlur={handleBlur}
          />
          {errors.isCompoundDly && touched.isCompoundDly && (
            <ErrorAlert>
              <span>{errors.isCompoundDly}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What should be the Interest accrual carryover approach?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accrCarryOverOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.accrCarryOverOpt}
            onChange={interestAccrualFormHandler("accrCarryOverOpt")}
            radioPropList={props.data.accrualCarryoverOptions}
            stackDirection={"column"}
            spacing={"1rem"}
            onBlur={handleBlur}
          />
          {errors.accrCarryOverOpt && touched.accrCarryOverOpt && (
            <ErrorAlert>
              <span>{errors.accrCarryOverOpt}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default InterestAccrual;
