"use client";
import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  InputText,
  SplitBtnInputValType,
  SplitButton,
} from "@/components/common";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import {
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
  useQueryParams,
} from "@/utils";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  constructTcEntryPayload,
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

const TransAccountingSegments = (props: any) => {
  const [expButton, setExpButton] = useState<SplitBtnInputValType>(true);

  const initialValue: { [key: string]: string } = {};
  props.data?.list?.data.forEach((item: any) => {
    initialValue[item.acctgSeg] = "";
  });

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    getQueryParam,
    entityWorkflowData,
    sideBarSubMenuIndex,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "tc-accounting-segement";
  const updateQueryParams = useQueryParams();
  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.TC_ENTRY_INDEX) || 0;
  const entrySetupStatus = entityWorkflowData?.filter(
    (wfd) =>
      wfd.stage === TC_STAGE_STATUS.entry_setup &&
      wfd.status === WORKFLOW_STATUS.completed
  )?.[0]?.status;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: props?.data?.formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_account_segments,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };

    let payload = constructTcEntryPayload(
      props.data.formData,
      {
        ...(expButton
          ? { acctgSegExpr: values.acctgSegExpr }
          : { acctgSegExpr: null }),
        ...(expButton === false
          ? { acctgSeg: values.acctgSeg }
          : { acctgSeg: null }),
      },
      Number(currEntriesIndex)
    );
    const response = await updateTransCode({
      formData: {
        ...payload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : entrySetupStatus
            ? entrySetupStatus
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_account_segments,
                entityWorkflowData
              ),
    });

    if (response) {
      moveToNextStep();
    }
  };
  const initTextFldVal = props.data?.formData?.entries[0]?.acctgSegExpr;
  const initDrpdwnVal = props.data?.formData?.entries[0]?.acctgSeg;
  // using useFormik hook from Formik Library
  const { values, handleSubmit, handleBlur, setFieldValue, dirty } = useFormik({
    initialValues: {
      acctgSeg: initDrpdwnVal ? initDrpdwnVal : initialValue,
      acctgSegExpr: initTextFldVal ? initTextFldVal : initialValue,
    },
    onSubmit,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    setExpButton(initDrpdwnVal ? false : true);
  }, []);

  //form handler
  const acntSgmntFomHandler =
    (key: string, handleOnChange: string) => (value: string) => {
      if (handleOnChange === "acctgSegExpr") {
        setFieldValue(`acctgSegExpr.${key}`, value);
      } else {
        setFieldValue(`acctgSeg.${key}`, value);
      }
    };
  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_account_segments
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <Box display="flex" justifyContent="right" pt={2}>
            <SplitButton
              leftBtnName="Expr on"
              rightBtnName="Expr off"
              value={expButton}
              onSelect={setExpButton}
            />
          </Box>
        </Box>
        {expButton && (
          <>
            {props.data?.list?.data?.map((item: any, index: number) => (
              <Box
                className="app-form-field-container"
                key={`accSegExp${item?.desc}${index}`}
              >
                <AppFormLabel
                  labelName={`Enter the expression for ${item?.desc}`}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("acctgSegExpr")
                      ?.tooltip_text_main
                  }
                  labelFor={item?.acctgSeg}
                />
                <InputText
                  id={item?.acctgSeg}
                  value={values.acctgSegExpr[item?.acctgSeg]}
                  onChange={acntSgmntFomHandler(item?.acctgSeg, "acctgSegExpr")}
                  onBlur={handleBlur}
                  textarea
                />
              </Box>
            ))}
          </>
        )}
        {expButton === false && (
          <>
            {props.data?.list?.data?.map((item: any, index: number) => (
              <Box
                className="app-form-field-container"
                key={`accDomainSeg${index}`}
              >
                <AppFormLabel
                  labelName={`Select the accounting segment domain value for ${item?.desc}`}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("acctgSeg")
                      ?.tooltip_text_main
                  }
                  labelFor={`${item?.acctgSeg}_input`}
                />
                <SelectDropdown
                  id={item?.acctgSeg}
                  value={values.acctgSeg[item?.acctgSeg]}
                  onChange={acntSgmntFomHandler(item?.acctgSeg, "acctgSeg")}
                  dropdownList={
                    item.domainVals?.length > 0
                      ? item.domainVals.map((dmnVal: any) => ({
                          label: dmnVal?.acctgSegDesc,
                          value: dmnVal?.acctgSegVal,
                        }))
                      : []
                  }
                />
              </Box>
            ))}
          </>
        )}
      </CardContainer>
    </form>
  );
};
export default TransAccountingSegments;
