import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Party_Phone_Details
interface PartyPhoneDetailsProps {
  phoneType: string;
  cntryCallCode: string;
  data: string;
  extension: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  numberValidity: Date | null;
  numberValidityTime: string | null;
  numberValidThrough: Date | null;
  numberValidThroughTime: string | null;
  numberVerifiedDate: Date | null;
  numberVerifiedTime: string | null;
}
// Defining Intitial Value For Party_Phone_Details
const partyPhoneDetailsInformation: PartyPhoneDetailsProps = {
  phoneType: "",
  cntryCallCode: "",
  data: "",
  extension: "",
  label: "",
  isPreferred: undefined,
  numberValidity: null,
  numberValidThrough: null,
  numberVerifiedDate: null,
  numberValidThroughTime: "",
  numberValidityTime: "",
  numberVerifiedTime: "",
};
// YUP LIBRARY :Defining Schema For validation of Party_Phone_Details
const partyPhoneInformationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["phones"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PartyPhoneDetailsProps };
// Exporting Validation Schema and Intial value
export {
  partyPhoneDetailsInformation,
  partyPhoneInformationSchema as getValidationSchema,
};
