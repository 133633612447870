import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import * as Yup from "yup";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { generateYupSchema } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Index_Rate_Offset_Terms
interface IndexRateOffsetTermsProps {
  addRateOffset: SplitBtnInputValType;
  index: { offsetMatrix: string; round: string; offSet: string };
}
// Defining Intitial Value For Index_Rate_Offset_Terms
const indexRateOffsetTerms: IndexRateOffsetTermsProps = {
  addRateOffset: undefined,
  index: { offsetMatrix: "", round: "", offSet: "" },
};
// YUP LIBRARY :Defining Schema For validation of Index_Rate_Offset_Terms
const indexRateOffsetTermschema = async (optionsSchema: PostSchema) => {
  const fields = ["index", "adjTerm"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { IndexRateOffsetTermsProps };
// Exporting Validation Schema and Intial value
export {
  indexRateOffsetTerms,
  indexRateOffsetTermschema as getValidationSchema,
};
