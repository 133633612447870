import { Box, Divider, Heading, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import "./DefaultEnvironmentContainer.scss";
import { AppFormLabel, AppModal, InputText } from "@/components/common";
import { UserContext } from "@/app/providers";
import {
  createEnvironmentRequest,
  getConsolePermissions,
} from "@/api-config/api-service";
import { TICKET_REQUESTS, TICKET_STATUS } from "@/utils";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { getOrganizations } from "@/store";
import { useSelector } from "react-redux";
import { TicketedRequestInterface } from "../Dashboard";

interface DefaultEnvironmentContainerProps {
  ticketStatus: TicketedRequestInterface[];
  updateTicketStatus: (value: TicketedRequestInterface[]) => void;
}

const DefaultEnvironmentContainer = (
  props: DefaultEnvironmentContainerProps
) => {
  const { ticketStatus, updateTicketStatus } = props;
  const appInputModalRef = useRef<any>();
  const userContext = useContext(UserContext);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [requestEntered, setRequestEntered] = useState<string>("");
  const allOrgs = useSelector(getOrganizations);

  const toast = useToast();
  const toastId = "speak-with-finxact";

  async function onModalInputSave() {
    const commonToastOptions = {
      toast,
      toastId,
    };
    // unset dropdown and input values
    await createEnvironmentRequest({
      body: {
        type: TICKET_REQUESTS.environment,
        message: requestEntered,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "Request sent successfully",
      },
    })
      .then(async (res) => {
        const api = new ApiClient({ baseUrl: getConsolePermissions() });
        const [data] = await Promise.all([
          api.get(`${API_ROUTE_CONFIGURATION.requests}`),
        ]).then((data) => data);

        updateTicketStatus(data);
        return res;
      })
      .catch((err) => {
        console.error(err);
      });

    setRequestEntered("");
    appInputModalRef.current.closeModal();
  }

  function onModalInputCancel() {
    // Reset modal input value
    setRequestEntered("");
  }

  function showModal() {
    setFirstName(userContext.user.firstName);
    setLastName(userContext.user.lastName);
    setEmail(userContext.user.email);
    setOrganization(allOrgs[userContext.user.organization].name ?? "");
    appInputModalRef?.current?.openModal();
  }

  return (
    <>
      <Box className="default-env-content">
        <Heading as={"h5"} className="env-heading">
          Current environment
        </Heading>
        <Text className="env-name">You currently have no environments</Text>
        {!ticketStatus.find(
          (ticket) => ticket.requestType === TICKET_REQUESTS.environment
        ) && (
          <>
            <Text onClick={showModal} className="app-btn-link env-link">
              Speak with Finxact
            </Text>
            <Text className="env-price-text">Pricing may vary</Text>
          </>
        )}
      </Box>

      <AppModal
        customClass="app-modal-selectors"
        ref={appInputModalRef}
        modalTitle={"Speak with Finxact"}
        primaryBtnProp={{
          name: "Submit request",
          btnClassName: "app-btn-inverse-secondary",
        }}
        primaryBtnDisabled={requestEntered.toString().trim() === ""}
        primaryBtnSelect={onModalInputSave}
        secondaryBtnSelect={onModalInputCancel}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-link",
          style: { marginRight: "1rem" },
        }}
      >
        <Box className="app-form-field-container-small">
          <Text>
            Let’s chat. We’d love to share more about our value offering and
            answer any questions you may have.{" "}
          </Text>
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"First name"} isRequired={true} />
          <InputText value={firstName} disabled={true} />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Last name"} isRequired={true} />
          <InputText value={lastName} disabled={true} />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Email"} isRequired={true} />
          <InputText value={email} disabled={true} />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Organization"} isRequired={true} />
          <InputText value={organization} disabled={true} />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName={"Enter request message"} />
          <InputText
            textarea
            value={requestEntered}
            onChange={setRequestEntered}
          />
        </Box>

        <Divider />
      </AppModal>
    </>
  );
};

export default DefaultEnvironmentContainer;
