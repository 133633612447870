import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import * as Yup from "yup";

interface FoundationDetailsProps {
  _Id: string;
  tmZoneCode: string;
  isReqAcctBankTmZone: SplitBtnInputValType;
  calendar: string;
  chkBottom: string;
  dfltNetwork: string;
  logo: File | null;
}
const defaultFoundationDetails: FoundationDetailsProps = {
  _Id: "",
  tmZoneCode: "",
  isReqAcctBankTmZone: undefined,
  calendar: "",
  chkBottom: "",
  dfltNetwork: "CORE (default)",
  logo: null,
};

const foundationDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "_Id",
    "tmZoneCode",
    "isReqAcctBankTmZone",
    "calendar",
    "chkBottom",
    "dfltNetwork",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { FoundationDetailsProps };
// Exporting Validation Schema and Intial value
export {
  defaultFoundationDetails,
  foundationDetailSchema as getValidationSchema,
};
