import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import { Option } from "@/utils/types";
import * as Yup from "yup";

interface GeneralOrganizationPartyIdentificationProps {
  primaryBankId: string;
  dbaName: string;
  desc: string;
  tradeName: string;
  dunsNbr: string;
  nbrEmployed: number | undefined;
}

const GeneralOrganizationPartyIdentificationInitialValue: GeneralOrganizationPartyIdentificationProps =
  {
    primaryBankId: "",
    dbaName: "",
    desc: "",
    tradeName: "",
    dunsNbr: "",
    nbrEmployed: undefined,
  };

const GeneralOrganizationPartyIdentificationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "primaryBankId",
    "dbaName",
    "desc",
    "tradeName",
    "dunsNbr",
    "nbrEmployed",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

//exporting type
export type { GeneralOrganizationPartyIdentificationProps };

export {
  GeneralOrganizationPartyIdentificationSchema as getValidationSchema,
  GeneralOrganizationPartyIdentificationInitialValue,
};
