import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";

//Defining Interface For Us_Specification_Financial_Organization
interface USSpecificationFinancialOrganizationFedwireDetails {
  // partyIdentifier: string;
  finanancialInstitutionName: string;
  finanancialInstitutionShortName: string;
  fedwireFundsEligible: SplitBtnInputValType;
  fedwireSecuritiesEligible: SplitBtnInputValType;
  fedwireSettlementOnly: SplitBtnInputValType;
  addressStreet: string;
  addressStreetLine2: string;
  addressStreetLine3: string;
  addressStreetLine4: string;
  premise: string;
  city: string;
  region: string;
  region2: string;
  region3: string;
  region4: string;
  country: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  altitude: string;
  googlePlaceId: string;
}
// Defining Intitial Value For General_Party_Parameter
const USSpecificationFinancialOrganizationFedwireDetailsParameter: USSpecificationFinancialOrganizationFedwireDetails =
  {
    finanancialInstitutionName: "",
    finanancialInstitutionShortName: "",
    fedwireFundsEligible: undefined,
    fedwireSecuritiesEligible: undefined,
    fedwireSettlementOnly: undefined,
    addressStreet: "",
    addressStreetLine2: "",
    addressStreetLine3: "",
    addressStreetLine4: "",
    premise: "",
    city: "",
    region: "",
    region2: "",
    region3: "",
    region4: "",
    country: "",
    postalCode: "",
    latitude: "",
    longitude: "",
    altitude: "",
    googlePlaceId: "",
  };
// YUP LIBRARY :Defining Schema For validation of General_Party_Parameter
const USSpecificationFinancialOrganizationFedwireDetailsParameterSchema =
  Yup.object().shape({
    // partyIdentifier: Yup.string().required(GENERIC_ERROR.required),
    finanancialInstitutionName: Yup.string().required(GENERIC_ERROR.required),
    finanancialInstitutionShortName: Yup.string().required(
      GENERIC_ERROR.required
    ),
    fedwireFundsEligible: Yup.string().required(GENERIC_ERROR.required),
    fedwireSecuritiesEligible: Yup.string().required(GENERIC_ERROR.required),
    fedwireSettlementOnly: Yup.string().required(GENERIC_ERROR.required),
    addressStreet: Yup.string().required(GENERIC_ERROR.required),
    addressStreetLine2: Yup.string().required(GENERIC_ERROR.required),
    addressStreetLine3: Yup.string(),
    addressStreetLine4: Yup.string(),
    premise: Yup.string().required(GENERIC_ERROR.required),
    city: Yup.string().required(GENERIC_ERROR.required),
    region: Yup.string().required(GENERIC_ERROR.required),
    region2: Yup.string(),
    region3: Yup.string(),
    region4: Yup.string(),
    country: Yup.string().required(GENERIC_ERROR.required),
    postalCode: Yup.string().required(GENERIC_ERROR.required),
    latitude: Yup.string().required(GENERIC_ERROR.required),
    longitude: Yup.string().required(GENERIC_ERROR.required),
    altitude: Yup.string().required(GENERIC_ERROR.required),
    googlePlaceId: Yup.string().required(GENERIC_ERROR.required),
  });
// Exporting Interface
export type { USSpecificationFinancialOrganizationFedwireDetails };
// Exporting Validation Schema and Intial value
export {
  USSpecificationFinancialOrganizationFedwireDetailsParameter,
  USSpecificationFinancialOrganizationFedwireDetailsParameterSchema as validationSchema,
};
