import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as yup from "yup";

interface BusinessConfigDaySelection {
  isOn?: boolean;
  day: string;
  openTm: string;
  closeTm: string;
  isAllDay: boolean;
  isClosed?: boolean;
  dayOfWk: number;
  calendarName: string;
}

interface FinancialCalenderBusinessDaysConfigProps {
  businessDays: BusinessConfigDaySelection[];
}
const financialCalenderBusinessDaysConfig: FinancialCalenderBusinessDaysConfigProps =
  {
    businessDays: [
      {
        calendarName: "",
        day: "Monday",
        isOn: true,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 1,
      },
      {
        calendarName: "",
        day: "Tuesday",
        isOn: true,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 2,
      },
      {
        calendarName: "",
        day: "Wednesday",
        isOn: true,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 3,
      },
      {
        calendarName: "",
        day: "Thursday",
        isOn: true,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 4,
      },
      {
        calendarName: "",
        day: "Friday",
        isOn: true,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 5,
      },
      {
        calendarName: "",
        day: "Saturday",
        isOn: false,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 6,
      },
      {
        calendarName: "",
        day: "Sunday",
        isOn: false,
        openTm: "",
        closeTm: "",
        isAllDay: false,
        dayOfWk: 0,
      },
    ],
  };

const financialCalenderBusinessDaysConfigSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["businessDays"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return yup.object().shape(shapeConfig);
};

export type { FinancialCalenderBusinessDaysConfigProps };

export {
  financialCalenderBusinessDaysConfig,
  financialCalenderBusinessDaysConfigSchema as getValidationSchema,
  type BusinessConfigDaySelection,
};
