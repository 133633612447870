"use client";

import { PROFILE_TEMPLATE_TABLE_COLUMN_REF } from "@/data/access-management-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { PROFILE_TEMPLATE_KEY, TAB_NAMES } from "@/utils";

import TabComponent from "../tab-component/TabComponent";

interface ProfileTemplateTabProps {
  tableData: any;
}

const ProfileTemplateTab = (props: ProfileTemplateTabProps) => {
  const { tableData } = props;
  return (
    <TabComponent
      heading="Profile templates"
      subHeading="Create a profile template to customize reusable role type combinations"
      createButtonLink={`${ROUTE_PATH.AM_PROFILE_TEMPLATE}?tab=${TAB_NAMES.profileTemplates}`}
      createButtonName="Create profile template"
      tableData={tableData}
      tableColumns={PROFILE_TEMPLATE_TABLE_COLUMN_REF}
      removeItemKey={PROFILE_TEMPLATE_KEY}
      editButtonLink={ROUTE_PATH.AM_PROFILE_TEMPLATE}
      appendModalTitle="profile template"
    />
  );
};

export default ProfileTemplateTab;
