import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface
interface CompositieFieldConfigProps {
  field: string;
  isVarLength: SplitBtnInputValType;
  prefix: string;
  size: string;
  idx: number | string;
  offset?: number;
}
interface CompositeDelimitedProps {
  delimitedName: string;
}

const defaultCompositeDelimitedVal = {
  delimitedName: "",
};
// Defining Intitial Value For Promotional_Rate_Foundational_Details
const defaultCompositieFieldConfig: CompositieFieldConfigProps = {
  field: "",
  isVarLength: undefined,
  prefix: "",
  size: "",
  idx: "",
};
// YUP LIBRARY :Defining Schema For validation
const compositieFieldConfigSchema = async (optionsSchema: PostSchema) => {
  const fields = ["posnFixed"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { CompositieFieldConfigProps, CompositeDelimitedProps };
export type CompositeFieldConfigValue = {
  posnFixed: CompositieFieldConfigProps[];
  posnDelim: CompositeDelimitedProps[];
};
// Exporting Validation Schema and Intial value
export {
  defaultCompositieFieldConfig,
  compositieFieldConfigSchema as getValidationSchema,
  defaultCompositeDelimitedVal,
};
