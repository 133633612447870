import React from "react";
import { Divider, Text } from "@/components/ChakraUiManager";
import styles from "./TitleInterstialScreen.module.scss";
import {
  FLOW_FINAL_DESCRIPTION_MAPPING,
  FLOW_TITLE_MAPPING,
} from "@/data/interstital-screen-data";

interface titleInterstialScreenProps {
  activeIndex: number;
  totalCount: number;
  descriptionText: string;
  flow:
    | "institutional-configuration"
    | "general-ledger"
    | "customer-relationship-types"
    | "transaction-code";
}

const TitleInterstialScreen = ({
  activeIndex,
  totalCount,
  descriptionText,
  flow,
}: titleInterstialScreenProps) => {
  return (
    <div className={styles["title-box"]}>
      <div className={styles["title-content"]}>
        <div className={styles["content-complete"]}>
          <Text>
            <span>{activeIndex}</span> of <span>{totalCount}</span> complete
          </Text>
        </div>
        <div className={styles["content-title-text"]}>
          <Text as="h3">{descriptionText}</Text>
          {activeIndex === totalCount ? (
            flow !== "transaction-code" ? (
              <Text>
                Continue to {FLOW_FINAL_DESCRIPTION_MAPPING[flow].next} or
                return to the {FLOW_FINAL_DESCRIPTION_MAPPING[flow].current}
              </Text>
            ) : (
              <Text>
                Create another transaction code or return to the transaction
                codes landing page
              </Text>
            )
          ) : (
            <Text>
              Click ‘Continue’ to move on to the next stage in your &nbsp;
              {FLOW_TITLE_MAPPING[flow]?.toLowerCase()} journey.
            </Text>
          )}
        </div>
      </div>
      <Divider className={styles["content-divider"]} />
    </div>
  );
};

export default TitleInterstialScreen;
