"use client";

import { Badges } from "@/components/common";
import { Box, Text, Image, Divider } from "@chakra-ui/react";
import {
  ALM_ENROLLMENT_STATE,
  getBadgeByState,
} from "../../../learning-util-service";
import { useEffect, useState } from "react";
import { getLearnerObjectInstancesByIds } from "@/client-api-manager/alm-api";

type BadgeDetails = {
  name: string;
  badgeImg: string;
};

type BadgeDetailsProps = {
  id: string;
  instanceId: string | undefined;
  status: string;
  progressPercent: number | undefined;
  label?: string;
  type?: string;
};

export default function BadgeDetails({
  id,
  instanceId,
  status,
  progressPercent,
  label = "Complete all the courses to earn this badge:",
  type = "badge",
}: BadgeDetailsProps) {
  const [badgeDetails, setBadgeDetails] = useState<BadgeDetails | null>(null);

  useEffect(() => {
    if (instanceId)
      getLearnerObjectInstancesByIds(id, instanceId, "badge").then((res) => {
        const badge = res.data.included && res.data.included[0].attributes;
        if (badge)
          setBadgeDetails({
            name: badge.name,
            badgeImg: badge.imageUrl,
          });
      });
  }, [id, instanceId]);

  const isCompleteOrEnrolled = (isEnrolled?: boolean) => {
    if (
      (status === ALM_ENROLLMENT_STATE.COMPLETED ||
        status === ALM_ENROLLMENT_STATE.STARTED) &&
      progressPercent === 100
    )
      return true;
    else if (isEnrolled && status === ALM_ENROLLMENT_STATE.ENROLLED)
      return true;

    return false;
  };
  if (!badgeDetails) return;

  return (
    <>
      <Divider className="badge-divider" />
      <Box className="badge-detail-holder">
        {type === "badge" ? (
          !isCompleteOrEnrolled() ? (
            <Text className="badge-detail-intro">{label}</Text>
          ) : null
        ) : (
          <Text className="badge-detail-intro">{label}</Text>
        )}
        <Box className="badge-detail-main">
          <Box
            className={`badge-detail-sub ${
              !isCompleteOrEnrolled() ? "not-complete" : ""
            }`}
          >
            <Image
              src={badgeDetails?.badgeImg}
              alt={badgeDetails?.name}
              height={100}
              width={100}
            />

            <Text as="h5" className="badge-detail-sub-title">
              {badgeDetails?.name}
            </Text>
          </Box>
          {!isCompleteOrEnrolled(true) && (
            <Box className="badge-detail-status-holder">
              <Badges
                size="lg"
                {...getBadgeByState({
                  status: status,
                  progressPercent: progressPercent,
                  showState: true,
                  label: status,
                })}
              ></Badges>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
