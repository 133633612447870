"use client";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";

import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FormFieldMsg,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { useFormik } from "formik";
import {
  PromotionalRateFoundationProps,
  promotionalRateFoundationDetails,
  getValidationSchema,
} from "./PromotionalRateFoundationValidation";
import {
  InterestComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import {
  addNewComponent,
  createInterestComponent,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  RjsfData,
  preventMainFormSubmitOnRJSFSubmit,
  getConstructedFeatureNameWithComponent,
  IFX_PRODUCT_TYPE_VAL,
  SIDEBAR_STATUS,
  REGEX_PATTERN,
} from "@/utils";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { useRouter } from "next/navigation";

type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    indexNameOptions: string[];
    promoCodeOptions: string[];
    formData: InterestComponentData | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    addNewPromoSchema: OptionsSchema;
    addNewOptionPromoCodeEndpoint: string;
    interestComponentOptionsData: PostSchema;
  };
};

const PromotionalRateFoundationalDetails = (props: Props) => {
  const { featureName, mainTitle, subText } = props;
  const {
    formData,
    addNewSchema,
    addNewOptionEndpoint,
    addNewPromoSchema,
    addNewOptionPromoCodeEndpoint,
    interestComponentOptionsData,
  } = props.data;
  const toast = useToast();
  const {
    WHAT_IS_PROMOTIONAL_RATE_TERM,
    SKIP_SECTION_INTEREST_DEPOSIT_PROMOTIONAL_FORM_MSG,
  } = INTEREST_DEPOSIT_LABELS;
  const router = useRouter();
  // Form Submit Handler
  const onSubmit = async (values: PromotionalRateFoundationProps) => {
    // Update only if component data exists and form has been touched
    /**
     * If "No" selected for first question in "Indexed Rate - Foundational Details", when continue is clicked go to "Promotional Rate - Foundational Details" (in Interest (deposit))
     * or to "Introduction Rate - Foundational Details" (in Interest (loan)) page
     * */
    if (!dirty && !values.addPromotionalRateDetails) {
      if (getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan) {
        updateStatusInSideBarMenuList?.("COMPLETED");
        const upcomingSubMenuIndex = sideBarMenuList?.[
          sideBarMainMenuIndex
        ]?.subMenuList?.findIndex(
          (item) => item.stageStatus === "promoPostingFrequencyRounding"
        );
        setSideBarCurrentIndex?.(
          sideBarMainMenuIndex,
          upcomingSubMenuIndex ?? 0
        );
        updateTheStatusOfSkippedForm();
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_LOAN_POST_FREQUENCY_ROUNDING"]}?productName=${productDetails?.name}`
        );

        getWorflowStatusToBeUpdated?.() &&
          updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: productDetails?.name ?? "",
            stage: COMPONENT_CLASS["interest"],
            status: getWorflowStatusToBeUpdated?.() ?? "",
          });
      } else {
        updateStatusInSideBarMenuList?.("COMPLETED");
        const upcomingSubMenuIndex = sideBarMenuList?.[
          sideBarMainMenuIndex
        ]?.subMenuList?.findIndex(
          (item) => item.stageStatus === "promoPostingFrequencyRounding"
        );
        setSideBarCurrentIndex?.(
          sideBarMainMenuIndex,
          upcomingSubMenuIndex ?? 0
        );
        updateTheStatusOfSkippedForm();
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_POST_FREQUENCY_ROUNDING"]}?productName=${productDetails?.name}`
        );
      }
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    } else if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            promoDtl: {
              ...formData.promoDtl,
              indexName: values.addPromotionalRateDetails
                ? values.promoDtl.indexName
                : null,
              promoCode: values.addPromotionalRateDetails
                ? values.promoDtl.promoCode
                : null,
              nomRate:
                values.addPromotionalRateDetails === false
                  ? Number(values.promoDtl.nomRate)
                  : null,
              firstDur: values.addPromotionalRateDetails
                ? values.promoDtl.firstDur
                : null,
              promoTerm: values.addPromotionalRateDetails
                ? values.promoDtl.promoTerm
                : null,
            },
          },
          toastOptions: {
            toast,
            toastId: "promotional-rate-foundational-detail",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getUpdatedStageStatus?.() ?? "",
        });
        //If "No" selected for first question in "Indexed Rate - Foundational Details" when back is clicked go to "Promotional Rate - Foundational Details" (in Interest (deposit)) or "Introduction Rate - Foundational Details" (in Interest (loan))  page
        if (res && !values.addPromotionalRateDetails) {
          if (getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan) {
            updateStatusInSideBarMenuList?.("COMPLETED");
            const upcomingSubMenuIndex = sideBarMenuList?.[
              sideBarMainMenuIndex
            ]?.subMenuList?.findIndex(
              (item) => item.stageStatus === "promoPostingFrequencyRounding"
            );
            setSideBarCurrentIndex?.(
              sideBarMainMenuIndex,
              upcomingSubMenuIndex ?? 0
            );
            updateTheStatusOfSkippedForm();
            navigateTo(
              `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_LOAN_POST_FREQUENCY_ROUNDING"]}?productName=${productDetails.name}`
            );
          } else {
            updateStatusInSideBarMenuList?.("COMPLETED");
            const upcomingSubMenuIndex = sideBarMenuList?.[
              sideBarMainMenuIndex
            ]?.subMenuList?.findIndex(
              (item) => item.stageStatus === "promoPostingFrequencyRounding"
            );
            setSideBarCurrentIndex?.(
              sideBarMainMenuIndex,
              upcomingSubMenuIndex ?? 0
            );
            updateTheStatusOfSkippedForm();
            navigateTo(
              `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_POST_FREQUENCY_ROUNDING"]}?productName=${productDetails.name}`
            );
          }
          getExistingProduct?.(productDetails.name, false);
        } else if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("promoDtl", {
      ...values.promoDtl,
      indexName: response.matrixName,
    });
  };

  //Promocode schema
  const promoCodeSchema = useMemo(() => {
    const { actions, name } = addNewPromoSchema;
    return { name, ...actions.POST };
  }, [addNewPromoSchema]);

  //Promotional code
  const addNewPromoCode = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionPromoCodeEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }

    setFieldValue("promoDtl", {
      ...values.promoDtl,
      promoCode: response.promoCode,
    });
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      ...promotionalRateFoundationDetails,
      addPromotionalRateDetails:
        formData?.promoDtl?.indexName !== undefined
          ? true
          : formData?.promoDtl?.nomRate?.toString()
            ? false
            : undefined,
      promoDtl: {
        indexName:
          formData?.promoDtl?.indexName ??
          promotionalRateFoundationDetails.promoDtl.indexName,
        firstDur:
          formData?.promoDtl?.firstDur ??
          promotionalRateFoundationDetails.promoDtl.firstDur,
        nomRate:
          formData?.promoDtl?.nomRate?.toString() ??
          promotionalRateFoundationDetails.promoDtl.nomRate,
        promoCode:
          formData?.promoDtl?.promoCode ??
          promotionalRateFoundationDetails.promoDtl.promoCode,
        promoTerm:
          formData?.promoDtl?.promoTerm ??
          promotionalRateFoundationDetails.promoDtl.promoTerm,
      },
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    sideBarMenuList,
    sideBarMainMenuIndex,
    sideBarSubMenuIndex,
    setSideBarCurrentIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    updateSidebarMenu,
    getCurrentWorkflowIndex,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const promotionalRateFondationalFormHandler =
    (key: string) => (value: string | SplitBtnInputValType | string[]) => {
      if (key === "addPromotionalRateDetails") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("promoDtl", {
            ...promotionalRateFoundationDetails.promoDtl,
          });
        });
      } else {
        setFieldValue("promoDtl", { ...values.promoDtl, [key]: value });
      }
    };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }

    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  function getUpdatedStageStatus() {
    if (
      sideBarMainMenuIndex >= 0 &&
      !values?.addPromotionalRateDetails &&
      sideBarMenuList?.length &&
      sideBarMenuList[sideBarMainMenuIndex]?.subMenuList?.length
    ) {
      const workflowIndex = getCurrentWorkflowIndex?.();
      const index = sideBarMenuList[
        sideBarMainMenuIndex
      ].subMenuList?.findIndex((item) => {
        return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
          ? item.href === `${ROUTE_PATH.INTEREST_LOAN_POST_FREQUENCY_ROUNDING}`
          : item.href === `${ROUTE_PATH.INTEREST_POST_FREQUENCY_ROUNDING}`;
      });
      if (
        index !== undefined &&
        index >= 0 &&
        workflowIndex !== undefined &&
        workflowIndex! >= 0
      ) {
        return workflowIndex! < index
          ? sideBarMenuList[sideBarMainMenuIndex].subMenuList![index - 1]!
              .stageStatus
          : getWorflowStatusToBeUpdated?.();
      }
    }
    return getWorflowStatusToBeUpdated?.() ?? "";
  }

  function updateTheStatusOfSkippedForm() {
    if (
      sideBarMainMenuIndex >= 0 &&
      !values?.addPromotionalRateDetails &&
      sideBarMenuList?.length &&
      sideBarMenuList[sideBarMainMenuIndex]?.subMenuList?.length
    ) {
      const index = sideBarMenuList[
        sideBarMainMenuIndex
      ].subMenuList?.findIndex((item) => {
        return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
          ? item.href === `${ROUTE_PATH.INTEREST_LOAN_POST_FREQUENCY_ROUNDING}`
          : item.href === `${ROUTE_PATH.INTEREST_POST_FREQUENCY_ROUNDING}`;
      });
      if (index !== undefined && index >= 0) {
        let updatedMainMenuList = sideBarMenuList.map((mainMenu, idx) => {
          if (idx === sideBarMainMenuIndex) {
            let updatedSubmenu = mainMenu?.subMenuList?.map(
              (subMenu, subIdx) => {
                if (
                  sideBarSubMenuIndex &&
                  subIdx < index &&
                  subIdx > sideBarSubMenuIndex
                ) {
                  return {
                    ...subMenu,
                    status: SIDEBAR_STATUS.completed,
                  };
                }
                return subMenu;
              }
            );
            return {
              ...mainMenu,
              subMenuList: updatedSubmenu,
            };
          }
          return mainMenu;
        });
        updateSidebarMenu?.(updatedMainMenuList);
      }
    }
  }

  useEffect(() => {
    // If no index is nominal rate is given in "Indexed Rate - Foundational Details" when back is clicked go to "Indexed Rate - Foundational Details" page
    if (isGoingBack && props.data.formData?.index) {
      navigateToNextPage?.();
      return;
    } else if (isGoingBack) {
      const upcomingSubMenuIndex = sideBarMenuList?.[
        sideBarMainMenuIndex
      ]?.subMenuList?.findIndex(
        (item) => item.stageStatus === "indexRateFoundationalDetails"
      );
      setSideBarCurrentIndex?.(sideBarMainMenuIndex, upcomingSubMenuIndex ?? 0);

      if (getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan) {
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_FOUND_DETAIL"]}?productName=${productDetails?.name}`
        );
      } else {
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${ROUTE_PATH["INTEREST_INDEX_RATE_FOUND_RATE"]}?productName=${productDetails?.name}`
        );
      }
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
      noValidate
    >
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to add a promotional rate detail with an indexed rate? "
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get(
                "addPromotionalRateDetails"
              )?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.addPromotionalRateDetails}
            onSelect={promotionalRateFondationalFormHandler(
              "addPromotionalRateDetails"
            )}
          />
          {errors.addPromotionalRateDetails && (
            <ErrorAlert>
              <span>{errors.addPromotionalRateDetails}</span>
            </ErrorAlert>
          )}
          {values.addPromotionalRateDetails === false ? (
            <FormFieldMsg
              msg={SKIP_SECTION_INTEREST_DEPOSIT_PROMOTIONAL_FORM_MSG}
            />
          ) : (
            ""
          )}
        </Box>

        {values.addPromotionalRateDetails ? (
          <>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the index name?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("indexName")
                    ?.tooltip_text_main
                }
                labelFor="index_name"
              />

              <CoupleRadioWithDropdown
                id="index_name"
                primaryOptionValue={values.promoDtl.indexName}
                primaryRadioName="Select an existing interest rate index"
                secondaryRadioName="Add new index"
                handlePrimaryOptionSelect={promotionalRateFondationalFormHandler(
                  "indexName"
                )}
                placeHolderName="Select existing index"
                dropdownList={props.data.indexNameOptions}
                onBlur={handleBlur}
                schema={schema}
                uiSchema={matrixTypeUiSchema}
                onAddNewSubmit={addNewOption}
                modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
              />
              {errors.promoDtl?.indexName && touched.promoDtl?.indexName && (
                <ErrorAlert>
                  <span>{errors.promoDtl.indexName}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <DurationInput
                labelName="What is the first duration?"
                value={values.promoDtl.firstDur}
                onChange={promotionalRateFondationalFormHandler("firstDur")}
                modalLinkName="Enter code manually"
                modalTitle="Enter first duration"
                modalLabelName={"What is the first duration?"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("firstDur")
                    ?.tooltip_text_main
                }
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("firstDur")
                    ?.tooltip_text_main
                }
              />
              {errors.promoDtl?.firstDur && touched.promoDtl?.firstDur && (
                <ErrorAlert>
                  <span>{errors.promoDtl?.firstDur}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={
                  ifxAcctProductType === IFX_PRODUCT_TYPE_VAL.loan
                    ? "What is the introduction (promotional) code?"
                    : "What is the promotional code?"
                }
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("promoCode")
                    ?.tooltip_text_main
                }
                labelFor="promo_code"
              />

              <CoupleRadioWithDropdown
                id="promo_code"
                primaryOptionValue={values.promoDtl.promoCode}
                primaryRadioName={
                  ifxAcctProductType === IFX_PRODUCT_TYPE_VAL.loan
                    ? "Use existing introduction (promotional) code"
                    : "Use existing promotional code"
                }
                secondaryRadioName={
                  ifxAcctProductType === IFX_PRODUCT_TYPE_VAL.loan
                    ? "Add new introduction (promotional) code"
                    : "Add new promotional code"
                }
                handlePrimaryOptionSelect={promotionalRateFondationalFormHandler(
                  "promoCode"
                )}
                placeHolderName="Select existing index"
                dropdownList={props.data.promoCodeOptions}
                onBlur={handleBlur}
                schema={promoCodeSchema}
                onAddNewSubmit={addNewPromoCode}
                modelViewUrl={API_ROUTE_CONFIGURATION.promotionalCode}
              />
              {errors.promoDtl?.promoCode && touched.promoDtl?.promoCode && (
                <ErrorAlert>
                  <span>{errors.promoDtl?.promoCode}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <DurationInput
                labelName={WHAT_IS_PROMOTIONAL_RATE_TERM}
                value={values.promoDtl.promoTerm}
                onChange={promotionalRateFondationalFormHandler("promoTerm")}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("promoTerm")
                    ?.tooltip_text_main
                }
                modalLinkName={"Enter code manually"}
                modalTitle={"Enter Promotional Rate Term"}
                modalLabelName={WHAT_IS_PROMOTIONAL_RATE_TERM}
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("promoTerm")
                    ?.tooltip_text_main
                }
                regex={REGEX_PATTERN.RESTRICT_DECIMAL}
              />

              {errors.promoDtl?.promoTerm && touched.promoDtl?.promoTerm && (
                <Box className="app-form-field-container">
                  <ErrorAlert>
                    <span>{errors.promoDtl.promoTerm}</span>
                  </ErrorAlert>
                </Box>
              )}
            </Box>
          </>
        ) : null}
        {values.addPromotionalRateDetails === false && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the nominal rate?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("nomRate")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={values.promoDtl.nomRate}
              onChange={promotionalRateFondationalFormHandler("nomRate")}
              valueSuffix="%"
              onBlur={handleBlur}
            />
            {errors.promoDtl?.nomRate && touched.promoDtl?.nomRate && (
              <ErrorAlert>
                <span>{errors.promoDtl.nomRate}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
      </CardContainer>
    </form>
  );
};

export default PromotionalRateFoundationalDetails;
