"use client";

import { Box, useToast } from "@/components/ChakraUiManager";
import {
  CommonTitle,
  CardContainer,
  AppFormLabel,
  SplitButton,
  ErrorAlert,
  InputText,
  CoupleRadioWithDropdown,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  IndexRateOffsetTermsProps,
  indexRateOffsetTerms,
  getValidationSchema,
} from "./IndexRateOffsetTermsValidation";
import { useFormik } from "formik";
import {
  InterestComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import {
  addNewComponent,
  createInterestComponent,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  preventMainFormSubmitOnRJSFSubmit,
  getConstructedFeatureNameWithComponent,
  RjsfData,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";

type Props = {
  depositName?: string;
  data: {
    indexRateOffsetOptions: string[];
    formData: InterestComponentData | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    interestComponentOptionsData: PostSchema;
  };
};

export default function IndexRateOffsetTerms(props: Props) {
  const { YES, NO, ADD_RATE_OFFSET, WHAT_IS_RATE_OFFSET_VAL } =
    INTEREST_DEPOSIT_LABELS;

  const {
    formData,
    addNewSchema,
    addNewOptionEndpoint,
    interestComponentOptionsData,
  } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: IndexRateOffsetTermsProps, actions: any) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            index: {
              ...formData.index,
              offsetMatrix: values.addRateOffset
                ? values.index.offsetMatrix
                : "",
              offSet: values.addRateOffset ? values.index.offSet : "",
              round: values.index.round,
            },
          },
          toastOptions: {
            toast,
            toastId: "index-rate-offset-terms",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("index.offsetMatrix", response.matrixName);
  };
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      addRateOffset:
        formData?.index?.offsetMatrix || formData?.index?.offSet
          ? true
          : undefined,
      index: {
        offsetMatrix:
          formData?.index?.offsetMatrix ??
          indexRateOffsetTerms.index.offsetMatrix,
        round:
          formData?.index?.round ?? indexRateOffsetTerms.index.offsetMatrix,
        offSet: formData?.index?.offSet ?? indexRateOffsetTerms.index.offSet,
      },
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const indexedRateOffsetTermsFormHandler =
    (key: string) =>
    (value: string | SplitBtnInputValType | number | boolean | string[]) => {
      if (key === "offsetMatrix") {
        values.index.offSet = "";
      } else if (key === "offSet") {
        values.index.offsetMatrix = "";
      }
      setFieldValue("index", { ...values.index, [key]: value });
    };

  const _handleAddRateOffset = (value: boolean) => {
    setFieldValue("addRateOffset", value).then((res) => {
      setFieldValue("index", {
        ...indexRateOffsetTerms.index,
      });
    });
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
      noValidate
    >
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={ADD_RATE_OFFSET}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("addRateOffset")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName={YES}
            rightBtnName={NO}
            value={values.addRateOffset}
            onSelect={_handleAddRateOffset}
          />
          {errors.addRateOffset && (
            <ErrorAlert>
              <span>{errors.addRateOffset}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.addRateOffset ? (
          <>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={WHAT_IS_RATE_OFFSET_VAL}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("offsetMatrix")
                    ?.tooltip_text_main
                }
                labelFor="rate_offset"
              />
              <CoupleRadioWithDropdown
                primaryOptionValue={values.index.offsetMatrix}
                tertiaryOptionValue={values.index.offSet}
                primaryRadioName={"Use existing rate offset matrix"}
                secondaryRadioName={"Add new rate offset value"}
                tertiaryRadioName={"Enter a single promotional offset amount"}
                tertiaryFieldType="number"
                handlePrimaryOptionSelect={
                  indexedRateOffsetTermsFormHandler("offsetMatrix") as any
                }
                handleTertiaryOption={
                  indexedRateOffsetTermsFormHandler("offSet") as any
                }
                placeHolderName={"Select offset"}
                dropdownList={props.data.indexRateOffsetOptions}
                id="promotional_rate_offset"
                schema={schema}
                uiSchema={matrixTypeUiSchema}
                onAddNewSubmit={addNewOption}
                modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
              />

              {errors.index?.offsetMatrix && touched.index?.offsetMatrix && (
                <ErrorAlert>
                  <span>{errors.index?.offsetMatrix}</span>
                </ErrorAlert>
              )}
            </Box>
          </>
        ) : (
          ""
        )}
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the rounding expression?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("round")?.tooltip_text_main
            }
          />
          <InputText
            value={values.index.round}
            onChange={indexedRateOffsetTermsFormHandler("round")}
            label="round"
          />
        </Box>
      </CardContainer>
    </form>
  );
}
