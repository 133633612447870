import { createIcon } from "@chakra-ui/icons";

const CustomEditIcon = createIcon({
  displayName: "CustomEditIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M19.0578 2.61767L20.4076 4.04307C21.1975 4.86663 21.1975 6.20756 20.4076 7.03112L7.17942 21H3V16.5866L13.3986 5.59517L16.2282 2.61767C17.0081 1.79411 18.2779 1.79411 19.0578 2.61767ZM4.99972 18.8883L6.40953 18.9517L16.2282 8.57266L14.8184 7.08391L4.99972 17.4523V18.8883Z"
    />
  ),
});

export default CustomEditIcon;
