"use client";

import { InfoIcon } from "@chakra-ui/icons";
import { Box, HStack, useToast } from "@chakra-ui/react";
import "./TakeFinalExam.scss";
import CloverCheckoutButton from "@/components/common/clover-checkout-button/CloverCheckoutButton";
import { LearningObjectDetails } from "@/models/academy-models";
import { Ref, useEffect } from "react";
import { fetchCloverChargeDetails } from "@/client-api-manager/clover-api";

interface ItemDetail {
  note?: string;
  id: string;
  name: string;
  price: number;
  tax?: number;
}

type TakeFinalExamProps = {
  daysLeft: number;
  hasPassed: boolean;
  isQualified: boolean;
  courseDetails: LearningObjectDetails | null;
  itemDetail: ItemDetail | null;
  buttonRef?: Ref<any>;
};
export default function TakeFinalExam({
  daysLeft,
  hasPassed,
  isQualified,
  courseDetails,
  itemDetail,
  buttonRef,
}: TakeFinalExamProps) {
  if (hasPassed && daysLeft > 30) return;

  return (
    <Box w={288}>
      <CloverCheckoutButton
        isDisabled={
          !courseDetails
            ? true
            : isQualified !== false && courseDetails?.learnerObjectId
              ? false
              : true
        }
        locked={!courseDetails}
        btnText={
          hasPassed && daysLeft < 30
            ? "Take refresher exam"
            : "Take the final exam"
        }
        courseName={courseDetails?.name || ""}
        courseId={courseDetails?.learnerObjectId || ""}
        imageUrl={courseDetails?.imageUrl || courseDetails?.bannerUrl || ""}
        priceDetails={{
          tax: "$" + ((itemDetail?.tax || 0) / 100).toString() || "$0",
          subTotal: "$" + ((itemDetail?.price || 0) / 100).toString() || "$0",
          total:
            "$" +
              (
                (itemDetail?.price || 0) / 100 +
                (itemDetail?.tax || 0) / 100
              ).toString() || "$0",
        }}
        buttonRef={buttonRef}
      ></CloverCheckoutButton>
      <HStack
        className="take-final-info"
        p={7}
        mt={7}
        h={104}
        borderRadius={16}
        gap={6}
      >
        <Box className="take-final-info-icon">
          <InfoIcon fontSize={20} />
        </Box>
        <Box fontSize={16} fontWeight={400}>
          {hasPassed && daysLeft < 30
            ? "Finish the latest courses and pass the updated exam to refresh your certification."
            : isQualified === true
              ? "Earn your certification by completing the final exam"
              : "Finish all of the courses to take the final exam"}
        </Box>
      </HStack>
    </Box>
  );
}
