import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, StarIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import "./BadgeCard.scss";
import Rating from "react-rating-stars-component";
import { formatDuration } from "@/utils";
import { Badges } from "@/components/common";
import { getBadgeByState } from "../../../learning-util-service";
import {
  getEnrollmentById,
  getLearnerObjectInstancesByIds,
} from "@/client-api-manager/alm-api";
import Frame from "../../../my-learning-profile/course-profile/course-profile-box/Frame/Frame";
import SubCourseCard from "./SubCourseCard";
import Link from "next/link";
import styleVars from "../../../../../styles/_export.module.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";

const { appRatingColor, appRatingActiveColor } = styleVars;

type BadgeCardProps = {
  id: string;
  instanceIds: string | undefined;
  title: string;
  rating: number | undefined;
  description: string;
  author: string | undefined;
  duration: number | undefined;
  format: string | undefined;
  required: boolean | undefined;
  status: string;
  progressPercent: number | undefined;
  enrollmentId: string;
  completionDate?: string;
  isExamCourse?: boolean;
  takeExamButtonClick?: () => void;
  isLocked?: boolean;
};

export default function BadgeCard({
  id,
  instanceIds,
  title,
  rating,
  description,
  author,
  duration,
  format,
  required,
  status,
  progressPercent,
  enrollmentId,
  completionDate,
  isExamCourse,
  takeExamButtonClick,
  isLocked = false,
}: BadgeCardProps) {
  const [moduleExpanded, toggleModuleExpanded] = useState<boolean>(false);
  const [subCourse, setSubCourse] = useState<any>();
  const queryParams = useSearchParams();
  const from = queryParams?.get("from");

  useEffect(() => {
    if (instanceIds)
      getLearnerObjectInstancesByIds(id, instanceIds, "loResources").then(
        (res) => {
          const subCourses = res.data.included.map((item: any) => ({
            name: item.attributes.localizedMetadata[0].name,
            moduleId: item.id,
            instanceId: instanceIds,
          }));

          getEnrollmentById(enrollmentId, "loResourceGrades").then((res) => {
            const updatedCourse = subCourses.map((item: any) => ({
              ...item,
              ...res.included.filter((inc: any) =>
                inc.id.includes(item.instanceId)
              )[0]?.attributes,
            }));
            setSubCourse(updatedCourse);
          });
        }
      );
  }, [instanceIds]);

  const getSubcourseState = (
    item: any
  ): "STARTED" | "ENROLLED" | "COMPLETED" => {
    if (item.progressPercent === 100 || item.hasPassed) return "COMPLETED";
    else if (item.progressPercent > 0) return "STARTED";
    return "ENROLLED";
  };

  const getUrlPath = () => {
    if (!id || isExamCourse) return "";
    let url = `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${id}`;
    if (from) url += `&from=${from}`;
    return url;
  };

  return (
    <Box className={`badge-card-container ${moduleExpanded ? "active" : ""}`}>
      <Box className="badge-card-title-holder">
        <Text as="h3" className="badge-title">
          {isLocked ? (
            <span>{title}</span>
          ) : isExamCourse ? (
            <ChakraLink onClick={takeExamButtonClick}>{title}</ChakraLink>
          ) : (
            <Link href={getUrlPath()}>{title}</Link>
          )}
        </Text>
        <Rating
          value={rating ?? 0}
          size={24}
          isHalf={false}
          edit={false}
          classNames="custom-rating"
          usingIcons={true}
          emptyIcon={<StarIcon color={appRatingColor} />}
          filledIcon={<StarIcon color={appRatingActiveColor} />}
        />
      </Box>
      <Text as="p" className="badge-description">
        {description}
      </Text>
      <Frame className="frame-1000005836" text={`By: ${author}`} />
      <Frame
        className="frame-1000005836"
        text={formatDuration(duration ?? 0, true)}
        showDivider={completionDate !== undefined}
      />
      {completionDate && (
        <Frame
          className="frame-1000005836"
          text={`Earned on ${completionDate}`}
          showDivider={false}
        />
      )}
      <Box className="badge-tag-holder">
        <Badges
          size="lg"
          {...getBadgeByState({
            status: status,
            progressPercent: progressPercent,
          })}
        />
        {format && (
          <Badges
            size="lg"
            {...getBadgeByState({ status: format, showState: true })}
          />
        )}
        {required && (
          <Badges
            size="lg"
            {...getBadgeByState({ status: "REQUIRED", showState: false })}
          />
        )}
      </Box>

      <Accordion
        allowToggle
        className="badge-module-accordion"
        onChange={() => toggleModuleExpanded((prev) => !prev)}
      >
        <AccordionItem>
          <h2>
            <AccordionButton
              className={`badge-module-accordion-button ${
                moduleExpanded ? "expanded" : ""
              }`}
            >
              <Box as="span" flex="1" textAlign="center">
                {subCourse && subCourse.length}{" "}
                {subCourse?.length > 1 ? "MODULES" : "MODULE"}
                {!moduleExpanded ? (
                  <ChevronDownIcon className="badge-module-accordion-icon" />
                ) : (
                  <ChevronUpIcon className="badge-module-accordion-icon" />
                )}
              </Box>
            </AccordionButton>
          </h2>

          <AccordionPanel padding={"1rem"}>
            {subCourse &&
              subCourse.map((item: any, index: number) => (
                <SubCourseCard
                  isLocked={isLocked}
                  key={index}
                  title={item.name}
                  duration={index === 0 ? duration : item.duration}
                  tag={format}
                  status={getSubcourseState(item)}
                  id={id}
                  moduleId={item.moduleId}
                  isExamCourse={isExamCourse}
                  takeExamButtonClick={takeExamButtonClick}
                />
              ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
