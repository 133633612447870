"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  SplitButton,
  InputText,
  AddNewButton,
  ErrorAlert,
  DragDropContainer,
} from "@/components/common";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { getValidationSchema } from "./GlExtractFileFormatsValidation";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: FinancialInstitutionData | null;
    bankParamOptionsData: PostSchema;
  };
};
const GLExtractFileFormats = ({ data }: Props) => {
  const { bankParamOptionsData } = data;
  const toast = useToast();
  const onSubmit = async (values: any, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "bank-params-cutoffs-limit-thresholds",
    };
    response = await updateBankParameters({
      formData: {
        _vn: data.formData?._vn,
        _Id: data.formData?._Id,
        // PATCH Fields
        glExtractFileFmt: values.glExtractFileFmt,
        isGlExtractSummary: values.isGlExtractSummary,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      navigateToNextPage?.();
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);

      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      glExtractFileFmt: data.formData?.glExtractFileFmt
        ? data.formData?.glExtractFileFmt
        : [],
      isGlExtractSummary: data.formData?.isGlExtractSummary ?? undefined,
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  // component handler
  const glExtractSummaryIndicatorHandler = (value: SplitBtnInputValType) => {
    setFieldValue("isGlExtractSummary", value);
  };

  const handleFileInput = (index: number) => (value: string) => {
    setFieldValue(`glExtractFileFmt.${index}.fmtName`, value);
  };

  const setGLExtractFile = (fn: any) => {
    setFieldValue(`glExtractFileFmt`, fn(values.glExtractFileFmt));
  };

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form">
        <CardContainer>
          <FieldArray
            name="glExtractFileFmt"
            render={({ insert, remove, push }) => (
              <Box w={"100%"}>
                <DragDropContainer
                  items={values.glExtractFileFmt?.map(
                    (fieldValues: any, index: any) => {
                      return {
                        id: index + 1,
                        deleteHandler: () => remove(index),
                        showDeleteIcon: true,
                        content: (
                          <CardContainer key={index}>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="What is the GL extract format name?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "glExtractFileFmt"
                                  )?.tooltip_text_main
                                }
                              />
                              <InputText
                                value={fieldValues.fmtName}
                                onChange={handleFileInput(index)}
                                onBlur={handleBlur}
                              />
                              {touched.glExtractFileFmt &&
                                touched.glExtractFileFmt[index]?.fmtName &&
                                errors.glExtractFileFmt &&
                                (errors.glExtractFileFmt[index] as any)
                                  ?.fmtName && (
                                  <ErrorAlert>
                                    <span>
                                      {
                                        (errors.glExtractFileFmt[index] as any)
                                          ?.fmtName
                                      }
                                    </span>
                                  </ErrorAlert>
                                )}
                            </Box>
                          </CardContainer>
                        ),
                      };
                    }
                  )}
                  setItems={setGLExtractFile}
                  isElement
                />
                <Box marginY={13}>
                  <AddNewButton
                    label="Add GL Extract File format"
                    onClick={() => {
                      push({ fmtName: "" });
                    }}
                  />
                </Box>
              </Box>
            )}
          ></FieldArray>

          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="GL Extract summary indicator?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isGlExtractSummary")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="GL Extract summary indicator"
              onSelect={glExtractSummaryIndicatorHandler}
              leftBtnName="Yes"
              rightBtnName="No"
              onBlur={handleBlur}
              value={values.isGlExtractSummary}
            />
            {errors.isGlExtractSummary && touched.isGlExtractSummary && (
              <ErrorAlert>
                <span>{errors.isGlExtractSummary}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};

export default GLExtractFileFormats;
