import * as Yup from "yup";
import { Option } from "@/utils/types";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";

//Defining Interface For Account_Type_Relationship_Foundational_Details
interface AccountTypeRelationshipFoundationalDetailsValueProps {
  depRightCapacity: string;
  groupType: string;
  reldesc: string;
  relType: string;
}
// Defining Intitial Value For Account_Type_Relationship_Foundational_Details
const accountTypeRelationshipFoundationDetails: AccountTypeRelationshipFoundationalDetailsValueProps =
  {
    relType: "",
    reldesc: "",
    groupType: "",
    depRightCapacity: "",
  };
// YUP LIBRARY :Defining Schema For validation of Account_Type_Relationship_Foundational_Details

const accountTypeRelationshipFoundationDetailSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rels"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountTypeRelationshipFoundationalDetailsValueProps };
// Exporting Validation Schema and Intial value
export {
  accountTypeRelationshipFoundationDetails,
  accountTypeRelationshipFoundationDetailSchema as getValidationSchema,
};
