import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import * as Yup from "yup";

//Defining Interface For System_Accounts
interface SystemAccountsProps {
  acctGroup: number;
  acctNbr: string;
  acctName: string;
  desc: string;
  posnAcctNbr: string;
}

// Defining Intitial Value For System_Accounts
const systemAccountsData: SystemAccountsProps = {
  acctGroup: 0,
  acctNbr: "",
  acctName: "",
  desc: "",
  posnAcctNbr: "",
};

// YUP LIBRARY :Defining Schema For validation of System_Accounts
const systemAccountsDataSchema = async (optionsSchema: PostSchema) => {
  const fields = ["acctGroup", "acctNbr", "acctName", "desc", "posnAcctNbr"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { SystemAccountsProps };
// Exporting Validation Schema and Intial value
export { systemAccountsData, systemAccountsDataSchema as getValidationSchema };
