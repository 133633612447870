import { ApiClient } from "@/api-config";
import { API_ROUTE_CONFIGURATION, CONSOLE_ENDPOINTS } from "@/api-config/urls";
import { useIsPermitted } from "@/utils";
import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { consoleOperations } from "@finxact/finxact-shared-ui";
import { useState } from "react";
import { useEffectOnce } from "react-use";

const fetchEnrollingUsers = async (): Promise<any> => {
  const api = new ApiClient({ baseUrl: CONSOLE_ENDPOINTS.base });
  return api.get(`${API_ROUTE_CONFIGURATION.pendingEnrollment}`);
};

type Props = {
  className: string;
};

const EnrollingUserAlert = ({ className }: Props) => {
  const isPermitted = useIsPermitted();

  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [enrollingUsers, setEnrollingUsers] = useState<any>(undefined);

  useEffectOnce(() => {
    const initialize = async () => {
      setShowLoader(true);
      const data = await fetchEnrollingUsers();
      setEnrollingUsers(data);
      setShowLoader(false);
    };
    if (isPermitted("cnsl", consoleOperations.adminAccountUpdate)) {
      initialize();
    }
  });

  return !showLoader &&
    isPermitted("cnsl", consoleOperations.adminAccountUpdate) &&
    enrollingUsers &&
    enrollingUsers.filter((user: any) => user.Status !== "Deleted").length >
      0 ? (
    <Alert className={className} status="info">
      <AlertIcon />
      <AlertDescription>
        A new user has requested to join your organization. Access{" "}
        <a href="/admin/accounts">Accounts</a> to review.
      </AlertDescription>
    </Alert>
  ) : null;
};

export default EnrollingUserAlert;
