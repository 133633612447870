"use client";

import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  AppNumberInput,
  RadioButtonGroup,
  SelectDropdown,
} from "@/components/common";
import { useContext, useEffect, useState } from "react";
import {
  RatesCurrenciesIdentifiersProps,
  getValidationSchema,
} from "./RatesCurrenciesIdentifiersValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import {
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  Option,
  DEFAULT_CONSTS,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: FinancialInstitutionData;
    assetClassOptions: Option<number>[];
    assetIdOptions: any[];
    bankParamOptionsData: PostSchema;
  };
};

const RatesCurrenciesIdentifiers = ({ data }: Props) => {
  const { bankParamOptionsData } = data;
  const toast = useToast();
  const onSubmit = async (
    values: RatesCurrenciesIdentifiersProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "bank-params-rate-currency-identifier",
    };
    response = await updateBankParameters({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Fields
        fedWthRt: Number(values.fedWthRt),
        dfltNraWthRt: Number(values.dfltNraWthRt),
        assetClass: Number(values.assetClass),
        assetId: values.assetId !== "" ? values.assetId : null,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      navigateToNextPage?.();
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    if (key === "assetClass") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("assetId", "");
      });
    } else {
      setFieldValue(key, value);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      fedWthRt: data?.formData?.fedWthRt ?? "",
      dfltNraWthRt: data.formData?.dfltNraWthRt ?? "",
      assetClass: data.formData?.assetClass?.toString() ?? "",
      assetId: data.formData?.assetId ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the federal withholding rate?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("fedWthRt")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.fedWthRt}
            onChange={handleOnChange("fedWthRt")}
            onBlur={handleBlur}
            placeholder={DEFAULT_CONSTS.percentage0}
            label="fedWthRt"
          />
          {touched.fedWthRt && errors.fedWthRt && (
            <ErrorAlert>
              <span>{errors.fedWthRt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the NRA withholding rate?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("dfltNraWthRt")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.dfltNraWthRt}
            onChange={handleOnChange("dfltNraWthRt")}
            onBlur={handleBlur}
            placeholder={DEFAULT_CONSTS.percentage0}
            label="dfltNraWthRt"
          />
          {touched.dfltNraWthRt && errors.dfltNraWthRt && (
            <ErrorAlert>
              <span>{errors.dfltNraWthRt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset class?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetClass")
                ?.tooltip_text_main
            }
          />

          <RadioButtonGroup
            value={values.assetClass}
            onChange={handleOnChange("assetClass")}
            isBoxedRadio={true}
            radioPropList={data?.assetClassOptions}
            stackDirection="column"
            spacing="1rem"
            onBlur={handleBlur}
          />
          {touched.assetClass && errors.assetClass && (
            <ErrorAlert>
              <span>{errors.assetClass}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset identifier?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetId")
                ?.tooltip_text_main
            }
          />

          <SelectDropdown
            dropdownList={data?.assetIdOptions.filter(
              (data) => data.assetClass === Number(values.assetClass)
            )}
            value={values.assetId}
            placeholder={"Select"}
            onChange={handleOnChange("assetId")}
            onBlur={handleBlur}
          ></SelectDropdown>
        </Box>
      </CardContainer>
    </form>
  );
};

export default RatesCurrenciesIdentifiers;
