import React from 'react';
import styles from './BoxInterstial.module.scss';
import { Box } from '@/components/ChakraUiManager';
import { CardContainer } from '@/components/common';

interface BoxInterstitialProps {
    children: React.ReactNode;
    activeIndex?: boolean;
  }
  
  const BoxInterstial = ({ children,activeIndex }: BoxInterstitialProps) => {
    return <CardContainer customClass={activeIndex ? styles["boxInterstial-active"]:styles["boxInterstitial"]}>{children}</CardContainer>;
  };
  
  export default BoxInterstial;
