import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import * as Yup from "yup";
import { Option } from "@/utils/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For FIDC_Related_Fields
interface FIDCRelatedFieldsProps {
  fdic: {
    inputPath: string;
    outputPath: string;
    maxDepInsAmt: string;
    deathCovrgTerm: string;
    restrictCode: string;
    uninsuredTrnCode: string;
    authRole: { role: string }[];
    signDocType: number;
  };
  int1099Min: string | number;
  isAggTaxData: SplitBtnInputValType;
  misc1099Min: string | number;
}
// Defining Intitial Value For FIDC_Related_Fields
const fidcRelatedFields: FIDCRelatedFieldsProps = {
  fdic: {
    inputPath: "/fdic/in",
    outputPath: "/fdic/out",
    maxDepInsAmt: "",
    deathCovrgTerm: "",
    restrictCode: "",
    uninsuredTrnCode: "",
    authRole: [],
    signDocType: -1,
  },
  int1099Min: "",
  isAggTaxData: undefined,
  misc1099Min: "",
};

const fidcRelatedFieldsSchema = async (optionsSchema: PostSchema) => {

  const fields = ["bankparamUSBankInfo"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { FIDCRelatedFieldsProps };
// Exporting Validation Schema and Intial value
export { fidcRelatedFields, fidcRelatedFieldsSchema as getValidationSchema };
