import * as Yup from "yup";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Priority_Posting
interface PriorityPostingProps {
  priorityOrder: number;
  networkPriority: string[];
  postingPriorityFreq: string;
}
// Defining Intitial Value For Priority_Posting
const PriorityPostingDetails: PriorityPostingProps = {
  priorityOrder: -1,
  networkPriority: [],
  postingPriorityFreq: "",
};

const PriorityPostingSchema = async (optionsSchema: PostSchema) => {
  const fields = ["priorityOrder", "networkPriority", "postingPriorityFreq"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { PriorityPostingProps };
// Exporting Validation Schema and Intial value
export { PriorityPostingDetails, PriorityPostingSchema as getValidationSchema };
