import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";

//Defining Interface For GPP_Party_Address_Details
interface PartyAddressDetailProps {
  addressType: string;
  addressLabel: string;
  addressPreferred: SplitBtnInputValType;
  invalidAddress: SplitBtnInputValType;
  fromDate: string;
  fromTime: string;
  throughDate: string;
  throughTime: string;
  verifiedDate: string;
  verifiedTime: string;
  partyYear: string;
}
// Defining Intitial Value For GPP_Party_Address_Details
const PartyAddress: PartyAddressDetailProps = {
  addressType: "",
  addressLabel: "",
  addressPreferred: undefined,
  invalidAddress: undefined,
  fromDate: "",
  fromTime: "",
  throughDate: "",
  throughTime: "",
  verifiedDate: "",
  verifiedTime: "",
  partyYear: "",
};
// YUP LIBRARY :Defining Schema For validation of GPP_Party_Address_Details
const PartyAddSchema = Yup.object({
  PartyAddress: Yup.array(
    Yup.object({
      addressType: Yup.string().required(ERROR_MESSAGE),
      addressLabel: Yup.string().required(ERROR_MESSAGE),
      addressPreferred: Yup.boolean(),
      invalidAddress: Yup.boolean(),
      fromDate: Yup.string().required(ERROR_MESSAGE),
      fromTime: Yup.string().required(ERROR_MESSAGE),
      throughDate: Yup.string().required(ERROR_MESSAGE),
      throughTime: Yup.string().required(ERROR_MESSAGE),
      verifiedDate: Yup.string().required(ERROR_MESSAGE),
      verifiedTime: Yup.string().required(ERROR_MESSAGE),
      partyYear: Yup.string().required(ERROR_MESSAGE),
    })
  ),
});
// Exporting Interface
export type { PartyAddressDetailProps };
// Exporting Validation Schema and Intial value
export { PartyAddress, PartyAddSchema as validationSchema };
