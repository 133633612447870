"use client";

import "./LearningStatusCardUserInfoWithAchivements.scss";
import { Divider, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import {
  fastBadgeIcon,
  fastCertificationIcon,
  fastCourseIcon,
} from "public/assets";
import {
  AcademyContextType,
  academyContext,
} from "@/components/context-api/academy-context-provider/AcademyContextReducer";
import { useContext, useEffect, useRef, useState } from "react";
import Avatar from "./Avatar";
import AccomplishmentItem from "./AccomplishmentItem";
import {
  getBadges,
  getListData,
  getUserEnrollments,
  getCompletedLearningObjects,
} from "@/client-api-manager/alm-api";
import { useUserInfo } from "@/app/providers";

const LearningStatusCardUserInfoWithAchivements = () => {
  const { userAttributes } = useContext<AcademyContextType>(academyContext);
  const {
    user: { firstName, lastName, email },
  } = useUserInfo();
  const badgeLoadRef = useRef<boolean>(false);
  const courseLoadRef = useRef<boolean>(false);
  const certificateLoadRef = useRef<boolean>(false);

  const [courseCount, setCourseCount] = useState<number>(0);
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const [certificationCount, setCertificationCount] = useState<number>(0);
  const userFullName = `${firstName}${firstName && lastName ? " " : ""}${lastName}`;

  const loadAllBadges = (userId: string, url?: string) => {
    getBadges(userId, url).then((res: any) => {
      const badgeCount = res.data.data.filter(
        (item: any) =>
          item.attributes?.dateAchieved &&
          res.data.included
            .find(
              (i: any) =>
                i.id === item.relationships.badge.data.id && i.type === "badge"
            )
            .attributes.state?.toLocaleLowerCase() !== "retired"
      ).length;

      setBadgeCount((count) => count + badgeCount);

      if (res.data.links?.next) loadAllBadges(userId, res.data.links?.next);
    });
  };

  useEffect(() => {
    if (userAttributes?.id && !badgeLoadRef.current) {
      loadAllBadges(userAttributes.id);
      badgeLoadRef.current = true;
    }
  }, [userAttributes]);

  const getAllCourse = async (url?: string) => {
    let courses = null;
    if (url) {
      courses = await getListData(url);
    } else {
      courses = await getCompletedLearningObjects();
    }
    const courseCount = courses.data?.data?.length || 0;

    setCourseCount((count) => count + courseCount);

    if (courses.data.links?.next) getAllCourse(courses.data.links?.next);
  };

  useEffect(() => {
    if (!courseLoadRef.current) {
      getAllCourse();
      courseLoadRef.current = true;
    }
  }, [courseLoadRef]);

  const getAllCert = async (url?: string) => {
    let certification = null;
    if (url) certification = await getListData(url);
    else certification = await getUserEnrollments("certification");
    const certificationCount = certification.data.data.filter(
      (item: any) => item.attributes.hasPassed
    ).length;

    setCertificationCount((count) => count + certificationCount);

    if (certification.data.links?.next)
      getAllCert(certification.data.links?.next);
  };

  useEffect(() => {
    if (!certificateLoadRef.current) {
      getAllCert();
      certificateLoadRef.current = true;
    }
  }, [certificateLoadRef]);

  const getInitials = (name: string | undefined) => {
    if (!name) return "";
    var names = name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getOrganization = () =>
    email?.split("@")[1]?.split(".")[0]?.toUpperCase() ?? "";

  return (
    <Stack className="userprofile-container">
      <Grid templateColumns="3fr 1fr" gap={6}>
        <GridItem w="100%">
          <Stack direction={"row"} gap={"14px"}>
            <Avatar
              initials={getInitials(userFullName)}
              url={userAttributes?.avatarUrl}
            />
            <Stack justify="center" width="-webkit-max-content" w="50%" gap={0}>
              <Text as="h3" className="name">
                {userFullName}
              </Text>
              <Divider className="profile-divider" />
              <Text className="organization">{getOrganization()}</Text>
            </Stack>
          </Stack>
        </GridItem>
        <GridItem w="100%">
          <Stack>
            <Text className="accomplishment-title">Accomplishments</Text>
            <Stack direction="row" align="center" gap={"14px"}>
              <AccomplishmentItem
                title={courseCount}
                subTitle="Courses"
                icon={fastCourseIcon}
              />
              <AccomplishmentItem
                title={badgeCount}
                subTitle="Badges"
                icon={fastBadgeIcon}
              />
              <AccomplishmentItem
                title={certificationCount}
                subTitle="Certifications"
                icon={fastCertificationIcon}
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default LearningStatusCardUserInfoWithAchivements;
