import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Party_Email_Details
interface PartyEmailDetailProps {
  emailType: string;
  data: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  fromDate: Date | null;
  fromTime: string;
  throughDate: Date | null;
  throughTime: string;
  verifiedDate: Date | null;
  verifiedTime: string;
}
// Defining Intitial Value For Party_Email_Details
const PartyEmail: PartyEmailDetailProps = {
  emailType: "",
  data: "",
  label: "",
  isPreferred: undefined,
  fromDate: null,
  fromTime: "",
  throughDate: null,
  throughTime: "",
  verifiedDate: null,
  verifiedTime: "",
};
// YUP LIBRARY :Defining Schema For validation of Party_Email_Details

const PartyEmailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["emails"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PartyEmailDetailProps };
// Exporting Validation Schema and Intial value
export { PartyEmail, PartyEmailSchema as getValidationSchema };
