import { generateYupSchema } from "@/utils";
import { Option } from "@/utils/types";
import * as Yup from "yup";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Reward_Configuration
interface RewardConfigurationProps {
  programType: Option<number>[];
  depInterval: string;
  intervalAmt: string;
  goalAmt: string;
  goalTerm: string;
  rewardIntPrem: string | null;
  reward: string | null;
  program: string | null;
  subGroups: string[];
  reviewFreq: string;
  rewardReviewModalLink: boolean;
  rewardReviewFrequencyModelInputValue: string;
  payoutTrnCode: string;
  payoutFreq: string;
  rewardPayoutFrequencyModelInputValue: string;
  modalLink: boolean;

  configuration: string;
  savingGoalRewardProgramType: Option<number>[];
  relationshipRewardProgramType: Option<number>[];
  rolloverRewardProgramType: Option<number>[];
}

// YUP LIBRARY :Defining Schema For validation of Rewards_Configuration
const rewardConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "payoutOpt",
    "goalAmt",
    "goalTerm",
    "depInterval",
    "intervalAmt",
    "reward",
    "rewardIntPrem",
    "relationshipProgram",
    "rewardOffset",
    "trnRewardProgram",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RewardConfigurationProps };
// Exporting Validation Schema and Intial value
export { rewardConfigurationSchema as getValidationSchema };
