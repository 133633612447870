import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface 
interface BaseSavingsRateProps {
  indexName: string;
  minRate: string;
  maxRate: string;
  offsetMatrix: string;
  round: string;
}
// Defining Intitial Value 
const BaseSavingsRateDetails: BaseSavingsRateProps = {
  indexName: "",
  minRate: "",
  maxRate: "",
  offsetMatrix: "",
  round: "",
};


const baseSavingsRateSchemaSchema = async (optionsSchema: PostSchema) => {
  const fields = ["baseSavRate"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { BaseSavingsRateProps };
// Exporting Validation Schema and Intial value
export {
  BaseSavingsRateDetails,
  baseSavingsRateSchemaSchema as getValidationSchema,
};
