import { ERROR_MESSAGE } from "@/components/data/form-data";

import * as Yup from "yup";

const teamValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(ERROR_MESSAGE),
  lastName: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().required(ERROR_MESSAGE).email("Enter a valid email"),
});

export { teamValidationSchema as validationSchema };
