"use client";

import { CUSTOM_ROLES_TABLE_COLUMN_REF } from "@/data/access-management-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { NAME_KEY, TAB_NAMES } from "@/utils";

import TabComponent from "../tab-component/TabComponent";

interface RoleProps {
  tableData: any;
}

const CustomRolesTab = (props: RoleProps) => {
  const { tableData } = props;
  return (
    <TabComponent
      heading="Custom roles"
      subHeading="Customize roles by permitting unique combinations of operations"
      createButtonLink={`${ROUTE_PATH.AM_CUSTOM_ROLES}?tab=${TAB_NAMES.roles}`}
      createButtonName="Create custom role"
      tableData={tableData}
      tableColumns={CUSTOM_ROLES_TABLE_COLUMN_REF}
      removeItemKey={NAME_KEY}
      editButtonLink={ROUTE_PATH.AM_CUSTOM_ROLES}
      appendModalTitle="role"
    />
  );
};

export default CustomRolesTab;
