"use client";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  InputText,
  ModalSelector,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  getValidationSchema,
  GeneralOrganizationPartyIdentificationProps,
} from "./GeneralOrganizationPartyIdentificationValidation";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { useSearchParams } from "next/navigation";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  Option,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: FinancialInstitutionData;
    noOfEmployeesOptions: Option<string>[];
    partyOrgFininstOptionsData: PostSchema;
  };
};

const GeneralOrganizationPartyIdentification = ({ data }: Props) => {
  const toast = useToast();
  const toastId = "general-organization-party-identification";
  const updateQueryParams = useQueryParams();
  const searchParams = useSearchParams();
  const entityId = searchParams?.get(QUERY_PARAM_KEY.ENTITY_ID);

  const { partyOrgFininstOptionsData } = data;

  const selectedModalOption = (option: any) => {
    setFieldValue("nbrEmployed", option);
  };

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (
    values: GeneralOrganizationPartyIdentificationProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_org_party_identification;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        name: data.formData.name,
        primaryBankId: values.primaryBankId,
        dbaName: values.dbaName,
        desc: values.desc,
        nbrEmployed: values.nbrEmployed,
        tradeName: values.tradeName,
        dunsNbr: parseInt(values.dunsNbr),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };

  const handleOnChange = (key: string) => (value: string | any[]) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      primaryBankId: data.formData?.primaryBankId ?? entityId,
      dbaName: data.formData?.dbaName ?? "",
      desc: data.formData?.desc ?? "",
      tradeName: data.formData?.tradeName ?? "",
      dunsNbr: data.formData?.dunsNbr?.toString() ?? "",
      nbrEmployed: data.formData?.nbrEmployed ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} noValidate id="finxact-form">
      <CardContainer>
        <Heading className="app-form-field-container">
          General organization party identification
        </Heading>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the primary bank name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("primaryBankId")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.primaryBankId}
            onChange={handleOnChange("primaryBankId")}
            onBlur={handleBlur}
            placeholder="Enter"
            disabled={data.formData?.primaryBankId ? true : false}
          />
          {errors.primaryBankId && touched.primaryBankId && (
            <ErrorAlert>
              <span>{errors.primaryBankId}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the institution’s DBA name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("dbaName")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.dbaName}
            onChange={handleOnChange("dbaName")}
            onBlur={handleBlur}
            placeholder="Enter"
          />
          {errors.dbaName && touched.dbaName && (
            <ErrorAlert>
              <span>{errors.dbaName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the party organization description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            onBlur={handleBlur}
            placeholder="Enter"
          />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the trade name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tradeName")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.tradeName}
            onChange={handleOnChange("tradeName")}
            onBlur={handleBlur}
            placeholder="Enter"
          />
          {errors.tradeName && touched.tradeName && (
            <ErrorAlert>
              <span>{errors.tradeName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the Dun & Bradstreet(DUNS) number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("dunsNbr")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.dunsNbr}
            onChange={handleOnChange("dunsNbr")}
            onBlur={handleBlur}
            placeholder="Enter"
          />
          {errors.dunsNbr && touched.dunsNbr && (
            <ErrorAlert>
              <span>{errors.dunsNbr}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="How many employees work for your organization?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("nbrEmployed")
                ?.tooltip_text_main
            }
          />
          <ModalSelector
            linkName="+ Select number of employees"
            modalTitle="Select number of employees"
            optionList={data.noOfEmployeesOptions}
            showSelection="description"
            onChange={selectedModalOption}
            value={values.nbrEmployed}
          />
        </Box>
        {errors.nbrEmployed && touched.nbrEmployed && (
          <ErrorAlert>
            <span>{errors.nbrEmployed}</span>
          </ErrorAlert>
        )}
      </CardContainer>
    </form>
  );
};
export default GeneralOrganizationPartyIdentification;
