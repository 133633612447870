import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { SECONDARY_RADIO_VALUE, deepCopy, generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface FinOrgCorrespondingNetworkDestAddrGeoLocProp {
  alt: number | string;
  geoplace: string;
  lat: number | string;
  lon: number | string;
}

interface FinOrgCorrespondingNetworkDestAddrProp {
  geoLoc: FinOrgCorrespondingNetworkDestAddrGeoLocProp;
  _Id: string; //address id
  addrType: string;
  city: string;
  cityLctn: string;
  cntry: string;
  dept: string;
  district: string;
  postCode: string;
  premise: string;
  premiseFloor: string;
  premiseNbr: string;
  premiseRoom: string;
  region: string;
  region2: string;
  region3: string;
  region4: string;
  street: string;
  street2: string;
  street3: string;
  street4: string;
  subDept: string;
  addrIdRadio?: string;
}

interface FinOrgCorrespondingNetworkDestValueProps {
  partyAddr: FinOrgCorrespondingNetworkDestAddrProp;
  _Id: string; //party identifier
  companyEntryDesc: string; //Company Entry Description
  dbaName: string;
  finInstAba: string;
  finInstBic: string;
  idNbr: string; //id number
  name: string; //institutution name
  otherId: string;
  otherIdType: string;
  city?: string;
}

const partyAddrInitialValues: FinOrgCorrespondingNetworkDestAddrProp = {
  geoLoc: {
    alt: "",
    geoplace: "",
    lat: "",
    lon: "",
  },
  _Id: "",
  addrType: "",
  city: "",
  cityLctn: "",
  cntry: "",
  dept: "",
  district: "",
  postCode: "",
  premise: "",
  premiseFloor: "",
  premiseNbr: "",
  premiseRoom: "",
  region: "",
  region2: "",
  region3: "",
  region4: "",
  street: "",
  street2: "",
  street3: "",
  street4: "",
  subDept: "",
};

const finOrgSpecificParamCorrespondingDestInitialValue: FinOrgCorrespondingNetworkDestValueProps =
  {
    partyAddr: deepCopy(partyAddrInitialValues),
    _Id: "",
    companyEntryDesc: "",
    dbaName: "",
    finInstAba: "",
    finInstBic: "",
    idNbr: "",
    name: "",
    otherId: "",
    otherIdType: "",
    city: "",
  };

const finOrgSpecificParamCorrespondingDestValidationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["corrs"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig) as any;
};

//interface
export type {
  FinOrgCorrespondingNetworkDestAddrGeoLocProp,
  FinOrgCorrespondingNetworkDestAddrProp,
  FinOrgCorrespondingNetworkDestValueProps,
};

export {
  finOrgSpecificParamCorrespondingDestInitialValue,
  finOrgSpecificParamCorrespondingDestValidationSchema,
  partyAddrInitialValues,
};
