import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { CrtSetupResponse, crtComponentCreatePayload } from "./model";
import {
  createComponent,
  getCoreModelEndpoint,
  updateComponent,
} from "@/api-config/api-service";

// Create crt Setup
export const createCustomerRelationshipType = async (
  config: crtComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.custRelType}`,
    body: JSON.stringify(formData),
  })
    .then(
      async (
        res: Pick<crtComponentCreatePayload["formData"], "custRelType">
      ) => {
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            description: successMessage,
            status: "success",
          });
        }
        return res;
      }
    )
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update crt Setup
export const updateCustomerRelationshipType = async (
  config: crtComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.custRelType}/${formData.custRelType}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
export const getCustomerRelationshipType = async (
  crtType: string
): Promise<CrtSetupResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.custRelType}/${crtType}`);
};
