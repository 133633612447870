import { Grid, GridItem, Text } from "@chakra-ui/react";
import Image from "next/image";

type AccomplishmentItemProps = {
  icon: any;
  title: number;
  subTitle: string;
};

const AccomplishmentItem = (props: AccomplishmentItemProps) => {
  return (
    <Grid className="accomplishment-container">
      <GridItem className="icon">
        <Image src={props.icon} alt={`${props.title} icon button`} />
      </GridItem>
      <GridItem>
        <Text className="title">{props.title}</Text>
        <Text className="subtitle">{props.subTitle}</Text>
      </GridItem>
    </Grid>
  );
};

export default AccomplishmentItem;
