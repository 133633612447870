"use client";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Text,
} from "@chakra-ui/react";
import Image from "next/image";
import {
  activeInterstial,
  completeInterstial,
  expandDownBlack,
  expandUpIcon,
  rightRotateYellow,
  solidRightArrow,
} from "public/assets";
import React from "react";
import "./MainContent.scss";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Badges, CardContainer } from "@/components/common";
import { DASHBOARD_JOURNEY, DASHBOARD_ACCORDION_STATUS } from "@/utils";
import { PrismicRichText } from "@prismicio/react";
import { ROUTE_PATH } from "@/route-config/route-path";
import { DashboardSectionSliceDefaultItem } from "../model";
import Link from "next/link";
import type * as prismic from "@prismicio/client";
import { buildURL } from "@/prismic-api/common-service";

interface MainContentProps {
  btnIcon?: boolean;
  btnLink: boolean;
  btnName: string;
  showBtn: boolean;
  onClick: any;
  btnDisabled: boolean;
  title?: string;
  desc?: string;
  overviewHeader?: string;
  overview?: prismic.RichTextField;
  helpfullRes?: DashboardSectionSliceDefaultItem[];
  status: string;
  progressItem?: string;
  hasAdminAccess?: boolean;
  isLastItem?: boolean;
}

const MainContent = (props: MainContentProps) => {
  const {
    btnLink,
    btnName,
    showBtn,
    onClick,
    btnDisabled,
    title,
    desc,
    overviewHeader,
    overview,
    helpfullRes,
    status = DASHBOARD_ACCORDION_STATUS.notStarted,
    progressItem = "",
    btnIcon = false,
    hasAdminAccess = false,
    isLastItem = false,
  } = props;

  let statusIdc = (
    <Image
      src={activeInterstial}
      alt={"Right rotate image"}
      className="step-img"
    />
  );

  if (progressItem === DASHBOARD_JOURNEY.exploreFinxact) {
    switch (status) {
      case DASHBOARD_ACCORDION_STATUS.completed:
        statusIdc = (
          <Image
            src={completeInterstial}
            alt={"Right rotate image"}
            className="step-img"
          />
        );
        break;
      case DASHBOARD_ACCORDION_STATUS.inProgress:
        statusIdc = (
          <Image
            src={rightRotateYellow}
            alt={"Right rotate image"}
            className="step-img"
          />
        );
        break;
      case DASHBOARD_ACCORDION_STATUS.notStarted:
        statusIdc = (
          <Image
            src={activeInterstial}
            alt={"Right rotate image"}
            className="step-img"
          />
        );
        break;
    }
  }

  let btnDisabling =
    (!btnDisabled || status === DASHBOARD_ACCORDION_STATUS.completed) &&
    progressItem !== DASHBOARD_JOURNEY.exploreFinxact;
  if (progressItem === DASHBOARD_JOURNEY.exploreFinxact) {
    btnDisabling = btnDisabled;
  }

  return (
    <Box className="dashboard-main-content">
      <Step>
        <Accordion
          className="dashboard-accordion-components"
          allowToggle={true}
        >
          <AccordionItem className="accordion-item">
            {({ isExpanded }) => (
              <Box
                className={`accordion-container ${
                  status === DASHBOARD_ACCORDION_STATUS.inProgress || isExpanded
                    ? `accordion-container-active ${isLastItem ? "active-last-item" : ""}`
                    : ""
                }`}
              >
                <AccordionButton
                  as="div"
                  className={`accordion-button ${isLastItem ? "last-item" : ""}`}
                >
                  <StepIndicator zIndex={10}>
                    <StepStatus
                      complete={
                        progressItem !== DASHBOARD_JOURNEY.exploreFinxact ? (
                          <Image
                            src={completeInterstial}
                            alt={"Right rotate image"}
                            className="step-img"
                          />
                        ) : (
                          statusIdc
                        )
                      }
                      incomplete={
                        <Image
                          src={activeInterstial}
                          alt={"Right rotate image"}
                          className="step-img"
                        />
                      }
                      active={
                        progressItem === DASHBOARD_JOURNEY.foundationalSetup ? (
                          <Image
                            src={rightRotateYellow}
                            alt={"Right rotate image"}
                            className="step-img"
                          />
                        ) : (
                          <Image
                            src={activeInterstial}
                            alt={"Right rotate image"}
                            className="step-img"
                          />
                        )
                      }
                    />
                  </StepIndicator>
                  <Flex
                    as={"div"}
                    flexGrow={1}
                    flexWrap={"wrap"}
                    rowGap={4}
                    justifyContent={"space-between"}
                    mr={4}
                  >
                    <Box className="accordion-description-container">
                      <Text
                        className={`accordion-title ${
                          isExpanded ? "accordion-title-bold" : ""
                        }`}
                      >
                        {title}
                      </Text>
                      <Text className="accordion-limit-name">{desc}</Text>
                    </Box>
                    {btnLink && showBtn ? (
                      <Button
                        onClick={onClick}
                        isDisabled={btnDisabling}
                        className="app-btn-reg-secondary accordion-title-button"
                      >
                        {btnName}
                        {btnIcon ? (
                          <ChevronRightIcon className="arrow-icon" />
                        ) : (
                          ""
                        )}
                      </Button>
                    ) : showBtn ? (
                      <Button
                        onClick={onClick}
                        className="app-btn-inverse-secondary accordion-title-button"
                        isDisabled={btnDisabled}
                      >
                        {btnName}
                      </Button>
                    ) : hasAdminAccess ? (
                      <Badges
                        size="lg"
                        variant="solid"
                        colorScheme={"gray"}
                        label="ADMIN TASK"
                        type="default"
                      />
                    ) : (
                      ""
                    )}
                  </Flex>
                  <Flex className="accordion-button-container">
                    {isExpanded ? (
                      <Image
                        className="accordion-expand"
                        src={expandUpIcon}
                        alt="expand up icon"
                      />
                    ) : (
                      <Image
                        className="accordion-expand"
                        src={expandDownBlack}
                        alt="expand down icon"
                      />
                    )}
                  </Flex>
                  <StepSeparator className="stepper-sep" />
                </AccordionButton>

                <AccordionPanel as={"div"} className={`accordion-panel`}>
                  <Text className="resource-heading">{overviewHeader}</Text>
                  <Box className="recent-products-text">
                    <PrismicRichText field={overview} />
                  </Box>
                  {helpfullRes && helpfullRes.length > 0 ? (
                    <Text className="helpful-resource-heading">
                      Helpful resources to get started
                    </Text>
                  ) : (
                    ""
                  )}
                  {helpfullRes &&
                    helpfullRes.map((data: any, index: number) => (
                      <CardContainer
                        key={index}
                        customClass="resource-containers"
                      >
                        <div>
                          <Text className="resource-title">
                            {data.link_title}
                          </Text>
                          <Text className="resource-caption">
                            {data.link_caption}
                          </Text>
                        </div>
                        <div>
                          {data.link_type === "prismic link" ? (
                            <Link
                              href={buildURL(
                                data?.prismic_link?.type,
                                data?.prismic_link?.uid
                              )}
                            >
                              <Image src={solidRightArrow} alt="Right arrow" />
                            </Link>
                          ) : data.link_type === "academy" ? (
                            <Link
                              href={
                                `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                                `${data?.academy_course_link}`
                              }
                            >
                              <Image src={solidRightArrow} alt="Right arrow" />
                            </Link>
                          ) : (
                            <a
                              href={
                                data?.link?.link_type === "Document"
                                  ? buildURL(data?.link?.type, data?.link?.uid)
                                  : data?.link?.url
                              }
                            >
                              <Image src={solidRightArrow} alt="Right arrow" />
                            </a>
                          )}
                        </div>
                      </CardContainer>
                    ))}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Step>
    </Box>
  );
};

export default MainContent;
