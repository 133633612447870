"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  DatePicker,
  AddNewButton,
  DragDropContainer,
  TimeField,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  TaxPartyPhoneContactProps,
  defaultTaxPartyPhoneContact,
  taxPrtyPhnProps,
  getValidationSchema,
} from "./TaxPartyPhoneContactValidation";
import { Box, Stack, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import { makeDateTime, shouldUpdateWorkflowStatus } from "@/utils/common";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER,
  DEFAULT_PHONE_NO_PLACEHOLDER,
  IC_ENTITY,
} from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type TaxPartyPhnProps = {
  data: {
    phoneTypeOptions: { label: string; value: string }[];
    formData: FinancialInstitutionData;
    bankParamOptionsData: PostSchema;
  };
};

const TaxPartyPhoneContact = (props: TaxPartyPhnProps) => {
  const { phoneTypeOptions, formData, bankParamOptionsData } = props.data;
  const toast = useToast();
  const activeAccordionRef = useRef<any>();
  const onSubmit = async (values: taxPrtyPhnProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "tax_party_phn_type",
    };
    response = await updateBankParameters({
      formData: {
        _vn: formData._vn,
        _Id: formData._Id,
        // PATCH Fields
        taxPartyContact: {
          phones:
            values.phones &&
            values.phones.map((phn) => ({
              cntryCallCode: phn.cntryCallCode,
              extension: phn.extension,
              phoneType: phn.phoneType,
              isPreferred: phn.isPreferred,
              data: phn.data,
              label: phn.label,
              validFromDtm:
                phn.phoneNumberValidFromDate !== "" &&
                phn.phoneNumberValidFromTime !== ""
                  ? makeDateTime(
                      phn.phoneNumberValidFromDate as Date,
                      phn.phoneNumberValidFromTime
                    )
                  : null,
              validToDtm:
                phn.phoneNumberValidThroughDate !== "" &&
                phn.phoneNumberValidThroughTime !== ""
                  ? makeDateTime(
                      phn.phoneNumberValidThroughDate as Date,
                      phn.phoneNumberValidThroughTime
                    )
                  : null,
              verifyDtm:
                phn.phoneNumberVerifiedOnDate !== "" &&
                phn.phoneNumberVerifiedOnTime !== ""
                  ? makeDateTime(
                      phn.phoneNumberVerifiedOnDate as Date,
                      phn.phoneNumberVerifiedOnTime
                    )
                  : null,
            })),
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      navigateToNextPage?.();
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`phones.${index}.${key}`, value);
  };

  const errorMessage = (key: string, index: number) => {
    return (
      <>
        {touched.phones &&
          touched.phones[index] &&
          (touched.phones[index] as any)[key] &&
          errors.phones &&
          errors.phones[index] &&
          (errors.phones[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.phones[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema.fields.taxPartyContact);
    })();
  }, [bankParamOptionsData]);

  const initialTaxPartyPhoneContact: TaxPartyPhoneContactProps[] =
    formData.taxPartyContact?.phones && formData.taxPartyContact?.phones.length
      ? formData.taxPartyContact?.phones.map((phn: any) => ({
          phoneType: phn.phoneType ?? undefined,
          cntryCallCode: phn.cntryCallCode ?? "",
          data: phn.data ?? "",
          extension: phn.extension ?? "",
          label: phn.label ?? "",
          isPreferred: phn.isPreferred ?? undefined,
          phoneNumberValidFromDate: phn.validFromDtm
            ? new Date(phn.validFromDtm as string)
            : "",
          phoneNumberValidFromTime: phn.validFromDtm
            ? new Date(phn.validFromDtm as string).toLocaleTimeString("it-IT")
            : "",
          phoneNumberValidThroughDate: phn.validToDtm
            ? new Date(phn.validToDtm as string)
            : "",
          phoneNumberValidThroughTime: phn.validToDtm
            ? new Date(phn.validToDtm as string).toLocaleTimeString("it-IT")
            : "",
          phoneNumberVerifiedOnDate: phn.verifyDtm
            ? new Date(phn.verifyDtm as string)
            : "",
          phoneNumberVerifiedOnTime: phn.verifyDtm
            ? new Date(phn.verifyDtm as string).toLocaleTimeString("it-IT")
            : "",
        }))
      : [];
  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { phones: initialTaxPartyPhoneContact },
  });
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);

  const setTaxPartyPhoneContact = (fn: any) => {
    setFieldValue(`phoneContact`, fn(values.phones));
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form">
        <FieldArray
          name="phones"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              <CardContainer>
                <DragDropContainer
                  items={values.phones?.map(
                    (fieldValues: any, index: number) => {
                      return {
                        id: index + 1,
                        deleteHandler: () => remove(index),
                        showDeleteIcon: true,
                        content: (
                          <Box ref={activeAccordionRef}>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="What is the phone type?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "emailType"
                                  )?.tooltip_text_main
                                }
                              />
                              <RadioButtonGroup
                                isBoxedRadio={true}
                                value={fieldValues.phoneType}
                                onChange={handleOnChange("phoneType", index)}
                                radioPropList={phoneTypeOptions}
                                stackDirection={"column"}
                                spacing={"1rem"}
                              />

                              {errorMessage("phoneType", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="What is the country calling code?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "countryCallingCode"
                                  )?.tooltip_text_main
                                }
                              />
                              <InputText
                                value={fieldValues.cntryCallCode}
                                onChange={handleOnChange(
                                  "cntryCallCode",
                                  index
                                )}
                                onBlur={handleBlur}
                                placeholder="+1 US"
                              />
                              {errorMessage("cntryCallCode", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="What is the phone number?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "phone"
                                  )?.tooltip_text_main
                                }
                              />
                              <InputText
                                value={fieldValues.data}
                                onChange={handleOnChange("data", index)}
                                onBlur={handleBlur}
                                placeholder={DEFAULT_PHONE_NO_PLACEHOLDER}
                              />
                              {errorMessage("data", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="What is the extension number?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get("extn")
                                    ?.tooltip_text_main
                                }
                              />
                              <InputText
                                id="phone-extension"
                                value={fieldValues.extension}
                                onChange={handleOnChange("extension", index)}
                                onBlur={handleBlur}
                                placeholder={
                                  DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER
                                }
                              />
                              {errorMessage("extension", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="Enter a recognizable label for the phone number"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "label"
                                  )?.tooltip_text_main
                                }
                              />
                              <InputText
                                value={fieldValues.label}
                                onChange={handleOnChange("label", index)}
                                onBlur={handleBlur}
                                placeholder={"Enter a label"}
                              />
                              {errorMessage("label", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="Is this the preferred phone number?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "isPreferred"
                                  )?.tooltip_text_main
                                }
                              />
                              <SplitButton
                                name="isPreferred"
                                onSelect={handleOnChange("isPreferred", index)}
                                leftBtnName="Yes"
                                rightBtnName="No"
                                onBlur={handleBlur}
                                value={fieldValues.isPreferred}
                              />
                              {errorMessage("isPreferred", index)}
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel labelName="When is the phone number valid from?" />
                              <Stack
                                gap="2rem"
                                display="flex"
                                flexDirection="row"
                                width="100%"
                              >
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Date"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "validFromDate"
                                      )?.tooltip_text_main
                                    }
                                    labelFor="phone_number_valid_from_date"
                                  />
                                  <DatePicker
                                    value={fieldValues.phoneNumberValidFromDate}
                                    onUpdate={handleOnChange(
                                      "phoneNumberValidFromDate",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    id="phone_number_valid_from_date"
                                    placeholder="Select"
                                  />
                                  {errorMessage(
                                    "phoneNumberValidFromDate",
                                    index
                                  )}
                                </Stack>
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Time"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "validFromTime"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <TimeField
                                    value={fieldValues.phoneNumberValidFromTime}
                                    onChange={handleOnChange(
                                      "phoneNumberValidFromTime",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    ariaLabel="phone-number-valid-from"
                                  />
                                  {errorMessage(
                                    "phoneNumberValidFromTime",
                                    index
                                  )}
                                </Stack>
                              </Stack>
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="When is the phone number valid through?"
                                //   tooltipDesc="When is the phone number valid through?"
                              />
                              <Stack
                                gap="2rem"
                                display="flex"
                                flexDirection="row"
                                width="100%"
                              >
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Date"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "validToDate"
                                      )?.tooltip_text_main
                                    }
                                    labelFor="phone_number_valid_through_date"
                                  />
                                  <DatePicker
                                    value={
                                      fieldValues.phoneNumberValidThroughDate
                                    }
                                    onUpdate={handleOnChange(
                                      "phoneNumberValidThroughDate",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    placeholder="Select"
                                    id="phone_number_valid_through_date"
                                  />
                                  {errorMessage(
                                    "phoneNumberValidThroughDate",
                                    index
                                  )}
                                </Stack>
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Time"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "validToTime"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <TimeField
                                    value={
                                      fieldValues.phoneNumberValidThroughTime
                                    }
                                    onChange={handleOnChange(
                                      "phoneNumberValidThroughTime",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    ariaLabel="phone-number-valid-through"
                                  />
                                  {errorMessage(
                                    "phoneNumberValidThroughTime",
                                    index
                                  )}
                                </Stack>
                              </Stack>
                            </Box>
                            <Box className="app-form-field-container">
                              <AppFormLabel labelName="When was the phone number verified on?" />
                              <Stack
                                gap="2rem"
                                display="flex"
                                flexDirection="row"
                                width="100%"
                              >
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Date"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "verifyDate"
                                      )?.tooltip_text_main
                                    }
                                    labelFor="phone_number_verified_on_date"
                                  />
                                  <DatePicker
                                    value={
                                      fieldValues.phoneNumberVerifiedOnDate
                                    }
                                    onUpdate={handleOnChange(
                                      "phoneNumberVerifiedOnDate",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    placeholder="Select"
                                    id="phone_number_verified_on_date"
                                  />
                                  {errorMessage(
                                    "phoneNumberValidThroughTime",
                                    index
                                  )}
                                </Stack>
                                <Stack flexBasis={"50%"}>
                                  <AppFormLabel
                                    labelName="Time"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "verifTime"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <TimeField
                                    value={
                                      fieldValues.phoneNumberVerifiedOnTime
                                    }
                                    onChange={handleOnChange(
                                      "phoneNumberVerifiedOnTime",
                                      index
                                    )}
                                    onBlur={handleBlur}
                                    ariaLabel="phone-number-verified-on"
                                  />
                                  {errorMessage(
                                    "phoneNumberVerifiedOnTime",
                                    index
                                  )}
                                </Stack>
                              </Stack>
                            </Box>
                          </Box>
                        ),
                      };
                    }
                  )}
                  setItems={setTaxPartyPhoneContact}
                  isElement
                />
                <AddNewButton
                  label={
                    !values.phones.length
                      ? "Add party phone"
                      : "Add another party phone"
                  }
                  onClick={() => {
                    push({
                      ...defaultTaxPartyPhoneContact,
                      idx: Math.random(),
                    });
                  }}
                />
              </CardContainer>
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
};

export default TaxPartyPhoneContact;
