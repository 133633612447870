"use client";
import {
  Box,
  Button,
  ChevronRightIcon,
  Flex,
  Text,
} from "@/components/ChakraUiManager";
import { CardContainer, StaticText } from "@/components/common";
import Style from "./TestingRead.module.scss";
import { blackCheck } from "public/assets";
import Image from "next/image";
import Link from "next/link";
import { buildURL } from "@/prismic-api/common-service";
import { TestingProps } from "../ProductReview";

const TestingRead = (props: { data: TestingProps | null | undefined }) => {
  const { data } = props;

  return (
    <Box className={Style["testing-read-container"]}>
      <Box className={Style["testing-read-heading"]}>
        <StaticText heading headingName={data?.heading || ""} />
      </Box>
      <Box className={Style["testing-read-head-paragraph"]}>
        <StaticText textValue={data?.description || ""} />
      </Box>
      <CardContainer customClass={Style["testing-read-card-container"]}>
        {data &&
          data.items.map((item: any) => {
            const linkItem: any = item.link;
            return (
              <Box className={`doc-card-wrapper`} key={Math.random()}>
                <Flex className={Style["doc-card-container"]}>
                  <Image src={blackCheck} alt="Check icon" />
                  <Box className={Style["doc-card-content"]}>
                    <Text className={Style["title"]}>{item.title}</Text>
                    <Text className={Style["sub-title"]}>{item.caption}</Text>
                  </Box>
                  <Flex className={Style["doc-tag-arrow-card"]}>
                    <Link
                      href={
                        linkItem.link_type === "Document"
                          ? buildURL(linkItem.type, linkItem.uid)
                          : linkItem.url
                      }
                    >
                      <Button className="app-btn-link links">
                        {item.anchor_text}
                        <ChevronRightIcon className="chakra-icon" />
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
      </CardContainer>
    </Box>
  );
};
export default TestingRead;
