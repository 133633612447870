import { useEffect, useState } from "react";
import { getDaysDiff, getExpiryDate } from "../../learning-util-service";
import { Box, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

type CourseValidityTextProps = {
  validity?: string;
  dateCompleted?: string | undefined;
  hasPassed: boolean;
};

export default function CourseValidityText({
  hasPassed,
  validity,
  dateCompleted,
}: CourseValidityTextProps) {
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const [daysToExpire, setDaysToExpire] = useState<string>("");
  const [expiryDate, setExpiryDate] = useState<string>("");
  const [expiryLabel, setExpiryLabel] = useState<string>("");

  useEffect(() => {
    setExpiryDate(getExpiryDate(dateCompleted, validity, "MM/DD/YYYY"));
    const getTimeRemaining = () => {
      const diffDuration = getDaysDiff(dateCompleted, validity);

      if (diffDuration <= 0) {
        setIsExpiring(true);
        setDaysToExpire(` 0 Days `);
        setExpiryLabel("EXPIRED");
      } else if (diffDuration < 30) {
        setIsExpiring(true);
        setDaysToExpire(` ${diffDuration} Days `);
        setExpiryLabel("EXPIRING SOON");
      } else {
        setIsExpiring(false);
        setDaysToExpire(` ${Math.floor(diffDuration / 30)} Months `);
      }
    };
    getTimeRemaining();
  }, [validity, dateCompleted]);

  return (
    <Box className="certificate-validity-details">
      {!hasPassed && (
        <Box className="certificate-validity-details-holder">
          <Text as="span" className="detail-text">
            Complete all modules first to see the course validity
          </Text>
        </Box>
      )}
      {hasPassed && (
        <>
          <Box className="certificate-validity-details-holder">
            <Box
              className={`status-tag ${isExpiring ? "inactive" : "active"}`}
              h={2.5}
              w={2.5}
              borderRadius={6}
            ></Box>
            <Text as="span" className="detail-text">
              Valid for:
              <Text as="span" className="detail-text-bold">
                {daysToExpire}
              </Text>
              ({expiryDate})
            </Text>
          </Box>
          {isExpiring && (
            <Tag
              size="lg"
              colorScheme="yellow"
              className="cert-expiry-tag"
              borderRadius={32}
            >
              <TagLeftIcon as={WarningIcon} />
              <TagLabel>{expiryLabel}</TagLabel>
            </Tag>
          )}
        </>
      )}
    </Box>
  );
}
