import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface ProductCadencesFormProps {
  ifxAcctType: string;
  stmtFreq: string;
  stmtFreqMatrix: string;
  interimStmtFreq: string;
  inactiveStatusAlertFreq: string;
  dormantStatusAlertFreq: string;
  interimStatementFrequencyButton: SplitBtnInputValType;
  inactiveStatusDur: string;
  dormantStatusDur: string;
  inactiveStatusFee: string;
  inactiveStatusRestrictCode: string;
  dormantStatusFee: string;
  dormantStatusRestrictCode: string;
  stmtStartDtmOpt: number;
}

const basicProductConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "stmtFreq",
    "stmtFreqMatrix",
    "interimStmtFreq",
    "inactiveStatusDur",
    "inactiveStatusAlertFreq",
    "inactiveStatusFee",
    "inactiveStatusRestrictCode",
    "dormantStatusAlertFreq",
    "dormantStatusDur",
    "dormantStatusFee",
    "dormantStatusRestrictCode",
    "stmtStartDtmOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

export type { ProductCadencesFormProps };
export { basicProductConfigurationSchema as getValidationSchema };
