import React from "react";
import "./FastInformationTag.scss";

interface Props {
  progressPercent: number;
  courseType?: string;
}

const FastInformationTag = ({ progressPercent, courseType }: Props): JSX.Element => {
  let tagClass = "tag-notstarted";
  let textInput = "NOT STARTED";

  if (courseType !== undefined && courseType !== "") {
    textInput = courseType;
  } else if (progressPercent === 100) {
    tagClass = "tag-completed";
    textInput = "COMPLETED";
  } else if (progressPercent > 0) {
    tagClass = "tag-inprogress";
    textInput = `${progressPercent}% COMPLETE`;
  }

  return (
    <div className={`FAST-information-tag ${tagClass}`}>
      <div className="tag">
        {textInput}
      </div>
    </div>
  );
};

export default FastInformationTag;
