"use client";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  VStack,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import "./CloverCheckout.scss";

import React, { useEffect, useState } from "react";
import { AppFormLabel, Loader } from "@/components/common";
import { PriceDetails } from "@/components/common/clover-checkout-button/CloverCheckoutButton";
import {
  postCloverCharge,
  fetchCloverOrderDetails,
  fetchCloverConfigurations,
  verifyPaymentStatus,
} from "@/client-api-manager/clover-api";
import { ROUTE_PATH } from "@/route-config/route-path";

interface CloverConfigurations {
  ecomAppToken: string;
  ecomPakmsKey: string;
  merchantID: string;
  ecomSdkUrl: string;
}

const CloverCheckout = ({
  priceDetails,
  courseName,
  imageUrl,
  courseId,
  pageData,
}: {
  priceDetails: PriceDetails;
  courseName: string;
  imageUrl: string;
  courseId: string;
  pageData: any;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [config, setConfig] = useState<CloverConfigurations | null>(null);
  const toast = useToast();
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const loadConfigurations = async () => {
      if (!config) {
        try {
          const fetchedConfig: CloverConfigurations =
            await fetchCloverConfigurations();
          setConfig(fetchedConfig);
          loadCloverSdk(fetchedConfig);
        } catch (error) {
          console.error("Failed to fetch Clover configurations:", error);
          toast({
            title: "Configuration Error",
            description: "Failed to load payment configurations",
            status: "error",
            duration: 9000,
          });
        }
      }
    };

    loadConfigurations();
  }, [config]);

  const loadCloverSdk = (fetchedConfig: CloverConfigurations) => {
    const script = document.createElement("script");
    script.src = fetchedConfig.ecomSdkUrl;
    script.async = true;

    script.onload = () => {
      if ((window as any).Clover && !isLoaded) {
        setIsLoaded(true);
        const clover = new (window as any).Clover(fetchedConfig.ecomPakmsKey, {
          merchantId: fetchedConfig.merchantID,
          locale: "en-US",
        });

        const elements = clover.elements();
        const styles = {
          body: {
            fontFamily: "Roboto, Open Sans, sans-serif",
            fontSize: "30px",
          },
          input: {
            fontSize: "14px",
            border: "1px solid #aaa",
            height: "50px",
            borderRadius: "10px",
            padding: "8px",
          },
          img: {
            right: "10px !important",
            top: "10px !important",
          },
        };

        const cardNumber = elements.create("CARD_NUMBER", styles);
        const cardDate = elements.create("CARD_DATE", styles);
        const cardCvv = elements.create("CARD_CVV", styles);
        const cardPostalCode = elements.create("CARD_POSTAL_CODE", styles);
        const cardName = elements.create("CARD_NAME", styles);
        const cardAddress = elements.create("CARD_STREET_ADDRESS", styles);

        cardNumber.mount("#card-number");
        cardDate.mount("#card-date");
        cardCvv.mount("#card-cvv");
        cardPostalCode.mount("#card-postal-code");
        cardName.mount("#card-name");
        cardAddress.mount("#card-address");

        const form = document.getElementById("payment-form");
        form?.addEventListener("submit", function (event) {
          event.preventDefault();
          setSubmitted(true);
          clover.createToken().then(function (result: any) {
            if (result.errors) {
              Object.values(result.errors).forEach(function (value) {
                console.error(value);
                setSubmitted(false);
              });
            } else {
              cloverTokenHandler(result.token, result);
            }
          });
        });
      } else {
        console.error("Clover SDK not loaded");
      }
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  };

  const getUrlPath = () => {
    if (!courseId) return "";
    return `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${courseId}`;
  };

  async function cloverTokenHandler(token: any, result: any) {
    const chargeDetails = {
      amount: parseInt(priceDetails.total),
      currency: "usd",
      ecomind: "ecom",
      source: token,
      courseId: courseId,
    };

    try {
      const response = await postCloverCharge(chargeDetails);
      if (verifyPaymentStatus(response.status, response.id)) {
        const coursePageUrl = getUrlPath();
        window.location.href = coursePageUrl;
      } else {
        toast({
          title: "Payment Verification Failed",
          description:
            "There was an error in verifying payment for this course.",
          status: "error",
          duration: 9000,
        });
      }

      if (verifyPaymentStatus(response.status, response.id)) {
        const coursePageUrl = getUrlPath();
        window.location.href = coursePageUrl;
      } else {
        toast({
          title: "Payment Verification Failed",
          description:
            "There was an error in verifying payment for this course.",
          status: "error",
          duration: 9000,
        });
        setSubmitted(false);
      }
    } catch (error) {
      console.error("Failed to post charge:", error);
      setSubmitted(false);
    }
  }

  return (
    <>
      <Box className="payment-popup-header">
        <Image
          src={pageData?.logo?.url}
          alt={pageData?.logo?.alt}
          mb={13}
        ></Image>
        <Heading as={"h3"} mb={3}>
          {pageData?.headline}
        </Heading>
        <Text>{pageData?.description}</Text>
      </Box>
      <Flex
        className="clover-checkout-container"
        gap={"40px"}
        justifyContent={"space-between"}
        mt={13}
      >
        <Box w={"60%"}>
          <form id="payment-form">
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Card holder name"
                isRequired={true}
                labelFor="card-name"
                tooltipDesc="Card holder name"
              />
              <div id="card-name" className="field"></div>
              <div
                className="input-errors"
                id="card-number-errors"
                role="alert"
              ></div>
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Card number"
                isRequired={true}
                labelFor="card-number"
                tooltipDesc="Card number"
              />
              <div id="card-number" className="field"></div>
              <div
                className="input-errors"
                id="card-number-errors"
                role="alert"
              ></div>
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Expiration date"
                isRequired={true}
                labelFor="card-date"
                tooltipDesc="Expiration date"
              />
              <div id="card-date" className="field"></div>
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Security code"
                isRequired={true}
                labelFor="card-cvv"
                tooltipDesc="Security code"
              />
              <div id="card-cvv" className="field"></div>
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Street address"
                isRequired={true}
                labelFor="card-address"
                tooltipDesc="Street address"
              />
              <div id="card-address" className="field"></div>
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Zip code"
                isRequired={true}
                labelFor="card-postal-code"
                tooltipDesc="Zip code"
              />
              <div id="card-postal-code" className="field"></div>
            </Box>
            <Flex justifyContent={"end"}>
              <Button
                type="submit"
                className="app-btn-inverse-secondary"
                isLoading={submitted}
                loadingText={"Submitting"}
              >
                Submit Payment
              </Button>
            </Flex>
          </form>
          <Text mt={13}>{pageData?.disclaimer}</Text>
        </Box>
        <VStack className="payment-course-details">
          <Box className="course-name">
            <Flex gap={"10px"} alignItems={"center"}>
              <Image src={imageUrl}></Image>
              <Heading as="h3" fontSize={"18px"}>
                {courseName}
              </Heading>
            </Flex>
          </Box>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <Text>Subtotal</Text>
            <Text>{priceDetails.subTotal}</Text>
          </Flex>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <Text>Tax</Text>
            <Text>{priceDetails.tax}</Text>
          </Flex>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <Text>Total</Text>
            <Text>{priceDetails.total}</Text>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
};

export default CloverCheckout;
