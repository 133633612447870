"use client";

import { Box } from "@chakra-ui/react";
import "./APILibNotLoggedInFooter.scss";
import { FooterNotLoggedInUser } from "@/components/common";
import { useUserInfo } from "@/app/providers";

const APILibNotLoggedInFooter = () => {
  const { user } = useUserInfo();
  const isUserAuthenticated = user.loading == false && user.signedIn == true;

  return (
    !isUserAuthenticated && (
      <Box className="not-logged-in-footer container-box">
        <FooterNotLoggedInUser />
      </Box>
    )
  );
};

export default APILibNotLoggedInFooter;
