import { ERROR_MESSAGE } from "@/components/data/form-data";

import * as Yup from "yup";

export type CustomRoleFormType = {
  name: string;
  description?: string;
  status: string;
  type: string;
  permissions: string[];
  organization?: string;
};
export const initialFormValues: CustomRoleFormType = {
  name: "",
  description: "",
  status: "Active",
  type: "cnsl",
  permissions: [],
};
const customRolesSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
});

export { customRolesSchema as validationSchema };
