import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface commonTitleProps {
  name: string;
}
//Defining Interface For General_Party_Parameter
interface GeneralParty {
  name: string;
  partyIdentifier?: string;
}
// Defining Intitial Value For General_Party_Parameter
const generalPartyParameter: GeneralParty = {
  name: "",
  partyIdentifier: "",
};
// YUP LIBRARY :Defining Schema For validation of General_Party_Parameter

const generalPartyParameterSchema = async (optionsSchema: PostSchema) => {
  const fields = ["name"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { commonTitleProps, GeneralParty };
// Exporting Validation Schema and Intial value
export {
  generalPartyParameter,
  generalPartyParameterSchema as getValidationSchema,
};
