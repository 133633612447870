import { GENERIC_ERROR } from "@/components/data/error-data";
import * as Yup from "yup";
import { Option } from "@/utils/types";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";

//Defining Interface For Asset_Origin_And_Classification
interface OriginAndClassificationProps {
  naics: string;
  issueDesc: string;
  issuerDesc: string;
}

// Defining Intitial Value For Asset_Origin_And_Classification
const originAndClassification: OriginAndClassificationProps = {
  naics: "",
  issueDesc: "",
  issuerDesc: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Asset_Origin_And_Classification
const originAndClassificationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["naics", "issueDesc", "issuerDesc"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { OriginAndClassificationProps };

// Exporting Validation Schema and Intial value
export {
  originAndClassification,
  originAndClassificationSchema as getValidationSchema,
};
