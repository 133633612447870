"use client";

import { useParams, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Heading } from "./ChakraUiManager";

export default function CloverConfigFunction() {
  const routerParams = useParams<any>();
  const { client_id, code } = routerParams;
  const [authKey, setAuthKey] = useState<string>("");

  async function getOauthToken() {
    const cloverFetch = await fetch(
      `https://sandbox.dev.clover.com/oauth/token?client_id=${client_id}&client_secret=${process.env.CLIENT_SECRET}&code=${code}`
    );
    const token = await cloverFetch.json();
    if (token?.access_token) {
      setAuthKey(token.access_token);
    }
  }

  useEffect(() => {
    if (!authKey) {
      getOauthToken();
    }
  }, []);

  return (
    <div>
      <Heading as="h3"> Clover API call</Heading>
    </div>
  );
}
