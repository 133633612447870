"use client";
import { Box, Heading } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  InputText,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  DefaultFormDetails,
  FinancialInstitutionData,
} from "@/components/institutional-configuration/model";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { SIDEBAR_STATUS, useQueryParams } from "@/utils";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { generalLimitInformation } from "./AddPartyLimitValidation";

interface AddPartyLimit extends DefaultFormDetails {
  data: {
    formData: FinancialInstitutionData | null;
  };
}

export default function AddPartyLimit(props: AddPartyLimit) {
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const updateQueryParams = useQueryParams();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const onSubmit = (values: any, actions: any) => {
    updateFormStatus?.(SIDEBAR_STATUS.completed);
    updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
    if (values.addLimit === "selectLeft") {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION"]
        }?${updateQueryParams()}`
      );
    } else {
      navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
    }
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    initialValues: generalLimitInformation,
  });

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      {formTitle}
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Add limit?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("addLimit")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={handleOnChange("addLimit")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.addLimit}
          />
        </Box>
        {values.addLimit && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the party identifier?"
              isRequired
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("_Id")?.tooltip_text_main
              }
            />
            <InputText
              value={data.formData?._Id}
              onChange={handleOnChange("partyIndentifier")}
              onBlur={handleBlur}
            />
          </Box>
        )}
      </CardContainer>
    </form>
  );
}
