"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  DatePicker,
  ErrorAlert,
  TimeField,
} from "@/components/common";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { ROUTE_PATH } from "@/route-config/route-path";
import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  LocationContactInformationValueProps,
  getValidationSchema,
  locationContactInformation,
} from "./LocationContactInformationValidation";

import "./LocationContactInformation.scss";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
interface LocationContactInformationProps {
  data: {
    contactPreference: [{ label: string; value: any }];
    lastContactReason: [{ label: string; value: any }];
    country: [{ label: string; value: any }];
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
}

const LocationContactInformation = (props: LocationContactInformationProps) => {
  const { data } = props;
  const toast = useToast();
  const toastId = "gpp-location-contact-information";
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      const nestedValidationSchema = yupSchema.fields.emails;
      setValidationSchema(nestedValidationSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);
  const onSubmit = async (
    values: LocationContactInformationValueProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_party_location_contact_info;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        // PATCH fields
        contactPref: values.contactPref,
        lstContactReason: values.lstContactReason,
        cntry: values.cntry === "" ? null : values.cntry,
        region: values.cntry !== props.data.formData?.cntry ? null : undefined,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      cntry: data.formData?.cntry ?? locationContactInformation.cntry,
      contactPref:
        data.formData?.contactPref ?? locationContactInformation.contactPref,
      lstContactReason:
        data.formData?.lstContactReason ??
        locationContactInformation.lstContactReason,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <Heading as="h3">Location & contact information</Heading>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the address country?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("cntry")?.tooltip_text_main
            }
            labelFor="address-country"
          />
          <SelectDropdown
            value={values.cntry}
            onChange={handleOnChange("cntry")}
            dropdownList={data.country}
            onBlur={() => setFieldTouched("cntry")}
            placeholder="Select address country"
            id="address-country"
          />
          {errors.cntry && touched.cntry && (
            <ErrorAlert>
              <span>{errors.cntry}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the contact preference?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("contactPref")
                ?.tooltip_text_main
            }
            labelFor="contact-preferences"
          />
          <SelectDropdown
            value={values.contactPref}
            onChange={handleOnChange("contactPref")}
            dropdownList={data.contactPreference}
            onBlur={() => setFieldTouched("contactPref")}
            placeholder="Select contact preference"
          />
          {errors.contactPref && touched.contactPref && (
            <ErrorAlert>
              <span>{errors.contactPref}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="When is the last contact date?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("contactDtm")
                ?.tooltip_text_main
            }
          />
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Box width={"48%"}>
              <AppFormLabel
                labelName="Date"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("contactDate")
                    ?.tooltip_text_main
                }
              />
              <Box className="field-disabled">
                <DatePicker
                  value={values.contactDate as Date}
                  onUpdate={() => {}}
                  placeholder="Select"
                  onBlur={() => {}}
                  disabled={true}
                  ariaLabel="Date select"
                />
              </Box>
              {errors.contactDate && touched.contactDate && (
                <ErrorAlert>
                  <span>{errors.contactDate}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box width={"48%"}>
              <AppFormLabel
                labelName="Time"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("contactTime")
                    ?.tooltip_text_main
                }
              />
              <Box className="field-disabled">
                <TimeField
                  value={values.contactTime ?? "00:00:00"}
                  onChange={(event) => {}}
                  ariaLabel="From time field"
                />
              </Box>
              {errors.contactTime && touched.contactTime && (
                <ErrorAlert>
                  <span>{errors.contactTime}</span>
                </ErrorAlert>
              )}
            </Box>
          </Flex>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the last contact reason?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("lstContactReason")
                ?.tooltip_text_main
            }
            labelFor="contact-reason"
          />
          <SelectDropdown
            value={values.lstContactReason}
            onChange={handleOnChange("lstContactReason")}
            dropdownList={data.lastContactReason}
            onBlur={() => setFieldTouched("lstContactReason")}
            placeholder="Select last contact reason"
          />
          {errors.lstContactReason && touched.lstContactReason && (
            <ErrorAlert>
              <span>{errors.lstContactReason}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default LocationContactInformation;
