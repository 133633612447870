import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import Badges from "../Badges/Badges";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./SelectColorDropdown.scss";
export interface SelectColorDropdownProps<T> {
  values?: string;
  onClick: any;
}
export default function SelectColorDropdown<T>(
  props: SelectColorDropdownProps<T>
) {
  const [value, setValue] = useState() as any;
  const [classType, setClass] = useState("");
  const { isOpen, onToggle, onClose } = useDisclosure();
  const selectedData = (e: any, data: any) => {
    setValue(e);
    setClass(data);
    props.onClick(e as T);
    onClose();
  };
  useEffect(() => {
    setValue(props.values === "" ? "incomplete" : props.values);
    setClass(props.values === "completed" ? "tag-success" : "");
  }, [props.values]);
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <div onClick={onToggle}>
        <PopoverTrigger>
          <Box className={`tag-style ${classType}`}>
            <p>
              {value}{" "}
              <ChevronDownIcon className="badge-module-accordion-icon" />
            </p>
          </Box>
        </PopoverTrigger>
      </div>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <Box
              onClick={() => selectedData("incomplete", "")}
              className="select-element"
            >
              <Badges
                size="lg"
                variant="subtle"
                label="InComplete"
                type="default"
              />
            </Box>

            <Box
              onClick={() => selectedData("completed", "tag-success")}
              className="select-element"
            >
              <Badges
                size="lg"
                variant="subtle"
                label="Completed"
                type="success"
              />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
