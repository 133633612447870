import {
  Box,
  Button,
  Divider,
  Text,
  Image as ChakraImg,
} from "@chakra-ui/react";
import "./BadgeStatus.scss";
import Rating from "react-rating-stars-component";
import { bookmarkIcon, bookmarkedIcon, shareIcon } from "public/assets";
import Image, { StaticImageData } from "next/image";
import { ChevronRightIcon, StarIcon } from "@chakra-ui/icons";
import {
  addBookmark,
  deleteBookmark,
  rateLearningProgram,
} from "@/client-api-manager/alm-api";
import { useState } from "react";
import styleVars from "@/styles/_export.module.scss";
import ActionButton from "@/components/academy/certification-path/action-button/ActionButton";
import { usePathname, useSearchParams } from "next/navigation";
import { safelyFetchDomain } from "@/utils";

const { appRatingColor, appRatingActiveColor } = styleVars;

type BadgeStatusProps = {
  id: string;
  enrollmentId: string;
  bookmark: boolean | undefined;
  totalCourse: number | undefined;
  completedCourse: number | undefined;
  shouldShowCompletion: boolean;
  userRating?: number | undefined;
  type: string;
  title: string;
};

export default function BadgeStatus({
  id,
  enrollmentId,
  bookmark,
  totalCourse,
  completedCourse,
  userRating,
  shouldShowCompletion,
  type,
  title,
}: BadgeStatusProps) {
  const [bookmarkState, toggleBookmarkState] = useState<Boolean>(
    bookmark ?? false
  );
  const [rating, setRating] = useState<number>(userRating || 0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const routePathName = usePathname();
  const routeSearchParams = useSearchParams()
  const domain = safelyFetchDomain()

  const bookmarkProgram = () => {
    bookmarkState ? deleteBookmark(id) : addBookmark(id);
    toggleBookmarkState((prevState) => !prevState);
  };

  const shareProgram = () => {
    const shareType = type === "badge" ? "Badge path page" : "Course page";
    window.open(
      `mailto:?subject=${shareType} - ${title}&body=${shareType} link - https://${domain}${routePathName}?${routeSearchParams?.toString()}`
    );
  };

  const submitHandler = () => {
    rateLearningProgram(enrollmentId, { rating: rating }).then((res) => {
      setRating(rating);
      setIsSubmitted(true);
    });
  };

  const selectRating = (rating: number) => {
    setRating(rating);
    setIsSelected(true);
  };

  return (
    <Box
      className={`badge-status-container ${shouldShowCompletion ? "" : "reduced-height"}`}
    >
      <Box className="badge-status-completion">
        {shouldShowCompletion && (
          <>
            <Text as="h5" className="completion-number">
              {completedCourse}/{totalCourse}
              <Text as="h6" className="completion-text">
                Trainings complete
              </Text>
            </Text>
            <Divider className="completion-divider" />
          </>
        )}
        <Box className="action-button-holder">
          <ActionButton
            name="Share"
            icon={shareIcon}
            onClick={shareProgram}
            isSrcIcon={true}
          />
          <ActionButton
            name={bookmarkState ? "Saved" : "Save"}
            icon={bookmarkState ? bookmarkedIcon : bookmarkIcon}
            onClick={bookmarkProgram}
            isSrcIcon={true}
          />
        </Box>
      </Box>
      <Box className="badge-rating-holder">
        <Text as="h6" className="badge-rating-intro">
          Rate this {type}
        </Text>
        <Rating
          value={rating}
          size={20}
          isHalf={false}
          edit={!isSubmitted}
          classNames="badge-rating-star"
          usingIcons={true}
          emptyIcon={<StarIcon color={appRatingColor} />}
          filledIcon={<StarIcon color={appRatingActiveColor} />}
          onChange={selectRating}
        />
        {!isSubmitted && (
          <Button
            className="app-btn-link badge-rating-submit"
            rightIcon={<ChevronRightIcon />}
            onClick={submitHandler}
            isDisabled={!isSelected}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
}