"use client";
import {
  CardContainer,
  CommonTitle,
  ToolTip,
  HolidayCard,
  AddNewButton,
  AppFormLabel,
} from "@/components/common";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { Box, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  getValidationSchema,
  FinancialCalenderHolidayItem,
} from "./FinancialCalenderHolidayConfigurationValidation";
import { FinancialCalendarData } from "../../model";
import { updateFinancialCalendar } from "../../ic-config-api-service";
import moment from "moment";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { useSearchParams } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import style from "./FinancialCalenderHolidayConfiguration.module.scss";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type FinancialCalendarConfigurationProps = {
  data: {
    formData: FinancialCalendarData | null;
    institutionTimezone: any;
    calendarOptionsData: PostSchema;
  };
};

const FinancialCalenderHolidayConfiguration = (
  props: FinancialCalendarConfigurationProps
) => {
  const toast = useToast();
  const financialCalendarData = props.data?.formData ?? null;
  const activeAccordionRef = useRef<any>();
  const params = useSearchParams();
  const { calendarOptionsData } = props.data;

  const moveToNextStep = (paramsArg: string) => {
    if (!canCheckFormStatus) {
      if (params?.get("initialFlow")) {
        navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
      } else {
        navigateTo(
          `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${paramsArg}&entityType=${IC_ENTITY.financial_calendar}`
        );
      }
    }
  };

  const onSubmit = async (values: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.calendarName!,
          stage: IC_ENTITY.financial_calendar,
          status: currentStage,
        });
      }
      moveToNextStep(`${props.data.formData?.calendarName}`);
      return;
    }

    const res = await updateFinancialCalendar({
      formData: {
        _vn: financialCalendarData?._vn,
        calendarName: props.data.formData?.calendarName,
        tmZoneCode: props.data?.formData?.tmZoneCode,

        //Patch Data
        holidays: values.holidays.map((data: any) => ({
          closeTm: data.isAllDay ? "" : data.closeTm,
          date: data.date ? moment(data.date).format("YYYY-MM-DD") : null,
          isAllDay: data.isAllDay,
          openTm: data.isAllDay ? "" : data.openTm,
        })),
      },
      toastOptions: {
        toast: toast,
        toastId: "financial-calender-holiday-configuration",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      moveToNextStep(`${props.data.formData?.calendarName}`);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(calendarOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [calendarOptionsData]);

  let initialLimits: FinancialCalenderHolidayItem[] =
    financialCalendarData?.holidays !== undefined &&
    financialCalendarData?.holidays?.length > 0
      ? financialCalendarData?.holidays?.map((limit: any, idx: number) => ({
          title: `Holiday ${idx + 1}`,
          date: limit.date ? new Date(limit.date) : null,
          isAllDay: limit.isAllDay,
          index: idx,
          openTm: limit.openTm !== "" ? limit.openTm : "09:00:00",
          closeTm: limit.closeTm !== "" ? limit.closeTm : "09:00:00",
          name: "",
        }))
      : [];
  // using useFormik hook from Formik Library
  const {
    handleSubmit,
    dirty,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema: null,
    initialValues: { holidays: initialLimits },
    enableReinitialize: true,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const onDelete = (index: number) => {
    const _holidays = [...values.holidays];
    _holidays.splice(index, 1);
    setFieldValue(
      `holidays`,
      _holidays.map((holiday, _index) => {
        const _h = { ...holiday };
        _h.title = `Holiday ${_index + 1}`;
        return _h;
      })
    );
    setFieldTouched(`holidays[${index}]`, false);
  };

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Time Zone"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tmZoneCode")
                ?.tooltip_text_main
            }
          />

          <Text
            fontSize="l"
            fontWeight="light"
            className={style["text-holiday-config"]}
          >
            {`UTC-05:00 Eastern Time (${
              props.data.institutionTimezone.find(
                (data: any) => data.value === financialCalendarData?.tmZoneCode
              ).label ?? "US & Canada"
            })`}
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Reference calendar"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("refCalendar")
                ?.tooltip_text_main
            }
          />

          <Text
            fontSize="l"
            fontWeight="light"
            className={style["text-holiday-config"]}
          >
            {props.data?.formData?.refCalendar
              ?.map((obj) => obj.refCalendarName)
              .join(", ") ?? "Standard"}
          </Text>

          {values.holidays.length == 0 && (
            <Text paddingTop={8} className={style["text-holiday-config"]}>
              No Holiday set up yet.
            </Text>
          )}
        </Box>
      </CardContainer>
      {values.holidays.map((holiday, index) => {
        return (
          <Box
            key={index}
            ref={activeAccordionRef}
            className="app-form-field-array-container"
          >
            <HolidayCard
              title={holiday.title}
              date={holiday.date}
              openTm={holiday.openTm}
              closeTm={holiday.closeTm}
              isAllDay={holiday.isAllDay}
              onDelete={() => {
                onDelete(index);
              }}
              onUpdate={(changedValue) =>
                setFieldValue(`holidays[${index}]`, changedValue)
              }
              errors={
                errors.holidays as FormikErrors<FinancialCalenderHolidayItem>[]
              }
              touched={
                touched.holidays as FormikTouched<FinancialCalenderHolidayItem>[]
              }
              index={index}
            />
          </Box>
        );
      })}

      <AddNewButton
        label={"Would you like to add a holiday?"}
        onClick={() => {
          setFieldValue(`holidays[${values.holidays.length}]`, {
            title: `Holiday ${values.holidays.length + 1}`,
            date: null,
            isAllDay: false,
            index: values.holidays.length,
            openTm: "00:00:00",
            closeTm: "00:00:00",
          });
        }}
      />
    </form>
  );
};

export default FinancialCalenderHolidayConfiguration;
