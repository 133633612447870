"use client";

import {
  Box,
  Button,
  Flex,
  Image,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import BadgeHeader from "../catalog/badge-path/badge-header/BadgeHeader";
import { CardContainer, GeneralizedTooltip } from "@/components/common";
import "./ShareCertification.scss";
import { useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { getBadges, getUserDetails } from "@/client-api-manager/alm-api";
import { FastInfoCallout, copyIcon } from "public/assets";
import copy from "copy-to-clipboard";
import { PrismicRichText } from "@prismicio/react";

const ShareCertification = ({ pageData }: any) => {
  const badgeLoadRef = useRef<boolean>(false);
  const [jsonData, setJsonData] = useState<string>("");

  const params = useSearchParams();
  const downloadId = params?.get("id") ?? "";

  useEffect(() => {
    getUserDetails().then((res) => {
      const userId = res.data?.data?.id;
      loadBadgesCert(userId);
    });
  }, []);

  const loadBadgesCert = (userId: string) => {
    if (!badgeLoadRef.current) {
      loadAllBadges(userId);
      badgeLoadRef.current = true;
    }
  };

  const loadAllBadges = (userId: string, url?: string) => {
    getBadges(userId, url).then((res: any) => {
      const jsonDoc = res.data.data.find(
        (item: any) => item?.relationships?.model?.data?.id === downloadId
      );
      setJsonData(jsonDoc?.attributes?.assertionUrl);

      if (res.data.links?.next && !jsonDoc) {
        try {
          loadAllBadges(userId, res.data.links?.next);
        } catch (error) {
          console.error("Error loading all badges", error);
        }
      }
    });
  };

  return (
    <>
      <BadgeHeader
        pageType="ACADEMY"
        title={pageData.heading}
        subtitle={pageData.subheading}
        showBadgeProgress={false}
        showBackButton={false}
      />
      <Box className="academy-sub-content share-guidelines">
        <CardContainer>
          <Text>{pageData.subheading}</Text>
          <Flex p={8} mb={3} mt={3} alignItems={"start"} gap={5}>
            <Text as="p">
              &#128161;NOTE: If you are viewing this document in Docs, begin at{" "}
              <Text as="span" className="bold italics">
                step 1.
              </Text>{" "}
              If you have already downloaded this document through the Badges &
              Certifications page in Academy, begin at{" "}
              <Text as="span" className="bold italics">
                step 3.
              </Text>
            </Text>
          </Flex>
          <UnorderedList className="guidelines" mt={10}>
            {pageData.slices.map((instruction: any, index: number) => (
              <ListItem key={index}>
                <PrismicRichText
                  field={instruction.primary.instructions}
                ></PrismicRichText>
                <OrderedList styleType="lower-latin">
                  {instruction.items.map((subinstruction: any) => {
                    if (subinstruction.subinstructions.length === 0)
                      return (
                        <Image
                          src={subinstruction.images.url}
                          alt={subinstruction.images.alt}
                        />
                      );
                    return subinstruction.subinstructions.map(
                      (i: any, idx: number) => {
                        return (
                          <ListItem key={idx}>
                            {i.text}
                            <Image
                              src={subinstruction.images.url}
                              alt={subinstruction.images.alt}
                            />
                          </ListItem>
                        );
                      }
                    );
                  })}
                </OrderedList>

                {index === 2 && jsonData && (
                  <Box my={8} className="json-holder announcement" p={8}>
                    <Box className="info-box">
                      <Image src={FastInfoCallout.src} alt="Campaign" />
                    </Box>
                    <Text as="p" className="bold">
                      {jsonData}
                    </Text>
                    <GeneralizedTooltip placement="top" content="Copy">
                      <Button
                        onClick={() => {
                          copy(jsonData);
                        }}
                        background="none"
                        className="copy-btn"
                      >
                        <Image
                          className="icon"
                          src={copyIcon.src}
                          alt="copy json url"
                        />
                      </Button>
                    </GeneralizedTooltip>
                  </Box>
                )}
              </ListItem>
            ))}
          </UnorderedList>
          <Text mt={10}>
            If you have any questions on how to share your badge through Badgr,
            please contact a Finxact team member.
          </Text>
        </CardContainer>
      </Box>
    </>
  );
};

export default ShareCertification;
