"use client";

import { Box, Button, Flex, Text } from "@/components/ChakraUiManager";
import "./ProductCatalogCard.scss";

type ProductCatalogCardProp = {
  name: string;
  description: string;
  showProductDetails: () => void;
  isProdDetailVisible: boolean;
  onProductSelection: () => void;
  isEnvDisabled: boolean;
};

function ProductCatalogCard(props: ProductCatalogCardProp) {
  const {
    name,
    description,
    showProductDetails,
    isProdDetailVisible = false,
    onProductSelection,
    isEnvDisabled,
  } = props;

  return (
    <Box
      className={`product-card-wrapper  ${
        isProdDetailVisible ? "highlight-product-detail" : ""
      }`}
    >
      <Flex className="product-card-container">
        <Box className="product-card-content">
          <Text className="container-width">{name}</Text>
          <Text my="0.8rem" className="description-width">
            {description}
          </Text>
          <Button onClick={showProductDetails} className="app-btn-link">
            What to expect
          </Button>
        </Box>
        <Button
          px={8}
          className="app-btn-reg-secondary app-btn-product-card"
          variant="solid"
          onClick={onProductSelection}
          isDisabled={isEnvDisabled}
        >
          Configure
        </Button>
      </Flex>
    </Box>
  );
}

export default ProductCatalogCard;
