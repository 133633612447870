import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Asset_Catalog_Details
interface AssetCatalogFoundationDetailsProps {
  assetClass: number | undefined;
  assetId: string;
  desc: string;
  isNotIso4217: SplitBtnInputValType;
  isoNumeric: string;
  acrScale: string;
  scale: string;
}

// Defining Intitial Value For Asset_Catalog_Details
const assetCatalogFoundationDetails: AssetCatalogFoundationDetailsProps = {
  assetClass: 1,
  assetId: "",
  desc: "",
  isNotIso4217: undefined,
  isoNumeric: "",
  acrScale: "",
  scale: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Asset_Catalog_Details
const assetCatalogFoundationDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["assetClass", "assetId", "desc", "isoNumeric", "scale","acrScale","isNotIso4217"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { AssetCatalogFoundationDetailsProps };

// Exporting Validation Schema and Intial value
export {
  assetCatalogFoundationDetails,
  assetCatalogFoundationDetailSchema as getValidationSchema,
};
