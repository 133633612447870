import PropTypes from "prop-types";
import React from "react";
import "./Frame.scss";

interface Props {
  showDivider?: boolean; // Now optional
  showText?: boolean; // Now optional
  className?: string;
  text: string;
}

const Frame = ({
  showDivider = true,
  showText = true,
  className,
  text = "By: Jane Doe",
}: Props): JSX.Element => {
  return (
    <div className={`frame ${className}`}>
      {showText && <div className="description">{text}</div>}

      {showDivider && (
        <img
          className="line"
          alt="Line"
          src="https://c.animaapp.com/08OHt7Qx/img/line-1513-4.svg"
        />
      )}
    </div>
  );
};

Frame.propTypes = {
  showDivider: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
};

export default Frame;
