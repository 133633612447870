import { formatDuration } from "@/utils";
import { CheckCircleIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Icon, Text } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { rightRotateYellow } from "public/assets";
import {
  ALM_ENROLLMENT_STATE,
  getBadgeByState,
} from "../../../learning-util-service";
import { Badges } from "@/components/common";
import { BsCircle } from "react-icons/bs";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";

type SubCourseCardProps = {
  title: string;
  duration: number | undefined;
  tag: string | undefined;
  status: "STARTED" | "ENROLLED" | "COMPLETED";
  id: string;
  moduleId: string;
  isExamCourse?: boolean;
  takeExamButtonClick?: () => void;
  isLocked: boolean;
};

export default function SubCourseCard({
  title,
  duration,
  tag,
  status,
  id,
  moduleId,
  isExamCourse,
  takeExamButtonClick,
  isLocked,
}: SubCourseCardProps) {
  const queryParams = useSearchParams();
  const from = queryParams?.get("from");
  const getUrlPath = () => {
    if (!id || !moduleId || isExamCourse) return "";
    let url = `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${id}&moduleid=${moduleId}`;
    if (from) url += `&from=${from}`;
    return url;
  };

  return (
    <Box className="badge-module-accordion-item">
      {status === ALM_ENROLLMENT_STATE.COMPLETED && (
        <CheckCircleIcon
          boxSize={"24px"}
          className="completed-status-icon start-icon"
        />
      )}
      {status === ALM_ENROLLMENT_STATE.STARTED && (
        <Image
          src={rightRotateYellow}
          className="start-icon"
          alt="inprogress"
        />
      )}
      {status === ALM_ENROLLMENT_STATE.ENROLLED && (
        <Icon
          boxSize={"24px"}
          as={BsCircle}
          className="enrolled-status-icon start-icon"
        />
      )}
      <Box className="badge-module-accordion-item-subcontainer">
        <Box>
          <Text as="h6" className="badge-module-accordion-item-title">
            {title}
          </Text>
          {duration !== undefined && (
            <Text as="p" className="badge-module-accordion-item-duration">
              {formatDuration(duration ?? 0)}
            </Text>
          )}
        </Box>
        <Box className="badge-module-accordion-item-subcontainer">
          {tag && (
            <Box mr={"3rem"}>
              <Badges
                size="lg"
                {...getBadgeByState({ status: tag, showState: true })}
              ></Badges>
            </Box>
          )}
          {isExamCourse ? (
            <Button
              className="app-btn-reg-secondary-transparent"
              rightIcon={<ChevronRightIcon boxSize={"1.8rem"} />}
              onClick={takeExamButtonClick}
              isDisabled={isLocked}
            >
              View
            </Button>
          ) : (
            <Link href={getUrlPath()}>
              <Button
                className="app-btn-reg-secondary-transparent"
                rightIcon={<ChevronRightIcon boxSize={"1.8rem"} />}
              >
                View
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
}
