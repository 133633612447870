import React, { useContext } from "react";
import { Switch, FormControl, FormLabel, Text } from "@chakra-ui/react";
import "./Toggle.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { IFX_ACCT_PRODUCT_TYPE, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

type ToggleProp = {
  title: string;
  isToggled?: boolean;
  isDisabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  financialCalendar?: boolean;
};

function Toggle({
  title,
  isToggled,
  isDisabled,
  onChange,
  financialCalendar,
}: ToggleProp) {
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  return (
    <div>
      <FormControl>
        <FormLabel
          htmlFor={title}
          display="flex"
          alignItems="center"
          height="48px"
        >
          <Switch
            id={title}
            size="lg"
            py={-2}
            defaultChecked={isToggled}
            isDisabled={(envIsDisabled && isConsolePage) || checkEntitlement || isDisabled}
            className="custom-switch"
            sx={{
              "& .chakra-switch__thumb": {
                marginTop: 2 - 0.5,
                marginLeft: "0.2rem",
                backgroundColor: `${isToggled ? `#FFFFFF` : `#8F9297`}`,
              },
            }}
            onChange={onChange}
            checked={isToggled}
          />
          <Text
            py={2}
            pl={3}
            fontSize="small"
            fontWeight="bold"
            className={financialCalendar === true ? "financial-calendar" : ""}
          >
            {title}
          </Text>
        </FormLabel>
      </FormControl>
      <br />
    </div>
  );
}

export default Toggle;
