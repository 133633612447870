"use client";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  AddNewButton,
  AppFormLabel,
  CardContainer,
  DatePicker,
  DragDropContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  TimeField,
} from "@/components/common";
import { useContext, useEffect, useRef, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  TaxPartyEmailContactArrayProps,
  TaxPartyEmailContactDetails,
  TaxPartyEmailContactProps,
  getValidationSchema,
} from "./TaxPartyEmailContactValidation";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import { makeDateTime } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type TaxPartyEmailProps = {
  data: {
    emailTypeOptions: { label: string; value: string }[];
    formData: FinancialInstitutionData;
    bankParamOptionsData: PostSchema;
  };
};

const TaxPartyEmailContact = (props: TaxPartyEmailProps) => {
  const { emailTypeOptions, formData, bankParamOptionsData } = props.data;

  const toast = useToast();
  const activeAccordionRef = useRef<any>();
  const onSubmit = async (
    values: TaxPartyEmailContactArrayProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "tax_party_email_type",
    };
    response = await updateBankParameters({
      formData: {
        _vn: formData._vn,
        _Id: formData._Id,
        // PATCH Fields
        taxPartyContact: {
          name: values.name,
          emails:
            values.emails &&
            values.emails.map((email) => ({
              emailType: Number(email.emailType),
              isPreferred: email.isPreferred,
              data: email.data,
              label: email.label,
              validFromDtm:
                email.emailValidFromDate && email.emailValidFromTime !== ""
                  ? makeDateTime(
                      email.emailValidFromDate as Date,
                      email.emailValidFromTime
                    )
                  : null,
              validToDtm:
                email.emailValidThroughDate &&
                email.emailValidThroughTime !== ""
                  ? makeDateTime(
                      email.emailValidThroughDate as Date,
                      email.emailValidThroughTime
                    )
                  : null,
              verifyDtm:
                email.emailVerifiedOnDate && email.emailVerifiedOnTime !== ""
                  ? makeDateTime(
                      email.emailVerifiedOnDate as Date,
                      email.emailVerifiedOnTime
                    )
                  : null,
            })),
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      navigateToNextPage?.();
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);

      setValidationSchema(yupSchema.fields.taxPartyContact);
    })();
  }, [bankParamOptionsData]);

  let initialTaxPartyEmailContact: TaxPartyEmailContactProps[] = formData
    .taxPartyContact?.emails?.length!
    ? formData.taxPartyContact?.emails.map((emailData: any) => ({
        emailType: emailData.emailType ?? undefined,
        data: emailData.data ?? "",
        label: emailData.label ?? "",
        isPreferred: emailData.isPreferred ?? undefined,
        emailValidFromDate: emailData.validFromDtm
          ? new Date(emailData.validFromDtm as string)
          : null,
        emailValidFromTime: emailData.validFromDtm
          ? new Date(emailData.validFromDtm as string).toLocaleTimeString(
              "it-IT"
            )
          : "",
        emailValidThroughDate: emailData.validToDtm
          ? new Date(emailData.validToDtm as string)
          : null,
        emailValidThroughTime: emailData.validToDtm
          ? new Date(emailData.validToDtm as string).toLocaleTimeString("it-IT")
          : "",
        emailVerifiedOnDate: emailData.verifyDtm
          ? new Date(emailData.verifyDtm as string)
          : null,
        emailVerifiedOnTime: emailData.verifyDtm
          ? new Date(emailData.verifyDtm as string).toLocaleTimeString("it-IT")
          : "",
      }))
    : [];

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      name: formData.taxPartyContact?.name ?? "",
      emails: initialTaxPartyEmailContact,
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const taxPartyEmailFormHandler =
    (key: string, index?: number) =>
    (value: string | SplitBtnInputValType | string[] | boolean | Date) => {
      const indexCheck = index == 0 ? true : index;
      if (indexCheck) {
        setFieldValue(`emails.${index}.${key}`, value);
      } else {
        setFieldValue(`${key}`, value);
      }
    };

  const handleOnBlur = (key: string, index?: number) => () => {
    //To avoid falsy value 0 as first time.
    const indexCheck = index == 0 ? true : index;
    if (indexCheck) {
      setFieldTouched(`emails.${index}.${key}`, true);
    } else {
      setFieldTouched(`${key}`, true);
    }
  };

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const setTaxPartyEmail = (fn: any) => {
    formik.setFieldValue(`emails`, fn(values.emails));
  };
  //Form_Error_Handler
  const _errorHandler = (
    key: keyof TaxPartyEmailContactProps,
    index: number
  ) => {
    return (
      <>
        {touched.emails?.[index]?.[key] &&
          touched.emails[index] &&
          (touched.emails[index] as any)[key] &&
          errors.emails &&
          errors.emails[index] &&
          (errors.emails[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.emails[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form">
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the tax party contact name?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("name")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.name}
              onChange={taxPartyEmailFormHandler("name")}
              onBlur={handleBlur}
            />
            {errors.name && (
              <ErrorAlert>
                <span>{errors.name}</span>
              </ErrorAlert>
            )}
          </Box>

          <FieldArray
            name="emails"
            render={({ insert, remove, push }) => (
              <>
                <DragDropContainer
                  items={values.emails?.map(
                    (fieldValues: any, index: number) => {
                      return {
                        id: index + 1,
                        deleteHandler: () => remove(index),
                        showDeleteIcon: true,
                        content: (
                          <Box ref={activeAccordionRef}>
                            <CardContainer key={index}>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the email type?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "emailType"
                                    )?.tooltip_text_main
                                  }
                                />
                                <RadioButtonGroup
                                  isBoxedRadio={true}
                                  value={fieldValues.emailType as string}
                                  onChange={taxPartyEmailFormHandler(
                                    "emailType",
                                    index
                                  )}
                                  onBlur={handleOnBlur("emailType", index)}
                                  radioPropList={emailTypeOptions}
                                  stackDirection={"row"}
                                  spacing={"3rem"}
                                />
                                {_errorHandler("emailType", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName={"What is the email address?"}
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "email"
                                    )?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={fieldValues.data}
                                  onChange={taxPartyEmailFormHandler(
                                    "data",
                                    index
                                  )}
                                  placeholder="abc@abc.com"
                                  onBlur={handleBlur}
                                />
                                {_errorHandler("data", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName={
                                    "Enter a recognizable label for the email"
                                  }
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "label"
                                    )?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={fieldValues.label}
                                  onChange={taxPartyEmailFormHandler(
                                    "label",
                                    index
                                  )}
                                  placeholder="Finxact ID"
                                  onBlur={handleBlur}
                                />
                                {_errorHandler("label", index)}
                              </Box>

                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName=" Is this the preferred email?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "isPreferred"
                                    )?.tooltip_text_main
                                  }
                                />
                                <SplitButton
                                  value={fieldValues.isPreferred}
                                  onSelect={taxPartyEmailFormHandler(
                                    "isPreferred",
                                    index
                                  )}
                                  leftBtnName="Yes"
                                  rightBtnName="No"
                                  onBlur={handleOnBlur("isPreferred", index)}
                                />
                                {_errorHandler("isPreferred", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When was the email validated?" />
                                <Stack
                                  gap="2rem"
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                >
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validFromDate"
                                        )?.tooltip_text_main
                                      }
                                      labelFor="email_valid_from_date"
                                    />
                                    <DatePicker
                                      value={
                                        fieldValues.emailValidFromDate as Date
                                      }
                                      onUpdate={taxPartyEmailFormHandler(
                                        "emailValidFromDate",
                                        index
                                      )}
                                      onBlur={handleOnBlur(
                                        "emailValidFromDate",
                                        index
                                      )}
                                      placeholder="Select"
                                      id="email_valid_from_date"
                                    />
                                    {_errorHandler("emailValidFromDate", index)}
                                  </Stack>
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validFromTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={fieldValues.emailValidFromTime}
                                      onChange={taxPartyEmailFormHandler(
                                        "emailValidFromTime",
                                        index
                                      )}
                                      onBlur={handleOnBlur(
                                        "emailValidFromTime"
                                      )}
                                      ariaLabel="email-valid-from"
                                    />
                                    {_errorHandler("emailValidFromTime", index)}
                                  </Stack>
                                </Stack>
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When is the email valid through?" />
                                <Stack
                                  gap="2rem"
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                >
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validToDate"
                                        )?.tooltip_text_main
                                      }
                                      labelFor="email_valid_through_date"
                                    />
                                    <DatePicker
                                      value={fieldValues.emailValidThroughDate}
                                      onUpdate={taxPartyEmailFormHandler(
                                        "emailValidThroughDate",
                                        index
                                      )}
                                      onBlur={handleOnBlur(
                                        "emailValidThroughDate",
                                        index
                                      )}
                                      placeholder="Select"
                                      id="email_valid_through_date"
                                    />
                                    {_errorHandler(
                                      "emailValidThroughDate",
                                      index
                                    )}
                                  </Stack>
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validToTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={fieldValues.emailValidThroughTime}
                                      onChange={taxPartyEmailFormHandler(
                                        "emailValidThroughTime",
                                        index
                                      )}
                                      ariaLabel="email-valid-through"
                                      onBlur={handleOnBlur(
                                        "emailValidThroughTime"
                                      )}
                                    />
                                    {_errorHandler(
                                      "emailValidThroughTime",
                                      index
                                    )}
                                  </Stack>
                                </Stack>
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When was the email verified?" />
                                <Stack
                                  gap="2rem"
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                >
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "verifyDate"
                                        )?.tooltip_text_main
                                      }
                                      labelFor="email_verified_on_date"
                                    />
                                    <DatePicker
                                      value={fieldValues.emailVerifiedOnDate}
                                      onUpdate={taxPartyEmailFormHandler(
                                        "emailVerifiedOnDate",
                                        index
                                      )}
                                      placeholder="Select"
                                      onBlur={handleOnBlur(
                                        "emailVerifiedOnDate",
                                        index
                                      )}
                                      id="email_verified_on_date"
                                    />
                                    {_errorHandler(
                                      "emailVerifiedOnDate",
                                      index
                                    )}
                                  </Stack>
                                  <Stack flexBasis={"50%"}>
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "verifyTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={fieldValues.emailVerifiedOnTime}
                                      onChange={taxPartyEmailFormHandler(
                                        "emailVerifiedOnTime",
                                        index
                                      )}
                                      onBlur={handleOnBlur(
                                        "emailVerifiedOnTime"
                                      )}
                                      ariaLabel="email-verified-on"
                                    />
                                    {_errorHandler(
                                      "emailVerifiedOnTime",
                                      index
                                    )}
                                  </Stack>
                                </Stack>
                              </Box>
                            </CardContainer>
                          </Box>
                        ),
                      };
                    }
                  )}
                  setItems={setTaxPartyEmail}
                  isElement
                />
                <Box marginTop="32px">
                  <AddNewButton
                    label={"Add party email"}
                    onClick={() => {
                      push({
                        ...TaxPartyEmailContactDetails,
                        idx: Math.random(),
                      });
                    }}
                  />
                </Box>
              </>
            )}
          ></FieldArray>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};

export default TaxPartyEmailContact;
