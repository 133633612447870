import { Button, Image as ChakraImg } from "@chakra-ui/react";
import Image, { StaticImageData } from "next/image";

type ActionButtonProps = {
  icon: StaticImageData;
  name: string;
  onClick: () => void;
  isSrcIcon?: boolean;
};

export default function ActionButton({
  icon,
  name,
  onClick,
  isSrcIcon,
}: ActionButtonProps) {
  function constructImag() {
    if (isSrcIcon) {
      return (
        <ChakraImg
          src={icon.src}
          className="action-button-icon"
          height={9}
          width={9}
          alt={`${name} icon`}
        />
      );
    } else {
      return (
        <Image
          src={icon}
          className="action-button-icon"
          height={24}
          width={24}
          alt={`${name} icon`}
        ></Image>
      );
    }
  }

  return (
    <Button
      className="app-btn-link action-button"
      fontSize={16}
      leftIcon={constructImag()}
      onClick={onClick}
    >
      {name}
    </Button>
  );
}
