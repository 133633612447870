import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface ProcessFlagsValueProps {
  isRestrict: SplitBtnInputValType;
}

const processFlags: ProcessFlagsValueProps = {
  isRestrict: undefined,
};

const processFlagsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["processFlags"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { ProcessFlagsValueProps };
// Exporting Validation Schema and Intial value
export { processFlags, processFlagsSchema as getValidationSchema };
