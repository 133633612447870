import React, { ReactNode } from "react";
import styles from "./CourseProfileBox.module.scss";
import { Box, Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { ROUTE_PATH } from "@/route-config/route-path";

interface CourseProfileBoxProps {
  title: string;
  subtitle: string;
  viewAll?: boolean;
  children?: ReactNode;
}

const CourseProfileBox: React.FC<CourseProfileBoxProps> = ({
  title,
  subtitle,
  viewAll = false,
  children,
}) => {
  return (
    <Box className={styles.recommendedCourseBox}>
      <Box
        display="flex"
        justifyContent="space-between"
        w="100%"
        alignItems="center"
      >
        <Box>
          <h1 className={styles.title}>{title}</h1>
          <h2 className={styles.subtitle}>{subtitle}</h2>
        </Box>
        {viewAll && (
          <Link href={`${ROUTE_PATH.ACADEMY_CATALOG}?bookmarked=true`}>
            <Button
              className="app-btn-link"
              rightIcon={<ChevronRightIcon boxSize={"1rem"} />}
            >
              View all
            </Button>
          </Link>
        )}
      </Box>
      {children} {/* Render children here */}
    </Box>
  );
};

export default CourseProfileBox;
