"use client";

import {
  AccordionComponent,
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  DragDropContainer,
} from "@/components/common";
import AddNewButton from "@/components/common/add-new-button/AddNewButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Heading, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  UsFinancialInstitutionParametersInformationProps,
  defaultUsFinancialInstitutionParametersInformation,
  getValidationSchema,
} from "./UsFinancialInstitutionParametersInformationValidation";
import {
  createUSBankParameters,
  updateBankParameters,
  updateBankParametersUSBankParams,
} from "../../ic-config-api-service";
import { FinancialInstitutionData } from "../../model";
import { IC_ENTITY, Option, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    creditBureauInstOptions: Option<number>[];
    formData: FinancialInstitutionData | null;
    bankParamOptionsData: PostSchema;
  };
};

const UsFinancialInstitutionParametersInformation = (props: Props) => {
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const { creditBureauInstOptions, formData, bankParamOptionsData } =
    props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (
    values: {
      crBureaus: UsFinancialInstitutionParametersInformationProps[];
      _attch?: string;
    },
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    // components are attached to their parents by setting the _attch string to include the component name, and having the component's id match the parent
    const attach = formData?._attch ?? "";
    if (!attach.includes("bankparamUSBankInfo")) {
      values._attch = `${attach}bankparamUSBankInfo|`;
    }
    if (!dirty) {
      // Create empty UsBankParams
      if (!formData?.bankparamUSBankInfo) {
        updateBankParameters({
          formData: {
            _vn: formData?._vn,
            _Id: formData?._Id,
            // PATCH Feilds
            _attch: values._attch,
          },
          toastOptions: {
            toast: toast,
            toastId: "additional-spec",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: shouldUpdateWorkflowStatus(
            entityWorkflowData!,
            currentStage
          )
            ? currentStage
            : "",
        });
        createUSBankParameters({
          formData: {
            _Id: formData?._Id,
          },
          toastOptions: {
            toast: toast,
            toastId: "additional-spec",
          },
          stageName: "",
        });
      }
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    if (formData) {
      const res = await Promise.all([
        updateBankParameters({
          formData: {
            _vn: formData._vn,
            _Id: formData._Id,
            // PATCH Feilds
            bankparamUSBankInfo: {
              crBureaus: values.crBureaus.map((bureau) => ({
                cbInst: bureau.cbInst,
                cbInstId: bureau.cbInstId,
              })),
            },
            _attch: values._attch,
          },
          toastOptions: {
            toast: toast,
            toastId: "additional-spec",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: shouldUpdateWorkflowStatus(
            entityWorkflowData!,
            currentStage
          )
            ? currentStage
            : "",
        }),
        // If bankParamsUSBankInfo exists update it, else create
        !formData.bankparamUSBankInfo
          ? createUSBankParameters({
              formData: {
                _Id: formData?._Id,
                crBureaus: values.crBureaus.map((bureau) => ({
                  cbInst: bureau.cbInst,
                  cbInstId: bureau.cbInstId,
                })),
              },
              toastOptions: {
                toast: toast,
                toastId: "additional-spec",
              },
              stageName: "",
            })
          : updateBankParametersUSBankParams({
              formData: {
                _vn: formData.bankparamUSBankInfo._vn,
                _Id: formData.bankparamUSBankInfo._Id,
                // PATCH Feilds
                crBureaus: values.crBureaus.map((bureau) => ({
                  cbInst: bureau.cbInst,
                  cbInstId: bureau.cbInstId,
                })),
              },
              toastOptions: {
                toast: toast,
                toastId: "additional-spec",
                successMessage: `${configPageTitle} updated.`,
              },
              stageName: "",
            }),
      ]);
      if (res.length) {
        navigateToNextPage?.();
      }
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`crBureaus.${index}.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`crBureaus.${index}.${key}`, true);
  };

  let initialCreditBureau: UsFinancialInstitutionParametersInformationProps[] =
    formData?.bankparamUSBankInfo?.crBureaus?.map((bureau) => ({
      cbInst: bureau.cbInst ?? -1,
      cbInstId: bureau.cbInstId ?? "",
    })) ?? [];

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);

      setValidationSchema(yupSchema.fields.bankparamUSBankInfo);
    })();
  }, [bankParamOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      crBureaus: initialCreditBureau,
    },
  });
  const {
    values,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const errors: any = formik.errors;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const setCreditBureaus = (fn: any) => {
    formik.setFieldValue(`crBureaus`, fn(values.crBureaus));
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} id="finxact-form">
        <CardContainer>
          <Heading as="h2" fontSize={"24px"} mt={"1.5rem"} mb={"3rem"}>
            Foundational Details
          </Heading>
          <FieldArray
            name="crBureaus"
            render={({ insert, remove, push }) => (
              <>
                <DragDropContainer
                  items={values.crBureaus?.map(
                    (
                      creditBureau: UsFinancialInstitutionParametersInformationProps,
                      index: number
                    ) => {
                      return {
                        id: index + 1,
                        deleteHandler: () => remove(index),
                        showDeleteIcon: false,
                        content: (
                          <Box key={index}>
                            <AccordionComponent
                              accordionTitle={`Credit bureau reporting information ${
                                index + 1
                              }`}
                              limitName={
                                creditBureauInstOptions.find(
                                  (opt) => opt.value === creditBureau.cbInst
                                )?.label ?? "[No institution selected]"
                              }
                              deleteIcon
                              onDeleteHandler={() => remove(index)}
                              isExpand={
                                values.crBureaus.length !== index + 1 ? 1 : 0
                              }
                            >
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the credit bureau institution?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "cbInst"
                                    )?.tooltip_text_main
                                  }
                                  labelFor="cbInst"
                                />
                                <SelectDropdown
                                  value={creditBureau.cbInst}
                                  onChange={handleOnChange("cbInst", index)}
                                  dropdownList={creditBureauInstOptions}
                                  onBlur={handleOnBlur("cbInst", index)}
                                  id="cbInst"
                                />
                                {touched.crBureaus?.[index]?.cbInst &&
                                  errors.crBureaus?.[index]?.cbInst && (
                                    <ErrorAlert>
                                      <span>
                                        {errors.crBureaus[index].cbInst}
                                      </span>
                                    </ErrorAlert>
                                  )}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the credit bureau institution ID?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "cbInstID"
                                    )?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={creditBureau.cbInstId}
                                  onChange={handleOnChange("cbInstId", index)}
                                  onBlur={handleOnBlur("cbInstId", index)}
                                  label="institutionId"
                                />
                                {touched.crBureaus?.[index]?.cbInstId &&
                                  errors?.crBureaus?.[index]?.cbInstId && (
                                    <ErrorAlert>
                                      <span>
                                        {errors.crBureaus[index].cbInstId}
                                      </span>
                                    </ErrorAlert>
                                  )}
                              </Box>
                            </AccordionComponent>
                          </Box>
                        ),
                      };
                    }
                  )}
                  setItems={setCreditBureaus}
                  isElement
                />

                <Box marginTop="32px">
                  <AddNewButton
                    label="Add credit bureau reporting information"
                    onClick={() => {
                      push({
                        ...defaultUsFinancialInstitutionParametersInformation,
                      });
                    }}
                  />
                </Box>
              </>
            )}
          ></FieldArray>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};

export default UsFinancialInstitutionParametersInformation;
