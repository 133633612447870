"use client";
import { Box, Flex, Heading, Stepper, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MainContent from "../main-content/MainContent";

import "./FoundationalSetup.scss";
import {
  createWorkflowGeneric,
  getWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import {
  DASHBOARD_JOURNEY,
  DASHBOARD_MODEL_KEY,
  DASHBOARD_ACCORDION_STATUS,
  FOUNDATIONAL_SETUP_STEPS_NAME,
  numberOfAccordionStepsCompleted,
  showButtonOnAccordion,
  showStepsStatus,
  DASHBOARD_COMPLETED_ORG_PHASES,
} from "@/utils";
import { useRouter } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { FoundationalProps } from "../model";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSelector } from "react-redux";
import { getSelectedOrg } from "@/store";

interface FoundationalSetupProps {
  title: string;
  foundationalDetails: FoundationalProps;
  disabledState: boolean;
  activeStep: (goToNextStep: boolean) => void;
}

const FoundationalSetup = (props: FoundationalSetupProps) => {
  const { title, disabledState, activeStep, foundationalDetails } = props;
  const [data, setData] = useState<any>([]);
  const [completed, setCompleted] = useState<number>(-1);
  const currOrg = useSelector(getSelectedOrg);

  const institutionalDetails = foundationalDetails?.institutional_configuration;
  const generalLedgerDetails = foundationalDetails?.general_ledger;
  const transactionCodesDetails = foundationalDetails?.transaction_codes;
  const networksDetails = foundationalDetails?.networks;
  const customerTypesDetails = foundationalDetails?.customer_relationship_types;
  const router = useRouter();
  const isCompleted = DASHBOARD_COMPLETED_ORG_PHASES.includes(currOrg?.phase);

  useEffect(() => {
    const getData = async () => {
      const data = await getWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.foundationalSetup,
      });
      setData(data);
      if (data?.length === 0) {
        setCompleted(-1);
      } else {
        setCompleted(numberOfAccordionStepsCompleted(data));
      }
    };

    getData();
  }, []);

  const handleOnClick = async (step: string, e: any) => {
    e.stopPropagation();
    let nextStep = "";

    switch (step) {
      case FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration:
        router.push(ROUTE_BASE_URL["INSTITUTIONAL_CONFIGURATION"]);
        return;
      case FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger:
        router.push(ROUTE_BASE_URL["GENERAL_LEDGER"]);
        return;
      case FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes:
        router.push(ROUTE_BASE_URL["TRANS_CODE"]);
        return;
      case FOUNDATIONAL_SETUP_STEPS_NAME.networks:
        window.location.href = "/network";
        nextStep = FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes;
        break;
      case FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes:
        router.push(ROUTE_BASE_URL["CUSTOMER_RELATIONSHIP_TYPE"]);
        return;
    }

    const [stepUpdateStatus, nextStepUpdateStatus] = await Promise.all([
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.foundationalSetup,
        stage: step,
        status: DASHBOARD_ACCORDION_STATUS.completed,
      }),
      createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.foundationalSetup,
        stage: nextStep,
        status: DASHBOARD_ACCORDION_STATUS.inProgress,
      }),
    ]).then((data) => data);

    const getData = async () => {
      const data = await getWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.foundationalSetup,
      });
      setData(data);
      setCompleted(numberOfAccordionStepsCompleted(data));
      const findNotCompleted = data?.find(
        (d: any) =>
          d.modelKey === DASHBOARD_MODEL_KEY.foundationalSetup &&
          d.status === DASHBOARD_ACCORDION_STATUS.inProgress
      );
      if (!findNotCompleted) {
        activeStep(true);
      }
    };

    getData();
  };

  return (
    <>
      <Box>
        <Flex className="foundational-setup-content">
          <Heading as={"h4"}>{title}</Heading>
          <Text className="foundation-setup-status">{`${
            isCompleted ? 5 : completed < 0 ? 0 : completed
          } of 5 complete`}</Text>
        </Flex>
        <Stepper
          size="lg"
          index={isCompleted ? 5 : completed}
          orientation="vertical"
        >
          <MainContent
            btnLink
            btnName="Go to your summary"
            showBtn={showButtonOnAccordion(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration
            )}
            onClick={handleOnClick.bind(
              null,
              FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration
            )}
            btnDisabled={disabledState}
            title={institutionalDetails?.sectionTitle}
            desc={institutionalDetails?.sectionSubTitle}
            overviewHeader={institutionalDetails?.header}
            overview={institutionalDetails?.description}
            helpfullRes={institutionalDetails?.resourcesLinks}
            status={showStepsStatus(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration
            )}
            progressItem={DASHBOARD_JOURNEY.foundationalSetup}
          />
          <MainContent
            btnLink
            btnName="Go to your summary"
            showBtn={showButtonOnAccordion(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger
            )}
            onClick={handleOnClick.bind(
              null,
              FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger
            )}
            btnDisabled={disabledState}
            title={generalLedgerDetails?.sectionTitle}
            desc={generalLedgerDetails?.sectionSubTitle}
            overviewHeader={generalLedgerDetails?.header}
            overview={generalLedgerDetails?.description}
            helpfullRes={generalLedgerDetails?.resourcesLinks}
            status={showStepsStatus(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger
            )}
            progressItem={DASHBOARD_JOURNEY.foundationalSetup}
          />
          <MainContent
            btnLink
            btnName="Go to your summary"
            showBtn={showButtonOnAccordion(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes
            )}
            onClick={handleOnClick.bind(
              null,
              FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes
            )}
            btnDisabled={disabledState}
            title={transactionCodesDetails?.sectionTitle}
            desc={transactionCodesDetails?.sectionSubTitle}
            overviewHeader={transactionCodesDetails?.header}
            overview={transactionCodesDetails?.description}
            helpfullRes={transactionCodesDetails?.resourcesLinks}
            status={showStepsStatus(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes
            )}
            progressItem={DASHBOARD_JOURNEY.foundationalSetup}
          />
          <MainContent
            btnLink
            btnName="Go to your summary"
            showBtn={showButtonOnAccordion(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.networks
            )}
            onClick={handleOnClick.bind(
              null,
              FOUNDATIONAL_SETUP_STEPS_NAME.networks
            )}
            btnDisabled={disabledState}
            title={networksDetails?.sectionTitle}
            desc={networksDetails?.sectionSubTitle}
            overviewHeader={networksDetails?.header}
            overview={networksDetails?.description}
            helpfullRes={networksDetails?.resourcesLinks}
            status={showStepsStatus(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.networks
            )}
            progressItem={DASHBOARD_JOURNEY.foundationalSetup}
          />
          <MainContent
            btnLink
            btnName="Go to your summary"
            showBtn={showButtonOnAccordion(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes
            )}
            onClick={handleOnClick.bind(
              null,
              FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes
            )}
            btnDisabled={disabledState}
            title={customerTypesDetails?.sectionTitle}
            desc={customerTypesDetails?.sectionSubTitle}
            overviewHeader={customerTypesDetails?.header}
            overview={customerTypesDetails?.description}
            helpfullRes={customerTypesDetails?.resourcesLinks}
            status={showStepsStatus(
              data,
              FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes
            )}
            progressItem={DASHBOARD_JOURNEY.foundationalSetup}
            isLastItem
          />
        </Stepper>
      </Box>
    </>
  );
};

export default FoundationalSetup;
