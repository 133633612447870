import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface 
interface AdvancedConfigProps {
  isOffBalSheet: SplitBtnInputValType;
  isRtPosnUpd: SplitBtnInputValType;
  isChkPosn: SplitBtnInputValType;
}
// Defining Intitial Value 
const defaultAdvancedConfig: AdvancedConfigProps = {
  isOffBalSheet: undefined,
  isRtPosnUpd: undefined,
  isChkPosn: undefined,
};
// YUP LIBRARY :Defining Schema 
const advancedConfigSchema = async (optionsSchema: PostSchema) => {
  const fields = ["isOffBalSheet", "isRtPosnUpd", "isChkPosn"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AdvancedConfigProps };
// Exporting Validation Schema and Intial value
export { defaultAdvancedConfig, advancedConfigSchema as getValidationSchema };
