// CertificationPath.tsx
"use client";

import { useEffect, useState, useCallback, useRef } from "react";
import { useSearchParams } from "next/navigation";
import { Divider, Grid, GridItem, HStack, Text } from "@chakra-ui/react";
import {
  getEnrollmentById,
  getLearnerObjectInstancesByIds,
  getLearnerObjectsByIds,
} from "@/client-api-manager/alm-api";
import {
  constructCourses,
  constructLearningObject,
  formatDate,
  getDaysDiff,
} from "../learning-util-service";
import { LearningObjectDetails } from "@/models/academy-models";
import "./CertificationPath.scss";
import CertificationStatus from "./certification-status/CertificationStatus";
import BadgeHeader from "../catalog/badge-path/badge-header/BadgeHeader";
import BadgeCard from "../catalog/badge-path/badge-card/BadgeCard";
import BadgeDetails from "../catalog/badge-path/badge-status/BadgeDetails";
import TakeFinalExam from "./final-exam-section/TakeFinalExam";
import { Loader } from "@/components/common";
import * as CloverApi from "../../../client-api-manager/clover-api";
import CertificationDescription from "./certification-description/CertificationDescription";

interface CertificationViewProps {
  certificationPath: LearningObjectDetails;
  certEnrollmentDetails: any;
  courses: LearningObjectDetails[] | null;
  expireDaysLeft: number;
  examCourse: LearningObjectDetails | null;
  itemDetail: ItemDetail | null;
}

interface ItemDetail {
  note?: string;
  id: string;
  name: string;
  price: number;
  tax?: number;
}

export default function CertificationPath() {
  const searchParams = useSearchParams();
  const certificationId = searchParams?.get("id");
  const [certificationPath, setCertificationPath] =
    useState<LearningObjectDetails>();
  const [certEnrollmentDetails, setCertEnrollmentDetails] = useState<any>();
  const [courses, setCourses] = useState<LearningObjectDetails[] | null>(null);
  const [examCourse, setExamCourse] = useState<LearningObjectDetails | null>(
    null
  );
  const [expireDaysLeft, setExpiryDaysLeft] = useState<number>(0);
  const [itemDetail, setItemDetail] = useState<ItemDetail | null>(null);
  const [isCalled, setIsCalled] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      if (!courses && !examCourse) {
        const certDetails = await fetchCertificationDetails(
          certificationId || ""
        );
        await fetchBadgeDetails(certDetails);
      }
    }
    if (certificationId) {
      if (!isCalled) {
        fetchData().then(() => {
          if (Array.isArray(courses)) {
            setIsCalled(true);
          }
          updateCoursesWithEnrollment();
          fetchCategories();
        });
      }
    }
  }, [certificationId, courses, examCourse, isCalled]);

  async function fetchCertificationDetails(
    certId: string
  ): Promise<LearningObjectDetails> {
    const res = await getLearnerObjectsByIds(
      certId,
      "subLOs, enrollment.loResourceGrades"
    );
    const certificationPathArray: LearningObjectDetails[] =
      constructLearningObject(res.data, true);
    const courses = constructCourses(res.data.included);
    if (certificationPathArray.length > 0) {
      const coursesExcludingExams = courses.filter(
        (course) => !course.tags?.toLowerCase().includes("exam")
      );
      const examCourseFound = courses.find((course) =>
        course.tags?.toLowerCase().includes("exam")
      );
      setCourses(coursesExcludingExams);
      setExamCourse(examCourseFound || null);
      setCertificationPath(certificationPathArray[0]);
      const certEnrollDetails = res.data.included.find(
        (item: any) => item.type === "learningObjectInstanceEnrollment"
      )?.attributes;
      if (certEnrollDetails) {
        updateCertEnrollmentDetails(certEnrollDetails);
        setExpiryDaysLeft(
          getDaysDiff(
            certEnrollDetails.dateCompleted,
            certEnrollDetails.validity
          )
        );
      }
    }
    return certificationPathArray[0];
  }

  async function fetchBadgeDetails(certPath: LearningObjectDetails) {
    const res = await getLearnerObjectInstancesByIds(
      certPath.learnerObjectId,
      certPath.instanceIds || "",
      "badge"
    );
    setCertEnrollmentDetails((prev: any) => ({
      ...prev,
      validity: res.data.data.attributes?.validity,
    }));
  }

  async function updateCoursesWithEnrollment() {
    if (Array.isArray(courses)) {
      const enrollmentPromises = courses.map((course) =>
        getEnrollmentById(course.enrollmentId, "loResourceGrades").catch(
          (error) => {
            console.error("Error fetching enrollment details:", error);
            return null;
          }
        )
      );
      const enrollmentDetails = await Promise.all(enrollmentPromises);
      const validEnrollments = enrollmentDetails.filter(
        (detail) => detail !== null
      );
      const updatedCourses = courses.map((course) => {
        const enrollmentDetail = validEnrollments.find(
          (detail) => detail.data.id === course.enrollmentId
        );
        return enrollmentDetail
          ? {
              ...course,
              ...enrollmentDetail?.data.attributes,
              dateCompleted:
                enrollmentDetail?.included?.[0]?.attributes?.dateCompleted,
            }
          : course;
      });

      const passedCoursesCount = updatedCourses.filter(
        (course) => course.hasPassed
      ).length;
      const allCoursesPassed = updatedCourses.every(
        (course) => course.hasPassed
      );
      let newCertEnrollDetails = certEnrollmentDetails;
      if (newCertEnrollDetails) {
        newCertEnrollDetails.isQualified = allCoursesPassed;
        newCertEnrollDetails.passedCoursesCount = passedCoursesCount;
      }
      updateCertEnrollmentDetails(newCertEnrollDetails);
      setCourses(updatedCourses);
    }
  }

  const updateCertEnrollmentDetails = useCallback((details: any) => {
    setCertEnrollmentDetails((prev: any) => ({ ...prev, ...details }));
  }, []);

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await CloverApi.fetchCloverCategories();
      const academyCategory = fetchedCategories.find(
        (category: { name: string }) =>
          category.name.toLowerCase() === "academy"
      );
      if (academyCategory) {
        fetchInventoryItems(academyCategory.id);
      } else {
        console.log("Academy category not found");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchInventoryItems = async (categoryId: string) => {
    try {
      const fetchedItems = await CloverApi.fetchItemsByCategory(categoryId);
      const selfPacedCourseItem = fetchedItems.find(
        (item: { name: string }) => item.name === "SelfPaced Course"
      );
      if (selfPacedCourseItem) {
        fetchItemDetails(selfPacedCourseItem.id);
      } else {
        console.log("SelfPaced Course item not found");
      }
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    }
  };

  const fetchItemDetails = async (itemId: string) => {
    try {
      const fetchedItemDetail = await CloverApi.fetchCloverItem(itemId);
      fetchedItemDetail.tax = 0;
      setItemDetail(fetchedItemDetail);
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };

  if (!certificationPath) {
    return <Loader isOverlay />;
  }

  return (
    <>
      <CertificationView
        certificationPath={certificationPath}
        certEnrollmentDetails={certEnrollmentDetails}
        courses={courses}
        expireDaysLeft={expireDaysLeft}
        examCourse={examCourse}
        itemDetail={itemDetail}
      />
    </>
  );
}

function CertificationView({
  certificationPath,
  certEnrollmentDetails,
  courses,
  expireDaysLeft,
  examCourse,
  itemDetail,
}: CertificationViewProps) {
  const takeExamButtonRef = useRef<any>();
  const handleTakeExamButtonClick = () => {
    takeExamButtonRef.current.click();
  };

  const progressText = courses?.length
    ? (certEnrollmentDetails.passedCoursesCount || 0) + "/" + courses.length
    : "";

  return (
    <>
      <BadgeHeader
        pageType="ACADEMY"
        title={certificationPath.name}
        subtitle={certificationPath.description}
        showBadgeProgress={false}
      />

      <Grid templateColumns="3fr 1fr" gap={6} mb={20}>
        <GridItem className="grid-item first-section">
          <CertificationDescription
            completionImageSrc={
              certificationPath?.imageUrl || certificationPath?.bannerUrl || ""
            }
            title={certificationPath.name || ""}
            progressText={
              courses?.length
                ? (certEnrollmentDetails.passedCoursesCount || 0) +
                  "/" +
                  courses.length
                : ""
            }
            overviewText={certificationPath.overview || ""}
            descriptionText={certificationPath.description || ""}
          />
          {courses ? (
            <>
              <Divider className="horizontalLineBottom" />
              <HStack justify="space-between" width="100%">
                <Text className="examText">Pre-requisite courses</Text>
                <Text className="progress">
                  {progressText && `${progressText} Complete`}
                </Text>
              </HStack>
              {courses.map((course) => (
                <BadgeCard
                  key={course.learnerObjectId}
                  id={course.learnerObjectId}
                  instanceIds={course.instanceIds}
                  title={course.name}
                  rating={course.rating}
                  description={course.description ?? course.overview}
                  author={course.author}
                  duration={course.duration}
                  format={course.format}
                  required={course.required}
                  status={course.state}
                  progressPercent={course.progressPercent}
                  enrollmentId={course.enrollmentId}
                  completionDate={formatDate(course.dateCompleted)}
                />
              ))}
            </>
          ) : null}
          {examCourse ? (
            <>
              <Divider className="horizontalLineBottom" />
              <HStack justify="space-between" width="100%">
                <Text className="examText">Exam Course</Text>
              </HStack>

              <BadgeCard
                key={examCourse?.learnerObjectId}
                id={examCourse?.learnerObjectId || ""}
                instanceIds={examCourse?.instanceIds}
                title={examCourse?.name || ""}
                rating={examCourse?.rating}
                description={
                  (examCourse?.description ?? examCourse?.overview) || ""
                }
                author={examCourse?.author}
                duration={examCourse?.duration}
                format={examCourse?.format}
                required={examCourse?.required}
                status={examCourse?.state || ""}
                progressPercent={examCourse?.progressPercent}
                enrollmentId={examCourse?.enrollmentId || ""}
                completionDate={formatDate(examCourse?.dateCompleted)}
                isExamCourse
                takeExamButtonClick={handleTakeExamButtonClick}
              />
            </>
          ) : null}
        </GridItem>
        <GridItem className="grid-item" alignItems={"flex-end"}>
          <CertificationStatus
            id={certificationPath.learnerObjectId}
            title={certificationPath.name}
            bookmark={certificationPath.isBookmarked}
            hasPassed={certEnrollmentDetails.hasPassed}
            dateCompleted={certEnrollmentDetails.dateCompleted}
            validity={certEnrollmentDetails.validity}
          />
          <BadgeDetails
            id={certificationPath.learnerObjectId}
            instanceId={certificationPath.instanceIds}
            status={certificationPath.state}
            progressPercent={certificationPath.progressPercent}
            label={
              certEnrollmentDetails.hasPassed
                ? expireDaysLeft > 30
                  ? "Congratulations, you've earned this certification"
                  : "Renew now to continue upskilling with Finxact."
                : certEnrollmentDetails.isQualified
                  ? "You are qualified to take this exam"
                  : "Finish all courses to qualify for the final exam and secure your badge."
            }
            type="certificate"
          />

          <TakeFinalExam
            daysLeft={expireDaysLeft}
            courseDetails={examCourse}
            isQualified={certEnrollmentDetails.isQualified}
            hasPassed={certEnrollmentDetails.hasPassed}
            itemDetail={itemDetail}
            buttonRef={takeExamButtonRef}
          />
        </GridItem>
      </Grid>
    </>
  );
}
