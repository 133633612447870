"use client";

import {
  AccordionComponent,
  AppFormLabel,
  CardContainer,
  DatePicker,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  StaticText,
  TimeField,
} from "@/components/common";
import AddNewButton from "@/components/common/add-new-button/AddNewButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import {
  PartyPhoneDetailsProps,
  getValidationSchema,
  partyPhoneDetailsInformation,
} from "./PartyPhoneDetailsValidation";

import "./PartyPhoneDetails.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  makeDateTime,
  useQueryParams,
  shouldUpdateWorkflowStatus,
} from "@/utils/common";
import {
  DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER,
  DEFAULT_PHONE_NO_PLACEHOLDER,
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  TIME_FORMAT,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type PartyPhoneProps = {
  data: {
    phoneTypeOptions: { label: string; value: string }[];
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
};
const PartyPhoneDetails = (props: PartyPhoneProps) => {
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "gpp-party-phone-details";
  const activeAccordionRef = useRef<any>();
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);

  // Form Submit Handler
  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_party_phone_details;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        phones: values.phones.map((phone: any, index: number) => ({
          _Id: props.data.formData?._Id,
          phoneType: parseInt(phone.phoneType),
          cntryCallCode: phone.cntryCallCode,
          data: phone.data,
          extension: phone.extension,
          label: phone.label,
          isPreferred: phone.isPreferred,
          validFromDtm:
            phone.numberValidity !== null &&
            phone.numberValidityTime !== "Invalid Date"
              ? makeDateTime(phone.numberValidity, phone.numberValidityTime)
              : null,
          validToDtm:
            phone.numberValidThrough !== null &&
            phone.numberValidThroughTime !== "Invalid Date"
              ? makeDateTime(
                  phone.numberValidThrough,
                  phone.numberValidThroughTime
                )
              : null,
          verifyDtm:
            phone.numberVerifiedDate !== null &&
            phone.numberVerifiedTime !== "Invalid Date"
              ? makeDateTime(phone.numberVerifiedDate, phone.numberVerifiedTime)
              : null,
        })),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`phones.${index}.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`phoneDetails.${index}.${key}`, true);
  };

  let initialPhoneDetails: PartyPhoneDetailsProps[] =
    props.data.formData?.phones?.map((phoneData: any, idx: number) => ({
      phoneType: phoneData.phoneType,
      cntryCallCode: phoneData.cntryCallCode,
      extension: phoneData.extension,
      data: phoneData.data,
      label: phoneData.label,
      isPreferred: phoneData.isPreferred ?? undefined,
      numberValidity: new Date(phoneData.validFromDtm),
      numberValidityTime: new Date(phoneData.validFromDtm).toLocaleTimeString(
        "it-IT"
      ),
      numberValidThrough: new Date(phoneData.validToDtm),
      numberValidThroughTime: new Date(phoneData.validToDtm).toLocaleTimeString(
        "it-IT"
      ),
      numberVerifiedDate: new Date(phoneData.verifyDtm),
      numberVerifiedTime: new Date(phoneData.verifyDtm).toLocaleTimeString(
        "it-IT"
      ),
    })) ?? [];

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { phones: initialPhoneDetails },
  });

  const {
    values,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const errors: any = formik.errors;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const _errorHandler = (key: string, index: number) => {
    return (
      <>
        {touched.phones &&
          touched.phones[index] &&
          (touched.phones[index] as any)[key] &&
          errors.phones &&
          errors.phones[index] &&
          (errors.phones[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.phones[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form" noValidate>
        <FieldArray
          name="phones"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              {values.phones?.map(
                (phoneDetail: PartyPhoneDetailsProps, index: number) => (
                  <Box key={index} ref={activeAccordionRef}>
                    <CardContainer>
                      <AccordionComponent
                        accordionTitle={`Party phone details ${index + 1}`}
                        limitName={""}
                        deleteIcon
                        onDeleteHandler={() => remove(index)}
                        isExpand={values.phones.length !== index + 1 ? 1 : 0}
                      >
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the party identifier?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get("_Id")
                                ?.tooltip_text_main
                            }
                          />
                          <StaticText textValue={props.data.formData?._Id} />
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the phone type?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "phoneType"
                              )?.tooltip_text_main
                            }
                            isRequired
                          />
                          <RadioButtonGroup
                            isBoxedRadio={true}
                            value={phoneDetail.phoneType}
                            onChange={handleOnChange("phoneType", index)}
                            radioPropList={props.data.phoneTypeOptions}
                            stackDirection={"row"}
                            spacing={"2rem"}
                            onBlur={handleBlur}
                          />
                          {_errorHandler("phoneType", index)}
                        </Box>

                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the country calling code?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "countryCallingCode"
                              )?.tooltip_text_main
                            }
                          />
                          <InputText
                            value={phoneDetail.cntryCallCode}
                            onChange={handleOnChange("cntryCallCode", index)}
                            placeholder="+1 US"
                            name="country calling code"
                          />
                          {_errorHandler("cntryCallCode", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the phone number?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "phoneNumber"
                              )?.tooltip_text_main
                            }
                            isRequired
                          />
                          <InputText
                            value={phoneDetail.data}
                            onChange={handleOnChange("data", index)}
                            placeholder={DEFAULT_PHONE_NO_PLACEHOLDER}
                            name="phone number"
                            phoneNumber
                          />
                          {_errorHandler("data", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the extension number?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "extension"
                              )?.tooltip_text_main
                            }
                          />
                          <InputText
                            value={phoneDetail.extension}
                            onChange={handleOnChange("extension", index)}
                            placeholder={DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER}
                            name="phone number"
                          />
                          {_errorHandler("extension", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Enter a recognizable label for the phone number"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get("label")
                                ?.tooltip_text_main
                            }
                          />
                          <InputText
                            value={phoneDetail.label}
                            onChange={handleOnChange("label", index)}
                            placeholder={"Enter a label"}
                            name="label"
                          />
                          {_errorHandler("label", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Is this the preferred phone number?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "isPreferred"
                              )?.tooltip_text_main
                            }
                          />
                          <SplitButton
                            name="isPreferred"
                            onSelect={handleOnChange("isPreferred", index)}
                            leftBtnName="Yes"
                            rightBtnName="No"
                            value={phoneDetail.isPreferred}
                          />
                          {_errorHandler("isPreferred", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When is this number valid from?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "validFromDtm"
                              )?.tooltip_text_main
                            }
                          />
                          <Stack direction="row" spacing="32px">
                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "validFromDate"
                                  )?.tooltip_text_main
                                }
                              />
                              <DatePicker
                                value={phoneDetail.numberValidity}
                                onUpdate={handleOnChange(
                                  "numberValidity",
                                  index
                                )}
                                onBlur={handleOnBlur("numberValidity", index)}
                                ariaLabel="numberVerifiedDate"
                                placeholder="Select"
                              />
                              {_errorHandler("numberValidity", index)}
                            </Box>
                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "validFromTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <Box className="time-select-party-phone">
                                <TimeField
                                  value={
                                    phoneDetail.numberValidityTime ??
                                    TIME_FORMAT.placeHolderTime
                                  }
                                  onChange={handleOnChange(
                                    "numberValidityTime",
                                    index
                                  )}
                                  ariaLabel="From time field"
                                  placeholder={TIME_FORMAT.placeHolderTime}
                                  onBlur={handleBlur}
                                />
                                {_errorHandler("numberValidityTime", index)}
                              </Box>
                            </Box>
                          </Stack>
                        </Box>

                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When is this number valid through?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "validToDtm"
                              )?.tooltip_text_main
                            }
                          />
                          <Stack direction="row" spacing="32px">
                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "validToDate"
                                  )?.tooltip_text_main
                                }
                              />
                              <DatePicker
                                value={phoneDetail.numberValidThrough}
                                onUpdate={handleOnChange(
                                  "numberValidThrough",
                                  index
                                )}
                                onBlur={handleOnBlur(
                                  "numberValidThrough",
                                  index
                                )}
                                ariaLabel="numberVerifiedDate"
                                placeholder="Select"
                              />
                              {_errorHandler("numberValidThrough", index)}
                            </Box>

                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "validToTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <Box className="time-select-party-phone">
                                <TimeField
                                  value={
                                    phoneDetail.numberValidThroughTime ??
                                    TIME_FORMAT.placeHolderTime
                                  }
                                  onChange={handleOnChange(
                                    "numberValidThroughTime",
                                    index
                                  )}
                                  ariaLabel="From time field"
                                  placeholder={TIME_FORMAT.placeHolderTime}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              {_errorHandler("numberValidThroughTime", index)}
                            </Box>
                          </Stack>
                        </Box>

                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When was the number verified?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "verifyDtm"
                              )?.tooltip_text_main
                            }
                          />
                          <Stack direction="row" spacing="32px">
                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "verifyDate"
                                  )?.tooltip_text_main
                                }
                              />
                              <DatePicker
                                value={phoneDetail.numberVerifiedDate}
                                onUpdate={handleOnChange(
                                  "numberVerifiedDate",
                                  index
                                )}
                                onBlur={handleOnBlur(
                                  "numberVerifiedDate",
                                  index
                                )}
                                ariaLabel="numberVerifiedDate"
                                placeholder="Select"
                              />
                              {_errorHandler("numberVerifiedDate", index)}
                            </Box>
                            <Box width={"48%"}>
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "verifyTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <Box className="time-select-party-phone">
                                <TimeField
                                  value={
                                    phoneDetail.numberVerifiedTime ??
                                    TIME_FORMAT.placeHolderTime
                                  }
                                  onChange={handleOnChange(
                                    "numberVerifiedTime",
                                    index
                                  )}
                                  ariaLabel="From time field"
                                  placeholder={TIME_FORMAT.placeHolderTime}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              {_errorHandler("numberVerifiedTime", index)}
                            </Box>
                          </Stack>
                        </Box>
                      </AccordionComponent>
                    </CardContainer>
                  </Box>
                )
              )}
              <AddNewButton
                label={
                  !values.phones.length
                    ? "Add party phone"
                    : "Add another party phone"
                }
                onClick={() => {
                  push({
                    ...partyPhoneDetailsInformation,
                    idx: Math.random(),
                  });
                }}
              />
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
};

export default PartyPhoneDetails;
