"use client";
import { Box, Button, Divider, Heading, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  createWorkflowGeneric,
  getAllWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import { PLATFORM_SETUP_MODEL_KEY, PLATFORM_SETUP_STATUS } from "@/utils";
import { CardContainer } from "../common";
import "./PlatformSetup.scss";
import { ChevronRightIcon } from "@chakra-ui/icons";
import SelectColorDropdown from "../common/select-color-dropdown/SelectColorDropdown";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

const PlatformSetup = (props: any) => {
  const [privateLinkStatus, setPrivateLinkData] = useState("");
  const [natIpsStatus, setNatIpsata] = useState("");
  const [listofIpsStatus, setlistofIpskData] = useState("");
  const setPrivateLinkValue = (consoleData: any[], step: string) => {
    if (step == "privateLink") {
      setPrivateLinkData(
        () =>
          consoleData.find(
            (ele: any) => ele.modelKey == PLATFORM_SETUP_MODEL_KEY[step]
          )?.status
      );
    } else if (step == "natIps") {
      setNatIpsata(
        () =>
          consoleData.find(
            (ele: any) => ele.modelKey == PLATFORM_SETUP_MODEL_KEY[step]
          )?.status
      );
    } else {
      setlistofIpskData(
        () =>
          consoleData.find(
            (ele: any) => ele.modelKey == PLATFORM_SETUP_MODEL_KEY[step]
          )?.status
      );
    }
  };
  useEffect(() => {
    const getAllData = async () => {
      const [consoleData] = await Promise.all([
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.platform),
      ]).then((data: any) => data);
      if (consoleData.length !== 3) {
        createData();
      } else {
        setPrivateLinkValue(consoleData, "privateLink");
        setPrivateLinkValue(consoleData, "natIps");
        setPrivateLinkValue(consoleData, "listofIps");
      }
    };
    getAllData();
    const createData = async () => {
      const [privateLink, natIps, listofIps] = await Promise.all([
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.platform,
          key: PLATFORM_SETUP_MODEL_KEY.privateLink,
          stage: PLATFORM_SETUP_MODEL_KEY.privateLink,
          status: PLATFORM_SETUP_STATUS.incomplete,
        }),
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.platform,
          key: PLATFORM_SETUP_MODEL_KEY.natIps,
          stage: PLATFORM_SETUP_MODEL_KEY.natIps,
          status: PLATFORM_SETUP_STATUS.incomplete,
        }),
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.platform,
          key: PLATFORM_SETUP_MODEL_KEY.listofIps,
          stage: PLATFORM_SETUP_MODEL_KEY.listofIps,
          status: PLATFORM_SETUP_STATUS.incomplete,
        }),
      ]).then((data) => data);
      getAllData();
    };
  }, []);

  const handleOnClick = async (step: string, ele?: any) => {
    await Promise.all([
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.platform,
        key: step,
        stage: step,
        status: PLATFORM_SETUP_STATUS[ele],
      }),
    ]).then((data) => data);
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        className="platform-setup"
      >
        <Box>
          <Heading as="h3">Platform Setup Checklist</Heading>
          <Text className="resource-description">
            Review key steps to set up your platform
          </Text>

          <CardContainer>
            <Box className="plat-form-continer">
              <Box>
                <Box className="resource-body">
                  <Box>
                    <Text className="resource-title">
                      Provide finxact with list of IPs to be whitelisted
                    </Text>

                    <Text className="resource-description">
                      Access to finxact core environments is restricted to a set
                      of IP addresses. In order to access the Core environment,
                      a user&apos;s IP address must be present in this listing.
                      Provide a set of IP addresses, in CIDR notation, to
                      represent your pool of user IP addresses. Generally, these
                      would be your public IP addresses for your VPN.
                    </Text>
                  </Box>

                  <SelectColorDropdown
                    values={listofIpsStatus}
                    onClick={(e: string) => {
                      handleOnClick(PLATFORM_SETUP_MODEL_KEY.listofIps, e);
                    }}
                  ></SelectColorDropdown>
                </Box>
                <Link href={""}></Link>
              </Box>
              <Divider className="bottom-divider" />
              <Box>
                <Box className="resource-body">
                  <Box>
                    <Text className="resource-title">Gather NAT IPs</Text>

                    <Text>
                      Ensure you have obtained Finxact’s specific NAT IPs to
                      facilitate communication and transactions in a secure
                      setting.
                    </Text>
                  </Box>
                  <SelectColorDropdown
                    values={natIpsStatus}
                    onClick={(e: string) => {
                      handleOnClick(PLATFORM_SETUP_MODEL_KEY.natIps, e);
                    }}
                  ></SelectColorDropdown>
                </Box>

                <Link href={""}></Link>
              </Box>
              <Divider className="bottom-divider" />
              <Box>
                <Box className="resource-body">
                  <Box>
                    <Text className="resource-title">
                      Request privateLink connectivity service
                    </Text>

                    <Text>
                      If needed, contact finxact about establishing privateLink
                      connectivity through the cloud provider
                    </Text>
                  </Box>
                  <SelectColorDropdown
                    values={privateLinkStatus}
                    onClick={(e: string) => {
                      handleOnClick(PLATFORM_SETUP_MODEL_KEY.privateLink, e);
                    }}
                  ></SelectColorDropdown>
                </Box>
                <Link href={""}></Link>
              </Box>
            </Box>
          </CardContainer>
        </Box>
      </Box>
    </>
  );
};

export default PlatformSetup;
