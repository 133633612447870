"use client";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import { INTERNAL_SCHEMA_TYPE } from "@/components/data/form-data";

import { useContext, useEffect, useMemo, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  getValidationSchema,
  repaymentPaymentConfigurationProps,
  repaymentPrepaymentConfigurationDetails,
} from "./RepaymentPaymentConfigurationValidation";
import { useFormik } from "formik";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  OptionsSchema,
  PostSchema,
  RepaymentComponentData,
} from "../../../model/types";
import {
  addNewComponent,
  createRepaymentComponent,
} from "../../product-config-client-service";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  getConstructedFeatureNameWithComponent,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS, RjsfData } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

interface RepaymentConfigProps {
  advancePaymentApplication: INTERNAL_SCHEMA_TYPE<string>[];
  paymentToleType: string[];
  formData?: RepaymentComponentData | null;
  addNewPayApplMatrixEndpoint: string;
  payApplMatrixOptions: string[];
  payApplMatrixSchema: OptionsSchema;
  repayPaymentOptionsData: PostSchema;
}
const RepaymentPaymentConfiguration = (props: {
  data: RepaymentConfigProps;
}) => {
  const repaymentPaymentData = props.data?.formData;
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.repayPaymentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.repayPaymentOptionsData]);
  const onSubmit = async (
    values: repaymentPaymentConfigurationProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData && productDetails) {
      const res = await createRepaymentComponent({
        productDetails,
        formData: {
          ...formData,
          // PATCH fields
          pmtDur: values.pmtDur,
          pmtFreq: values.pmtFreq,
          minPmtAmt: parseFloat(values.minPmtAmt),
          payoffVarianceAmt: parseFloat(values.payoffVarianceAmt),
          gracePeriod: values.gracePeriod,
          isRevolving: values.isRevolving,
          pmtApplAdvance: values.pmtApplAdvance,
          toleranceAmt:
            values.paymentToleranceType === "Payment Tolerance Amount"
              ? parseFloat(values.tolerancePct)
              : null,
          tolerancePct:
            values.paymentToleranceType === "Payment Tolerance Percentage"
              ? parseFloat(values.tolerancePct)
              : null,
          pmtOffset: values.pmtOffset,
          payApplMatrix: values.payApplMatrix || null,
        },
        toastOptions: {
          toast,
          toastId: "repayment-config",
          successMessage: `${configPageTitle} updated.`,
        },

        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const addPayApplMatrixSchema = useMemo(() => {
    const { actions, name } = props.data.payApplMatrixSchema;
    return { name, ...actions.POST };
  }, [props.data.payApplMatrixSchema]);

  const addNewPaymentApplicationMatrix = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewPayApplMatrixEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`payApplMatrix`, response.matrixName);
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
    setTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      pmtDur:
        repaymentPaymentData?.pmtDur ??
        repaymentPrepaymentConfigurationDetails.pmtDur,
      pmtFreq:
        repaymentPaymentData?.pmtFreq ??
        repaymentPrepaymentConfigurationDetails.pmtFreq,
      minPmtAmt:
        repaymentPaymentData?.minPmtAmt?.toString() ??
        repaymentPrepaymentConfigurationDetails.minPmtAmt,
      payoffVarianceAmt:
        repaymentPaymentData?.payoffVarianceAmt?.toString() ??
        repaymentPrepaymentConfigurationDetails.payoffVarianceAmt,
      gracePeriod:
        repaymentPaymentData?.gracePeriod ??
        repaymentPrepaymentConfigurationDetails.gracePeriod,
      isRevolving:
        repaymentPaymentData?.isRevolving ??
        repaymentPrepaymentConfigurationDetails.isRevolving,
      pmtApplAdvance:
        repaymentPaymentData?.pmtApplAdvance ??
        repaymentPrepaymentConfigurationDetails.pmtApplAdvance,
      paymentToleranceType:
        repaymentPaymentData?.toleranceAmt !== undefined
          ? "Payment Tolerance Amount"
          : repaymentPaymentData?.tolerancePct !== undefined
            ? "Payment Tolerance Percentage"
            : repaymentPrepaymentConfigurationDetails.paymentToleranceType,
      tolerancePct:
        (repaymentPaymentData?.toleranceAmt !== undefined
          ? repaymentPaymentData?.toleranceAmt
          : repaymentPaymentData?.tolerancePct
        )?.toString() ?? repaymentPrepaymentConfigurationDetails.tolerancePct,
      pmtOffset:
        repaymentPaymentData?.pmtOffset ??
        repaymentPrepaymentConfigurationDetails.pmtOffset,
      payApplMatrix:
        repaymentPaymentData?.payApplMatrix ??
        repaymentPrepaymentConfigurationDetails.payApplMatrix,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Handling Form
  const repaymentPaymentConfigurationFormHandler =
    (key: string) =>
    (value: string | string[] | number | SplitBtnInputValType) => {
      if (key === "paymentToleranceType") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("tolerancePct", "");
        });
      } else {
        setFieldValue(key, value);
      }
    };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
      noValidate
    >
      <CardContainer>
        <Box className="app-form-field-container">
          <DurationInput
            labelName="What is the payment duration?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtDur")
                ?.tooltip_text_main
            }
            value={values.pmtDur}
            onChange={repaymentPaymentConfigurationFormHandler("pmtDur")}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter Payment Duration"}
            modalLabelName="What is the payment duration?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtDur")
                ?.tooltip_text_main
            }
          />

          {errors.pmtDur && touched.pmtDur && (
            <ErrorAlert>
              <span>{errors.pmtDur}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <FrequencyModule
            value={values.pmtFreq}
            onChange={repaymentPaymentConfigurationFormHandler("pmtFreq")}
            frequencyQuestionTitle="How often should loan payments be made?"
            frequencyQuestionDescription={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtFreq")
                ?.tooltip_text_main
            }
            monthDropdownLabel="On what date should the loan payments be due?"
            monthDropdownTooltip={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtFreqMon")
                ?.tooltip_text_main
            }
            notBusinessDayLabel="If the scheduled day falls on a non-business day, when should the payment be received?"
            notBusinessDayTooltip={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtFreqNotBusiness")
                ?.tooltip_text_main
            }
            frequencyCodeText="Enter code manually"
            frequencyOutputContent="The payments will be occur every"
          />
          {errors.pmtFreq && touched.pmtFreq && (
            <ErrorAlert>
              <span>{errors.pmtFreq}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <DurationInput
            labelName="How long until the repayment due date of the receivable?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtOffset")
                ?.tooltip_text_main
            }
            value={values.pmtOffset}
            onChange={repaymentPaymentConfigurationFormHandler("pmtOffset")}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter Repayment Due Date"}
            modalLabelName="How long until the repayment due date of the receivable?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtOffset")
                ?.tooltip_text_main
            }
          />

          {errors.pmtOffset && touched.pmtOffset && (
            <ErrorAlert>
              <span>{errors.pmtOffset}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the minimum payment amount?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minPmtAmt")
                ?.tooltip_text_main
            }
            value={values.minPmtAmt}
            onChange={repaymentPaymentConfigurationFormHandler("minPmtAmt")}
            valuePrefix="$"
          />
          {errors.minPmtAmt && touched.minPmtAmt && (
            <ErrorAlert>
              <span>{errors.minPmtAmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the acceptable variance amount between payoff and an outstanding balance?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("payoffVarianceAmt")
                ?.tooltip_text_main
            }
            value={values.payoffVarianceAmt}
            onChange={repaymentPaymentConfigurationFormHandler(
              "payoffVarianceAmt"
            )}
            valuePrefix="$"
          />
          {errors.payoffVarianceAmt && touched.payoffVarianceAmt && (
            <ErrorAlert>
              <span>{errors.payoffVarianceAmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <DurationInput
            labelName="What is the payment grace period?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("gracePeriod")
                ?.tooltip_text_main
            }
            value={values.gracePeriod}
            onChange={repaymentPaymentConfigurationFormHandler("gracePeriod")}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter Payment Grace Period"}
            modalLabelName="What is the payment grace period?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("gracePeriod")
                ?.tooltip_text_main
            }
          />
          {errors.gracePeriod && touched.gracePeriod && (
            <ErrorAlert>
              <span>{errors.gracePeriod}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Select payment tolerance type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("paymentToleType")
                ?.tooltip_text_main
            }
          />
          <SelectDropdown
            dropdownList={props.data.paymentToleType}
            placeholder="Select Option"
            value={values.paymentToleranceType}
            onChange={repaymentPaymentConfigurationFormHandler(
              "paymentToleranceType"
            )}
          />
          {values.paymentToleranceType && (
            <Box className="dashed-left-border">
              <AppNumberInput
                labelName=""
                value={values.tolerancePct}
                onChange={repaymentPaymentConfigurationFormHandler(
                  "tolerancePct"
                )}
                valuePrefix={
                  values.paymentToleranceType === "Payment Tolerance Amount"
                    ? "$"
                    : ""
                }
                valueSuffix={
                  values.paymentToleranceType === "Payment Tolerance Amount"
                    ? ""
                    : "%"
                }
              />
              {errors.tolerancePct && touched.tolerancePct && (
                <ErrorAlert>
                  <span>{errors.tolerancePct}</span>
                </ErrorAlert>
              )}
            </Box>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is the payment revolving?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isRevolving")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isRevolving}
            onSelect={repaymentPaymentConfigurationFormHandler("isRevolving")}
          />
          {errors.isRevolving && touched.isRevolving && (
            <ErrorAlert>
              <span>{errors.isRevolving}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the payment application matrix?"
            labelFor="app_matrix"
          />
          <CoupleRadioWithDropdown
            primaryRadioName="Use existing payment application matrix"
            secondaryRadioName="Add new payment application matrix"
            placeHolderName="Select"
            primaryOptionValue={values.payApplMatrix}
            handlePrimaryOptionSelect={repaymentPaymentConfigurationFormHandler(
              "payApplMatrix"
            )}
            dropdownList={props.data.payApplMatrixOptions}
            id="app_matrix"
            schema={addPayApplMatrixSchema}
            uiSchema={matrixTypeUiSchema}
            onAddNewSubmit={addNewPaymentApplicationMatrix}
            modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
          />
          {errors.payApplMatrix && touched.payApplMatrix && (
            <ErrorAlert>
              <span>{errors.payApplMatrix}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the advance payment application method?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("pmtApplAdvance")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.pmtApplAdvance}
            onChange={repaymentPaymentConfigurationFormHandler(
              "pmtApplAdvance"
            )}
            radioPropList={props.data.advancePaymentApplication}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.pmtApplAdvance && touched.pmtApplAdvance && (
            <ErrorAlert>
              <span>{errors.pmtApplAdvance}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default RepaymentPaymentConfiguration;
