"use client";

import { Box, Button, HStack, Text, Image, Center } from "@chakra-ui/react";
import "./HeroBannerWithSlider.scss";
import { academyBannerImage } from "public/assets";

import { useContext, useEffect } from "react";
import {
  AcademyContextType,
  academyContext,
} from "@/components/context-api/academy-context-provider/AcademyContextReducer";

import { getUserDetails } from "@/client-api-manager/alm-api";
import { HeaderData, UserAttributesType } from "@/models/academy-models";
import { useUserInfo } from "@/app/providers";

export default function HeroBannerWithSlider({
  bannerImage,
  headline,
  subhead,
  auth,
}: HeaderData) {
  const { setUserAttributes } = useContext<AcademyContextType>(academyContext);
  const {
    user: { firstName },
  } = useUserInfo();

  useEffect(() => {
    const getData = async () => {
      const data = await getUserDetails();
      if (data?.data?.data?.attributes) {
        const userData: UserAttributesType = {
          id: data?.data?.data?.id,
          ...data?.data?.data?.attributes,
        };
        setUserAttributes && setUserAttributes(userData);
      }
    };
    getData();
  }, []);

  return (
    <Box className="hero-banner-slider-container">
      <Box className="banner-text">
        <Text as="h2">
          {headline || "Welcome to Finxact Academy"}
          {auth && `, ${firstName}`}
        </Text>
        <Text whiteSpace={"pre-line"} as="p">
          {subhead ||
            "Welcome to your Learning Academy, where you can fulfill all your learning needs.\n\nTake courses to understand how to use Console, earn badges, complete certifications, and so much more."}
        </Text>
        {!auth && (
          <>
            <HStack className="banner-button" gap={13}>
              <Button
                className="app-btn-reg-secondary custom-btn"
                onClick={() => {
                  window.location.href = `/profile-setup?`;
                }}
              >
                Enroll now
              </Button>
              <Button
                className="app-btn-inverse-secondary"
                onClick={() => {
                  window.location.href = `/console/login?returnPage=${window.location.pathname}`;
                }}
              >
                Log in
              </Button>
            </HStack>
          </>
        )}
      </Box>
      <Center className="banner-image">
        <Image
          src={bannerImage || academyBannerImage.src}
          alt="graphic representing a learning path"
        />
      </Center>
    </Box>
  );
}
