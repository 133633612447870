"use client";

import { Box, Button, Text, Tooltip } from "@chakra-ui/react";
import "./BadgeHeader.scss";
import { ArrowBackIcon } from "@chakra-ui/icons";
import BadgeProgress from "./BadgeProgress";
import { useRouter, useSearchParams } from "next/navigation";
import styleVars from "../../../../../styles/_export.module.scss";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";

const { appFontColor } = styleVars;

type BadgeHeaderProps = {
  pageType: string;
  title: string;
  subtitle: string;
  duration?: number | undefined;
  courseProgress?: number | undefined;
  showBadgeProgress?: boolean;
  showBackButton?: boolean;
};

export default function BadgeHeader({
  pageType,
  title,
  subtitle,
  duration,
  courseProgress,
  showBadgeProgress = true,
  showBackButton = true,
}: BadgeHeaderProps) {
  const router = useRouter();
  const queryParams = useSearchParams();
  const from = queryParams?.get("from");

  const getBackURL = () => {
    switch (from) {
      case "catalog":
        return ROUTE_PATH.ACADEMY_CATALOG;
      case "my-learning":
        return ROUTE_BASE_URL.ACADEMY;
      default:
        return ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION;
    }
  };
  const getBacktoText = () => {
    switch (from) {
      case "catalog":
        return "Catalog";
      case "my-learning":
        return "My Learning";
      default:
        return "Badges & Certifications";
    }
  };

  return (
    <Box
      className={`badge-path-header-container ${
        showBadgeProgress
          ? "min-height-badge-progress"
          : "min-height-no-badge-progress"
      }`}
    >
      <Text className="main-title">{pageType}</Text>
      <Box className="page-title">
        {showBackButton && (
          <Tooltip
            label={`Back to ${getBacktoText()}`}
            placement="right"
            hasArrow
            bgColor={appFontColor}
            className="tooltip-back-text"
          >
            <Button
              aria-label="back"
              className="back-btn"
              onClick={() => router.push(getBackURL())}
            >
              <ArrowBackIcon fontSize={24} />
            </Button>
          </Tooltip>
        )}
        {title}
      </Box>
      <Text className="page-subtitle">{subtitle}</Text>
      {showBadgeProgress && (
        <BadgeProgress duration={duration} courseProgress={courseProgress} />
      )}
    </Box>
  );
}
