import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  GENERIC_ERROR,
  additionalAttributesZeroBalanceDaysError,
  nominalError,
} from "@/components/data/error-data";
import * as Yup from "yup";
import { AccumulatedLimitDetailsProps } from "../../model";
import { REGEX_PATTERN, generateYupSchema } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Accumulated_Transaction_Limit_Configuration
interface LimitConfigProps extends AccumulatedLimitDetailsProps {
  idx?: number | string;
  selectLimitType?: string;
  time?: string;
  expTime?: string;
  feeViolated?: SplitBtnInputValType;
  needTempLimits?: SplitBtnInputValType;
}

interface SingleTransactionLimitConfigProps {
  idx: number | string;
  _Id: string;
  singlefee: string;
  name: string;
  minBal: string;
  maxBal: string;
  definedBy: string;
  minCrAmt: string;
  maxCrAmt: string;
  minDrAmt: string;
  maxDrAmt: string;
  increCrAmt: string;
  increDrAmt: string;
  violationAct: string;
  limitfeeViolated: SplitBtnInputValType;
  violationFee: string;
  trnCodeExcl: string[];
}

interface AddLimitComponentProps {
  addLimitComponent: SplitBtnInputValType;
  componentName: string;
  minimumBalance: SplitBtnInputValType;
  minBal: string;
  minToOpen: string;
  maxBal: string;
  deminimisAmt: string;
  restrictCrFundExp: SplitBtnInputValType;
  restrictCr: SplitBtnInputValType;
  restrictDr: SplitBtnInputValType;
  minBalFee: string;
  minBalOpt: string;
  accumTrnLimits: LimitConfigProps[];
  perTrnLimits: SingleTransactionLimitConfigProps[];
}

// Defining Intitial Value For Accumulated_Transaction_Limit_Configuration
const limitConfig: LimitConfigProps = {
  idx: "",
  selectLimitType: "",
  _Id: "",
  crAmt: "",
  crCnt: "",
  definedBy: "",
  drAmt: "",
  drCnt: "",
  name: "",
  startDtm: "",
  statGroup: "",
  totAmt: "",
  totCnt: "",
  violationAct: "",
  violationFee: "",
  time: "",
  feeViolated: undefined,
};

const singleTransactionLimitConfig: SingleTransactionLimitConfigProps = {
  idx: "",
  _Id: "",
  singlefee: "",
  name: "",
  definedBy: "",
  minBal: "",
  maxBal: "",
  minCrAmt: "",
  maxCrAmt: "",
  minDrAmt: "",
  maxDrAmt: "",
  increCrAmt: "",
  increDrAmt: "",
  violationAct: "",
  limitfeeViolated: undefined,
  violationFee: "",
  trnCodeExcl: [],
};

const AddLimitConfig: AddLimitComponentProps = {
  addLimitComponent: undefined,
  componentName: "",
  minimumBalance: undefined,
  minBal: "",
  minToOpen: "",
  maxBal: "",
  deminimisAmt: "",
  restrictCrFundExp: undefined,
  restrictCr: undefined,
  restrictDr: undefined,
  minBalFee: "",
  minBalOpt: "",
  accumTrnLimits: [],
  perTrnLimits: [],
};

const limitValidation = (schema: any, errorMsg: string) =>
  schema.when(["selectLimitType", "$addLimitComponent"], {
    is: (selectLimitType: string, addLimitComponent: string) =>
      selectLimitType === "add_new" && addLimitComponent === "selectLeft",
    then: (innerSchema: any) => innerSchema.required(errorMsg),
  });

const limitNumberValidation = () =>
  Yup.number().when(["selectLimitType", "$addLimitComponent"], {
    is: (selectLimitType: string, addLimitComponent: string) =>
      selectLimitType === "add_new" && addLimitComponent === "selectLeft",
    then: (schema) =>
      schema
        .required(GENERIC_ERROR.required)
        .min(0, GENERIC_ERROR.minNumberError),
  });

const limitNumberMaxValidation = () =>
  Yup.number().when(["selectLimitType", "$addLimitComponent"], {
    is: (selectLimitType: string, addLimitComponent: string) =>
      selectLimitType === "add_new" && addLimitComponent === "selectLeft",
    then: (schema) =>
      schema
        .required(GENERIC_ERROR.required)
        .min(0, GENERIC_ERROR.minNumberError)
        .max(65535, additionalAttributesZeroBalanceDaysError.max),
  });

const limitSchema = Yup.array(
  Yup.object({
    selectLimitType: Yup.string().when("$addLimitComponent", {
      is: "selectLeft",
      then: (schema) => schema.required(GENERIC_ERROR.required),
    }),
    _Id: Yup.string().when(["selectLimitType", "$addLimitComponent"], {
      is: (selectLimitType: string, addLimitComponent: string) =>
        selectLimitType === "leverage_existing" &&
        addLimitComponent === "selectLeft",
      then: (schema) => schema.required(GENERIC_ERROR.required),
    }),
    statGroup: limitValidation(Yup.string(), GENERIC_ERROR.required),
    name: limitValidation(
      Yup.string().matches(
        REGEX_PATTERN.ALPHA_NUMERIC_WITH_UNDERSCORE,
        GENERIC_ERROR.alphaNumeric
      ),
      GENERIC_ERROR.required
    ),
    definedBy: limitValidation(Yup.string(), GENERIC_ERROR.required),
    startDtm: limitValidation(Yup.string(), GENERIC_ERROR.required),
    time: limitValidation(Yup.string(), GENERIC_ERROR.required),

    crAmt: limitNumberValidation(),
    crCnt: limitNumberMaxValidation(),
    drAmt: limitNumberValidation(),
    drCnt: limitNumberMaxValidation(),
    totAmt: limitNumberValidation(),
    totCnt: limitNumberMaxValidation(),
    violationAct: limitValidation(Yup.string(), GENERIC_ERROR.required),
    feeViolated: limitValidation(Yup.string(), GENERIC_ERROR.required),
    violationFee: Yup.string().when("feeViolated", {
      is: "selectLeft",
      then: (schema) => schema.required(GENERIC_ERROR.required),
    }),
  })
);


//YUP LIBRARY :Defining Schema For validation of Accumulated_Transaction_Limit_Configuration
const limitConfigSchema = async (optionsSchema: PostSchema) => {
  const fields = ["rels"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig) as any;
};
// Exporting Interface
export type {
  AddLimitComponentProps,
  SingleTransactionLimitConfigProps,
  LimitConfigProps,
};
// Exporting Validation Schema and Intial value
export {
  AddLimitConfig,
  limitConfig,
  singleTransactionLimitConfig,
  limitConfigSchema as getValidationSchema,
   limitSchema,
  
};
