// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  CodeBlock: dynamic(() => import("./CodeBlock")),
  CodeSideBySide: dynamic(() => import("./CodeSideBySide")),
  CodeSnippet: dynamic(() => import("./CodeSnippet")),
  CodeWalkthrough: dynamic(() => import("./CodeWalkthrough")),
  Course: dynamic(() => import("./Course")),
  Example: dynamic(() => import("./Example")),
  Excerpt: dynamic(() => import("./Excerpt")),
  FileDownload: dynamic(() => import("./FileDownload")),
  HighlightedTextLegacy: dynamic(() => import("./HighlightedTextLegacy")),
  HighlightedText: dynamic(() => import("./HighlightedTextLegacy")),
  Image: dynamic(() => import("./Image")),
  ImageTextBlock: dynamic(() => import("./ImageTextBlock")),
  ImageWidth: dynamic(() => import("./ImageWidth")),
  Markdown: dynamic(() => import("./Markdown")),
  Paragraphlegacy: dynamic(() => import("./Paragraphlegacy")),
  PostmanCollection: dynamic(() => import("./PostmanCollection")),
  Step: dynamic(() => import("./Step")),
  TAccount: dynamic(() => import("./TAccount")),
  TwoVideosSideBySide: dynamic(() => import("./TwoVideosSideBySide")),
  VideoSeries: dynamic(() => import("./VideoSeries")),
  VimeoEmbed: dynamic(() => import("./VimeoEmbed")),
  academy_share_instructions: dynamic(
    () => import("./AcademyShareInstructions"),
  ),
  api_additional_information: dynamic(
    () => import("./ApiAdditionalInformation"),
  ),
  api_code_selector_widget: dynamic(() => import("./ApiCodeSelectorWidget")),
  api_library_api_info: dynamic(() => import("./ApiLibraryApiInfo")),
  api_library_links: dynamic(() => import("./ApiLibraryLinks")),
  code_snippet_header: dynamic(() => import("./CodeSnippetHeader")),
  code_snippets: dynamic(() => import("./CodeSnippets")),
  content_categories: dynamic(() => import("./ContentCategories")),
  dashboard_section: dynamic(() => import("./DashboardSection")),
  doc_center_topic: dynamic(() => import("./DocCenterTopic")),
  document_center_links: dynamic(() => import("./DocumentCenterLinks")),
  endpoints_list: dynamic(() => import("./EndpointsList")),
  image_with_tooltips: dynamic(() => import("./ImageWithTooltips")),
  learning_links: dynamic(() => import("./LearningLinks")),
  learning_objectives: dynamic(() => import("./LearningObjectives")),
  main_menu_items: dynamic(() => import("./MainMenuItems")),
  menu_links_repeater: dynamic(() => import("./MenuLinksRepeater")),
  navigation_item: dynamic(() => import("./NavigationItem")),
  next_api_reference: dynamic(() => import("./NextApiReference")),
  paragraph: dynamic(() => import("./Paragraph")),
  Paragraph: dynamic(() => import("./Paragraph")),
  resources_links_section: dynamic(() => import("./ResourcesLinksSection")),
  rich_text: dynamic(() => import("./RichText")),
  tool_tip_all: dynamic(() => import("./ToolTipAll")),
  user_stories: dynamic(() => import("./UserStories")),
  video_embeds: dynamic(() => import("./VideoEmbeds")),
};
