import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface ExternalReferencesValueProps {
  finInstAba: string;
  finInstBic: string;
}

const externalReferences: ExternalReferencesValueProps = {
  finInstAba: "",
  finInstBic: "",
};

const externalReferencesSchema = async (optionsSchema: PostSchema) => {
  const fields = ["finInstAba","finInstBic"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { ExternalReferencesValueProps };
// Exporting Validation Schema and Intial value
export { externalReferences, externalReferencesSchema as getValidationSchema };
