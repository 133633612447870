import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema, REGEX_PATTERN } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Accounts
interface AccountsProps {
  acctGroup: number;
  acctTitle: string;
  desc: string;
  acctNbr: string;
  glCat: string;
}

// Defining Intitial Value For Accounts
const accountsData: AccountsProps = {
  acctGroup: -1,
  acctTitle: "",
  desc: "",
  acctNbr: "",
  glCat: "",
};

// YUP LIBRARY :Defining Schema For validation of Accounts
const accountsDataSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "acctGroup",
    "acctTitle",
    "desc",
    "acctNbr",
    "glCat",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountsProps };
// Exporting Validation Schema and Intial value
export { accountsData, accountsDataSchema as getValidationSchema };
