"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  SplitButton,
} from "@/components/common";
import { useToast } from "@/components/ChakraUiManager";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RepaymentAdvancedProps,
  getValidationSchema,
} from "./RepaymentAdvancedComponentOptionsValidation";
import { Box } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { useFormik } from "formik";
import { createRepaymentComponent } from "../../product-config-client-service";
import { PostSchema, RepaymentComponentData } from "../../../model/types";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    indexNameOptions: string[];
    formData: RepaymentComponentData | null;
    repayPaymentOptionsData: PostSchema;
  };
};
const RepaymentAdvancedComponentOptions = (props: any) => {
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const { formData, repayPaymentOptionsData } = props.data;
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.repayPaymentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.repayPaymentOptionsData]);

  // Form Submit Handler
  const onSubmit = async (values: RepaymentAdvancedProps, actions: any) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && productDetails) {
      const res = await createRepaymentComponent({
        productDetails,
        formData: {
          ...formData,
          // PATCH fields
          billCapInt: values.billCapInt,
          isMaturityAutoPmt: values.isMaturityAutoPmt,
          isRtReceivable: values.isRtReceivable,
          isUnapplFunds: values.isUnapplFunds,
        },
        toastOptions: {
          toast,
          toastId: "repayment-advanced-component-options",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      billCapInt: props.data.formData?.billCapInt ?? undefined,
      isMaturityAutoPmt: props.data.formData?.isMaturityAutoPmt ?? undefined,
      isRtReceivable: props.data.formData?.isRtReceivable ?? undefined,
      isUnapplFunds: props.data.formData?.isUnapplFunds ?? undefined,
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]); //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  //Handling Form
  const restrictionFormDetailsHandler =
    (key: string) => (value: SplitBtnInputValType) => {
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Does the component bill include capitalized interest?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("billCapInt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("billCapInt")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.billCapInt}
          />
          {errors.billCapInt && (
            <ErrorAlert>
              <span>{errors.billCapInt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Does the component have an automatic payment upon maturity?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isMaturityAutoPmt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("isMaturityAutoPmt")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isMaturityAutoPmt}
          />
          {errors.isMaturityAutoPmt && (
            <ErrorAlert>
              <span>{errors.isMaturityAutoPmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Will the component be real time receivable?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isRtReceivable")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("isRtReceivable")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isRtReceivable}
          />
          {errors.isRtReceivable && (
            <ErrorAlert>
              <span>{errors.isRtReceivable}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Will the component have unapplied funds?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isUnapplFunds")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("isUnapplFunds")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isUnapplFunds}
          />
          {errors.isUnapplFunds && (
            <ErrorAlert>
              <span>{errors.isUnapplFunds}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default RepaymentAdvancedComponentOptions;
