"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  AppNumberInput,
  TimeField,
  DurationInput,
} from "@/components/common";
import { useContext, useEffect, useState } from "react";
import {
  CutoffsLimitsThresholdsProps,
  getValidationSchema,
} from "./CutoffsLimitsThresholdsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: FinancialInstitutionData | null;
    bankParamOptionsData: PostSchema;
  };
};
const CutoffsLimitsThresholds = ({ data }: Props) => {
  const { bankParamOptionsData } = data;
  const toast = useToast();
  const onSubmit = async (
    values: CutoffsLimitsThresholdsProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "bank-params-cutoffs-limit-thresholds",
    };
    response = await updateBankParameters({
      formData: {
        _vn: data.formData?._vn,
        _Id: data.formData?._Id,
        // PATCH Fields
        finCutOffTm: values.finCutOffTm,
        maxExtAcct: Number(values.maxExtAcct),
        repeatODDuration: values.repeatODDuration,
        largeOdLimit: Number(values.largeOdLimit),
        taxDistThld: Number(values.taxDistThld),
        taxIntThld: Number(values.taxIntThld),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      navigateToNextPage?.();
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      finCutOffTm: data.formData?.finCutOffTm ?? "",
      maxExtAcct: data.formData?.maxExtAcct ?? "",
      largeOdLimit: data.formData?.largeOdLimit ?? "",
      repeatODDuration: data.formData?.repeatODDuration ?? "",
      taxIntThld: data.formData?.taxIntThld ?? "",
      taxDistThld: data.formData?.taxDistThld ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the financial cutoff time?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("finCutOffTm")
                ?.tooltip_text_main
            }
            labelFor="financialCutoffTime_input"
          />
          <TimeField
            value={values.finCutOffTm}
            onChange={(value: string) => setFieldValue("finCutOffTm", value)}
            onBlur={handleBlur}
            ariaLabel="finCutOffTm"
          />
          {touched.finCutOffTm && errors.finCutOffTm && (
            <ErrorAlert>
              <span>{errors.finCutOffTm}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the maximum allowable number of external accounts a customer can link?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxExtAcct")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.maxExtAcct}
            onChange={handleOnChange("maxExtAcct")}
            onBlur={handleBlur}
            label="maxExtAcct"
          />
          {touched.maxExtAcct && errors.maxExtAcct && (
            <ErrorAlert>
              <span>{errors.maxExtAcct}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the large overdraft limit?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("largeOdLimit")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.largeOdLimit}
            onChange={handleOnChange("largeOdLimit")}
            onBlur={handleBlur}
            label="largeOdLimit"
          />
          {touched.largeOdLimit && errors.largeOdLimit && (
            <ErrorAlert>
              <span>{errors.largeOdLimit}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <DurationInput
            labelName={"What is the repeat overdraft duration?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("repeatODDuration")
                ?.tooltip_text_main
            }
            value={values.repeatODDuration}
            onChange={handleOnChange("repeatODDuration")}
            modalLinkName={"Enter code manually"}
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("repeatODDuration")
                ?.tooltip_text_main
            }
            modalTitle={"Enter repeat overdraft duration"}
            modalLabelName={"What is the repeat overdraft duration?"}
          />
          {touched.repeatODDuration && errors.repeatODDuration && (
            <ErrorAlert>
              <span>{errors.repeatODDuration}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the tax reporting interest threshold?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("taxIntThld")
                ?.tooltip_text_main
            }
            valuePrefix="$"
            value={values.taxIntThld}
            onChange={handleOnChange("taxIntThld")}
            onBlur={handleBlur}
            label="taxIntThld"
          />
          {touched.taxIntThld && errors.taxIntThld && (
            <ErrorAlert>
              <span>{errors.taxIntThld}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the tax reporting posted distribution threshold?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("taxDistThld")
                ?.tooltip_text_main
            }
            valuePrefix="$"
            value={values.taxDistThld}
            onChange={handleOnChange("taxDistThld")}
            onBlur={handleBlur}
            label="taxDistThld"
          />
          {touched.taxDistThld && errors.taxDistThld && (
            <ErrorAlert>
              <span>{errors.taxDistThld}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default CutoffsLimitsThresholds;
