"use client";
import { TEAM_TABLE_COLUMN_REF } from "@/data/access-management-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { NAME_KEY, TAB_NAMES } from "@/utils";

import TabComponent from "../tab-component/TabComponent";

interface TeamTabProps {
  tableData: any;
}

const TeamTab = (props: TeamTabProps) => {
  const { tableData } = props;
  return (
    <TabComponent
      heading="Team"
      subHeading="All your team members at a glance"
      createButtonLink={`${ROUTE_PATH.AM_TEAM}?tab=${TAB_NAMES.team}`}
      createButtonName="Add user"
      tableData={tableData}
      tableColumns={TEAM_TABLE_COLUMN_REF}
      removeItemKey={NAME_KEY}
      editButtonLink={ROUTE_PATH.AM_TEAM}
    />
  );
};

export default TeamTab;
