import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import * as Yup from "yup";
//Defining Interface For Lending_Party_Email
interface SortModuleProps {
  emailType: number | undefined;
  data: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  fromDate: Date | null;
  fromTime: string;
  throughDate: Date | null;
  throughTime: string;
  verifiedDate: Date | null;
  verifiedTime: string;
}
interface LendingPartyEmailProps {
  businessLending: SplitBtnInputValType;
  name: string;
  emails: SortModuleProps[];
}
const lpaSortModuleValue: SortModuleProps = {
  emailType: undefined,
  data: "",
  label: "",
  isPreferred: undefined,
  fromDate: null,
  fromTime: "",
  throughDate: null,
  throughTime: "",
  verifiedDate: null,
  verifiedTime: "",
};

const PartyAddSchema = async (optionsSchema: PostSchema) => {
  const fields = ["bankparamUSBankInfo"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig) as any;
};
// Exporting Interface
export type { LendingPartyEmailProps, SortModuleProps };
// Exporting Validation Schema and Intial value
export { lpaSortModuleValue, PartyAddSchema as getValidationSchema };
