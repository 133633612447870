"use client";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import Image from "next/image";
import { expandDownBlack, expandUpIcon, solidRightArrow } from "public/assets";
import React from "react";
import "./MainContentWithoutStep.scss";
import { PrismicRichText } from "@prismicio/react";
import { CardContainer, HoverToolTip } from "@/components/common";
import { ROUTE_PATH } from "@/route-config/route-path";
import { DashboardSectionSliceDefaultItem } from "../model";
import Link from "next/link";
import type * as prismic from "@prismicio/client";
import { buildURL } from "@/prismic-api/common-service";
interface MainContentWithoutStepProps {
  btnLink?: boolean;
  btnName?: string;
  title?: string;
  desc?: string;
  overviewHeader?: string;
  overview?: prismic.RichTextField;
  helpfullRes?: DashboardSectionSliceDefaultItem[];
  btnDisabled?: boolean;
  noBtn?: boolean;
  onClick?: (e: any) => void;
  isLastItem?: boolean;
  toolTip?: string | null
}

const MainContentWithoutStep = (props: MainContentWithoutStepProps) => {
  return (
    <Box className="dashboard-main-contents">
      <Accordion className="dashboard-accordion-components" allowToggle={true}>
        <AccordionItem className="accordion-item">
          {({ isExpanded }) => (
            <Box
              className={`accordion-container
                  ${
                    isExpanded
                      ? `accordion-container-active ${props.isLastItem ? "active-last-item" : ""}`
                      : ""
                  }`}
            >
              <AccordionButton
                as="div"
                className={`accordion-button ${props.isLastItem ? "last-item" : ""}`}
              >
                <Flex>
                  <Box className="accordion-description-container">
                    <Text className={`accordion-title`}>{props.title}</Text>
                    <Text className="accordion-limit-name">{props.desc}</Text>
                  </Box>
                </Flex>
                <Flex className="accordion-button-container">
                {!props.noBtn && props.btnLink && (
                  props.toolTip ? (
                    <HoverToolTip label={props.toolTip} placement={"top"}>
                      <Button
                        onClick={props.onClick}
                        className="app-btn-reg-secondary accordion-title-button"
                        isDisabled={props.btnDisabled}
                      >
                        {props.btnName}
                      </Button>
                    </HoverToolTip>
                  ) : (
                    <Button
                      onClick={props.onClick}
                      className="app-btn-reg-secondary accordion-title-button"
                      isDisabled={props.btnDisabled}
                    >
                      {props.btnName}
                    </Button>
                  )
                )}
                  {isExpanded ? (
                    <Image src={expandUpIcon} alt="expand up icon" />
                  ) : (
                    <Image src={expandDownBlack} alt="expand down icon" />
                  )}
                </Flex>
              </AccordionButton>
              <AccordionPanel as={"div"} className="accordion-panel">
                <Text className="resource-heading">{props.overviewHeader}</Text>
                <Box className="recent-products-text">
                  <PrismicRichText field={props.overview} />
                </Box>
                {props.helpfullRes && props.helpfullRes.length > 0 ? (
                  <Text className="helpful-resource-heading">
                    Helpful resources to get started
                  </Text>
                ) : (
                  ""
                )}
                {props.helpfullRes &&
                  props.helpfullRes.map((data: any, index: number) => (
                    <CardContainer
                      key={index}
                      customClass="resource-containers"
                    >
                      <div>
                        <Text className="resource-title">
                          {data.link_title}
                        </Text>
                        <Text className="resource-caption">
                          {data.link_caption}
                        </Text>
                      </div>
                      <div>
                        {data.link_type === "prismic link" ? (
                          <Link
                            href={buildURL(
                              data?.prismic_link?.type,
                              data?.prismic_link?.uid
                            )}
                          >
                            <Image src={solidRightArrow} alt="Right arrow" />
                          </Link>
                        ) : data.link_type === "academy" ? (
                          <Link
                            href={
                              `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                              `${data?.academy_course_link}`
                            }
                          >
                            <Image src={solidRightArrow} alt="Right arrow" />
                          </Link>
                        ) : (
                          <a
                            href={
                              data?.link?.link_type === "Document"
                                ? buildURL(data?.link?.type, data?.link?.uid)
                                : data?.link?.url
                            }
                          >
                            <Image src={solidRightArrow} alt="Right arrow" />
                          </a>
                        )}
                      </div>
                    </CardContainer>
                  ))}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default MainContentWithoutStep;
