"use client";
import {
  Box,
  Divider,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
} from "@chakra-ui/react";
import BoxInterstial from "../box-interstial/BoxInterstial";
import styles from "./StepperInterstial.module.scss";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Image from "next/image";
import {
  completeInterstial,
  activeInterstial,
  expandDownIcon,
} from "public/assets";

type stepItem = {
  title: string;
  description: string;
  addNewEntity: string;
  addNewHref: string;
};

type StepperProps = {
  steps: stepItem[];
  activeIndex: number;
  totalCount: number;
  orientation?: string;
};

const StepperInterstial = ({
  steps,
  activeIndex,
  orientation = "vertical",
  totalCount,
}: StepperProps) => {
  return (
    <Stepper
      size="lg"
      colorScheme="facebook"
      index={activeIndex}
      orientation="vertical"
    >
      {steps.map((step, index) => (
        <Step
          key={index}
          className={`${styles["step-interstial"]}
            ${index === activeIndex - 1 ? styles["active-step-interstial"] : ""}`}
        >
          <BoxInterstial activeIndex={index === activeIndex}>
            <StepIndicator>
              <StepStatus
                complete={
                  <Image src={completeInterstial} alt={"step-completed"} />
                }
                active={
                  <Image
                    src={activeInterstial}
                    alt={"step-active"}
                    className={styles["image-active-interstial"]}
                  />
                }
              />
            </StepIndicator>

            <Box flexShrink="0" w="90%">
              <h4 className={styles["title-heading"]}>{step.title}</h4>
              <p className={styles["description"]}>{step.description}</p>
              {step?.addNewEntity !== "" &&
              step?.addNewEntity !== undefined &&
              index < activeIndex &&
              index !== 5 ? (
                <Box mt={5}>
                  <a href={step.addNewHref} className="app-btn-link">
                    + {step.addNewEntity}
                  </a>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </BoxInterstial>
          {index === activeIndex - 1 && index < totalCount - 1 ? (
            <Box className={styles["divider-interstial-with-text"]}>
              <Box className={styles["divider-interstial-down-arrow"]}>
                <Divider
                  orientation="vertical"
                  className={styles["divider-step-active-interstial"]}
                />
                <Image
                  className={styles["arrow-with-divider-interstial"]}
                  src={expandDownIcon}
                  alt="expand down icon"
                />
              </Box>

              <p className={styles["divider-text"]}>UP NEXT...</p>
            </Box>
          ) : (
            <StepSeparator
              className={
                orientation === "vertical" ? styles["stepper-intertial"] : ""
              }
            />
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperInterstial;
