import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Asset_Financial_Specification
interface FinancialSpecificationProps {
  maxPledge: string;
}

// Defining Intitial Value For Asset_Financial_Specification
const financialSpecification: FinancialSpecificationProps = {
  maxPledge: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Asset_Financial_Specification
const financialSpecificationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["maxPledge"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { FinancialSpecificationProps };

// Exporting Validation Schema and Intial value
export {
  financialSpecification,
  financialSpecificationSchema as getValidationSchema,
};
