import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import * as Yup from "yup";
import { generateYupSchema } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Negative_Limit_Details
interface NegativeLimitDetailsProps {
  negLimitOpt: string | undefined;
  negLimitMatrix: string;
  negLimitFreq: string;
  negLimitMin: string;
  negLimitMax: string;
  negLimit: string;
  negLimitStartOpt: number | undefined;
  negLimitOpenDur: string;
  isNegLimitOptIn: SplitBtnInputValType;
  isAutoAuth: SplitBtnInputValType;
  isWaiveNsfFee: SplitBtnInputValType;
  isNegLimitNsfFee: SplitBtnInputValType;
  negBalAlertDays: string;
  nsfDrRestrictDays: string;
  nsfDrRestrict: string;
  nsfDrRestrictPosBalDays: string;
}
// Defining Intitial Value For Negative_Limit_Details
const negativeLimitDetails: NegativeLimitDetailsProps = {
  negLimitOpt: "",
  negLimitMatrix: "",
  negLimitFreq: "",
  negLimitMin: "",
  negLimitMax: "",
  negLimit: "",
  negLimitStartOpt: undefined,
  negLimitOpenDur: "",
  isNegLimitOptIn: undefined,
  isAutoAuth: undefined,
  isWaiveNsfFee: undefined,
  isNegLimitNsfFee: undefined,
  negBalAlertDays: "",
  nsfDrRestrictDays: "",
  nsfDrRestrict: "",
  nsfDrRestrictPosBalDays: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Negative_Limit_Details
const negativeLimitSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "negLimitMatrix",
    "negLimitFreq",
    "negLimitMax",
    "negLimitMin",
    "negLimit",
    "negLimitStartOpt",
    "negLimitOpenDur",
    "isNegLimitOptIn",
    "isAutoAuth",
    "isWaiveNsfFee",
    "isNegLimitNsfFee",
    "negBalAlertDays",
    "nsfDrRestrictDays",
    "nsfDrRestrict",
    "nsfDrRestrictPosBalDays",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { NegativeLimitDetailsProps };
// Exporting Validation Schema and Intial value
export { negativeLimitDetails, negativeLimitSchema as getValidationSchema };
