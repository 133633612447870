"use client";

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SearchIcon,
  Text,
} from "@/components/ChakraUiManager";
import { AppModal } from "../..";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./ModalSelectorV1.scss";
import { modalSelectorBtnProps } from "@/components/data/global-config";
import { Option } from "@/utils";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";

export type ModalSelectorV1Prop<T> = {
  value: T;
  linkName: string;
  modalTitle: string;
  optionsTitle?: string;
  optionsDescTitle?: string;
  optionList: Option<string | number>[];
  isSingleSelect?: boolean;
  isClearable?: boolean;
  showSelection?: "description" | "value" | "value - description";
  showDashedBorder?: boolean;
  savePreferences: any;
};

// search options functionality is TBD
export default function ModalSelectorV1<T extends string | string[]>(
  props: ModalSelectorV1Prop<T>
) {
  const {
    value,
    linkName,
    modalTitle,
    optionsTitle,
    optionsDescTitle,
    optionList,
    isSingleSelect = true,
    isClearable = true,
    showSelection = "value",
    showDashedBorder = true,
    savePreferences,
  } = props;

  const modalRef = useRef<any>(); //modal ref to open/close the modal
  const [filterText, setFilterText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(value);
  const envIsDisabled = useSelector(getEnvIsDisable);

  // sync internal state when value changes
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  // Get option from given value
  const getOption = useCallback(
    (val: string) => {
      return optionList.find((opt) => opt.value === val);
    },
    [optionList]
  );

  const tagLabelValue = (val: string) => {
    const option = getOption(val);
    if (option) {
      switch (showSelection) {
        case "description":
          return option.label;
        case "value - description":
          return `${option.value} - ${option.label}`;
        case "value":
          return option.value;
        default:
          return option.value;
      }
    }
    return "";
  };

  function showModal() {
    setTimeout(() => {
      modalRef?.current?.openModal();
    });
  }

  const removeSelectedOption = (removedOption: string) => () => {
    if (!Array.isArray(value)) {
      setSelectedOptions("" as T);
    } else {
      const updateValues = value.filter((val) => val !== removedOption);
      setSelectedOptions(updateValues as T);
    }
  };

  // clear all
  const clearAll = () => {
    if (!Array.isArray(value)) {
      setSelectedOptions("" as T);
    } else {
      setSelectedOptions([] as string[] as T);
    }
  };

  // Save and close modal
  const onSave = async () => {
    await savePreferences(selectedOptions);
    setFilterText("");
    modalRef?.current?.closeModal();
  };

  // reset internal selection state
  const onCancel = () => {
    setSelectedOptions(value);
  };

  const _handleSelect = (selectedValue: string) => (e: any) => {
    e.preventDefault();
    setSelectedOptions((oldValue) => {
      if (!Array.isArray(oldValue)) {
        return selectedValue as T;
      }
      if (oldValue.includes(selectedValue)) {
        return oldValue.filter((val) => val !== selectedValue) as T;
      }
      return [...oldValue, selectedValue] as T;
    });
  };

  const filteredOptions = useMemo(() => {
    return optionList.filter((option) => {
      return (
        option.label?.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        option.value
          .toString()
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) > -1
      );
    });
  }, [optionList, filterText]);

  const isOptionSelected = useCallback(
    (option: Option<string | number>) => {
      if (!Array.isArray(selectedOptions)) {
        return selectedOptions === option.value;
      }
      return selectedOptions.includes(option.value as string);
    },
    [selectedOptions]
  );

  const selectedValue =
    typeof value === "string" || typeof value === "number"
      ? value !== ""
        ? [value]
        : []
      : (value as string[]);

  return (
    <Box className="modal-selector-container">
      <Box>
        <Button
          className="app-btn-reg-secondary-transparent"
          isDisabled={envIsDisabled}
          onClick={showModal}
        >
          {linkName}
        </Button>
      </Box>
      <AppModal
        customClass="app-modal-selector"
        ref={modalRef}
        modalTitle={modalTitle}
        {...modalSelectorBtnProps}
        primaryBtnSelect={onSave}
        secondaryBtnSelect={onCancel}
      >
        <Box>
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </InputGroup>
          </Box>
          <Flex className="modal-selector-option-flex">
            <Text className="modal-selector-option" fontWeight={700}>
              {optionsTitle || "Options"}
            </Text>
            <Text className="modal-selector-description" fontWeight={700}>
              {optionsDescTitle || "Description"}
            </Text>
          </Flex>
          <Box height="50vh" overflow="auto">
            {filteredOptions.length ? (
              filteredOptions.map((option) => (
                <Flex
                  key={option.value}
                  className={`modal-selector-option-flex ${
                    isOptionSelected(option) ? "option-selected" : ""
                  }`}
                  onClick={_handleSelect(option.value as string)}
                >
                  <Text className="modal-selector-option">
                    {isSingleSelect ? (
                      option.value
                    ) : (
                      <Checkbox isChecked={isOptionSelected(option)}>
                        {option.value}
                      </Checkbox>
                    )}
                  </Text>
                  <Text className="modal-selector-description">
                    {option.label}
                  </Text>
                </Flex>
              ))
            ) : (
              <Center mt={10}>No Results Found.</Center>
            )}
          </Box>
        </Box>
        <Box mt={5} className="modal-selector-selected-options">
          {selectedOptions.length ? (
            <Flex className="modal-selector-edit-btn-group">
              {isClearable && (
                <Button className="app-btn-link" onClick={clearAll}>
                  Clear All
                </Button>
              )}
            </Flex>
          ) : null}
        </Box>
      </AppModal>
    </Box>
  );
}
