import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";
//Defining Interface For Index_Rate_Foundation_Details
interface RepaymentAdvancedProps {
  billCapInt: SplitBtnInputValType;
  isMaturityAutoPmt: SplitBtnInputValType;
  isRtReceivable: SplitBtnInputValType;
  isUnapplFunds: SplitBtnInputValType;
}
// Defining Intitial Value For Index_Rate_Foundation_Details
const repaymentAdvanced: RepaymentAdvancedProps = {
  billCapInt: undefined,
  isMaturityAutoPmt: undefined,
  isRtReceivable: undefined,
  isUnapplFunds: undefined,
};
// YUP LIBRARY :Defining Schema For validation of Index_Rate_Foundation_Details
const repaymentAdvancedSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "billCapInt",
    "isMaturityAutoPmt",
    "isRtReceivable",
    "isUnapplFunds",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RepaymentAdvancedProps };
// Exporting Validation Schema and Intial value
export { repaymentAdvanced, repaymentAdvancedSchema as getValidationSchema };
