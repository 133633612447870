"use client";

import React, { useRef, useState } from "react";
import styles from "./RequestClassroom.module.scss";
import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import { AppFormLabel, AppModal, InputText } from "@/components/common";
import { HeaderData } from "@/models/academy-models";
import { useUserInfo } from "@/app/providers";
import { createFastRequest } from "@/api-config/api-service";

const RequestClassroom = ({
  headerData,
}: {
  headerData: HeaderData;
}): JSX.Element => {
  const modalRef = useRef<any>();
  const userInfo = useUserInfo()?.user;
  const [message, setMessage] = useState<string>();

  function showModal() {
    if (modalRef?.current) {
      setMessage("");
      modalRef.current.openModal();
    }
  }
  const toast = useToast();
  const toastId = "request-finxact";

  const submitRequest = async () => {
    if (!message) return;

    const commonToastOptions = {
      toast,
      toastId,
    };
    await createFastRequest({
      body: {
        message: message,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "Request sent successfully",
      },
      type: "classroom",
    }).then((res) => {
      modalRef.current.closeModal();
    });
  };

  const handleOnclose = (actions: any) => {
    setMessage("");
  };

  return (
    <>
      <Box className={styles["frame"]}>
        <Box className="box-group">
          <Text as="h3" className={styles["requestclassroom-title"]}>
            {headerData?.headline || "Request a classroom session"}
          </Text>
          <Text as="p" className={styles["requestclassroom-subText"]}>
            {headerData?.subhead || "Lorem ipsum dolor sit amet"}
          </Text>
        </Box>
        <Button
          className="app-btn-reg-secondary-transparent"
          onClick={showModal}
        >
          Request
        </Button>
      </Box>

      {/* Modal component with props for open/close */}
      <AppModal
        customClass="academy-modal-selector"
        ref={modalRef}
        modalTitle={"Request a customized classroom session"}
        description={headerData?.subhead || "Lorem ipsum dolor sit amet"}
        primaryBtnSelect={submitRequest}
        secondaryBtnSelect={handleOnclose}
        primaryBtnProp={{
          name: "Submit request",
          btnClassName: `app-btn-inverse-secondary ${styles["requestclassroom-submit-btn"]}`,
        }}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-link",
          style: { marginRight: "1rem" },
        }}
        primaryBtnDisabled={!message}
      >
        <Box className="box-group">
          <Text as="p" mb={5} fontSize={16} pr={2}>
            Request an instructor-led private training for your institution,
            providing focused expertise and valuable understanding for your
            unique objectives.
          </Text>

          <form>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="First name"
                isRequired={true}
                labelFor="firstName"
              />
              <InputText id="firstName" value={userInfo.firstName} disabled />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Last name"
                isRequired={true}
                labelFor="lastName"
              />
              <InputText id="lastName" value={userInfo.lastName} disabled />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Email"
                isRequired={true}
                labelFor="email"
              />
              <InputText id="email" value={userInfo.email} disabled />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Enter request message"
                labelFor="message"
              />
              <InputText
                id="message"
                onChange={setMessage}
                textarea
                value={message}
              />
            </Box>
          </form>
        </Box>
        <Divider />
      </AppModal>
    </>
  );
};

export default RequestClassroom;
