import { Box, CardBody, Heading, Text } from "@chakra-ui/react";
import { HoverToolTip, StaticText } from "@/components/common";
import Link from "next/link";
import ProductSummaryFeatureDetailsStyle from "./ProductSummaryFeatureDetailsCard.module.scss";

import StatusBubble from "@/components/common/status-bubble/StatusBubble";
import {
  QUERY_PARAM_KEY,
  ROUTE_BASE_URL,
  ROUTE_PATH,
} from "@/route-config/route-path";
import {
  PROD_SMMRY_LIST_ITEM_ZERO_STATE,
  PROD_SUMM_MANUALLY_ADDED_KEY_COMPONENT,
} from "@/utils";
import { ProductSummaryFeatureFields } from "../../product-summary-config";
import CustomEditIcon from "@/components/common/icons/EditIcon";
type FeatureDetailsCardProps = {
  name: string;
  fields: { label: string; value: string }[];
  status: string;
  link: string;
  currProductId: string;
  componentName: string;
  manuallyAddedFields?: Record<string, ProductSummaryFeatureFields[]>;
};

const ProductSummaryFeatureDetailsCard = (props: FeatureDetailsCardProps) => {
  const {
    name,
    fields,
    status,
    link,
    currProductId,
    componentName,
    manuallyAddedFields,
  } = props;

  function ManualListFeatureCard({
    field,
    keyProp,
  }: {
    keyProp: string;
    field: ProductSummaryFeatureFields;
  }) {
    return (
      <Box
        key={keyProp}
        className={ProductSummaryFeatureDetailsStyle["options-wrapper"]}
      >
        <Heading
          className={ProductSummaryFeatureDetailsStyle["heading-container"]}
        >
          {field.label}:
        </Heading>
        <StaticText
          className={ProductSummaryFeatureDetailsStyle["value-container"]}
          textValue={`${field.value}`}
        />
      </Box>
    );
  }

  return (
    <CardBody
      className={
        ProductSummaryFeatureDetailsStyle["feature-details-card-container"]
      }
    >
      <StatusBubble
        status={status}
        iconWidth={16}
        className={
          ProductSummaryFeatureDetailsStyle["feature-details-card-status-icon"]
        }
      />
      <Box
        className={
          ProductSummaryFeatureDetailsStyle[
            "feature-details-card-title-container"
          ]
        }
      >
        <Text
          className={
            ProductSummaryFeatureDetailsStyle["feature-details-card-title"]
          }
        >
          {name}
        </Text>
        {componentName && (
          <Link
            href={`${ROUTE_BASE_URL["DASHBOARD_PATH"]}${link}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${currProductId}`}
          >
            <Box className={ProductSummaryFeatureDetailsStyle["edit-icon"]}>
              <HoverToolTip placement="top" label="Edit component details">
                <CustomEditIcon boxSize={8} />
              </HoverToolTip>
            </Box>
          </Link>
        )}
      </Box>
      <Box>
        {!PROD_SUMM_MANUALLY_ADDED_KEY_COMPONENT.includes(name) ? (
          fields?.map((field, index) => (
            <Box
              key={index}
              className={
                ProductSummaryFeatureDetailsStyle[
                  "feature-details-options-container"
                ]
              }
            >
              <Text>
                <span
                  className={
                    ProductSummaryFeatureDetailsStyle["feature-details-heading"]
                  }
                >
                  {field.label}
                </span>
                :
                <span
                  className={
                    ProductSummaryFeatureDetailsStyle["feature-details-value"]
                  }
                >
                  {" " + field.value}
                </span>
              </Text>
            </Box>
          ))
        ) : (
          <Box
            className={
              ProductSummaryFeatureDetailsStyle[
                "feature-details-manual-field-container-wrapper"
              ]
            }
          >
            {manuallyAddedFields ? (
              Object.keys(manuallyAddedFields).map((key, index) => {
                return (
                  <Box
                    key={index}
                    className={
                      ProductSummaryFeatureDetailsStyle[
                        "feature-details-manual-field-container"
                      ]
                    }
                  >
                    <Box>
                      <Heading
                        className={`${ProductSummaryFeatureDetailsStyle["main-heading"]}`}
                      >
                        {key}
                      </Heading>
                      {manuallyAddedFields?.[key].map((field, index) => (
                        <>
                          {field?.subFields?.length ? (
                            (field?.subFields as any).map(
                              (item: any, subIndex: number) => (
                                <>
                                  <Heading
                                    className={`${ProductSummaryFeatureDetailsStyle["main-heading"]} ${ProductSummaryFeatureDetailsStyle["sub-heading"]}`}
                                  >
                                    {`${field.label} ${subIndex + 1}`}
                                  </Heading>
                                  <ManualListFeatureCard
                                    field={item}
                                    keyProp={`subField${subIndex}`}
                                  />
                                </>
                              )
                            )
                          ) : (
                            <ManualListFeatureCard
                              field={field}
                              keyProp={`field${index}`}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                className={
                  ProductSummaryFeatureDetailsStyle[
                    "feature-details-manual-field-container"
                  ]
                }
              >
                <Heading
                  className={ProductSummaryFeatureDetailsStyle["main-heading"]}
                >
                  {PROD_SMMRY_LIST_ITEM_ZERO_STATE[name]}
                </Heading>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </CardBody>
  );
};

export default ProductSummaryFeatureDetailsCard;
