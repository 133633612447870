import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const baseUrl = '/console';

export async function fetchCloverCategories() {
  try {
    const response = await axios.get(`${baseUrl}/clover-fetch-categories`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover categories:', error);
    toast.error('Failed to fetch Clover categories');
    throw error;
  }
}

export async function fetchItemsByCategory(categoryId: string) {
  try {
    const response = await axios.get(`${baseUrl}/clover-items-by-category/${categoryId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover items by category:', error);
    toast.error('Failed to fetch Clover items by category');
    throw error;
  }
}

export async function fetchCloverItem(itemId: string) {
  try {
    const response = await axios.get(`${baseUrl}/clover-fetch-item/${itemId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover item:', error);
    toast.error('Failed to fetch Clover item');
    throw error;
  }
}

export async function fetchCloverOrderDetails(orderId: string) {
  try {
    const response = await axios.get(`${baseUrl}/clover-order-details/${orderId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover order details:', error);
    toast.error('Failed to fetch Clover order details');
    throw error;
  }
}

interface LineItem {
  note: string;
  name: string;
  price: number;
  unitQty: number;
}

export type CheckoutDetails = {
  customer: {
    firstName: string;
    lastName: string;
    email: string;
  };
  redirectUrls: {
    success: string;
    failure: string;
    cancel: string;
  };
  shoppingCart: {
    lineItems: LineItem[];
  };
};


export async function startCloverCheckout(details: CheckoutDetails) {
  try {
    const payload = {
      customer: {
        firstName: details.customer.firstName,
        lastName: details.customer.lastName,
        email: details.customer.email,
      },
      redirectUrls: {
        success: details.redirectUrls.success,
        failure: details.redirectUrls.failure,
        cancel: details.redirectUrls.cancel,
      },
      shoppingCart: {
        lineItems: details.shoppingCart.lineItems.map(item => ({
          name: item.name,
          price: item.price,
          unitQty: item.unitQty,
        })),
      },
    };

    const response = await axios.post(`${baseUrl}/clover-start-checkout-for-url`, payload, {
      withCredentials: true,
    });
    return response.data.redirectURL; 
  } catch (error) {
    console.error('Error during Clover checkout:', error);
    toast.error('Clover checkout failed');
    throw error;
  }
}

interface PostChargeRequest {
  ecomind: string;
  amount: number;
  currency: string;
  source: string;
  courseId: string;
}


export async function postCloverCharge(chargeDetails: PostChargeRequest) {
  try {
    const response = await axios.post(`${baseUrl}/clover-post-charge`, chargeDetails, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting Clover charge:', error);
    toast.error('Failed to post Clover charge');
    throw error;
  }
}

export function verifyPaymentStatus(status: string, id: string) {
  if (status === "Already Paid" || status === "Paid" || status === "succeeded" && id)
    return true;
  else 
    return false;
}

export async function fetchCloverChargeDetails(chargeId: string) {
  try {
    const response = await axios.get(`${baseUrl}/clover-charge-details/${chargeId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover Charge details:', error);
    toast.error('Failed to fetch Clover Charge details');
    throw error;
  }
}

export async function fetchCloverConfigurations() {
  try {
    const response = await axios.get(`${baseUrl}/clover-configurations`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Clover Charge details:', error);
    toast.error('Failed to fetch Clover Charge details');
    throw error;
  }
}


