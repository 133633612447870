"use client";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
} from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CoupleRadioWithDropdown,
  ErrorAlert,
  InputText,
  ModalSelector,
  RadioButtonGroup,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  DATE_FORMAT,
  IC_ENTITY,
  MAIN_FORM_ID,
  TIME_FORMAT,
  RjsfData,
  deepCopy,
  formatTime,
  preventMainFormSubmitOnRJSFSubmit,
  shouldUpdateWorkflowStatus,
  Option,
} from "@/utils";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  AddLimitComponentProps,
  AddLimitConfig,
  LimitConfigProps,
  SingleTransactionLimitConfigProps,
  getValidationSchema,
  limitConfig,
  singleTransactionLimitConfig,
} from "./AddLimitValidation";

import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import moment from "moment";
import { updateAccountType } from "../../ic-config-api-service";
import "./AddLimit.scss";
import { AccumulatedTransactionLimitForm } from "@/components";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import {
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
} from "@finxact/finxact-shared-ui";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { constructRelData } from "../../ic-utils-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";

type Props = {
  data: {
    definedByOptions: Option<string>[];
    accumulationGroupOptions: string[];
    violationActionOptions: Option<string>[];
    feeCalcDtlOptions: string[];
    transactionCodes: Option<string>[];
    minimumBalanceOption: Option<string>[];
    accumTrnLimit: Option<string>[];
    singleTrnLimit: Option<string>[];
    feeCalcDtlSchema: OptionsSchema;
    addFeeCalcDtlEndpoint: string;
    addNewTransactionStatOptionEndpoint: string;
    addNewTransactionSchema: OptionsSchema;
    acctTypeOptionsData: PostSchema;
    formData: any;
  };
};

const AddLimit = (props: Props) => {
  const RadioSingleBtnProps = [
    {
      label: "Leverage existing single transaction limit",
      value: "leverage_existing",
    },
    {
      label: "Add new single transaction limit",
      value: "add_new",
    },
  ];

  const toast = useToast();
  const toastId = "account-type-rel-found-det";
  const activeAccordionRef = useRef<any>();
  const refAccumulatedTransaction = useRef<null | HTMLDivElement>(null);
  const refSingleTransaction = useRef<null | HTMLDivElement>(null);
  const activeSingleAccordionRef = useRef<any>();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    getCurrentStageStatusName,
    navigateToNextPage,
    getQueryParam,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX)
    ? Number(getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX))
    : 0;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.acctTypeOptionsData
      );

      setValidationSchema(
        yupSchema.fields.rels.innerType.fields.limit.innerType
      );
    })();
  }, [props.data.acctTypeOptionsData]);

  const onSubmit = async (values: AddLimitComponentProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctType!,
          stage: IC_ENTITY.account_type,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const limitsData = constructLimitData(values);
    const relData: any = constructRelData(
      props.data.formData,
      limitsData,
      currEntriesIndex
    );

    const response = await updateAccountType({
      formData: {
        _vn: props.data.formData?._vn,
        acctType: props.data.formData?.acctType,
        rels: relData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  function constructLimitData(values: AddLimitComponentProps) {
    return {
      limit: !values.addLimitComponent
        ? []
        : [
            {
              accumTrnLimits: values.accumTrnLimits.map((limit: any) => ({
                _Id:
                  limit.selectLimitType === "leverage_existing"
                    ? limit._Id
                    : "",
                crAmt:
                  limit.selectLimitType === "add_new"
                    ? parseFloat(limit.crAmt)
                    : null,
                crCnt:
                  limit.selectLimitType === "add_new"
                    ? parseInt(limit.crCnt)
                    : null,
                definedBy:
                  limit.selectLimitType === "add_new" ? limit.definedBy : null,
                drAmt:
                  limit.selectLimitType === "add_new"
                    ? parseFloat(limit.drAmt)
                    : null,
                drCnt:
                  limit.selectLimitType === "add_new"
                    ? parseInt(limit.drCnt)
                    : null,
                name: limit.selectLimitType === "add_new" ? limit.name : null,
                startDtm:
                  limit.selectLimitType === "add_new" &&
                  values.accumTrnLimits[0].startDtm !== ""
                    ? `${moment(values.accumTrnLimits[0].startDtm).format(
                        DATE_FORMAT.international
                      )}T${values.accumTrnLimits[0].time}Z`
                    : null,
                statGroup:
                  limit.selectLimitType === "add_new" ? limit.statGroup : null,
                totAmt:
                  limit.selectLimitType === "add_new"
                    ? parseFloat(limit.totAmt)
                    : null,
                totCnt:
                  limit.selectLimitType === "add_new"
                    ? parseInt(limit.totCnt)
                    : null,
                violationAct:
                  limit.selectLimitType === "add_new"
                    ? limit.violationAct
                    : null,
                violationFee:
                  limit.selectLimitType === "add_new"
                    ? limit.violationFee
                    : null,
              })),
              perTrnLimits: values.perTrnLimits.map((limit: any) => ({
                _Id:
                  limit.singlefee === "leverage_existing"
                    ? limit.singleTransactionLimit
                    : "",
                definedBy:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitdefinedBy)
                    : null,
                increCrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitcreditAmount)
                    : null,
                increDrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitdebitAmount)
                    : null,
                maxBal:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitMaxBalance)
                    : null,
                maxCrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitcreditMax)
                    : null,
                maxDrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitdebitMax)
                    : null,
                minBal:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitMinBalance)
                    : null,
                minCrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitcreditMin)
                    : null,
                minDrAmt:
                  limit.singlefee === "add_new"
                    ? parseFloat(limit.limitdebitMin)
                    : null,
                name: limit.singlefee === "add_new" ? limit.limitname : null,
                trnCodeExcl:
                  limit.singlefee === "add_new" ? limit.limitexcTranCode : null,
                trnCodeIncl:
                  limit.singlefee === "add_new" ? limit.limitincTranCode : null,
                violationAct:
                  limit.singlefee === "add_new"
                    ? parseInt(limit.limitviolationAction)
                    : null,
                violationFee:
                  limit.singlefee === "add_new"
                    ? limit.limitfeeForViolation
                    : null,
              })),
              componentName:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? values.componentName
                  : null,
              deminimisAmt:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? parseFloat(values.deminimisAmt)
                  : null,
              maxBal:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? parseFloat(values.maxBal)
                  : null,
              minBal:
                (values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                  values.accumTrnLimits.find(
                    (limit: any) => limit.selectLimitType === "add_new"
                  )) &&
                values.minimumBalance
                  ? parseFloat(values.minBal)
                  : null,
              minBalFee:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? values.minBalFee
                  : null,
              minBalOpt:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? parseFloat(values.minBalOpt)
                  : null,
              minToOpen:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? parseFloat(values.minToOpen)
                  : null,
              restrictCr:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? values.restrictCr
                  : undefined,
              restrictCrFundExp:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? values.restrictCrFundExp
                  : undefined,
              restrictDr:
                values.perTrnLimits.find(
                  (limit: any) => limit.singlefee === "add_new"
                ) ||
                values.accumTrnLimits.find(
                  (limit: any) => limit.selectLimitType === "add_new"
                )
                  ? values.restrictDr
                  : undefined,
            },
          ],
    };
  }

  let initialLimits: LimitConfigProps[] =
    props.data.formData?.rels?.[
      currEntriesIndex
    ]?.limit?.[0]?.accumTrnLimits.map((limit: any, idx: number) => ({
      idx: idx,
      _Id: limit._Id,
      selectLimitType: limit._Id?.length > 0 ? "leverage_existing" : "add_new",
      name: limit.name,
      definedBy: limit.definedBy,
      statGroup: limit.statGroup,
      startDtm: limit.startDtm ? new Date(limit.startDtm as string) : "",
      time: formatTime(limit?.startDtm, TIME_FORMAT.shortTime),
      crAmt: limit.crAmt,
      crCnt: limit.crCnt,
      drAmt: limit.drAmt,
      drCnt: limit.drCnt,
      totAmt: limit.totAmt,
      totCnt: limit.totCnt,
      violationAct: limit.violationAct,
      feeViolated:
        limit.voilationFee === undefined
          ? "selectRight"
          : limit.violationFee === false
            ? "selectRight"
            : "selectLeft",
      violationFee: limit.violationFee === "" ? "" : limit.violationFee,
      alertId: "",
    })) ?? [];

  let singleIntialLimits: SingleTransactionLimitConfigProps[] =
    props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]?.perTrnLimits.map(
      (limit: any, idx: number) => ({
        idx: idx,
        _Id: limit._Id,
        singlefee: limit._Id?.length > 0 ? "leverage_existing" : "add_new",
        definedBy: limit.definedBy,
        increCrAmt: limit.increCrAmt,
        increDrAmt: limit.increDrAmt,
        maxBal: limit.maxBal,
        maxCrAmt: limit.maxCrAmt,
        maxDrAmt: limit.maxDrAmt,
        minBal: limit.minBal,
        minCrAmt: limit.minCrAmt,
        minDrAmt: limit.minDrAmt,
        name: limit.name,
        trnCodeExcl: limit?.trnCodeExcl ?? [],
        violationAct: limit.violationAct,
        limitfeeViolated:
          limit.voilationFee === undefined
            ? ""
            : limit.violationFee === false
              ? "selectRight"
              : "selectLeft",
        violationFee: limit.violationFee === "" ? "" : limit.violationFee,
        singleAlertId: "",
      })
    ) ?? [];

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      addLimitComponent: props.data.formData?.rels?.[currEntriesIndex]?.limit
        ? props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
            ?.accumTrnLimits?.length > 0 ||
          props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
            ?.perTrnLimits?.length > 0
        : !shouldUpdateWorkflowStatus(
              entityWorkflowData! ?? [],
              getCurrentStageStatusName()
            )
          ? undefined
          : AddLimitConfig.addLimitComponent,
      accumTrnLimits: initialLimits,
      perTrnLimits: singleIntialLimits,
      componentName:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.componentName as string) || AddLimitConfig.componentName,
      minimumBalance: props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
        ?.minBal
        ? props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]?.minBal
        : !props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]?.minToOpen
          ? AddLimitConfig.minimumBalance
          : false,
      minBal:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.minBal as string) || AddLimitConfig.minBal,
      minToOpen:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.minToOpen as string) || AddLimitConfig.minToOpen,
      maxBal:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.maxBal as string) || AddLimitConfig.maxBal,
      deminimisAmt:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.deminimisAmt as string) || AddLimitConfig.deminimisAmt,
      restrictCrFundExp: props.data.formData?.rels?.[currEntriesIndex]
        ?.limit?.[0]?.restrictCrFundExp
        ? props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
            ?.restrictCrFundExp
        : AddLimitConfig.restrictCrFundExp,
      restrictCr: props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
        ?.restrictCr
        ? props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]?.restrictCr
        : AddLimitConfig.restrictCr,
      restrictDr: props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
        ?.restrictDr
        ? props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]?.restrictDr
        : AddLimitConfig.restrictDr,
      minBalFee:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.minBalFee as string) || AddLimitConfig.minBalFee,
      minBalOpt:
        (props.data.formData?.rels?.[currEntriesIndex]?.limit?.[0]
          ?.minBalOpt as string) || AddLimitConfig.minBalOpt,
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleChange = (key: string) => (value: any) => {
    if (key === "addLimitComponent") {
      setFieldValue(key, value).then(() => {
        setTouched({});
      });
    } else {
      setFieldValue(key, value);
    }
  };

  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`accumTrnLimits.${index}.${key}`, value);
  };

  const handleSingleLimitChange =
    (key: string, index: number) => (value: any) => {
      setFieldValue(`perTrnLimits.${index}.${key}`, value);
    };

  const schema = useMemo(() => {
    const { actions, name } = props.data.feeCalcDtlSchema;
    return { name, ...actions.POST };
  }, [props.data.feeCalcDtlSchema]);

  const addNewOption = (index: number) => async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addFeeCalcDtlEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`perTrnLimits.${index}.violationFee`, response.name);
  };

  const transactionschema = useMemo(() => {
    const { actions, name } = props.data.addNewTransactionSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewTransactionSchema]);

  const addNewTransactionOption =
    (index: number) => async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.addNewTransactionStatOptionEndpoint,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`accumTrnLimits.${index}.statGroup`, response.statGroup);
    };

  const addNewFeeCalcDtl = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addFeeCalcDtlEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("minBalFee", response.name);
  };

  const addNewViolationOption =
    (index: number) => async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.addFeeCalcDtlEndpoint,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`accumTrnLimits.${index}.violationFee`, response.name);
    };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [isGoingBack, canCheckFormStatus]);

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`perTrnLimits.${index}.${key}`, true);
  };

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
        id={MAIN_FORM_ID}
      >
        <CardContainer>
          <AppFormLabel
            labelName="Do you want to add a limit component?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("addLimitComponent")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="addLimitComponent"
            onSelect={handleChange("addLimitComponent")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.addLimitComponent}
            onBlur={handleBlur}
          />
          {errors.addLimitComponent && touched.addLimitComponent && (
            <ErrorAlert>
              <span>{errors.addLimitComponent}</span>
            </ErrorAlert>
          )}
        </CardContainer>
        {values.addLimitComponent && (
          <>
            <Box ref={refAccumulatedTransaction}>
              <FieldArray
                name="accumTrnLimits"
                render={({ insert, remove, push }) => (
                  <Box>
                    {values.accumTrnLimits?.map((limit, index) => (
                      <Box key={index} ref={activeAccordionRef}>
                        <CardContainer key={limit.idx}>
                          <AccordionComponent
                            accordionTitle={`Accumulated Transaction limit ${
                              index + 1
                            }`}
                            limitName={limit.name}
                            deleteIcon
                            onDeleteHandler={() => remove(index)}
                            isExpand={
                              values.accumTrnLimits.length !== index + 1 ? 1 : 0
                            }
                          >
                            <AccumulatedTransactionLimitForm
                              touched={touched}
                              errors={errors}
                              limit={limit}
                              index={index}
                              showLimitType={true}
                              definedByOptions={
                                props.data.definedByOptions || []
                              }
                              accumulationGroupOptions={
                                props.data.accumulationGroupOptions || []
                              }
                              violationActionOptions={
                                props.data.violationActionOptions || []
                              }
                              violationFeeOptions={
                                props.data.feeCalcDtlOptions || []
                              }
                              transactionCodes={
                                props.data.transactionCodes || []
                              }
                              accumTrnLimit={props.data.accumTrnLimit}
                              handleOnChange={handleOnChange}
                              transactionschema={transactionschema}
                              addNewTransactionOption={addNewTransactionOption}
                              schema={schema}
                              addNewViolationOption={addNewViolationOption}
                            />
                          </AccordionComponent>
                        </CardContainer>
                      </Box>
                    ))}
                    {values.addLimitComponent ? (
                      <Box className="add-limit-add-new-button">
                        <AddNewButton
                          label={
                            !values.accumTrnLimits.length
                              ? "Add an accumulated transaction limit"
                              : "Add another accumulated transaction limit"
                          }
                          onClick={() => {
                            push({ ...limitConfig, idx: Math.random() });
                            refAccumulatedTransaction.current?.scrollIntoView();
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                )}
              />
            </Box>
            <Box ref={refSingleTransaction}>
              <FieldArray
                name="perTrnLimits"
                render={({ insert, remove, push }) => (
                  <Box>
                    {values.perTrnLimits?.map((singlelimit, index) => (
                      <Box key={index} ref={activeSingleAccordionRef}>
                        <CardContainer key={singlelimit.idx}>
                          <AccordionComponent
                            accordionTitle={`Single transaction limit ${
                              index + 1
                            }`}
                            limitName={singlelimit.name}
                            deleteIcon
                            onDeleteHandler={() => remove(index)}
                            isExpand={
                              values.perTrnLimits.length !== index + 1 ? 1 : 0
                            }
                          >
                            <Box className="add-limit-bottom-spacing">
                              <AppFormLabel
                                labelName="How would you like to establish the limit?"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "singlefee"
                                  )?.tooltip_text_main
                                }
                                labelFor="acc_group_input"
                              />
                              <RadioButtonGroup
                                isBoxedRadio={true}
                                value={singlelimit.singlefee}
                                onChange={handleSingleLimitChange(
                                  "singlefee",
                                  index
                                )}
                                radioPropList={RadioSingleBtnProps}
                                stackDirection={"row"}
                                spacing={12}
                              />
                              {touched.perTrnLimits &&
                                touched.perTrnLimits[index]?.name &&
                                errors.perTrnLimits &&
                                (errors.perTrnLimits[index] as any)
                                  ?.singlefee && (
                                  <ErrorAlert>
                                    <span>
                                      {
                                        (errors.perTrnLimits[index] as any)
                                          ?.singlefee
                                      }
                                    </span>
                                  </ErrorAlert>
                                )}
                            </Box>
                            {singlelimit.singlefee === "leverage_existing" ? (
                              <Flex direction={"row"}>
                                <Divider
                                  orientation="vertical"
                                  className="dashed-left-border"
                                  height="72px"
                                />
                                <Box className="add-limit-accumulated-width">
                                  <AppFormLabel
                                    labelName="What is the unique single limit identifier"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "singleTrnLimit"
                                      )?.tooltip_text_main
                                    }
                                    labelFor="acc_group_input"
                                  />
                                  <SelectDropdown
                                    value={singlelimit._Id}
                                    onChange={handleSingleLimitChange(
                                      "_Id",
                                      index
                                    )}
                                    dropdownList={props.data.singleTrnLimit}
                                    id="violation_action"
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?._Id &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?._Id && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?._Id
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>
                              </Flex>
                            ) : singlelimit.singlefee === "add_new" ? (
                              <Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What is the limit name?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "name"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <InputText
                                    value={singlelimit.name}
                                    placeholder="Enter"
                                    onChange={handleSingleLimitChange(
                                      "name",
                                      index
                                    )}
                                    onBlur={handleOnBlur("name", index)}
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.name &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.name && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.name
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>

                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="Who is the limit defined by?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "definedBy"
                                      )?.tooltip_text_main
                                    }
                                  />

                                  <RadioButtonGroup
                                    value={singlelimit.definedBy}
                                    onChange={handleSingleLimitChange(
                                      "definedBy",
                                      index
                                    )}
                                    isBoxedRadio={true}
                                    radioPropList={props.data.definedByOptions}
                                    stackDirection="column"
                                    spacing={4}
                                    onBlur={handleOnBlur("definedBy", index)}
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.definedBy &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.definedBy && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.definedBy
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What is the minimum balance allowed before transaction limits are affected?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "minBal"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <Stack
                                    spacing={"var(--chakra-space-14)"}
                                    className="add-limit-stack-division"
                                  >
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Min"
                                        value={singlelimit.minBal}
                                        onChange={handleSingleLimitChange(
                                          "minBal",
                                          index
                                        )}
                                        onBlur={handleOnBlur("minBal", index)}
                                        placeholder="1"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.minBal &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.minBal && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.minBal
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                  </Stack>
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What is the maximum balance allowed?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "maxBal"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <Stack
                                    className="add-limit-stack-division"
                                    spacing={"var(--chakra-space-14)"}
                                  >
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Max"
                                        value={singlelimit.maxBal}
                                        onChange={handleSingleLimitChange(
                                          "maxBal",
                                          index
                                        )}
                                        onBlur={handleOnBlur("maxBal", index)}
                                        placeholder="1"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.maxBal &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.maxBal && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.maxBal
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                  </Stack>
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What are the minimum and maximum per transaction credit amounts?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "minMaxCrAmt"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <Stack
                                    className="add-limit-stack-division"
                                    spacing={"var(--chakra-space-14)"}
                                  >
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Min"
                                        value={singlelimit.minCrAmt}
                                        onChange={handleSingleLimitChange(
                                          "minCrAmt",
                                          index
                                        )}
                                        onBlur={handleOnBlur("minCrAmt", index)}
                                        placeholder="0"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.minCrAmt &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.minCrAmt && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.minCrAmt
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Max"
                                        value={singlelimit.maxCrAmt}
                                        onChange={handleSingleLimitChange(
                                          "maxCrAmt",
                                          index
                                        )}
                                        onBlur={handleOnBlur("maxCrAmt", index)}
                                        placeholder="0"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.maxCrAmt &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.maxCrAmt && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.maxCrAmt
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                  </Stack>
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What are the minimum and maximum per transaction debit amounts?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "minMaxDrAmt"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <Stack
                                    direction="row"
                                    width="60%"
                                    spacing={"var(--chakra-space-14)"}
                                  >
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Min"
                                        value={singlelimit.minDrAmt}
                                        onChange={handleSingleLimitChange(
                                          "minDrAmt",
                                          index
                                        )}
                                        onBlur={handleOnBlur("minDrAmt", index)}
                                        placeholder="0"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.minDrAmt &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.minDrAmt && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.minDrAmt
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                    <Box className="add-limit-flex-basis">
                                      <AppNumberInput
                                        labelName="Max"
                                        value={singlelimit.maxDrAmt}
                                        onChange={handleSingleLimitChange(
                                          "maxDrAmt",
                                          index
                                        )}
                                        onBlur={handleOnBlur("maxDrAmt", index)}
                                        placeholder="0"
                                      />
                                      {touched.perTrnLimits &&
                                        touched.perTrnLimits[index]?.maxDrAmt &&
                                        errors.perTrnLimits &&
                                        (errors.perTrnLimits[index] as any)
                                          ?.maxDrAmt && (
                                          <ErrorAlert>
                                            <span>
                                              {
                                                (
                                                  errors.perTrnLimits[
                                                    index
                                                  ] as any
                                                )?.maxDrAmt
                                              }
                                            </span>
                                          </ErrorAlert>
                                        )}
                                    </Box>
                                  </Stack>
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What is the incremental per transaction debit amount?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "increDrAmt"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <AppNumberInput
                                    value={singlelimit.increDrAmt}
                                    onChange={handleSingleLimitChange(
                                      "increDrAmt",
                                      index
                                    )}
                                    onBlur={handleOnBlur("increDrAmt", index)}
                                    placeholder="1"
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.increDrAmt &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.increDrAmt && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.increDrAmt
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What is the incremental per transaction credit amount?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "increCrAmt"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <AppNumberInput
                                    value={singlelimit.increCrAmt}
                                    onChange={handleSingleLimitChange(
                                      "increCrAmt",
                                      index
                                    )}
                                    onBlur={handleOnBlur("increCrAmt", index)}
                                    placeholder="1"
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.increCrAmt &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.increCrAmt && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.increCrAmt
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>

                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="If violated, what is the violation action?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "violationAct"
                                      )?.tooltip_text_main
                                    }
                                    labelFor="violation_action"
                                  />
                                  <SelectDropdown
                                    value={singlelimit.violationAct}
                                    onChange={handleSingleLimitChange(
                                      "violationAct",
                                      index
                                    )}
                                    dropdownList={
                                      props.data.violationActionOptions
                                    }
                                    onBlur={handleOnBlur("violationAct", index)}
                                    id="violation_action"
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.violationAct &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.violationAct && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.violationAct
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="Do you want to charge a fee for violating the single transaction limit?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "addLimitFee"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <SplitButton
                                    value={singlelimit.limitfeeViolated}
                                    onSelect={handleSingleLimitChange(
                                      "limitfeeViolated",
                                      index
                                    )}
                                    leftBtnName="Yes"
                                    rightBtnName="No"
                                    onBlur={handleOnBlur(
                                      "limitfeeViolated",
                                      index
                                    )}
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]
                                      ?.limitfeeViolated &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.limitfeeViolated && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.limitfeeViolated
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>

                                {singlelimit.limitfeeViolated && (
                                  <Box className="app-form-field-container">
                                    <AppFormLabel
                                      labelName="What is the fee for a violation?"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "violationFee"
                                        )?.tooltip_text_main
                                      }
                                      labelFor="violation"
                                    />
                                    <CoupleRadioWithDropdown
                                      primaryRadioName="Leverage existing fee"
                                      secondaryRadioName="Add new fee"
                                      primaryOptionValue={
                                        singlelimit.violationFee
                                      }
                                      handlePrimaryOptionSelect={handleSingleLimitChange(
                                        "violationFee",
                                        index
                                      )}
                                      placeHolderName="Select"
                                      dropdownList={
                                        props.data.feeCalcDtlOptions
                                      }
                                      onBlur={handleOnBlur(
                                        "feeForViolation",
                                        index
                                      )}
                                      id="violation"
                                      schema={schema}
                                      uiSchema={getFeeCalcDtlUiSchema()}
                                      unRequire={feeCalcDtlUnRequire}
                                      onAddNewSubmit={addNewOption(index)}
                                    />
                                    {touched.perTrnLimits &&
                                      touched.perTrnLimits[index]
                                        ?.violationFee &&
                                      errors.perTrnLimits &&
                                      (errors.perTrnLimits[index] as any)
                                        ?.violationFee && (
                                        <ErrorAlert>
                                          <span>
                                            {
                                              (
                                                errors.perTrnLimits[
                                                  index
                                                ] as any
                                              )?.violationFee
                                            }
                                          </span>
                                        </ErrorAlert>
                                      )}
                                  </Box>
                                )}
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="What transaction codes are excluded?"
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "trnCodeExcl"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <ModalSelector
                                    linkName="+ Select transaction code(s)"
                                    modalTitle="Select the transaction code"
                                    optionList={deepCopy(
                                      props.data.transactionCodes
                                    )}
                                    onChange={handleSingleLimitChange(
                                      "trnCodeExcl",
                                      index
                                    )}
                                    isSingleSelect={false}
                                    value={singlelimit?.trnCodeExcl || []}
                                  />
                                  {touched.perTrnLimits &&
                                    touched.perTrnLimits[index]?.trnCodeExcl &&
                                    errors.perTrnLimits &&
                                    (errors.perTrnLimits[index] as any)
                                      ?.trnCodeExcl && (
                                      <ErrorAlert>
                                        <span>
                                          {
                                            (errors.perTrnLimits[index] as any)
                                              ?.trnCodeExcl
                                          }
                                        </span>
                                      </ErrorAlert>
                                    )}
                                </Box>
                              </Box>
                            ) : null}
                          </AccordionComponent>
                        </CardContainer>
                      </Box>
                    ))}
                    {values.addLimitComponent ? (
                      <Box className="add-limit-add-new-button">
                        <AddNewButton
                          label={
                            !values.perTrnLimits.length
                              ? "Add a single transaction limit"
                              : "Add another single transaction limit"
                          }
                          onClick={() => {
                            push({
                              ...singleTransactionLimitConfig,
                              idx: Math.random(),
                            });
                            activeSingleAccordionRef.current?.scrollIntoView();
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                )}
              ></FieldArray>
            </Box>
          </>
        )}
        {(values.accumTrnLimits.some(
          (limit) => limit.selectLimitType === "add_new"
        ) ||
          values.perTrnLimits.some(
            (singleLimit: any) => singleLimit.singlefee === "add_new"
          )) &&
          values.addLimitComponent && (
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as={"h4"}>
                  {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_title || "General Component Setup"}{" "}
                </Heading>
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                      ?.section_subhead || ""
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the component name?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.componentName}
                  onChange={handleChange("componentName")}
                  name="featureName"
                  placeholder="[Component_name]"
                />
                {errors.componentName && touched.componentName && (
                  <ErrorAlert>
                    <span>{errors.componentName}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Version"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("version")
                      ?.tooltip_text_main
                  }
                />

                <StaticText textValue="1" />
              </Box>
              <Box className="app-form-field-container">
                <Heading as={"h4"}>
                  {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                    ?.section_title || "Account Balance Configuration"}{" "}
                </Heading>
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                      ?.section_subhead || ""
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Do you want to require a minimum balance?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("addMinBal")
                      ?.tooltip_text_main
                  }
                />
                <SplitButton
                  name="minimumBalance"
                  onSelect={handleChange("minimumBalance")}
                  leftBtnName="Yes"
                  rightBtnName="No"
                  value={values.minimumBalance}
                  onBlur={handleBlur}
                />
                {errors.minimumBalance && touched.minimumBalance && (
                  <ErrorAlert>
                    <span>{errors.minimumBalance as string}</span>
                  </ErrorAlert>
                )}
              </Box>
              {values.minimumBalance && (
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="What is the minimum required balance?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("minBal")
                        ?.tooltip_text_main
                    }
                  />
                  <Box className="input-width-small">
                    <AppNumberInput
                      value={values.minBal}
                      onChange={handleChange("minBal")}
                      placeholder="0"
                    />
                  </Box>
                  {errors.minBal && touched.minBal && (
                    <ErrorAlert>
                      <span>{errors.minBal}</span>
                    </ErrorAlert>
                  )}
                </Box>
              )}
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the minimum amount to open?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("minToOpen")
                      ?.tooltip_text_main
                  }
                />
                <Box className="input-width-small">
                  <AppNumberInput
                    value={values.minToOpen}
                    onChange={handleChange("minToOpen")}
                    placeholder="0"
                  />
                </Box>
                {errors.minToOpen && touched.minToOpen && (
                  <ErrorAlert>
                    <span>{errors.minToOpen}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the maximum balance?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("maxBal")
                      ?.tooltip_text_main
                  }
                />
                <Box className="input-width-small">
                  <AppNumberInput
                    value={values.maxBal}
                    onChange={handleChange("maxBal")}
                    placeholder="0"
                  />
                </Box>
                {errors.maxBal && touched.maxBal && (
                  <ErrorAlert>
                    <span>{errors.maxBal}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the de minimis amount?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("deminimisAmt")
                      ?.tooltip_text_main
                  }
                />
                <Box className="input-width-small">
                  <AppNumberInput
                    value={values.deminimisAmt}
                    onChange={handleChange("deminimisAmt")}
                    placeholder="0"
                  />
                </Box>
                {errors.deminimisAmt && touched.deminimisAmt && (
                  <ErrorAlert>
                    <span>{errors.deminimisAmt}</span>
                  </ErrorAlert>
                )}
              </Box>

              <Box className="app-form-field-container">
                <Heading as={"h4"}>
                  {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                    ?.section_title || "Minimum Balance Fee Details"}{" "}
                </Heading>
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                      ?.section_subhead || ""
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the minimum balance fee?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("minBalFee")
                      ?.tooltip_text_main
                  }
                  labelFor="minimum_balance_fee_dropdown"
                />
                <CoupleRadioWithDropdown
                  id="minimum_balance_fee_dropdown"
                  primaryOptionValue={values.minBalFee}
                  primaryRadioName="Leverage existing fee"
                  secondaryRadioName="Create new fee"
                  handlePrimaryOptionSelect={handleChange("minBalFee")}
                  placeHolderName="Select existing fee"
                  dropdownList={props.data.feeCalcDtlOptions}
                  onBlur={handleBlur}
                  schema={schema}
                  uiSchema={getFeeCalcDtlUiSchema()}
                  unRequire={feeCalcDtlUnRequire}
                  onAddNewSubmit={addNewFeeCalcDtl}
                />
                {errors.minBalFee && touched.minBalFee && (
                  <ErrorAlert>
                    <span>{errors.minBalFee}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the minimum balance option?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("minBalOpt")
                      ?.tooltip_text_main
                  }
                />

                <RadioButtonGroup
                  value={values.minBalOpt}
                  onChange={handleChange("minBalOpt")}
                  isBoxedRadio={true}
                  radioPropList={props.data?.minimumBalanceOption}
                  stackDirection="row"
                  spacing={4}
                />
                {errors.minBalOpt && touched.minBalOpt && (
                  <ErrorAlert>
                    <span>{errors.minBalOpt}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <Heading as={"h4"}>
                  {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                    ?.section_title || "Restriction Configuration"}{" "}
                </Heading>
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                      ?.section_subhead || ""
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <Table className="table" variant="unstyled" borderRadius="2xl">
                  <Thead className="custom-table-header">
                    <Tr className="custom-table-tr-fedwire">
                      <Th className="fedwire-left-custom-table-tr">
                        <Text fontSize="xl">Enable any of the following</Text>
                      </Th>
                      <Th className="fedwire-right-custom-table-tr">
                        <Text className="fedwire-custom-table-right-header">
                          heading-text
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr className="custom-table-data-row">
                      <Td className="custom-table-padding-appLabel">
                        <AppFormLabel
                          labelName={
                            "Do you want to restrict credits after funding expiration?"
                          }
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "restrictCrFundExp"
                            )?.tooltip_text_main
                          }
                        />
                      </Td>
                      <Td className="custom-table-padding-splitButton">
                        <SplitButton
                          name="restrictCrFundExp"
                          onSelect={handleChange("restrictCrFundExp")}
                          leftBtnName="Yes"
                          rightBtnName="No"
                          value={values.restrictCrFundExp}
                        />
                        {errors.restrictCrFundExp &&
                          touched.restrictCrFundExp && (
                            <ErrorAlert>
                              <span>{errors.restrictCrFundExp as string}</span>
                            </ErrorAlert>
                          )}
                      </Td>
                    </Tr>
                    <Tr className="custom-table-data-row">
                      <Td className="custom-table-padding-appLabel">
                        <AppFormLabel
                          labelName={"Do you want to restrict all credits?"}
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("restrictCr")
                              ?.tooltip_text_main
                          }
                        />
                      </Td>
                      <Td className="custom-table-padding-splitButton">
                        <SplitButton
                          name="restrictCr"
                          onSelect={handleChange("restrictCr")}
                          leftBtnName="Yes"
                          rightBtnName="No"
                          value={values.restrictCr}
                        />
                        {errors.restrictCr && touched.restrictCr && (
                          <ErrorAlert>
                            <span>{errors.restrictCr as string}</span>
                          </ErrorAlert>
                        )}
                      </Td>
                    </Tr>
                    <Tr className="custom-table-data-last-row">
                      <Td className="custom-table-padding-appLabel">
                        <AppFormLabel
                          labelName={"Do you want to restrict all debits?"}
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("restrictDr")
                              ?.tooltip_text_main
                          }
                        />
                      </Td>
                      <Td className="custom-table-padding-splitButton">
                        <SplitButton
                          name="restrictDr"
                          onSelect={handleChange("restrictDr")}
                          leftBtnName="Yes"
                          rightBtnName="No"
                          value={values.restrictDr}
                        />
                        {errors.restrictDr && touched.restrictDr && (
                          <ErrorAlert>
                            <span>{errors.restrictDr as string}</span>
                          </ErrorAlert>
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </CardContainer>
          )}
      </form>
    </FormikProvider>
  );
};

export default AddLimit;
