"use client";

import { Box, Text } from "@chakra-ui/react";
import "./CertificationStatus.scss";
import { bookmarkIcon, bookmarkedIcon, shareIcon } from "public/assets";
import { addBookmark, deleteBookmark } from "@/client-api-manager/alm-api";
import { useState } from "react";
import ActionButton from "../action-button/ActionButton";
import CourseValidityText from "../course-validity-text/CourseValidityText";
import { usePathname, useSearchParams } from "next/navigation";
import { safelyFetchDomain } from "@/utils";

type CertificationStatusProps = {
  id: string;
  title: string;
  bookmark: boolean | undefined;
  hasPassed: boolean;
  dateCompleted: string | undefined;
  validity: string;
};

export default function CertificationStatus({
  id,
  title,
  bookmark,
  hasPassed,
  dateCompleted,
  validity,
}: CertificationStatusProps) {
  const routePathName = usePathname();
  const routeSearchParams = useSearchParams()
  const domain = safelyFetchDomain()
  
  const [bookmarkState, toggleBookmarkState] = useState<Boolean>(
    bookmark ?? false
  );

  const bookmarkProgram = () => {
    bookmarkState ? deleteBookmark(id) : addBookmark(id);
    toggleBookmarkState((prevState) => !prevState);
  };

  const shareProgram = () => {
    window.open(
      `mailto:?subject=Certification - ${title}&body=Certification link - https://${domain}${routePathName}?${routeSearchParams?.toString()}`
    );
  };

  return (
    <Box className="certificate-status-container" w={288} borderRadius={16}>
      <Box className="certificate-status-completion" p={12}>
        <Box className="action-button-holder">
          {hasPassed && (
            <ActionButton
              name="Share certification"
              icon={shareIcon}
              onClick={shareProgram}
              isSrcIcon={true}
            />
          )}
          <ActionButton
            name={bookmarkState ? "Saved" : "Save certification"}
            icon={bookmarkState ? bookmarkedIcon : bookmarkIcon}
            onClick={bookmarkProgram}
            isSrcIcon={true}
          />
        </Box>
      </Box>
      <Box className="certificate-validity-holder" p={12}>
        <Text as="h5" className="certificate-validity-intro">
          Course validity
        </Text>
        <CourseValidityText
          hasPassed={hasPassed}
          validity={validity}
          dateCompleted={dateCompleted}
        />
      </Box>
    </Box>
  );
}
