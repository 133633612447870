"use client";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DurationInput,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { TERM_MATURITY_CONFIGURATION } from "@/components/data/component-config-label";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  MaturityConfigurationSetupProps,
  getValidationSchema,
} from "./TermMaturityConfigurationValidation";
import { createTermComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  Option,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import {
  PostSchema,
  TermComponentData,
} from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

const {
  WHAT_IS_PROMOTIONAL_RATE_TERM,
  WHAT_RATE_ADJUSTMENT_TERM,
  WHAT_IS_THE_MATURITY_OPTION,
} = TERM_MATURITY_CONFIGURATION;

type Props = {
  data: {
    formData: TermComponentData;
    maturityOptions: Option<string>[];
    termComponentOptionsData: PostSchema;
  };
};

const TermMaturityConfiguration = (props: Props) => {
  const toast = useToast();

  const onSubmit = async (
    values: MaturityConfigurationSetupProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["term"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData) {
      if (productDetails) {
        const previousMaturityOption = formData?.maturityOpt;
        const newMaturityOption = values.maturityOpt;
        const res = await createTermComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            notice: values.notice,
            maturityOpt: values.maturityOpt,
            rollProd:
              previousMaturityOption === newMaturityOption
                ? formData.rollProd
                : null,
          },
          toastOptions: {
            toast,
            toastId: "mat-config",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.termComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.termComponentOptionsData]);

  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    useFormik({
      onSubmit,
      validationSchema,
      initialValues: {
        maturityOpt: props.data.formData?.maturityOpt ?? "",
        notice: props.data.formData?.notice ?? "",
      },
    });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const termMaturityConfigurationFormHandler =
    (key: string) => (value: string | number | boolean) => {
      setFieldValue(key, value);
    };
  //Handling Form
  const transactionsCodeSpecificationFormHandler =
    (key: string) => (value: any[]) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className={"app-form-field-container"}>
          <DurationInput
            labelName={WHAT_IS_PROMOTIONAL_RATE_TERM}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("notice")
                ?.tooltip_text_main
            }
            value={values.notice}
            onChange={termMaturityConfigurationFormHandler("notice")}
            modalLinkName={"Enter code manually"}
            modalTitle={WHAT_RATE_ADJUSTMENT_TERM}
            modalLabelName={"Enter adjustment term"}
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("notice")
                ?.tooltip_text_main
            }
          />

          {errors.notice && touched.notice && (
            <ErrorAlert>
              <span>{errors.notice}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className={"app-form-field-container"}>
          <AppFormLabel
            labelName={WHAT_IS_THE_MATURITY_OPTION}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maturityOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            value={values.maturityOpt}
            onChange={termMaturityConfigurationFormHandler("maturityOpt")}
            radioPropList={props.data.maturityOptions}
            stackDirection={"column"}
            spacing={"0.8rem"}
            isBoxedRadio={true}
          />
          {errors.maturityOpt && touched.maturityOpt && (
            <ErrorAlert>
              <span>{errors.maturityOpt}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default TermMaturityConfiguration;
