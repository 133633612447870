"use client";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ACADEMY_SIDEBAR_MENU_MULTI } from "@/data/sidebar-menu";
import { usePathname } from "next/navigation";
import { MultiTieredSideMenu } from "@/components/common";
import { MultiTieredSideMenuFormat } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenu";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { ROUTE_BASE_URL } from "@/route-config/route-path";

interface GlobalSideMenuProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const AcademySideMenu = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
}: GlobalSideMenuProps) => {
  const pathName: any = usePathname()?.replace(/\/$/, "") ?? null;
  const [selectedElements, setSelectedElements] = useState<
    MultiTieredSideMenuDataModel[]
  >([]);

  const [selectedUID, setSelectedUID] = useState(pathName);

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | null
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };

  useEffect(() => {
    const items = handleSelection(
      selectedElements.length === 0
        ? ACADEMY_SIDEBAR_MENU_MULTI
        : selectedElements,
      selectedUID
    );
    setSelectedElements([...items]);
  }, [selectedUID]);

  useEffect(() => {
    //Selecting default sidebar if user selects academy from topmenu bar
    if (pathName === ROUTE_BASE_URL.ACADEMY && pathName !== selectedUID) {
      setSelectedUID(ROUTE_BASE_URL.ACADEMY);
    }
  }, [pathName]);

  return (
    <MultiTieredSideMenu
      selectedElements={selectedElements}
      setSelectedElements={setSelectedElements}
      isSideMenuOpen={isSideMenuOpen}
      setIsSideMenuOpen={setIsSideMenuOpen}
      format={MultiTieredSideMenuFormat.academy}
      showFooter={false}
      setSelectedUID={setSelectedUID}
      selectedUID={selectedUID}
    />
  );
};

export default AcademySideMenu;
