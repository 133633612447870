import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface UsFinancialInstitutionParametersInformationProps {
  cbInst: number;
  cbInstId: string;
}

const defaultUsFinancialInstitutionParametersInformation: UsFinancialInstitutionParametersInformationProps =
  {
    cbInst: -1,
    cbInstId: "",
  };

const usFinancialInstitutionParametersInformationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["bankparamUSBankInfo"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig) as any;
};
// Exporting Interface
export type { UsFinancialInstitutionParametersInformationProps };
// Exporting Validation Schema and Intial value
export {
  defaultUsFinancialInstitutionParametersInformation,
  usFinancialInstitutionParametersInformationSchema as getValidationSchema,
};
